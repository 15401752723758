import React, { useState, useEffect } from 'react';
import BlockTime from './BlockTime';

const SelectSchedule = ({schedules=[],day, deleteSchedule, changeSchedule, sameCampus}) => {

    const [times,setTimes] = useState([]);

    const getTimes = schedule => {
        let hour = 6,
            minutes = 0,
            increment = 5;
        const times = [];
        // while(`${hour}:${minutes}` !== '23:30'){
        while(`${hour}:${minutes}` !== '23:5'){    
            let time = `${hour<10?'0'+hour:hour}:${minutes===0?'00':minutes<10?'0'+minutes:minutes}`,
                formatedTime = hour<=12?hour:hour-12,
                full = false;
                formatedTime = `${formatedTime<10?'0'+formatedTime:formatedTime}:${minutes===0?'00':minutes<10?'0'+minutes:minutes} ${hour<=11?'am':'pm'}`;
                schedule.forEach( sch =>{
                    const HStart = parseInt(sch.from.replace(/:/g,'')),
                          HEnd = parseInt(sch.to.replace(/:/g,''));
                    if (parseInt(time.replace(/:/g,'')) >= HStart && parseInt(time.replace(/:/g,'')) <= HEnd)
                        full = true            
                });
            times.push({ time: time, formatedTime: formatedTime, full: full });    
            /*if (minutes===30){
                hour +=1;
                minutes=0;
            }
            else{
                minutes = 30;
            }*/
            if (minutes===55) {
                minutes=0;
                hour +=1;
            } else {
                minutes = parseInt(minutes)+increment;
            }
            full = false;
        }
        return times;
    }

    const handleSchedule = async (e,last) => {
        await changeSchedule(e,last);
        await setTimes(getTimes(schedules));
    }

    useEffect( () =>{
        setTimes(getTimes(schedules));
    },[schedules.length]);

    return <React.Fragment>
        {schedules.map( (schedule,index) => {
           let last = schedules.filter(time => parseInt(time.to.replace(/:/g, '')) <= parseInt(schedule.from.replace(/:/g,'')));
           let next = schedules.filter(time => parseInt(time.from.replace(/:/g, '')) >= parseInt(schedule.to.replace(/:/g,'')));
               last = last.sort( (a,b) =>{
                  return parseInt(a.from.replace(/:/g,'')) - parseInt(b.from.replace(/:/g,''))
               });
               next = next.sort( (a,b) =>{
                  return parseInt(a.from.replace(/:/g,'')) - parseInt(b.from.replace(/:/g,''))
               });
           last = last.length>0?parseInt(last[last.length-1].to.replace(/:/g, '')):0;
           // next = next.length>0?parseInt(next[0].from.replace(/:/g, '')):2330;
           next = next.length>0?parseInt(next[0].from.replace(/:/g, '')):2305;

           return <BlockTime blockTime={schedule}
                             laxtS={last}
                             nextS={next}
                             times={times} 
                             day={day} 
                             index={index} 
                             deleteSchedule={deleteSchedule} 
                             changeSchedule={handleSchedule} 
                             key={index}
                             sameCampus={sameCampus}
                              />
        })}
    </React.Fragment>
   
}

export default SelectSchedule;