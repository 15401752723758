import React, { useState, useEffect } from 'react';
import { Box, TextField, InputLabel, Select, MenuItem, Button } from '@material-ui/core';
import { SketchPicker } from 'react-color';
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMessage, statusMessage } from '../../../redux/actions/global';
// Calendar
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
//import enLocale from "date-fns/locale/en-US";
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import ReactCrop from 'react-image-crop';

import hexToRgba from 'hex-to-rgba';

// handle of languages
import { Translation } from 'react-i18next';

// Models
import UserProfile from "../../../models/UserProfile";

const userProfile = new UserProfile();

const ModalForm = ({ status, state, changeStatus, color, newToEdit, updateNew, saveNew, getMessage, statusMessage }) => {

    const [warning,getWarning] = useState('');

	const [dataNew,setDataNew] = useState({
		link: '',
		title: '',
		subtitle: '',
		status: state.status,
		publishDate: new Date(),
		color: color
	});

	const [imgNew, setImgNew] = useState({
		file: '',
		url: ''
	});

	const [img,setImg] = useState({
		file: '',
		url: ''
	});
	const [imgTag,setImgTag] = useState(''); 

	const [statusColorPicket,setStatusColorPicket] = useState(false);

    const [heightContentImg,setHeightContentImg] = useState(140);

	const [crop, setCrop] = useState({
		unit: 'px',
		x: 0,
		y: 0,
		width: 320,
        height: 152
	});

	const [inputsEmpty, setInputsEmpty] = useState(false);

	const changeWarning = tr => {
        getWarning(tr('news.modal.warning'))
	}

	const handleCrop = cp => {
		setCrop(cp);
	}

	const handleInput = (input,val) => {
        setDataNew({ ...dataNew, [input] : val });
	}

	const handleDate = (input, date: Date | null) => { // Change Date
        setDataNew({ ...dataNew, [input]: date });
    }

    const handleImg = (img) => { // Fuction to get image of input file
    	if (img.files.length > 0) {
    	    img = { url : URL.createObjectURL(img.files[0]), name: img.files[0].name, file: img.files[0] }
            setImg(img);
        }
    }

    const handleCloseModal = () => {
        changeStatus(false);     
        setInputsEmpty(false);
        setStatusColorPicket(false);
        setImgNew({ ...imgNew, file: '', url : '' });
        setImgTag('');
        setImg('');
        setDataNew({ ...dataNew, link: '', title : '', subtitle : '', publishDate : new Date(), color : color });
    }

    const handleImgTag = async img => { // Change crop to change imgTag
    	await setImgTag(img);
    	const newCrop = await getsizeCrop();
    	await setCrop({ ...newCrop, x : 0, y : 0});
    	await makeClientCrop({ ...newCrop, x : 0, y : 0},img)
    }

    const getCroppedImg = (image,crop) => { // Cut and create a new image
    	if (image.naturalWidth % 320 ===0 && image.naturalHeight % 152 === 0) {
            let dat = new FormData();
        	    dat.append('noticePhoto', img.file, img.name);
        	setImgTag(''); 
        	setImg('');   
            setImgNew({ ...imgNew, url : img.url, file : dat });
    	}else {
            const canvas = document.createElement("canvas");
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = 640;
            canvas.height = 304;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                canvas.width,
                canvas.height
            );

            new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                      //reject(new Error('Canvas is empty'));
                      console.error("Canvas is empty");
                      return;
                    }
                    window.URL.revokeObjectURL(imgNew.url);
                    const url = window.URL.createObjectURL(blob),
                          file = new File([blob], img.name, { type: "image/"+img.name.split('.').pop() });

                    let dat = new FormData();
                        dat.append('noticePhoto', file, file.name);

                    setImgNew({ ...imgNew, url : url, file: dat });
        
                });
            });
        }
    }

    const makeClientCrop = async (crop,img) => { 
    	const image = img?img:imgTag;
        if (image && crop.width && crop.height) {
            await getCroppedImg(image,crop);
        }
    }

    const sendForm = (e) => { // action to submit form
    	e.preventDefault();
    	 let formDatas = Object.values({...dataNew, url : imgNew.url}); // get form datas how array
    	     formDatas = formDatas.filter(data => data === ''); // get empty inputs
    	     
         if (formDatas.length === 0) { // if all inputs are full do somethink
            setInputsEmpty(false);
    	    let formatDate = dataNew.publishDate,
    	        year = formatDate.getFullYear(),
    	        month = formatDate.getMonth() + 1,
    	        day = formatDate.getDate(),
                hour = formatDate.getHours(),
                minutes = formatDate.getMinutes(),
                seconds = formatDate.getSeconds();

    	        formatDate = year+'-'+(month<10?'0'+month:month)+'-'+(day<10?'0'+day:day)+' '+(hour<10?'0'+hour:hour)+':'+(minutes<10?'0'+minutes:minutes)+':'+(seconds<10?'0'+seconds:seconds);

    	    if (state.new !== '')
    		    updateNew(userProfile.getToken(), { ...dataNew, id : state.new.id, publishDate : formatDate }, imgNew);
    	    else
    		    saveNew(userProfile.getToken(), { ...dataNew, publishDate : formatDate }, imgNew);

        }else{ // set message if one or more inputs are empty
        	setInputsEmpty(true);
            getMessage(warning,'warning');
            statusMessage(true);
        }
    }

    const getsizeCrop = () => {
        const contentImg = document.getElementsByClassName('ReactCrop')[0],
    	      cropW = ( (contentImg.offsetWidth * 100 / 320) / 100),
    	      cropH = ( (contentImg.offsetHeight * 100 / 152) / 100);

        setHeightContentImg(152 * cropW);

    	if (cropH<cropW){
    	    return { ...crop, width: (320 * cropH), height: (152 * cropH) }
    	}
    	else{
    		return { ...crop, width: (320 * cropW), height: (152 * cropW) }
    	}
    	      
    }

    const changeSizeCrop = () => {
    	const size = getsizeCrop();
    	setCrop(size);
    }

    useEffect( () => {
    	if (state.new !== '' && status) {
            setDataNew({ ...dataNew, link: state.new.link, title : state.new.title, subtitle : state.new.subtitle, status: state.status, publishDate : new Date(state.new.pd.replace(' ','T')), color : state.new.color });
    	}
    	if (state.new.picture  && status){
    		setImgNew({ ...imgNew, url: state.new.picture });
    	}
    },[state.news,state.new]);

    useEffect( () => {
    	setDataNew({ ...dataNew, status: state.status});
    },[state.status]);

    useEffect( () => {
    	if (!status)
    		handleCloseModal();
    },[status]);

    useEffect( () => {
        window.addEventListener('resize', changeSizeCrop);
        const content = document.getElementsByClassName('input-file')[0],
              cropW = ( (content.offsetWidth * 100 / 320) / 100);
        setHeightContentImg(152 * cropW);      
    	return () => {
    	    window.removeEventListener('resize', changeSizeCrop);
    	}
    },[]);
    
    return <Translation>
        { 
            (t, { i18n }) => {
            	changeWarning(t);
                return <Box className={ status?'show modal-form':'modal-form' } position="absolute" display="flex" justifyContent="center" zIndex="modal" width={1} height={1}>
                    <Box className="colorBlur" position="fixed" left={0} right={0} top={0} bottom={0} bgcolor="rgba(0,0,0,0.7)" onClick={ () => handleCloseModal() }></Box>
                    <Box className="content-modal" width="90%" height="100%" maxWidth={950} position="absolute" zIndex="modal" bgcolor="#fff">
        	            {/* ============== Title ============= */}
    	                <Box width={1} pt={6} display="flex" flexWrap="wrap" justifyContent="center" className="form-title" pb={4} style={{ background: color }}>
    	                    <Box width={1} maxWidth={800} position="relative">
    	                        <Box component="h3" fontWeight={500} fontSize={23} pl={4} pr={4} mt={0}>{t('news.modal.title')}</Box>
    	                        <Box component="p" fontWeight={300} fontSize={18} pl={4} pr={4} m={0}>{t('news.modal.subtitle')}</Box>
    	                        <Box fontSize="24px" component="i" className="btn-close icon-cross2" onClick={ () => handleCloseModal() }></Box>
    	                    </Box>
    	                </Box>
                        {/* ============== Form ============= */}
                        <Box id="content-form" width={1} display="flex" flexWrap="wrap" justifyContent="center">
                            <Box width={1} maxWidth={800} pl={4} pr={4} pt={4} component="form" display="flex" flexWrap="wrap" onSubmit={ e => sendForm(e) }> 
        	                    <Box width={{ xs : 1, sm : 1/2 }} order={{ xs : 1, sm : 0 }} position="relative">
        	                        <Box component="h4" fontWeight={500} fontSize={16} mb={8} display="inline-block" borderBottom={ '3px solid '+color }>{t('news.modal.contentTitle')}</Box>
        		                    <TextField
        		                        error={dataNew.title===''&&inputsEmpty?true:false}
                                        placeholder={t('news.modal.form.title.placeholder')}
                                        label={t('news.modal.form.title.label')}
                                        className="input-form"
                                        value={dataNew.title}
                                        onChange={ e => handleInput('title',e.target.value)}
                                        margin="normal"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{maxLength: 30}}
                                    />
                                    <TextField
                                        error={dataNew.subtitle===''&&inputsEmpty?true:false}
                                        placeholder={t('news.modal.form.subtitle.placeholder')}
                                        label={t('news.modal.form.subtitle.label')}
                                        className="input-form"
                                        multiline
                                        maxRows="3"
                                        value={dataNew.subtitle}
                                        onChange={ e => handleInput('subtitle',e.target.value)}
                                        margin="normal"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{maxLength: 70}}
                                        style={{ maxHeight: 'initial' }}
                                    />
                                    <TextField
                                        error={dataNew.link===''&&inputsEmpty?true:false}
                                        placeholder={t('news.modal.form.link.placeholder')}
                                        label={t('news.modal.form.link.label')}
                                        className="input-form"
                                        type="url"
                                        value={dataNew.link}
                                        onChange={ e => handleInput('link',e.target.value)}
                                        margin="normal"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{maxLength: 255}}
                                    />
                                    <InputLabel shrink htmlFor="age-label-placeholder">{t('news.modal.form.status.label')}</InputLabel>
                                    <Select
                                        onChange={ e => handleInput('status',e.target.value)}
                                        value={dataNew.status}
                                        name="age"
                                        className="input-form"
                                    >
                                        <MenuItem value='Activo'>{t('news.modal.form.status.options.opc1')}</MenuItem>
                                        <MenuItem value='Inactivo'>{t('news.modal.form.status.options.opc2')}</MenuItem>
                                    </Select>

                                    {/* ============== Date and hour ============ */}
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ i18n.language==='en'?enLocale:esLocale }>
                                        <Box display="flex" flexWrap="wrap">
                                            <Box width={{ xs : 1, md : 1/2 }} pr={{ xs : 0, md : 2 }}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    value={dataNew.publishDate}
                                                    onChange={ e => handleDate('publishDate',e)}
                                                    label={t('news.modal.form.date')}
                                                    className="date-picker"
                                                    format={t('dateFormat')}
                                                />            
                                            </Box>
                                            <Box width={{ xs : 1, md : 1/2 }} pl={{ xs : 0, md : 2 }}>
                                                <KeyboardTimePicker
                                                    margin="normal"
                                                    value={dataNew.publishDate}
                                                    onChange={ e => handleDate('publishDate',e)}
                                                    label={t('news.modal.form.hour')}
                                                    className="time-picker"
                                                    format="hh:mm"
                                                />
                                            </Box>      
                                        </Box>
                                    </MuiPickersUtilsProvider>
                                    {/* ============== Color ============ */}
                                    <InputLabel shrink htmlFor="time-label-placeholder">{t('news.modal.form.color')}</InputLabel>
                                    <Box mb={6} mt={1} display="flex" className="color-picket" borderRadius="4px" onClick={() => setStatusColorPicket(!statusColorPicket)}>
                                        <Box position="relative" className="btn-color" flexGrow={1} height={32} width={1} maxWidth={60} bgcolor={dataNew.color}>
                                            {statusColorPicket?<Box className="icon-chevron-up chevron" color="#fff" component="i"></Box>
                                            :<Box className="icon-chevron-down chevron" color="#fff" component="i"></Box>}
                                        </Box>
                                        <Box maxWidth={1} flexGrow={1} className="input-color">
                                            <TextField
        		                                error={dataNew.color===''&&inputsEmpty?true:false}
                                                value={dataNew.color}
                                                onChange={ e => handleInput('color',e.target.value) }
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                style={{ input : { paddingLeft : 15 } }} 
                                                inputProps={{maxLength: 15}}                  
                                            />
                                        </Box>
                                        {statusColorPicket?<SketchPicker 
                                            className="colorpicket"
                                            triangle={'hide'} 
                                            color={ dataNew.color }
                                            width="initial"
                                            onChange={ e => handleInput('color',e.hex)}
                                        />:null}
                                        
                                    </Box>

                                    {/* ============== Img ============ */}
                                    <InputLabel shrink htmlFor="time-label-placeholder" error={!img.file&&inputsEmpty?true:false}>{t('news.modal.form.img.label')}</InputLabel>
                                    <Box position="relative">
                                        <Box className={!img.file&&inputsEmpty?"input-file input-error":"input-file"} display="flex" justifyContent="center" alignItems="center" borderRadius={5} mt={1} mb={3} position="relative" height={heightContentImg} width={1} style={{ background: imgNew.url!==''?'url('+imgNew.url+')center center / contain no-repeat,#eaeaea':'#eaeaea', overflow: 'hidden' }}>
                                            <input id="input-file" type="file" accept="image/png,image/jpg,image/jpeg" onChange={ e => handleImg(e.target) } onClick={ e => e.target.value = null }/>
                                            <Box position="absolute" left={0} top={0} right={0} bottom={0} sx={{ background: `linear-gradient(to right, ${dataNew.color}, ${hexToRgba(dataNew.color, 0.9)}, ${hexToRgba(dataNew.color, 0.7)}, ${hexToRgba(dataNew.color, 0.5)}, ${hexToRgba(dataNew.color, 0.3)}, ${hexToRgba(dataNew.color, 0.1)}, transparent)` }}></Box>
                                            <Box width="90%" color="#fff" style={{ zIndex : 10 }}>
                                                <Box className="title" fontSize={17} fontWeight={500} maxWidth={3/4}>{ dataNew.title }</Box>
                                                <Box className="subtitle" fontSize={15} fontWeight={300} maxWidth={3/4} mb={4}>{ dataNew.subtitle }</Box>
                                                <Box className="btn-image" mt={3.5} component="a" href={dataNew.link} target="blanck_" ><button type="button" style={{ color: dataNew.color }}>{t('news.modal.form.img.btnBabber')}</button></Box>
                                            </Box>
                                        </Box>
                                        <Box mt={1} width={1} className="img-drop">
                                            <ReactCrop
                                                src={img.url || ''}
                                                crop={crop}
                                                onImageLoaded={ img => handleImgTag(img) } // Get a tag img
                                                onComplete={ crop => makeClientCrop(crop) }
                                                onChange={ crop => handleCrop(crop) }
                                                locked={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box width={1} display="flex" flexWrap="wrap">
                                        <Box width={1}>
                                            <Button className="btn-load" variant="contained" color="primary" onClick={ () => document.getElementById('input-file').click() }>{t('news.modal.form.img.btnImg')}</Button>
                                        </Box>
                                    </Box>  
        	                    </Box>
        	                    <Box width={{ xs : 1, sm : 1/2 }} order={{ xs : 0, sm : 1 }} pt={5} textAlign={{ xs : "center", sm : "right"}}>
        	                        <Box width="90%" maxWidth={250} src={require('../../../assets/img/new-mock-en.png')} component="img" mb={6}></Box>
        	                    </Box>
                                <Box pt={10} pb={10} width={1} textAlign="center" order={2}>
                	                <Button className="btn-add" variant="contained" color="primary" type="submit">{t('news.modal.form.btnSave')}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        }
    </Translation>
}

const mapStateToProps = state => ({
    color: state.global.color,
    state: state.news
})

const mapDispatchToProps = {
   getMessage,
   statusMessage
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ModalForm));