import React, { useState, useEffect } from 'react';
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getNews, getNew, changeStatusNew, updateNew, saveNew, deleteNew, changePage, changeSearch } from '../../redux/actions/news';
// Material UI
import { Grid, Box, Tabs, Tab, Button, IconButton } from '@material-ui/core';
import { Edit, Clear } from '@material-ui/icons';
// Components
import SearcherInput from "../../components/Inputs/SearcherInput";
import Datagrid from "../../components/Datagrid/Grid";
import AlertDelete from "../../components/Modal/AlertDelete";
import AlertMessage from "../../components/Modal/AlertMessage";
import ModalForm from "./components/ModalForm";
// handle of languages
import { Translation } from 'react-i18next';

// Models
import UserProfile from "../../models/UserProfile";

const userProfile = new UserProfile();

const News = ({state, getNews, getNew, changeStatusNew, saveNew, updateNew, deleteNew, message, getMessage, statusMessage, changePage, changeSearch}) => {

    const [alert, setAlert] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
	/* ================ Vars API ================= */

    const changeColumns = tr => {
        let columns = { 
                        title : { label : tr('news.table.title') }, 
                        pd : { label : tr('news.table.date') }, 
                        link : { label : tr('news.table.link') } 
                      };
        if (typeof userProfile.getPermissions()['home_news_write'] !== 'undefined')  
          columns.opc = {label: tr('news.table.opc')};            
    	return columns;
    }

    const changeStatusModal = (band,id='') => {
    	getNew(id);
        setStatusModal(band);
    }

    const handleNews = (tr,i18n) => {
    	return state.news.rows.length > 0?state.news.rows.reduce( ( arrayNews, data, index ) => {
            let { id, link, title, pd } = data;
            title = state.status==='Activo'&&state.page===0&&index<5&&state.search===''?(<Box position="relative"><Box className="text" component="span" maxWidth={320} display="block">{title}</Box>                                                                         <Box className="point" fontSize={10} fontWeight={700} position="absolute" component="span" left={-60}>VISIBLE</Box></Box>):title;
            link = (<a className="external-link" href={link} target="blanck_">{tr('news.table.btnLink')}</a>);
            let opc;
            if (typeof userProfile.getPermissions()['home_news_write'] !== 'undefined') 
                opc = (<div>
                            <IconButton 
                            classes={{
                                root: 'zeroPaddingTableButton'
                            }}
                            style={{marginTop:0, color: '#3498db', display: 'inline-block'}} onClick={ () => changeStatusModal(true,id) }>
                                <Edit />
                            </IconButton>
                            <IconButton 
                            classes={{
                                root: 'zeroPaddingTableButton'
                            }}
                            style={{marginTop:0, color: '#d9534f', display: 'inline-block'}} onClick={ () => statusAlert(id) }>
                                <Clear />
                            </IconButton>
                      </div>);
            pd = pd.replace(' ','T');
            let formatDate = new Date(pd),
                    year = formatDate.toLocaleString(i18n.language, { year: tr('news.formatDate.year') }),
                    month = formatDate.toLocaleString(i18n.language, { month: tr('news.formatDate.month') }),
                    day = formatDate.toLocaleString(i18n.language, { day: tr('news.formatDate.day') }),
                    time = formatDate.toLocaleString(i18n.language, { hour: tr('news.formatDate.hour'), minute: tr('news.formatDate.minute'), hour12: tr('news.formatDate.hour12') === "true" });

                    pd = tr('news.formatDate.format');
                    pd = pd.replace('DD',day).replace('MM',month).replace('YYYY',year).replace('TIME',time);

            return [ ...arrayNews,  { ...data, title: title, pd : pd, link : link, opc : opc }]
    	},[]):[];
    }

    const statusAlert = (id) => {
    	getNew(id);
        setAlert(true);
    }

    const deleteRow = () => {
    	deleteNew(userProfile.getToken(), state.new.id, state.limit, state.page, state.search, state.status);
    	setAlert(false);
    }
 
    useEffect(() => {
        getNews(userProfile.getToken(), state.limit, state.page, state.search, state.status);
    },[state.page]);

    useEffect(() => {
        changePage(0);
        getNews(userProfile.getToken(), state.limit, 0, state.search, state.status);
    },[state.status,state.search]);

    useEffect(() => {
        setStatusModal(false);
    },[state.news]);

    return <Translation>
        { 
            (t, { i18n }) => {
                return <Grid id="news" container>
                    {/* ==================== Filters =================== */}
                    <Box ml={-2} mr={-2} className="filters" display="flex" flexWrap="wrap" mb={2} width={1}>
                    	<Box pl={2} pr={2} width={{ xs: 1, md: 1/2 }} mb={4} flexGrow={1} order={0}>
                    		<Box className="actions" p={2} height={1} borderRadius={2} display="flex" flexWrap="wrap" alignItems="center">
                    			<Grid item xs={true}>
                    			    <Tabs value={state.status==='Activo'?0:1} indicatorColor="primary" textColor="primary" style={{ flex : 1 }}>
                                        <Tab label={t('news.tabs.active')} onClick={ () => changeStatusNew('Activo')}/>
                                        <Tab label={t('news.tabs.inactive')} onClick={ () => changeStatusNew('Inactivo')}/>
                                    </Tabs>
                    			</Grid>
                                {typeof userProfile.getPermissions()['home_news_write'] !== 'undefined' &&
                    			<Box flexGrow={1} width={1} maxWidth={{ xs: 110, sm: 140, md : 110, lg : 140 }}>
                    				<Button className="btn-filter" variant="contained" color="primary" onClick={ () => changeStatusModal(true) }>{t('news.tabs.btn')}</Button>
                    			</Box>}
                    		</Box>
                    	</Box>
                    	<Box pl={2} pr={2} width={{ xs: 1, md: 1/2 }} mb={4} flexGrow={1} order={{ xs: 2, md: 1 }}>
                    		<Box className="search" p={2} height={1} borderRadius={2} display="flex" flexWrap="wrap" alignItems="center">
                    			<Grid item xs={true}>
                    				<SearcherInput className="input-search" placeholder={t('news.search.input')} eventChange={ (e, search) => changeSearch(search.value) } band={true}></SearcherInput>
                    			</Grid>
                    		</Box>
                    	</Box>
                    	<Box fontSize="14px" color="#000" pl={5} pr={2} width={{ md: [1/2] }} m={0} mb={{ xs : 3, md : 0 }} order={{ xs: 1, md: 2 }} component="p">{t('news.tabs.text')}</Box>
                    	<Box fontSize="14px" color="#000" pl={5} pr={2} width={{ md: [1/2] }} m={0} order={3} component="p">{t('news.search.text')}</Box>
                    </Box>

                    {/* ==================== Table =================== */}
                    <Datagrid data={handleNews(t,i18n)} page={state.page} totalRows={state.news.total} rowsPerPage={state.limit} columns={ changeColumns(t)} eventOnChange={ (e,page) => changePage(page) }></Datagrid>
                    <AlertDelete open={alert} eventClose={() => setAlert(false)} eventOk={() => deleteRow()} content="" />
                    <AlertMessage eventClose={ () => statusMessage(false) }></AlertMessage>
                    <ModalForm status={statusModal} statusNews={state.status} changeStatus={changeStatusModal} updateNew={ updateNew } saveNew={ saveNew }></ModalForm>
                </Grid>
            }
        }
    </Translation>
}

const mapStateToProps = state => ({
    state: state.news,
    message: state.global.message
})

const mapDispatchToProps = {
   getNews,
   getNew,
   updateNew,
   saveNew,
   changeStatusNew,
   deleteNew,
   changePage,
   changeSearch
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(News));