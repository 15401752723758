import React, { useState, useEffect } from "react";
// Router
import { Link, withRouter } from 'react-router-dom';
// Material UI
import { Grid, Box, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { getSectionTitle } from '../../../../redux/actions/global';
// handle of languages
import { Translation } from 'react-i18next';


const Communication = ({history,getSectionTitle}) => {

    const [items] = useState([
        {
            title: 'promotion.sections.promotion.items.institution.title',
            descript: 'promotion.sections.promotion.items.institution.descrip',
            img: require('../../../../assets/img/promotion/institution.png'),
            routes: {
                print: '/promotion/audience/institution/print',
                digital: '/promotion/audience/institution/digital'
            }
        },
        {
            title: 'promotion.sections.promotion.items.students.title',
            descript: 'promotion.sections.promotion.items.students.descrip',
            img: require('../../../../assets/img/promotion/students.png'),
            routes: {
                print: '/promotion/audience/students/print',
                digital: '/promotion/audience/students/digital'
            }
        },
        {
            title: 'promotion.sections.promotion.items.tutors.title',
            descript: 'promotion.sections.promotion.items.tutors.descrip',
            img: require('../../../../assets/img/promotion/tutors.png'),
            routes: {
                print: '/promotion/audience/tutors/print',
                digital: '/promotion/audience/tutors/digital'
            }
        }
    ]);

    const [idiom, setIdiom] = useState('');

	const maxW = 370;

    const handleTitle = (t) => {
        setIdiom(t('promotion.sections.promotion.items.title'));
        return t('promotion.sections.linc.items.title');
    }

    useEffect(() => {
        if (idiom !== '')
           getSectionTitle({ title : idiom, icon : 'icon-mic' });
    }, [idiom]);

    return <Translation>
        {
    	    (t, { i18n }) => <Grid id="promotion" container>

                <Box p={6} width={1}>
                    <Box ml={-3} mr={-3} display="flex" flexWrap="wrap" justifyContent="center">
                        <Box flexGrow={1} width={1} maxWidth={maxW*3} pl={3} pr={3}>
                            <Box className="btn-back" fontSize={{ xs : 17, sm : 20 }} color="text.main" mb={10} component="button" onClick={() => history.push('/promotion')}>
                                <Box className="icon-arrow-left" mr={4} fontSize={{ xs : 18, sm : 20 }} color="text.main" component="i"></Box>{handleTitle(t)}
                            </Box> 
                        </Box>
                    </Box>
                    <Box ml={-3} mr={-3} display="flex" flexWrap="wrap" justifyContent="center">
                        { items.map( (item,index) => <Box flexGrow={1} width={{ md : 1/2, lg : 1/3 }} maxWidth={maxW} pl={3} pr={3} mb={9} key={index}>
                                <Box width={1} height={1} display="flex" flexWrap="wrap">
                                    <Box>
                                        <Box component="img" maxWidth="100%" src={item.img}></Box>
                                        <Box width={1} fontSize={{ xs : 16, sm : 18 }} fontWeight={400} mt={{ xs : 1, sm : 2.5 }} mb={1} color="text.main" component="h3">{t(item.title)}</Box>
                                        <Box width={1} fontSize={14} mt={0} mb={2} color="text.main" component="p">{t(item.descript)}</Box>
                                    </Box>
                                    <Box width={1} display="flex" alignSelf="flex-end">
                                        <Box flexGrow={1} width={1/2} pr={1.5}>
                                            <Button className="btn-downloads" variant="contained" color="primary" component={Link} to={item.routes.print}>
                                                <Box className="text" color="#000" fontSize={14} component="span">
                                                    <Box className="icon-printer" fontSize={16} color="text.main" component="i"></Box>{ t('promotion.sections.promotion.items.btnPrint') }
                                                </Box>
                                            </Button>
                                        </Box>
                                        <Box flexGrow={1} width={1/2} pl={1.5}>
                                            <Button className="btn-downloads" variant="contained" color="primary" component={Link} to={item.routes.digital}>
                                                <Box className="text" color="#000" fontSize={14} component="span">
                                                    <Box className="icon-laptop-phone" fontSize={18} color="text.main" component="i"></Box>{ t('promotion.sections.promotion.items.btnDigital') }
                                                </Box>
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box> 
                            </Box>
                        ) }
                    </Box>
                </Box>
      
    	    </Grid>
        }
    </Translation>

}

const mapDispatchToProps = {
    getSectionTitle
};

export default withRouter(connect('', mapDispatchToProps)(Communication))