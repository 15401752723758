import {buttonList, miniBtnDanger, miniBtnInfo, cardTitle, cardCategory, cardContet, cardHeader} from "../stylesVariables/globals";
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
  textArea:{
    '& > .MuiInput-root': {
      maxHeight: 'initial'
    }
  },
  grid: {
    padding: "0 6px !important",
    position: "relative"
  },
  grid2: {
    padding: "0 6px",
    position: "relative"
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  gridHeader:{
  	display: "flex",
    alignItems: "center",
    color: "#666",
    paddingLeft: "6px",
    marginBottom: "10px"
  },
  gridHeaderPadding:{
    paddingTop: "5px",
    paddingBottom: "5px"
  },
  iconHeader: {
    width: "1.5em",
    overflow: "auto",
    fontSize: "20px",
    marginTop: "6px",
    height: "1.2em",
    userSelect: "none",
    flexShrink: 0
  },
  iconList: {
    fontSize: "12px",
    '&:hover': {
      color: "#fafafa",
    }
  },
  iconColumn: {
    fontSize: "25px"
  },
  buttonList: {
  	...buttonList
  },
  btnDanger: {
  	...miniBtnDanger
  },
  btnInfo: {
  	...miniBtnInfo
  },
  titleStyles: {
    fontSize: "18px"
  },
  cardTitleWhite: {
    ...cardTitle
  },
  cardCategoryWhite: {
    ...cardCategory
  },
  cardContet:{
    ...cardContet
  },
  cardHeader: {
    ...cardHeader
  },
  iconBack: {
    width: "1.5em",
    fontSize: "20px",
    height: "1.2em",
    marginRight: "0px",
    marginTop: "4px"
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: "27px 0 0 0",
    position: "relative",
    paddingBottom: "10px"
  },
  styleColor: {
    backgroundColor: "red",
    '&:hover': {
      backgroundColor: "red",
    }
  },
  buttonBottom: {
    margin: "0 0 27px 25px"
  },
  error:{
    color: "#f44336"
  },
  paperCss: {
    display: "flex",
    position: "relative",
    alignItems: "center"
  },
  btnAdd:{
    right: "8px",
    display: "flex",
    position: "absolute"
  },
  btnNew:{
    right: "16px",
    display: "flex",
    position: "absolute",
    top: "26px",
    '&:hover': {
      color: "#3f51b5",
    }
  },
  btnBack:{
    '&:hover': {
      color: "rgba(0, 0, 0, 0.87)",
    }
  },
  iOSSwitchBase: {
    '&$iOSChecked': {
      color: theme.palette.common.white,
      '& + $iOSBar': {
        backgroundColor: '#52d869',
      },
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: 'translateX(15px)',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none',
    },
  },
  iOSBar: {
    borderRadius: 13,
    width: 42,
    height: 26,
    marginTop: -13,
    marginLeft: -21,
    border: 'solid 1px',
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  iOSIcon: {
    width: 24,
    height: 24,
  },
  subjectsList: {
    overflow: 'auto', 
    maxHeight: 400, 
    minHeight: 300,
    border: '1px solid silver', 
    borderRadius:"4px"
  },
  subjectsSelectedPaper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
  },
  subjectChip: {
    margin: theme.spacing(0.5),
    color: "#fff"
  },
  fontDetail:{
    fontSize: "0.9em",
    fontWeight: "bold"
  },
  identifier: {
    margin: 10,
    padding: 5
  },
  statusText: {
    margin: 10,
    padding: 5,
    borderRadius: 14,
    paddingLeft: 10,
    paddingRight: 10
  },
  colorSuccess: {
    color: '#fff',
    backgroundColor: "#00A651"
  },
  colorInfo: {
    color: '#fff',
    backgroundColor: "#3498db"
  },
  colorPending: {
    color: '#fff',
    backgroundColor: "#e0e0e0"
  },
  colorFinished: {
    color: '#fff',
    backgroundColor: "burlywood"
  },
  colorDanger: {
    color: '#fff',
    backgroundColor: "#d9534f"
  },
  lessonAvatar: {
    width: 140,
    height: 140,
    display: "inline-block"
  },
  smallAvatar: {
    display: "inline-block",
    width: 30,
    height: 30,
    margin: '0px 5px -7px 0px'
  },
  paperInfoDate: {
    marginTop: "20px",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingBottom: 13.5
  },
  centerContent: {
    textAlign: 'center'
  },
  centerLeft: {
    textAlign: 'left'
  },
  textOpacity: {
    color: "#666"
  },
  tileStatus: {
    borderRadius: 26,
    width: "70%",
    display: "inline-block",
    background: "#eee"
  },
  tileStatusp: {
    fontSize: "1em",
    fontWeight: "bold",
    color: "#666"
  },
  iconStyle: {
    color: "#666",
    fontSize: "3em"
  },
  iconStyleComments: {
    fontSize: "1em"
  },
  backAlmond: {
    background: "blanchedalmond"
  },
  backGray: {
    background: "#eee"
  },
  backBeige: {
    background: "beige"
  },
  backDarkgreen: {
    background: "darkseagreen"
  },
  colorYellow: {
    color: "rgb(252, 208, 54)"
  },
  colorRed: {
    color: "#C55B5B"
  },
  colorGreen: {
    color: "#00B01D"
  },
  tabForm: {
    [theme.breakpoints.up('md')]: {
      minWidth: 120
    }
  },
  contentWeekDays: {
  },
  cntAlertTutor: {
    padding: "15px 0px",
    backgroundColor: amber[700]
  },
  cntAlertTutorColor: {
    color: "#fff"
  },
  messageTutorp: {
    color: "#333",
    fontSize: 15,
    fontWeight: 500
  },
  listItemTutor: {
    padding: 0,
    fontSize: 14
  },
  listItemTextTutor: {
    fontSize: 14,
    color: "#333"
  },
  listItemChip: {
    color: "#fff"
  },
  iconsIntoListSubjects:{
    minWidth: 25
  },
  pLeft: {
    paddingLeft: 6
  },
  chipSubjects: {
    display: 'block'
  },
  btnFinishLesson: {
    marginLeft: 5
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  lightTooltip: {
    backgroundColor: '#2188FC',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 12
  },
  cntSeparate: {
    marginTop: 34,
    padding: 10,
    position: 'relative',
    background: '#fff',
    borderRadius: 5,
    border: '1px solid silver'
  },
  cntTitle: {
    position: 'absolute',
    top:'-16px',
    background: '#fff',
    padding: 10,
    fontWeight: 400,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderRadius: 5,
    fontSize: 17,
    border: '1px solid silver'
  },
  wrapInput: {
    position: 'relative'
  },
  wrapLabel: {
    position: 'absolute',
    bottom: 41
  },
  configurationLabel: {
    marginRight: 10,
    fontSize: 13,
    transform: 'inherit'
  },
  controlAccessLabel: {
    marginRight: 10,
    fontSize: 16,
    transform: 'inherit',
    color: '#333'
  },
  input: {
    display: 'flex',
    padding: 0,
    cursor: 'pointer'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    fontWeight: 'inherit'
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  customCheck: {
    color: green[600],
    '&$checked': {
      color: green[500],
    },
    padding: 4
  },
  checked: {},
  domainRemoveBtn: {
    cursor:'pointer', 
    color: 'rgb(217, 83, 79)'
  },
  controlSubtitle: {
    transform: 'inherit',
    color: '#333',
    fontSize: 18
  },
  gestionSubtitle: {
    transform: 'inherit',
    color: '#333',
    fontSize: 18
  },
  pictureEmpty: {
    width: 200,
    height: 90
  },
  customH6:{
    textTransform: 'inherit',
    fontSize: 18,
    color: '#333',
    fontWeight: 400
  },
  hidden: {
    display: 'none'
  },
  paddingConfig: {
    paddingLeft: 15
  },
  marginTopCnt: {
    marginTop: 10
  },
  paRight: {
    paddingRight: 10
  },
  btnAddDomain: {
    paddingLeft: 38, 
    marginTop: 15
  },
  categoryLabel: {
    color: '#333', 
    fontSize: 16, 
    fontWeight: 500
  },
  assignPlacesTitle: {
    transform: 'inherit',
    color: '#333',
    fontSize: 16
  },
  nested: {
    paddingLeft: 30
  },
  zeroPadTableBtn: {
    padding: 0,
    marginRight: 10
  },
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}px`,
  },
  disableText: {
    color: '#D0D0D0'
  },
  reviewCommentsContent: {
    justifyContent: 'center', 
    display: 'flex'
  },
  reviewCommentsP:{
    paddingLeft: 29, 
    position: 'relative', 
    lineHeight: '19px'
  },
  reviewCommentsSpan:{
    fontSize: 16, 
    position: 'absolute', 
    top: 0, 
    left: 0
  },
  importBoxContentBorder:{
    border: '1px solid #999'
  },
  customDialogPaper: {
    overflowY: 'none'
  },
  customDialogRoot: {
    backgroundColor: 'none',
    color: '#fff'
  },
  studentCheckboxContainerFirts: {
    marginTop:35
  },
  studentCheckboxContainer: {
    marginTop:25
  },
  studentCheckboxContainerItemLabel: {
    fontSize: 16, 
    color: '#333'
  },
  studentCheckboxContainerItemTooltipSpan: {
    fontSize: 15, 
    color: '#2196f3', 
    paddingLeft:10, 
    paddingTop:7
  },
  spanIconInfo: {
    fontSize: 17, 
    color: 'dimgray',  
    paddingTop:3
  }
});

export {
  styles
}