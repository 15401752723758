import React, { Component } from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
// Components
import CustomInput from "../../components/Inputs/CustomInput";

import { Translation } from 'react-i18next';

const styles = theme => ({
  gridHeader:{
    display: "flex",
    alignItems: "center",
    color: "#666",
    paddingLeft: "0px",
    marginBottom: "30px"
  },
  button: {
    margin: '22px '+theme.spacing(1)+'px '+theme.spacing(1)+'px 0',
    height: '35px'
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  bootstrapRoot: {
    padding: 0,
    maxHeight: 'initial',
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '7px 12px',
    width: 'calc(100% - 24px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  widthFormControl:{
    paddingRight: '10px',
    width: "100%"
  }
});
class SearcherInput extends Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.eventChange(e, this.search);
  }	

  render() {
    
  	const { classes, textToSearch, placeholder, band=false } = this.props;
    
    if (typeof textToSearch !== 'undefined' && textToSearch !== '') {
        if (typeof this.search !== 'undefined')
           this.search.value = textToSearch;
    }

    return (
      
       <form onSubmit={this.handleSubmit}>
        <Translation>
         {
          (t, { i18n }) => {
            
            return (
      	      <Box width={1} maxWidth={600} display="flex" flexWrap="wrap" alignItems="center" className={!band?classNames(classes.gridHeader):''}>
                <Grid item xs={true}>
                  <CustomInput
                        formControlProps={{
                          fullWidth: false,
                          classes: {
                              root: classes.widthFormControl
                          }
                        }}
                        band={band}
                        inputProps={{
                              placeholder: (typeof placeholder !== 'undefined' && placeholder !== '')?placeholder:t('categories.search.placeholder'),
                              disableUnderline: true,
                              name: "search",
                              classes: {
                                  root: classes.bootstrapRoot,
                                  input: classes.bootstrapInput
                              },
                              inputRef: input => this.search = input
                        }}
                      />
                </Grid>
      		      <Box flexGrow={1} width={{ xs:100, sm:140 }} maxWidth={{ xs:100, sm:140 }}>
                  <Button variant="contained" color="primary" type="submit" className={classNames(['btn-filter',(!band?classes.button:'')])}>
                    {t('categories.search.button')}
                  </Button> 
                </Box>
      		        
      	       </Box>
             )
            }
          }
        </Translation>
       </form>     

    );

  }

}

export default withStyles(styles, { withTheme: true })(SearcherInput);