import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// handle of languages
import { Translation } from 'react-i18next';

class GenericModal extends Component {

    closeModal = (event, reason) => {

		if (reason === "backdropClick") {
			return false;
		}
	  
		if (reason === "escapeKeyDown") {
			return false;
		} else {
			this.props.eventClose();
		}

	};

	render(){
		
		let {title, content, open, size, contentCustomPadding} = this.props;

		return (
			<Translation>
            {
	          (t, { i18n }) => {
	            
	            return (
			        <Dialog
			          open={open}
			          onClose={this.closeModal}
			          scroll="body"
			          aria-labelledby="scroll-dialog-title"
			          maxWidth={size?size:'md'}
			        >
			          <DialogTitle style={{position: 'relative'}}>
					    <div style={{position:'absolute', top:0, right:0}}>
							<IconButton color="default" onClick={this.closeModal}>
								<CloseIcon />
							</IconButton>
						</div>
						  {title}
					  </DialogTitle>
			          <DialogContent
					    style={{overflowY: 'hidden', padding: (contentCustomPadding?contentCustomPadding:'8px 24px 45px 24px')}}
					  >
			             {content}
			          </DialogContent>
			          <DialogActions>
						  <div style={{height: 30}}>
							  &nbsp;
						  </div>
					  </DialogActions>
			        </Dialog>
	          )
            }
          }
         </Translation>
		);
	}
}

export default withStyles( { withTheme: true })(GenericModal);