import React, { useState, useEffect  } from 'react';
import classNames from 'classnames';
import { Link, useParams, useHistory } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Tabs, Tab, Button } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Components
import AlertMessage from "../../components/Modal/AlertMessage";
import CardHeader from "../../components/Card/CardHeader";
import CustomInput from "../../components/Inputs/CustomInput";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';

// Api
import ProfilesApi from "../../api/Profiles/ProfilesApi";

// Models
import UserProfile from "../../models/UserProfile";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

// handle of languages
import { Translation } from 'react-i18next';

const Api = new ProfilesApi();
const userProfile = new UserProfile();
let titleKey = 'titleAdd';
let titleIcon = 'fa fa-plus';

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    marginTop: 17,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 1,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    
  },
  expanded: {},
})(AccordionSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    flexWrap: 'wrap'
  },
}))(AccordionDetails);

const Profile = ({classes}) => {
    return <App classes={classes} />;
};
  
const App = ({classes}) => {

    let history = useHistory();
    let { id } = useParams();

    const [errors, setErrors] = useState({
        name: false,
        description: false
    });
    const [openAlert, setOpenAlert] = useState(false);
    const [currentTab, setTab] = useState('general');
    const [alertMsg, setAlertMsg] = useState({
        open: false,
        className: 'success',
        message: ''
    });
    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        description: '',
        status: 'Activo'
    });
    const [expandedPanels, setExpanded] = useState({});
    const [selectedValue, setSelectedValue] = useState('open');
    const [saveButton, enableButton] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [menu, setMenu] = useState([]);
    const [permissions, setPermissions] = useState({});
    const [lockChecks, setLockChecks] = useState(false);

    const changeTab = (event, tab) => {  
        setTab(tab);
    }

    const closeMessage = (event) => {
      setAlertMsg({...alertMsg, open: false});
    };

    const handleChange = e => {

	    const name = e.target.name;
	    const value = e.target.value;

        setFormData({ 
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: false
        });
   
    };

    const handleCheckAll = e => {
      let permis = checkMenu(permissions, menu, !checkAll);
      setCheckAll(!checkAll);
      setPermissions({...permis});
    }

    const openPanels = (e) => {  
      
      let v = e.target.value;
      let panels = expandedPanels, st = v === 'close'?false:true;
      
      panels = updatePanelsStatus(panels, st);

      setExpanded({...panels});
      setSelectedValue(v);
    
    }

    const handleChangePanels = panel => (event, newExpanded) => {
      setExpanded({...expandedPanels, [panel]: !expandedPanels[panel]});
    };

    const getSubLevels = (items, id) => {

      let subLevels = [];
    
      for (var k in items) {

        if (items[k].permissionId === id) {
           subLevels = items[k].subSections;
           break;
        } else if (items[k].subSections.length > 0) {
          subLevels = getSubLevels(items[k].subSections, id);
          if (subLevels.length > 0) break;
        }

      }

      return subLevels;

    };

    const getParentLeves = (items, id) => {

      let parentLevels = [], plevels = [];
    
      for (var k in items) {

        parentLevels.push(items[k]); 
        
        if (items[k].permissionId === id) {
           break;
        } else if (items[k].subSections.length > 0) {
          plevels = getParentLeves(items[k].subSections, id);
          if (plevels.length > 0) { parentLevels.push(...plevels); break; }
          else { parentLevels = []; }
        } else {
          parentLevels = [];
        }

      }

      return parentLevels;

    };

    const checkMenu = (permis, items, parentCheck) => {
    
      for (var k in items) {

          permis[items[k].permissionId] = {
                        permissionId:items[k].permissionId, 
                        allowed:parentCheck
                    };
           
        if (items[k].subSections.length > 0)
           permis = checkMenu(permis, items[k].subSections, parentCheck);

      }

      return permis;

    };

    const checkParents = (permis, items) => {
    
      for (var k in items) {

          permis[items[k].permissionId] = {
                        permissionId:items[k].permissionId, 
                        allowed:true
                    };

      }

      return permis;

    };

    const allMenuIsChecked = (items) => {

      let f = true;
    
      for (var k in items) {

        if (!items[k].allowed) {
          f = false; 
          break;
        } 

      }

      return f;

    };

    const handlePermissions = (v, e) => {

      let permis = permissions, subPermissions = [], 
          parentCheck = (typeof permis[v] !== 'undefined' && permis[v].allowed?false:true),
          allParents = [];
          
      permis[v] = {permissionId:v, allowed:parentCheck};
      subPermissions = getSubLevels(menu, v);

      if (parentCheck) allParents = getParentLeves(menu, v);
      
      if (allParents.length > 0) { 
        allParents.pop();
        if (parentCheck) permis = checkParents(permis, allParents); 
      }

      if (subPermissions.length > 0)
         permis = checkMenu(permis, subPermissions, parentCheck);
    
      setCheckAll(allMenuIsChecked(permis));
  
      setPermissions({...permissions, ...permis});

    };

    function sendData(data) {
      
        Api.save(
            userProfile.getToken(), 
            userProfile.getUser().universityId,
            data,
            permissions
        ).then(response => {
  
          let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
          let data = responseData.data;
  
          enableButton(false);

          if (responseData.modal) {

            if (data.className === 'success') {
              userProfile.addAlert({msg: data.message, class: 'success'});
              history.push("/roles");
            } else {
              setAlertMsg({
                open: true,
                className: data.className,
                message: responseData.message
              });
            }

          } else {
            console.log("Error");
          }
  
        }).catch((err) => {enableButton(false);Api.closeSession(err)});
  
    }

    function update(data) {
      
      Api.update(
           userProfile.getToken(), 
           data,
           permissions
      ).then(response => {
             
        enableButton(false);
        let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
        let data = responseData.data;

        if (responseData.modal) {

          if (data.className === 'success') {
            userProfile.addAlert({msg: data.message, class: 'success'});
            history.push("/roles");
          } else {
            setAlertMsg({
              open: true,
              className: data.className,
              message: responseData.message
            });
          }

        } else {
          console.log(responseData.message);
        }
  
      }).catch((err) => {enableButton(false);Api.closeSession(err)});
      
    };

    function showMenu(items, padding) {
      return items.map((v, i) => {
      
        return (
        <React.Fragment key={i}> 
          <ListItem button style={{paddingLeft:padding}}>  
         {/*
            <ListItemIcon>
              <i className={'fa fa-arrow-right'}></i>
            </ListItemIcon>
         */}

            <ListItemIcon>
              <Checkbox
                edge="end"
                checked={permissions[v.permissionId].allowed}
                value={v.permissionId}
                onChange={e => handlePermissions(v.permissionId, e)}
                disableRipple
                disabled={lockChecks}
              />
            </ListItemIcon>
            <ListItemText primary={<div><i className={v.icon}></i> <span>{v.caption}</span></div>} />
          </ListItem>
        {v.subSections.length > 0? 
          <List component="div" disablePadding>
            {showMenu(v.subSections, padding + 20)}
          </List>:'' }
        </React.Fragment>
       )});
    }

    const searchInSubLeves = (permis, items) => {
    
      for (var k in items) {

        permis[items[k].permissionId] = {permissionId:items[k].permissionId, allowed: false}; 
           
        if (items[k].subSections.length > 0)
           permis = searchInSubLeves(permis, items[k].subSections);

      }

      return permis;

    };

    const updatePanelsStatus = (items, st) => {

      let panelsExpanded = {};

      for (var k in items)
        panelsExpanded[k] = st; 

      return panelsExpanded;

    };

    const addPanelsExpanded = (items, st) => {

      let panelsExpanded = {};

      for (var k in items) {

        panelsExpanded['panel'+k] = st; 

      }

      return panelsExpanded;

    };

    function showPermissions() {
      
      Api.getPermissions(userProfile.getToken()).then(response => {

        let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
        let currentPermis = {}, panels = {};

        currentPermis = searchInSubLeves(currentPermis, responseData.data);
        panels = addPanelsExpanded(responseData.data, true);
        
        setCheckAll(allMenuIsChecked(currentPermis));
        setMenu(responseData.data);
        setPermissions(currentPermis);
        setExpanded(panels);

      }).catch((err) => {Api.closeSession(err)});
      
    };

    const validateForm = (e) => {
    
        e.preventDefault();
    
        let labels = {};

        if (formData.name.trim() === '') labels['name'] = true;
        // if (formData.description.trim() === '') labels['description'] = true;
    
        if (Object.keys(labels).length > 0) {
            setErrors({...errors, ...labels});
        } else {

            enableButton(true);

            if (id > 0) {
              update(formData);
            } else {
              sendData(formData);
            }

        }
        
    }

    const convertPermsToObject = (permis, items) => {
    
      for (var k in items) {

        permis[items[k].permissionId] = {permissionId:items[k].permissionId, allowed: (items[k].allowed === "SI"?true:false)}; 
           
        if (items[k].subSections.length > 0)
           permis = convertPermsToObject(permis, items[k].subSections);

      }

      return permis;

    };

    const getOneRow = (id) => {

      Api.byId(
        userProfile.getToken(), id
      ).then(response => {

        let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
        let data = responseData.data;
        let currentPermis = {}, panels = {};

        if (responseData.modal) {
	        history.push("/roles");
	      } else {
        
          currentPermis = searchInSubLeves(currentPermis, data.allPerms);
          currentPermis = convertPermsToObject(currentPermis, data.perms);
          panels = addPanelsExpanded(data.allPerms, false);
          
          setCheckAll(allMenuIsChecked(currentPermis));
          setFormData(data.info);
          setMenu(data.allPerms);
          setPermissions(currentPermis);
          setExpanded(panels);
          setSelectedValue('close');

          if (data.master)
              setLockChecks(true);

        }

      }).catch((err) => {Api.closeSession(err)});

  }

    useEffect(() => {

      if (id !== 'add') {
    
        titleKey = 'titleModify';
        titleIcon = 'fa fa-pen'
        
        if (isNaN(id)) {
          history.push("/roles");
        } else {
          getOneRow(id);
        }
  
      } else {
        titleIcon = 'fa fa-plus';
        titleKey = 'titleAdd';
        showPermissions();
      }

    }, [])

    return (
        <div>
            <Translation>
             {
                (t, { i18n }) => {
                    
                    return (  
                      <Grid container> 
                         <Grid item className={classes.grid} xs={12} sm={12} md={3}>
                            <Grid item className={classNames(classes.gridHeader, classes.gridHeaderPadding)} xs={12} sm={12} md={12}>
                                <Button color="default" component={Link} to="/roles" className={classes.btnBack}>
                                <span className={classNames(classes.iconBack, "fa fa-arrow-left")}></span> 
                                {t('categories.form.back')}
                                </Button>                            
                            </Grid>
                         </Grid>   
                        
                         <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                         <form onSubmit={validateForm}>
                          <Card className={classes.cardContet}>    
                             
                             <CardHeader className={classes.cardHeader}>
                                <h4 className={classes.cardTitleWhite}>
                                    <span className={classNames(classes.iconBack, titleIcon)}></span> 
                                    {t('profiles.form.'+titleKey)}
                                </h4>
                                <p className={classes.cardCategoryWhite}>{t('categories.form.subtitle')}</p>
                             </CardHeader>
                             <AppBar position="static" style={{backgroundColor: "#f5f5f5", color: "#333"}}>
                                <Tabs value={currentTab} onChange={changeTab} indicatorColor="primary" textColor="primary">
                                  <Tab value="general" label={t('profiles.form.tabs.general')} />
                                  <Tab value="permissions" label={t('profiles.form.tabs.permissions.name')} />
                                </Tabs>
                             </AppBar>
                             
                             {currentTab === 'general' && <CardContent>
                                <Grid container>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                                    <CustomInput
                                      labelText={t('profiles.form.fields.name')}
                                      id="name"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name:"name",
                                        value:formData.name,
                                        onChange:(handleChange),
                                        error:errors.name
                                      }}
                                      customInputProps={{maxLength: "60"}}
                                    />
                                  </Grid>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                                    <TextField
                                      fullWidth={true}
                                      id="description"
                                      name="description"
                                      label={t('profiles.form.fields.description')}
                                      value={formData.description}
                                      className={classes.textArea}
                                      onChange={handleChange}
                                      style={{marginTop: "27px"}}
                                      error={errors.description}
                                      inputProps={{maxLength:(100)}}
                                    />
                                  </Grid>
                                </Grid>
                             </CardContent>}

                             {currentTab === 'permissions' && <CardContent>
                                <Grid container style={{marginTop:10}}>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                                    <label className={classes.assignPlacesTitle} style={{fontWeight:500}}>
                                      {t('profiles.form.tabs.permissions.title')}
                                    </label> <br /><br />
                                    <label className={classes.assignPlacesTitle}>
                                    {t('profiles.form.tabs.permissions.title2')}
                                    </label>
                                  </Grid>
                                </Grid>
                                <Grid container style={{marginTop:25}}>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                                    <Grid container style={{marginBottom: 10}}>
                                      <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                          <Checkbox
                                            checked={checkAll}
                                            value={'all'}
                                            onChange={handleCheckAll}
                                            disableRipple
                                            color="primary"
                                            inputProps={{}}
                                            disabled={lockChecks}
                                          />
                                          <span style={{fontSize: 17,fontWeight: 300}}>
                                            {t('profiles.form.tabs.permissions.checkAll')}
                                          </span>
                                      </Grid>
                                      <Grid item className={classes.grid} xs={12} sm={12} md={6} style={{textAlign: 'right'}}>
                                        <FormControlLabel 
                                          control={<Radio value="open" checked={selectedValue === 'open'} onChange={openPanels} color="primary" />} 
                                          label={t('profiles.form.tabs.permissions.expand')} />
                                        <FormControlLabel 
                                          control={<Radio value="close" checked={selectedValue === 'close'} onChange={openPanels} color="primary" />} 
                                          label={t('profiles.form.tabs.permissions.close')} />
                                      </Grid>    
                                    </Grid>  
                                  </Grid>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                                    
                                  {menu.map((v, i) => {
						            	          return (
                                      <ExpansionPanel key={i} expanded={expandedPanels['panel'+i]} 
                                      onChange={handleChangePanels('panel'+i)}>
                                        <ExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                        >
                                            <Typography className={classes.heading}>
                                              <i className={v.icon}></i> {v.caption}
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                          <div> 
                                              <Checkbox
                                                checked={permissions[v.permissionId].allowed}
                                                value={v.permissionId}
                                                onChange={e => handlePermissions(v.permissionId, e)}
                                                disableRipple
                                                inputProps={{}}
                                                disabled={lockChecks}
                                              />
                                              <span style={{fontSize: 17,fontWeight: 300}}>
                                              <i className={'icon-lock'}></i>&nbsp;
                                              {t('profiles.form.tabs.permissions.allowedLabel')}</span>
                                          </div>
                                          <List
                                            component="nav"
                                            aria-labelledby="nested-list-subheader"
                                            className={classes.root}
                                          >
                                          {showMenu(v.subSections, 20)}

                                          </List>
                                        </ExpansionPanelDetails>
                                      </ExpansionPanel>
                                     )
                                    })}  

                                  </Grid>
                                </Grid>
                             </CardContent>}
                             <Grid container>
                                <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                                    <Button variant="contained" color="primary" className={classes.buttonBottom} 
                                            type="submit" style={{marginTop: 20, marginLeft: 20}} disabled={saveButton}>
                                        {t('profiles.form.btnSave')}
                                    </Button>
                                </Grid>
                            </Grid> 
                          </Card>
                          </form> 
                         </Grid>
                      
                      </Grid>
                )}
            }
           </Translation>    
           <AlertMessage open={alertMsg.open} variant={alertMsg.className} message={alertMsg.message} eventClose={closeMessage} />
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(Profile);