import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  content: {
    flexGrow: 1,
    zIndex: 1,
    backgroundColor: '#fff',
    padding: 0,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  container:{
    marginTop: "0px",
    minHeight: "calc(100vh - 123px)",
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingRight: "0px",
    paddingLeft: "0px",
    marginRight: "auto",
    marginLeft: "auto"
  }
});

class Main extends Component {

  render() {

  	const { classes, children } = this.props;

    return (
        <div className={classes.root}>
          
          <main className={classes.content}>
            <div className={classes.container}>{children}</div>
          </main>

        </div>
    );

  }

}

export default withStyles(styles, { withTheme: true })(Main);
