
import React, { Component } from 'react';
import Selectr from 'react-select';
import classNames from 'classnames';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import NoSsr from '@material-ui/core/NoSsr';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';

// Libs
import ReeValidate from 'ree-validate';

// handle of languages
import { Translation } from 'react-i18next';
import parse from 'html-react-parser'; 
import i18nHelper from '../../../helpers/i18n';

// Models
import UserProfile from "../../../models/UserProfile";

import {urlApi} from "../../../variables/globals";

import viewPicture1 from "../../../assets/img/introductory_modal/slide1.png";
import viewPicture2 from "../../../assets/img/introductory_modal/slide2.png";
import viewPicture4 from "../../../assets/img/introductory_modal/slide4.png";
import viewPicture5 from "../../../assets/img/introductory_modal/slide5.png";

let backgrounds = {
   'es': {img: require('../../../assets/img/introductory_modal/backgrounds/es.jpg')},
   'en': {img: require('../../../assets/img/introductory_modal/backgrounds/en.jpg')}
};

let timezonesByLan = {
   'es': {file: require('../../../assets/json/timezones/es.json')},
   'en': {file: require('../../../assets/json/timezones/en.json')}
};

const userProfile = new UserProfile();
const i18nH = new i18nHelper();

let totalChildren = 0;

const styles = theme => ({
  slide: {
  	marginBottom: 5,
  	display: 'none',
  	paddingLeft: 20,
    paddingRight: 20
  },
  cntTitles: {
    textAlign: 'center'
  },
  title: {
    color: '#2188FC',
    fontSize: 40,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 25
    }
  },
  titleGray: {
    color: '#9B9B9B'
  },
  subtitle: {
    color: '#4A4A4A',
    fontSize: 21,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15
    }
  },
  p: {
    color: '#4A4A4A',
    fontSize: 15
  },
  hidep: {
  	[theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  cntform: {
  	marginTop: 50,
  	[theme.breakpoints.down('sm')]: {
      marginTop: 15
    }
  },
  lightTooltip: {
    backgroundColor: '#2188FC',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  error:{
    color: "#f44336"
  },
  green:{
  	color: "#005E58"
  },
  alignCenter: {
    textAlign: 'center'
  },
  modalPincture: {
  	maxWidth: '100%', 
  	maxHeight: 295
  },
  formLabel: {
  	fontSize: 15,
  	color: '#4A4A4A'
  },
  btnModal: {
  	backgroundColor: '#2188FC',
  	color: '#fff',
  	fontWeight: 'normal',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#2188FC',
      opacity: '0.8'
    }
  },
  backImage: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundSize: 'cover',
    minHeight: 500,
    maxHeight: 600
  },
  progress: {
  },
  input: {
    display: 'flex',
    padding: 0,
    cursor: 'pointer'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    fontWeight: 'inherit'
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class IntroductoryModal extends Component {

	constructor(){

	  super();
	  
	  this.validator = new ReeValidate({
	      language: 'required',
	      domain: 'required',
	      tz: 'required'
	  })

	  this.state = {
        open: true, 
        currentIndex: 1,
        formData: {
	       language: ' ', 
           domain: '',
           tz: ''
	    },
        loading: false,
        loadingStart: false,
        hideNextButton: false,
        errors: this.validator.errors,
        message: '',
        showBack: false
	  }

	  this.container = React.createRef();
	  this.btnNext = React.createRef();
	  this.cntButtons = React.createRef();
	  this.cntBtnPrev = React.createRef();
	  this.btnStart = React.createRef();
	  this.slides = React.createRef();
	  this.validateAndSubmit = this.validateAndSubmit.bind(this);

    }

    send(formData) {
        // formData.tz = formData.tz.value;
	    axios.request({
           method:'patch',
           url:urlApi+'universities/completedata',
           data:formData,
           headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18nH.getLanguage(),
            'Authorization': userProfile.getToken()
         }
	    }).then(response => {
	           
	         this.handleLoading();
	         let responseData = response.data.data;
          
	         if (responseData.modal) {
	            this.setState({ message: responseData.message });
	         } else {
                this.btnNext.current.style.display = 'inline';
                this.setState({ message: '', hideNextButton: !this.state.hideNextButton });
                this.nextView();
	         }

	    }).catch((err) => {this.handleLoading(); console.log(err);});
	
	}

	addDemo() {
        
	    axios.request({
           method:'post',
           url:urlApi+'universities/createdemo',
           data:{},
           headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18nH.getLanguage(),
            'Authorization': userProfile.getToken()
         }
	    }).then(response => {
	           
	        this.loading();
	        let responseData = response.data.data;
            userProfile.completedGuide(responseData.data.completedGuide); 
            // this.btnStart.current.style.display = 'inline';
            this.setState({ open: false });
	        // console.log(responseData);

	    }).catch((err) => {this.loading(); console.log(err);});
	
	}

    closeModal = () => {
	   this.props.eventClose();
	};

	handleLoading = () => {
	   this.setState({ loading: !this.state.loading });
	   this.setState({ hideNextButton: !this.state.hideNextButton });
	};

    loading = () => {
	   this.setState({ loadingStart: !this.state.loadingStart });
	};

	nextView = () => {

	     let elemParent = this.container.current.firstElementChild,
	         childs = elemParent.children;
	     totalChildren = elemParent.childElementCount;

	     for (let i = 0; i < childs.length; i++) {
		     childs[i].style.display = 'none';
		 }

	     childs[this.state.currentIndex].style.display = 'block';
	     this.setState({currentIndex: this.state.currentIndex + 1});

	     if (this.state.currentIndex === 1 || this.state.currentIndex === 2) {
	     	this.cntBtnPrev.current.style.display = 'block';
	     }

	     if (this.state.currentIndex === (totalChildren - 1)) {
	     	this.btnNext.current.style.display = 'none';
	     	this.btnStart.current.style.display = 'block';
	     }

	     if (this.state.currentIndex === 3) { 
	     	this.slides.current.style.color = '#fff';
	     	this.setState({showBack: true});
	     }
	     	
	     if (this.state.currentIndex > 3) {
	        this.slides.current.style.color = '#3C4858'; 
	     	this.setState({showBack: false});
	     }
     
	}

	prevView = () => {

	     let elemParent = this.container.current.firstElementChild,
	         childs = elemParent.children;
	     totalChildren = elemParent.childElementCount;

	     for (let i = 0; i < childs.length; i++) {
		     childs[i].style.display = 'none';
		 }

		 let cIndex = this.state.currentIndex - 1;
         
	     childs[cIndex - 1].style.display = 'block';
	     this.setState({currentIndex: cIndex});

	     if (this.state.currentIndex === 2) {
	     	this.cntBtnPrev.current.style.display = 'none';
	     	this.btnNext.current.style.display = 'none';
	     	this.setState({ hideNextButton: !this.state.hideNextButton });
	     }
         
	     if (this.state.currentIndex === totalChildren) {
	     	this.btnNext.current.style.display = 'block';
	     	this.btnStart.current.style.display = 'none';
	     }

	     if (this.state.currentIndex === 4) { 
	     	this.slides.current.style.color = '#3C4858';
	     	this.setState({showBack: false});
	     }
	     
	     if (this.state.currentIndex === 5) { 
	     	this.slides.current.style.color = '#fff';
	     	this.setState({showBack: true});
	     }
     
	}

	changeLanguage = (e) => {
		const name = e.target.name;
	    const value = e.target.value;
	    const { _validator } = this.validator;
		const { errors } = _validator;
	    errors.remove(name);
        this.setState({ formData: { ...this.state.formData, [name]: value }, errors });
	} 

	chooseTZ = name => tz => {
		const { _validator } = this.validator;
		const { errors } = _validator;
		// console.log(errors.items, "*************")
		errors.remove('tz');
		this.setState({ formData: { ...this.state.formData, 'tz': tz }, errors });
	}

	onChangeDomain = (e) => {
	    const re = /^[a-z0-9.-]+$/;
	    const { _validator } = this.validator;
		const { errors } = _validator;
	    errors.remove('domain');
	    // const re = /^(([a-z0-9])+\.)+([a-zA-Z0-9]{2,4})+$/
	    if (e.target.value === '' || re.test(e.target.value)) {
	       this.setState({ formData: { ...this.state.formData, 'domain': e.target.value } });
	    }

	    this.setState({ errors });
	}

	validateDomain(){
		const re = /^[a-z0-9][a-z0-9-]{0,61}[a-z0-9](?:\.[a-z]{2,})+$/;
		if (!re.test(this.state.formData.domain)) return false;
		return true;
	}

	submit(formData, t) {
	   if (this.validateDomain()) {
	      this.handleLoading();
	      this.send(formData);
	   } else {
	   	  this.setState({ message: t('introductoryModal.view1.alerts.domain') });
	   }
	}
	  
	validateAndSubmit(t, e) {

	   e.preventDefault()
	   const { formData } = this.state;
	   const { _validator } = this.validator;
	   const { errors } = _validator;

	   _validator.validateAll(formData)
	      .then(success => {
	        if (success) {
	          this.submit(formData, t)
	        } else {
	          this.setState({ errors })
	        }
	   })
	    
	}

	loadDemo = () => {
	   this.btnStart.current.style.display = 'none';
	   this.loading();
	   this.addDemo();
	}

	render(){
		
		let {open, currentIndex, formData, errors, showBack} = this.state;
		const { classes } = this.props;

		const selectStyles = {
	      input: base => ({
	        ...base,
	        color: 'blue',
	        '& input': {
	          font: 'inherit',
	        },
	      }),
	    };

		return (
			<form onSubmit={this.validateAndSubmit}>
				<Translation>
	            {
		          (t, { i18n }) => {
		            
		            return (
				        <Dialog
				          open={open}
				          onClose={(event, reason) => {
							if (reason !== 'backdropClick') {
								this.closeModal(event, reason)
							}
						  }}
				          disableEscapeKeyDown
				          scroll="body"
				          aria-labelledby="scroll-dialog-title"
				          maxWidth="md"
				        >
				          <DialogContent className={showBack?classes.backImage:''} style={{backgroundImage: showBack?'url('+backgrounds[i18nH.getLanguage()].img+')':''}}>
				            <Grid container className={classNames(classes.container)} style={{height: '100%', minHeight: 500}}> 
				              <div ref={ this.container } style={{width:'100%', display: 'flex', flexWrap: 'wrap'}}>

					             <Grid container className={classes.container} style={{alignSelf: 'flex-start'}}>
						             <Grid container className={classNames(classes.container, classes.slide)} style={{display: 'block'}}>
			        	               <Grid item className={classNames(classes.grid, classes.alignCenter)} xs={12} sm={12} md={12}>
			        	                 <Typography component="h2" variant="h6" className={classes.title}>
								           {t('introductoryModal.view1.title')}
								         </Typography>
								         <Typography variant="subtitle1" className={classes.subtitle}>
								           {t('introductoryModal.view1.subtitle')}
								         </Typography>
			        	               </Grid>
			        	               <Grid item className={classNames(classes.grid, classes.cntform)} xs={12} sm={12} md={12}>
			        	                <Grid container>
			        	                  <Grid item className={classes.grid} xs={12} sm={12} md={7} style={{margin: 'auto'}}>
							                 <Typography variant="subtitle1" className={classes.formLabel} gutterBottom>
									           {t('introductoryModal.view1.fields.timeZone.label')}
									         </Typography>
							                 <NoSsr>
									          <Selectr
									            classes={classes}
									            styles={selectStyles}
									            options={timezonesByLan[i18nH.getLanguage()].file.timezones}
									            components={components}
									            value={formData.tz}
									            onChange={this.chooseTZ('tz')}
									            placeholder={t('introductoryModal.view1.fields.timeZone.placeholder')}
									            isClearable
									            error={errors && errors.has('tz')}
									          />
									        </NoSsr> 
									        { errors && errors.has('tz') &&
			                                    <hr style={{height: 2, backgroundColor: '#f44336', border: 'none', marginTop: -1}} />
			                                } 
							              </Grid>
							               <Grid item className={classes.grid} xs={12} sm={12} md={7} style={{margin: 'auto', marginTop: 20}}>
							                 <Typography variant="subtitle1" className={classes.formLabel} gutterBottom>
									           {t('introductoryModal.view1.fields.language.label')}
									         </Typography>
									         <FormControl fullWidth={true}>
									          <Select
									            value={formData.language}
									            inputProps={{
									              name: 'language',
									              id: 'language'
									            }}
									            onChange={this.changeLanguage}
									            error={errors && errors.has('language')}
									            style={{fontSize: 16}}
									            >
									              <MenuItem value=" " key="1" >{t('introductoryModal.view1.fields.language.placeholder')}</MenuItem>
									              <MenuItem value="es" key="2">{t('header.languages.spanish')}</MenuItem>
									              <MenuItem value="en" key="3">{t('header.languages.english')}</MenuItem>
									          </Select>
									         </FormControl>
							               </Grid>
							               <Grid item className={classes.grid} xs={12} sm={12} md={7} style={{margin: 'auto', marginTop: 20}}>
							                 <Typography variant="subtitle1" className={classes.formLabel} gutterBottom>
									           {t('introductoryModal.view1.fields.domain.label')} 
									           <Tooltip title={t('introductoryModal.view1.fields.domain.tooltip')} classes={{ tooltip: classes.lightTooltip }}>  
												 <span className="fa fa-info-circle" style={{marginLeft: 8, fontSize: 13, color: '#2196f3', cursor: 'pointer'}}></span>
											   </Tooltip> 
									         </Typography>
									         <FormControl fullWidth={true}>
									          <Input
									            id="domain"
									            name="domain"
						                        value={formData.domain}
									            startAdornment={
									              <InputAdornment position="start">{t('introductoryModal.view1.fields.domain.adornment')}@</InputAdornment>
									            }
									            onChange={this.onChangeDomain}
									            inputProps={{maxLength: "40"}}
									            placeholder={t('introductoryModal.view1.fields.domain.placeholder')}
									            error={errors && errors.has('domain')}
									          />
									          {this.state.message !== '' ?
									          <label className={classes.error} htmlFor="domain">{this.state.message}</label>: ''}
									          { errors && errors.has('domain') &&
			                                    <label className={classes.error} htmlFor="domain"></label>
			                                  }
									         </FormControl>
							               </Grid>
										</Grid>  
			        	               </Grid>
			        	             </Grid>
			        	             <Grid container className={classNames(classes.container, classes.slide)}>
				        	           <Grid item className={classNames(classes.grid, classes.alignCenter)} xs={12} sm={12} md={12}>
			        	                 <img src={viewPicture1} alt="logo" className={classes.modalPincture} />
			        	               </Grid>
				        	           <Grid item className={classNames(classes.grid, classes.alignCenter)} xs={12} sm={12} md={12}>
			        	                 <Typography component="h2" variant="h6" className={classes.title} gutterBottom>
								           {t('introductoryModal.view2.title')}
								         </Typography>
								         <Typography variant="subtitle1" className={classes.p} gutterBottom>
								           <span style={{fontWeight:500}}>{t('introductoryModal.view2.paragraphFisrtPart')}</span> {t('introductoryModal.view2.paragraphSecondPart')} <span style={{color: '#00C0FF'}}>{t('introductoryModal.view2.paragraphWordsWithColor')}</span> 
								           &nbsp;{parse(t('introductoryModal.view2.paragraph'))}
								         </Typography>
			        	               </Grid>
			        	             </Grid>  
			        	             <Grid container className={classNames(classes.container, classes.slide)}>
				        	           <Grid item className={classNames(classes.grid, classes.alignCenter)} xs={12} sm={12} md={12}>
			        	                 <img src={viewPicture2} alt="logo" className={classes.modalPincture} style={{maxHeight: 214}} />
			        	               </Grid>
				        	           <Grid item className={classNames(classes.grid, classes.alignCenter)} xs={12} sm={12} md={12}>
			        	                 <Typography component="h2" variant="h6" className={classNames(classes.title, classes.titleGray)} gutterBottom>
			        	                   {t('introductoryModal.view3.titleFisrtPart')} <span className={classes.green}>{t('introductoryModal.view3.titleSecondPart')}</span> {t('introductoryModal.view3.titleThirdPart')} <span style={{color: '#2188FC'}}>{t('introductoryModal.view3.titleFourthPart')}</span> 
			        	                   <br/> {t('introductoryModal.view3.titleLastPart')}
								         </Typography>
								         <Typography variant="subtitle1" className={classes.p} gutterBottom>
								           {parse(t('introductoryModal.view3.paragraph1'))}
								         </Typography>
								         <Typography variant="subtitle1" className={classes.p} gutterBottom>
											{parse(t('introductoryModal.view3.paragraph2'))}
								         </Typography>
			        	               </Grid>
			        	             </Grid>  
			        	             <Grid container className={classNames(classes.container, classes.slide)}>
				        	           <Grid item className={classNames(classes.grid, classes.alignCenter)} xs={12} sm={12} md={12}>
			        	                 <Typography component="h2" variant="h6" className={classes.title} gutterBottom>
								           {t('introductoryModal.view4.title')}
								         </Typography>
								         <Typography variant="subtitle1" className={classes.p} gutterBottom>
								           <span style={{fontWeight:500}}>{parse(t('introductoryModal.view4.paragraph1'))}</span>
								         </Typography>
								         <Typography variant="subtitle1" className={classNames(classes.p, classes.hidep)} style={{ marginTop: 14}} gutterBottom>
								           <span style={{fontWeight:500}}>{parse(t('introductoryModal.view4.paragraph2'))}</span>
								         </Typography>
			        	               </Grid>
			        	             </Grid> 
			        	             <Grid container className={classNames(classes.container, classes.slide)}>
				        	           <Grid item className={classNames(classes.grid, classes.alignCenter)} xs={12} sm={12} md={12}>
			        	                 <img src={viewPicture4} alt="logo" className={classes.modalPincture} style={{maxHeight: 265}} />
			        	               </Grid>
				        	           <Grid item className={classNames(classes.grid, classes.alignCenter)} xs={12} sm={12} md={12}>
			        	                 <Typography component="h2" variant="h6" className={classes.title} gutterBottom>
								           {parse(t('introductoryModal.view5.title'))}
								         </Typography>
								         <Typography variant="subtitle1" className={classes.p} gutterBottom>
								           {parse(t('introductoryModal.view5.paragraph'))}
								         </Typography>
			        	               </Grid>
			        	             </Grid>  
			        	             <Grid container className={classNames(classes.container, classes.slide)}>
				        	           <Grid item className={classNames(classes.grid, classes.alignCenter)} xs={12} sm={12} md={12}>
			        	                 <img src={viewPicture5} alt="logo" className={classes.modalPincture} style={{maxHeight: 245}}/>
			        	               </Grid>
				        	           <Grid item className={classNames(classes.grid, classes.alignCenter)} xs={12} sm={12} md={12}>
			        	                 <Typography component="h2" variant="h6" className={classes.title} gutterBottom>
								           {parse(t('introductoryModal.view6.title'))}
								         </Typography>
								         <Typography variant="subtitle1" className={classes.p} gutterBottom>
								           {parse(t('introductoryModal.view6.paragraph'))}
								         </Typography>
			        	               </Grid>
			        	             </Grid>  
		        	             </Grid>
		        	             
		        	             <Grid container className={classes.container} style={{alignSelf: 'flex-end', marginTop: 15}}>
                                 
                                  <div ref={this.cntButtons} style={{width: '100%', display: 'flex'}}> 
		        	               
		        	               <Grid item className={classes.grid} xs={12} sm={12} md={4}>
		        	                  <div style={{display: 'none'}} ref={this.cntBtnPrev}>
		        	                    <Button color="default" onClick={this.prevView} className={classes.btnModal}> 
		        	                      <span className="fa fa-chevron-left"></span> &nbsp;{t('introductoryModal.view1.buttons.back')}
		        	                    </Button>
		        	                  </div>
		        	               </Grid>
		        	               <Grid item className={classes.grid} xs={12} sm={12} md={4} style={{textAlign: 'center', fontWeight: 600, paddingTop:6}}>
		        	                  <span ref={this.slides}>{currentIndex}/6</span> 
		        	               </Grid>
		        	               <Grid item className={classes.grid} xs={12} sm={12} md={4} style={{textAlign: 'right'}}>
		        	                    <div style={{display:this.state.hideNextButton?'none':'inline'}}>
			        	                    <Button color="default" onClick={this.validateAndSubmit.bind(this, t)} type="submit" className={classes.btnModal}>
			        	                      {t('introductoryModal.view1.buttons.next')}&nbsp; <span className="fa fa-chevron-right" ></span>
			        	                    </Button>
		        	                    </div>
		        	                    { this.state.loading ? <div><CircularProgress className={classes.progress} style={{width: 29, height: 29}} /></div> : ''}
		        	                    { this.state.loadingStart ? <div>{t('introductoryModal.view6.loadingText')}&nbsp; <CircularProgress className={classes.progress} style={{width: 29, height: 29}} /></div> : ''}
		        	                    <div style={{display:'none'}} ref={this.btnNext}>
			        	                    <Button color="default" onClick={this.nextView} className={classes.btnModal}>
			        	                      {t('introductoryModal.view1.buttons.next')}&nbsp; <span className="fa fa-chevron-right"></span>
			        	                    </Button>
		        	                    </div>
		        	                    <div style={{display:'none'}} ref={this.btnStart}>
			        	                    <Button color="default" className={classes.btnModal} onClick={this.loadDemo} style={{backgroundColor: '#4caf50'}}>
			        	                      {t('introductoryModal.view1.buttons.start')}&nbsp; <span className="fa fa-chevron-right"></span>
			        	                    </Button>
		        	                    </div>
		        	               </Grid>

                                  </div>
		        	             
		        	             </Grid>
	                          </div> 
	        	            </Grid> 
				          </DialogContent>
				        </Dialog>
		          )
	            }
	          }
	         </Translation>
         </form>
		);
	}
}

export default withStyles(styles,  { withTheme: true })(IntroductoryModal);