/* ================= Actions types ================ */
export const ADD_MENU = 'ADD_MENU';
export const ACTIVE_SUB = 'ACTIVE_SUB';
export const COMPACT = 'COMPACT';

/* ================= Actions creators ================ */

const addMenu = (datas) => {
	return { type: ADD_MENU, payload: datas }
}

const activeSub = (id) => {
	return { type: ACTIVE_SUB, payload: id }
}

const compactMenu = (band) => {
	return { type: COMPACT, payload: band }
}

export{
	addMenu, activeSub, compactMenu
}
