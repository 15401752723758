import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  customDialogPaper: {
    overflowY: 'none'
  },
  root: {
    backgroundColor: 'none',
    color: '#fff'
  }
}));

export default function LoadingFullPage({open, size}) {

  const classes = useStyles();
  
  return (
    <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            return false
          }
        }}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        maxWidth={size?size:'md'}
        className={classes.root}
        PaperProps={{
          style: {
            background: 'none',
            color:'#fff',
            overflowY: 'visible',
            boxShadow: 'inherit'
          },
        }}
    >

      <CircularProgress color="inherit" />

    </Dialog>
  );
}