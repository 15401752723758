import i18n from '../i18n';
import UserProfile from "../models/UserProfile";

const userProfile = new UserProfile();

class i18nHelper {

    constructor(){
	    this.defaultLanguage = 'en';
	    this.allowedLanguages = {en: {}, es: {}, fr: {}};
		this.getLanguage = this.getLanguage;
		this.setLanguage = this.setLanguage;
		this.changeLanguage = this.changeLanguage;
    }
  
    existsLan(currentLan){

		let allowedLanguages = this.allowedLanguages;

		if (typeof currentLan !== 'undefined' && currentLan !== '') {

			// for (var lan in allowedLanguages) {
                // if (allowedLanguages[lan] === currentLan.trim()) {
            
            if (typeof allowedLanguages[currentLan.trim()] !== 'undefined') {  
               return currentLan.trim();
            }
                // }
			// }

			return this.defaultLanguage;

	    } else return this.defaultLanguage;

    }

	changeLanguage(lan){

		i18n.changeLanguage(lan);

	}

	getLanguage(){

		const cl = userProfile.getLanguage() || i18n.language || window.localStorage.i18nextLng || navigator.language || navigator.userLanguage || '';
		let splitcl = cl.split("-"), currenLan = (cl !== '')?splitcl[0]:'';
		return this.existsLan(currenLan);

	}

	setLanguage(){

		const cl = this.getLanguage();
		this.changeLanguage(cl);

		return cl;

	}

}

export default i18nHelper;