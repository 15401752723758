import axios from 'axios';
import BaseApi from "./BaseApi";

class MainApi extends BaseApi {

  constructor(){
    super();
    this.var = '';
  }
  
  getPermissions(token, lan) {

    this.headers.Authorization = token;

    if (typeof lan !== 'undefined' && lan !== '') 
        this.headers['Accept-Language'] = lan;
    
    return axios.request({
       method:'get',
       url: this.url+'administrators/getpermissions',
       headers: this.headers
    });

  }
  

}

export default MainApi;