import * as types from '../actions/news';

const initialState = {
	action: '',
	limit: 10,
	page: 0,
	search: '',
	status: 'Activo',
	new: '',
    news: {
    	rows: [],
    	total: 0
    }
};

const reducer = (state = initialState, {type, payload}) => {
	switch(type){
		case types.GET_NEWS :{
			return { ...state, new : '', news : { ...state.news, rows : payload.rows, total : payload.total } }
		}
	    case types.GET_NEW :
            return { ...state, new : state.news.rows.find(item => item.id === payload) || '' }
        case types.CHANGE_STATUS :
            return { ...state, status : payload }    
		case types.CHANGE_PAGE :
            return { ...state, page : payload }    
        case types.CHANGE_SEARCH :
            return { ...state, search : payload }    
		default: 
		    return state;
	}
}

export default reducer;