let Components = {};
// import Home from '../modules/Home';

// Pages
Components['Home'] = require('../modules/Home').default;
Components['Chat'] = require('../modules/Chat').default;
Components['Categories'] = require('../modules/Categories').default;
Components['Category'] = require('../modules/Categories/Category').default;
Components['Subjects'] = require('../modules/Subjects').default;
Components['Subject'] = require('../modules/Subjects/Subject').default;
Components['GroupSubjects'] = require('../modules/Subjects/GroupSubjects').default;
Components['Places'] = require('../modules/Places').default;
Components['Place'] = require('../modules/Places/Place').default;
Components['Careers'] = require('../modules/Careers').default;
Components['Career'] = require('../modules/Careers/Career').default;
Components['Students'] = require('../modules/Users/Students').default;
Components['Tutors'] = require('../modules/Users/Tutors').default;
Components['Student'] = require('../modules/Users/Student').default;
Components['Tutor'] = require('../modules/Users/Tutor').default;
Components['ImportTutors'] = require('../modules/Users/ImportTutors').default;
Components['Lessons'] = require('../modules/Lessons').default;
Components['GroupLessons'] = require('../modules/Lessons/GroupLessons').default;
Components['Statistics'] = require('../modules/Statistics/index2.js').default;
Components['Login'] = require('../modules/Login').default;
Components['RecoverPassword'] = require('../modules/Login/RecoverPassword').default;
Components['RestorePassword'] = require('../modules/Login/RestorePassword').default;
Components['Start'] = require('../modules/Login/Start').default;
Components['Configuration'] = require('../modules/Configuration').default;
Components['Profiles'] = require('../modules/Configuration/Profiles').default;
Components['Administrators'] = require('../modules/Configuration/Administrators').default;
Components['Profile'] = require('../modules/Configuration/Profile').default;
Components['Administrator'] = require('../modules/Configuration/Administrator').default;
Components['News'] = require('../modules/News').default;
Components['Promotion-and-dissemination'] = require('../modules/Promotion-and-dissemination').default;
Components['Formats'] = require('../modules/Promotion-and-dissemination/views/Formats.js').default;
Components['Communication'] = require('../modules/Promotion-and-dissemination/views/Communication-by-segment').default;
Components['Segment'] = require('../modules/Promotion-and-dissemination/views/Communication-by-segment/segment').default;

export {Components};