import React, {useState,useEffect} from 'react'
import { AppBar, Toolbar, Typography, Button, Grid, Box, Card, CardContent } from '@material-ui/core'
// Calendar
import {parseISO} from "date-fns";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";

// Icons
// import { Search } from '@material-ui/icons'
// handle of languages
import { Translation } from 'react-i18next'
// import XLSX from 'xlsx';
import fileSaver from 'file-saver';
// Graphics
import { HorizontalBar, Bar, Line, Doughnut } from './components'
// Api
import StatisticsLessonsApi from "../../api/Statistics/LessonsApi";
// Models
import UserProfile from "../../models/UserProfile";
// Theme
import { createTheme } from '@mui/material/styles';
// React anchor
import AnchorLink from 'react-anchor-link-smooth-scroll';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

import AlertMessage from "../../components/Modal/AlertMessage";

// Material ui v5
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

var moment = require('moment-timezone');

const defaultTheme = createTheme();
const Api = new StatisticsLessonsApi();
const userProfile = new UserProfile();
let loadCategories = true;
const regexForDateFormat = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

const Statistics = () => {

    const [filterType, setFilterType] = useState('all');
    const [disableDownload, setDisableDownload] = useState(false);
    const [disableFilter, setDisableFilter] = useState(false);
    const [showErrorIncorrectRange, setErrorIncorrectRange] = useState(false);

    const [filterModality, setfilterModality] = useState('all');
    const [filterCategory, setFilterCategory] = useState(0);
    const [categories, setCategories] = useState([]);
    const [alertMsg, setAlertMsg] = useState({
        open: false,
        className: 'success',
        message: ''
    });

    const getRealDate = (date) =>{ // Get real date
        date = new Date(date);
        date = date.setDate(date.getDate() + 1);
        return date;
    }

    const formatDate = (date) => { // Format to api
        date = new Date(date);
        date = date.getFullYear()+"-"+((date.getMonth() + 1) < 10?"0"+(date.getMonth() + 1):(date.getMonth() + 1))+"-"+(date.getDate() < 10?"0"+date.getDate():date.getDate());
        return date;
    }

    const disabledDate = (current) => {
        // Disable dates that are after today
        return current && current > moment().endOf('day');
    };

    const closeMessage = (event) => {
        setAlertMsg({...alertMsg, open: false});
    };

    const [datesRange, setDatesRange] = React.useState([getRealDate('1900-01-01'), getRealDate('1900-01-01')]);
    const [anchorActive,setAnchorActive] = useState(''); // paint border of the graph to anchor
    
    /*const [dateRange, setDateRange] = useState({ // Range of dates
        start: getRealDate('1900-01-01'), end: getRealDate('1900-01-01')
    });*/

    const [period, setPeriod] = useState({
        lessonsTotals: '',
        hoursTotals: '',
        lessonsCancelled: '',
        lessonsAutocancelled: '',
        period: ''
    })

    const [generalDatas,setGeneralDatas] = useState([ // Btns general datas
        {
            title: 'statistics.tabs.tutorials.generalDatas.data1.title',
            data: '',
            color: '#F5A623',
            icon: 'run',
            textTotal: 'statistics.tabs.tutorials.generalDatas.data1.total',
            total: 0,
            anchor: 'topTutors'
        },
        {
            title: 'statistics.tabs.tutorials.generalDatas.data2.title',
            data: '',
            color: '#67B031',
            icon: 'walk',
            textTotal: 'statistics.tabs.tutorials.generalDatas.data2.total',
            total: 0,
            anchor: 'topLessTutors'
        },
        {
            title: 'statistics.tabs.tutorials.generalDatas.data3.title',
            data: '',
            color: '#FD5307',
            icon: 'run',
            textTotal: 'statistics.tabs.tutorials.generalDatas.data3.total',
            total: 0,
            anchor: 'topHoursTutors'
        },
        {
            title: 'statistics.tabs.tutorials.generalDatas.data4.title',
            data: '',
            color: '#A7194B',
            icon: 'walk',
            textTotal: 'statistics.tabs.tutorials.generalDatas.data4.total',
            total: 0,
            anchor: 'topLessHoursTutors'
        },
        {
            title: 'statistics.tabs.tutorials.generalDatas.data5.title',
            data: '',
            color: '#0C46FC',
            icon: 'user-minus',
            textTotal: 'statistics.tabs.tutorials.generalDatas.data5.total',
            total: 0,
            anchor: 'topTutorsCancelled'
        },
        {
            title: 'statistics.tabs.tutorials.generalDatas.data6.title',
            data: '',
            color: '#FEE066',
            icon: 'user-minus',
            textTotal: 'statistics.tabs.tutorials.generalDatas.data6.total',
            total: 0,
            anchor: 'topStudentByCancelLessons'
        },
        {
            title: 'statistics.tabs.tutorials.generalDatas.data7.title',
            data: '',
            color: '#70C0B3',
            icon: 'library',
            textTotal: 'statistics.tabs.tutorials.generalDatas.data7.total',
            total: 0,
            anchor: 'topSolicitedSubjects'
        },
        {
            title: 'statistics.tabs.tutorials.generalDatas.data8.title',
            data: '',
            color: '#430059',
            icon: 'library',
            textTotal: 'statistics.tabs.tutorials.generalDatas.data8.total',
            total: 0,
            anchor: 'topSolicitedSubjectsCancelled'
        },
        {
            title: 'statistics.tabs.tutorials.generalDatas.data9.title',
            data: '',
            color: '#B14997',
            icon: 'calendar-31',
            textTotal: 'statistics.tabs.tutorials.generalDatas.data9.total',
            total: 0,
            anchor: 'topDays'
        },
        {
            title: 'statistics.tabs.tutorials.generalDatas.data10.title',
            data: '',
            color: '#FC8190',
            icon: 'calendar-31',
            textTotal: 'statistics.tabs.tutorials.generalDatas.data10.total',
            total: 0,
            anchor: 'topDaysCancelled'
        },
        {
            title: 'statistics.tabs.tutorials.generalDatas.data11.title',
            data: '',
            color: '#0191CD',
            icon: 'graduation-hat ',
            textTotal: 'statistics.tabs.tutorials.generalDatas.data11.total',
            total: 0,
            anchor: 'topCarrersByLessons'
        },
        {
            title: 'statistics.tabs.tutorials.generalDatas.data12.title',
            data: '',
            color: '#FFA85D',
            icon: 'calendar-full',
            textTotal: 'statistics.tabs.tutorials.generalDatas.data12.total',
            total: 0,
            anchor: 'topSemestersByLessons'
        }
    ])

    const [dataTutores, setDataTutores] = useState([ // Tutors stats
        {
            index: 'topTutors',
            title: 'statistics.tabs.tutorials.graphicsTutors.topTutors.title',
            label: 'statistics.tabs.tutorials.graphicsTutors.topTutors.label',
            labels: [],
            labelsTitle: [],
            data: []
        },
        {
            index: 'topLessTutors',
            title: 'statistics.tabs.tutorials.graphicsTutors.topLessTutors.title',
            label: 'statistics.tabs.tutorials.graphicsTutors.topLessTutors.label',
            labels: [],
            labelsTitle: [],
            data: []
        },
        {
            index: 'topHoursTutors',
            title: 'statistics.tabs.tutorials.graphicsTutors.topHoursTutors.title',
            label: 'statistics.tabs.tutorials.graphicsTutors.topHoursTutors.label',
            labels: [],
            labelsTitle: [],
            data: []
        },
        {
            index: 'topLessHoursTutors',
            title: 'statistics.tabs.tutorials.graphicsTutors.topLessHoursTutors.title',
            label: 'statistics.tabs.tutorials.graphicsTutors.topLessHoursTutors.label',
            labels: [],
            labelsTitle: [],
            data: []
        },
        {
            index: 'topTutorsCancelled',
            title: 'statistics.tabs.tutorials.graphicsTutors.topTutorsCancelled.title',
            label: 'statistics.tabs.tutorials.graphicsTutors.topTutorsCancelled.label',
            labels: [],
            labelsTitle: [],
            data: []
        }

    ])

    const [dataEstudiantes,setDataEstudiantes] = useState({ // Students stats 
        index: 'topStudentByCancelLessons',
        title: 'statistics.tabs.tutorials.graphStudents.topStudentByCancelLessons.title',
        label: 'statistics.tabs.tutorials.graphStudents.topStudentByCancelLessons.label',
        labels: [],
        labelsTitle: [],
        data: []
    })

    const [dataMaterias, setDataMaterias] = useState([ // Matters stats
        {
            index: 'topSolicitedSubjects',
            title: 'statistics.tabs.tutorials.graphsSubjects.topSolicitedSubjects.title',
            labels: [],
            labelsTitle: [],
            label: 'statistics.tabs.tutorials.graphsSubjects.topSolicitedSubjects.label',
            data: []
        },
        {
            index: 'topHoursSubjects',
            color: '#00C0FF',
            title: 'statistics.tabs.tutorials.graphsSubjects.topHoursSubjects.title',
            labels: [],
            labelsTitle: [],
            label: 'statistics.tabs.tutorials.graphsSubjects.topHoursSubjects.label',
            data: []
        },
        {
            index: 'topSolicitedSubjectsCancelled',
            title: 'statistics.tabs.tutorials.graphsSubjects.topSolicitedSubjectsCancelled.title',
            labels: [],
            labelsTitle: [],
            label: 'statistics.tabs.tutorials.graphsSubjects.topSolicitedSubjectsCancelled.label',
            data: []
        }
    ])

    const [daysWithBestTutorialsImparted, setDaysWithBestTutorialsImparted] = useState({ // tutorials stats
        index: 'topDays',
        title: 'statistics.tabs.tutorials.days.topDays.title',
        label: 'statistics.tabs.tutorials.days.topDays.label',
        labels: [],
        data: []
    })

    const [daysWithBestTutorialsCanseled, setDaysWithBestTutorialsCanseled] = useState({ // tutorials stats
        index: 'topDaysCancelled',
        title: 'statistics.tabs.tutorials.days.topDaysCancelled.title',
        label: 'statistics.tabs.tutorials.days.topDaysCancelled.label',
        labels: [],
        data: []
    })

    const [requestedTutorialsByCareer, setRequestedTutorialsByCareer] = useState({ // tutorials stats
        index: 'topCarrersByLessons',
        title: 'statistics.tabs.tutorials.topTutorials.titleByCareer',
        labels: [],
        data: [],
        activeSelect: '',
        selectDatas: []
    })

    const [requestedTutorialsBySemester, setRequestedTutorialsBySemester] = useState({ // tutorials stats
        index: 'topSemestersByLessons',
        title: 'statistics.tabs.tutorials.topTutorials.titleByLessons',
        labels: [],
        data: [],
        activeSelect: '',
        selectDatas: []
    })

    const [exelName,setExelName] = useState('');

    /*const handleDateChange = (date: Date | null, typeDate) => { // Change Date
        setDateRange({ ...dateRange, [typeDate]: date });
    }*/

    const customFontWeight = (text) => { // Change font-size when are a lot caracters
        text = text.length;
        return text<53?'16px':'15px;';
    }

    const getColor = (index) => { // Get correct color of the graph
        return generalDatas.find(ex => ex.anchor === index).color;
    }

    const getGraph = ( arrayGraph , arrayResponse, arrVals ) => { // get a graph
        arrayGraph.data = [];
        arrayGraph.labels = [];
        arrayGraph.labelsTitle = [];
        return arrayResponse.reduce( (arrData, data) => {
            const name = data[arrVals['name']].length<27?data[arrVals['name']]:data[arrVals['name']].substr(0,26)+'...',
                nameTitle = data[arrVals['nameTitle']] || '',
                total = !(arrVals['total'] instanceof Array)?data[arrVals['total']]:data[arrVals['total'].find(val => typeof data[val] !== 'undefined' )];
            arrData = nameTitle!==''?{ ...arrData, 'data' : [ ...arrData['data'], total ], 'labels' : [ ...arrData['labels'], name ],  'labelsTitle' : [ ...arrData['labelsTitle'], nameTitle  ] }:
                { ...arrData, 'data' : [ ...arrData['data'], total ], 'labels' : [ ...arrData['labels'], name ] };
            return arrData;
        }, arrayGraph );
    }

    const getallGraphs = ( arrayGraph, arrayResponse, arrVals ) => { // get all graphs
        return arrayGraph.reduce( (arrDatas, data) => {
            const arrData = getGraph(data,arrayResponse[data.index],arrVals);
            arrDatas = [ ...arrDatas, arrData ];
            return arrDatas;
        }, []) ;
    }

    const getDatas = (tr, sendManually) => { // get all view datas

        if (typeof sendManually !== 'undefined') {
            
            if ( (datesRange[0] === null || datesRange[1] === null) || (datesRange[0] === '' || datesRange[1] === '') ||
                (!regexForDateFormat.test(formatDate(datesRange[0])) || !regexForDateFormat.test(formatDate(datesRange[1])))
            ) {
                setAlertMsg({
                    open: true,
                    className: 'warning',
                    message: tr('statistics.tabs.tutorials.period.customDatePicker.alerts.rangeFormatIncorrect')
                });
                return false;
            }

            if (formatDate(datesRange[0]) > formatDate(new Date()) || formatDate(datesRange[1]) > formatDate(new Date())) {

                setAlertMsg({
                    open: true,
                    className: 'warning',
                    message: tr('statistics.tabs.tutorials.period.customDatePicker.alerts.maxDate')
                });
                return false;

            }

            /*if (showErrorIncorrectRange) {
                setAlertMsg({
                    open: true,
                    className: 'warning',
                    message: tr('statistics.tabs.tutorials.period.customDatePicker.alerts.exceededRange')
                });
                return false;
            }*/

            setDisableFilter(true);

        }

        Api.getStatisticOfLessons(
            userProfile.getToken(),
            userProfile.getUser().universityId,
            formatDate(datesRange[0])==='1900-01-01'?'':formatDate(datesRange[0]),
            formatDate(datesRange[1])==='1900-01-01'?'':formatDate(datesRange[1]),
            filterCategory,
            filterType,
            filterModality
        ).then(response => {
            setDisableFilter(false);
            let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
            if (responseData.modal) {
                console.log(responseData.message);
            }else{

                // if (loadCategories) {
                setCategories(responseData.data.categories);
                loadCategories = false;
                // }

                // Exel
                setExelName(responseData.data.excelText);
                // range of dates
                setDatesRange([getRealDate(responseData.data.initialDate),
                getRealDate(responseData.data.endDate)]);
                // Period
                setPeriod({ lessonsTotals : responseData.data.lessonsTotals[0].total,
                    hoursTotals : responseData.data.lessonsTotals[0].elapsedHours,
                    lessonsCancelled : responseData.data.lessonsTotalsCancelled[0].total,
                    lessonsAutocancelled: responseData.data.totalAutocancelled[0].total,
                    period: responseData.data.headerText.replace('Del', '').replace('From', '').replace(/de/g, '').replace('al', '-').replace('to', '-') });
                // General datas
                setGeneralDatas( generalDatas.reduce( (generalDatas, data) => {
                    const { tutor, subject, category, name, fullname, daymfull, daym, period, total, top, elapsedHours } = responseData.data[data.anchor][0] || '';
                    generalDatas = [ ...generalDatas, { ...data, 'data' : tutor || (subject && category?subject+'</br><span category>('+category+')</span>':undefined) || daymfull || daym || name || fullname || period || '',
                        'total' : total || top || elapsedHours || 0  } ];
                    return generalDatas
                }, []) );
                // Tutors
                setDataTutores( getallGraphs( dataTutores, responseData.data, { name: 'name', total: [ 'total', 'elapsedHours' ], 'nameTitle' : 'tutor' } ) );
                // Student
                setDataEstudiantes( getGraph(dataEstudiantes, responseData.data[dataEstudiantes.index], { name: 'name', total: 'top', 'nameTitle' : 'fullname' }) );
                // Matters
                setDataMaterias( getallGraphs( dataMaterias, responseData.data, { name: 'subject', total: [ 'total', 'elapsedHours' ], 'nameTitle' : 'fullSubject' } ) );
                // Days with more tutorials
                setDaysWithBestTutorialsImparted(getGraph(daysWithBestTutorialsImparted, responseData.data[daysWithBestTutorialsImparted.index],{ name: 'daym', total: 'total' }));
                // Days with more canceled tutorials
                setDaysWithBestTutorialsCanseled(getGraph(daysWithBestTutorialsCanseled, responseData.data[daysWithBestTutorialsCanseled.index],{ name: 'daym', total: 'total' }));
                // requested tutorials by career
                const tutorialsByCarrer = getGraph(requestedTutorialsByCareer, responseData.data['topSubjectsByCareer'], { name: 'name', total: 'top' });
                const responseTutorialByCarrer = responseData.data['topCarrersByLessons'];
                setRequestedTutorialsByCareer({ ...requestedTutorialsByCareer, 'data' : tutorialsByCarrer.data, 'labels' : tutorialsByCarrer.labels,
                    'activeSelect' : responseTutorialByCarrer.length > 0?responseTutorialByCarrer[0].id:'',
                    'selectDatas' : responseData.data['topCarrersByLessons'] });
                // requested tutorials by semester
                const tutorialsBySemester = getGraph(requestedTutorialsBySemester, responseData.data['topSubjectsBySemester'],{ name: 'name', total: 'top' });
                const responseTutorialBySemester = responseData.data['topSemestersByLessons'];
                setRequestedTutorialsBySemester({ ...requestedTutorialsBySemester, 'data' : tutorialsBySemester.data, 'labels' : tutorialsBySemester.labels,
                    'activeSelect' : responseTutorialBySemester.length > 0?responseTutorialBySemester[0].id:'',
                    'selectDatas' : responseData.data['topSemestersByLessons'] });
            }

        }).catch((err) => {setDisableFilter(false); Api.closeSession(err); return '';});
    }

    const  handleDataSelect = (id,by) => { // get request tutorials to change select value
        Api.getSubjects(
            userProfile.getToken(),
            by,
            id,
            formatDate(datesRange[0]),
            formatDate(datesRange[1]),
            filterCategory,
            filterType,
            filterModality).then(response => {
                let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
                if (responseData.modal) {
                    console.log(responseData.message);
                }else{
                    if (by==='semester'){
                        const tutorialsBySemester = getGraph(requestedTutorialsBySemester, responseData.data.subjects, { name: 'name', total: 'top' });
                        setRequestedTutorialsBySemester({ ...requestedTutorialsBySemester, 'data' : tutorialsBySemester.data, 'labels' : tutorialsBySemester.labels, 'activeSelect' : id});
                    }
                    else{
                        const tutorialsByCarrer = getGraph(requestedTutorialsByCareer, responseData.data.subjects, { name: 'name', total: 'top' });
                        setRequestedTutorialsByCareer({ ...requestedTutorialsByCareer, 'data' : tutorialsByCarrer.data, 'labels' : tutorialsByCarrer.labels, 'activeSelect' : id});
                    }
                }
            });
    }

    const filterByType = (e) => {
        setFilterType(e.target.value);
        // this.filterSearch(searchText);
    };

    const filterByModality = (e) => {
        setfilterModality(e.target.value);
    };

    const filterByCategory = (e) => {
        setFilterCategory(e.target.value);
    };

    const download = (tr, e) => {

        if ( (datesRange[0] === null || datesRange[1] === null) || (datesRange[0] === '' || datesRange[1] === '') ||
            (!regexForDateFormat.test(formatDate(datesRange[0])) || !regexForDateFormat.test(formatDate(datesRange[1])))
        ) {
            setAlertMsg({
                open: true,
                className: 'warning',
                message: tr('statistics.tabs.tutorials.period.customDatePicker.alerts.rangeFormatIncorrect')
            });
            return false;
        }

        if (formatDate(datesRange[0]) > formatDate(new Date()) || formatDate(datesRange[1]) > formatDate(new Date())) {

            setAlertMsg({
                open: true,
                className: 'warning',
                message: tr('statistics.tabs.tutorials.period.customDatePicker.alerts.maxDate')
            });
            return false;

        }

        setDisableDownload(true);
        Api.getDownloadStatistic(
            userProfile.getToken(),
            userProfile.getUser().universityId,
            // '2019-08-01',
            // '2021-12-31',
            formatDate(datesRange[0]),
            formatDate(datesRange[1]),
            filterCategory,
            filterType,
            filterModality).then(response => {
                setDisableDownload(false);
                let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data:{};

                var blob = new Blob([responseData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fileSaver.saveAs(blob, exelName+'.xlsx');

            }).catch((err) => {setDisableDownload(false);Api.closeSession(err)});

    };

    const downloadAvailability = (tr, e) => {

        setDisableDownload(true);

        Api.downloadAvailabilityOfTutors(
            userProfile.getToken()
        ).then(response => {

            setDisableDownload(false);
            let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data:{};

            var blob = new Blob([responseData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fileSaver.saveAs(blob, 'tutors_availability.xlsx');

        }).catch((err) => {setDisableDownload(false);Api.closeSession(err)});

    };

    // Grid Ghapics only md and xs size
    const [fullWidthGraphics,setFullWidthGraphics] = useState(false); // When is true change the grapics in movil

    const responsiveGraphic = (graphics,index) => { // Grid grafics 
        const grid = index+1 !== graphics.length & graphics.length%2!==0?[1/2]:'100%';
        return  grid==='100%'?{ xs: grid }:{ xs: [1], md: grid };
    }

    const changeGrapic = () => { // method to change status to var "fullWidthGraphics"
        const md = defaultTheme.breakpoints.values.md;
        const w = document.body.clientWidth;
        if (w >= md) {
            setFullWidthGraphics(false);
        }else{
            setFullWidthGraphics(true);
        }
    }

    useEffect(() => { // componentDidMount 
        loadCategories = true;
        getDatas();
        window.addEventListener("resize", changeGrapic);
        changeGrapic();
        return () => { // componentWillUnmount
            window.removeEventListener("resize", changeGrapic);
        }
    },[])

    return <Translation>
        {
            (t, { i18n }) => {
                return <Grid id="statistics" item xs={12}>
                    {/* ==================== Filters and tabs =================== */}
                    <AppBar position="static" component="div" color="default" style={{border: '1px solid rgb(232, 232, 232)', padding: '4px 15px', borderRadius: 4, background: 'white', }}>
                        {/*<Toolbar className="tool-bar">
                            <Box>
                                <Tabs value={0} indicatorColor="primary" textColor="primary" style={{ flex : 1 }}>
                                    <Tab label={t('statistics.tabs.tutorials.title')} />
                                    <Tab label={t('statistics.tabs.comingSoon.title')} />
                                </Tabs>
                            </Box>
                        </Toolbar>*/}
                        <Toolbar className="tool-bar" style={{marginTop:20}}>

                            <Box width={{ xs: [1], md: [3/12] }} display="flex" alignItems="center" flexWrap="wrap" justifyContent="flex-end">
                                <FormControl fullWidth={true} style={{marginRight: 15}}>
                                    <InputLabel className="colorsquare yellow" htmlFor="category">{t('users.form.tutor.tabSubjects.selectFilterByCategory.label')}</InputLabel>
                                    <Select
                                        value={filterCategory}
                                        onChange={filterByCategory}
                                        inputProps={{
                                            name: 'filterCategory',
                                            id: 'filterCategory'
                                        }}
                                        style={{fontSize: 14, marginBottom: 10}}
                                        input={<Input classes={{
                                            underline: 'remove-underline'
                                        }} />}
                                        classes={{
                                            selectMenu:'borderInputs'
                                        }}
                                    >
                                        <MenuItem value="0" key="0">{t('users.form.tutor.tabSubjects.selectFilterByCategory.options.all')}</MenuItem>
                                        {categories.map((i, k) => {
                                            return (
                                                <MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box width={{ xs: [1], md: true }} flexBasis={{md:"0"}} flexGrow={{md:"1"}}  display="flex" alignItems="center" flexWrap="wrap" justifyContent="flex-end">
                                <FormControl fullWidth={true} style={{marginRight: 15}}>
                                    <InputLabel className="colorsquare green" htmlFor="type">{t('users.form.tutor.tabSubjects.selectFilterByType.label')}</InputLabel>
                                    <Select
                                        value={filterType}
                                        onChange={filterByType}
                                        inputProps={{
                                            name: 'filterType',
                                            id: 'filterType'
                                        }}
                                        style={{fontSize: 14, marginBottom: 10}}
                                        input={<Input classes={{
                                            underline: 'remove-underline'
                                        }} />}
                                        classes={{
                                            selectMenu:'borderInputs'
                                        }}
                                    >
                                        <MenuItem value="all">{t('users.form.tutor.tabSubjects.selectFilterByType.options.all')}</MenuItem>
                                        <MenuItem value="Individual">{t('users.form.tutor.tabSubjects.selectFilterByType.options.individual')}</MenuItem>
                                        <MenuItem value="Grupal">{t('users.form.tutor.tabSubjects.selectFilterByType.options.group')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box width={{ xs: [1], md: true }} flexBasis={{md:"0"}} flexGrow={{md:"1"}} display="flex" alignItems="center" flexWrap="wrap" justifyContent="flex-end">
                                <FormControl fullWidth={true} style={{marginRight: 15}}>
                                    <InputLabel className="colorsquare blue" htmlFor="modality">{t('users.form.tutor.tabSubjects.selectFilterByModality.label')}</InputLabel>
                                    <Select
                                        value={filterModality}
                                        onChange={filterByModality}
                                        inputProps={{
                                            name: 'filterModality',
                                            id: 'filterModality'
                                        }}
                                        style={{fontSize: 14, marginBottom: 10}}
                                        input={<Input classes={{
                                            underline: 'remove-underline'
                                        }} />}
                                        classes={{
                                            selectMenu:'borderInputs'
                                        }}
                                    >
                                        <MenuItem value="all">{t('users.form.tutor.tabSubjects.selectFilterByModality.options.all')}</MenuItem>
                                        <MenuItem value="Presencial">{t('users.form.tutor.tabSubjects.selectFilterByModality.options.inperson')}</MenuItem>
                                        <MenuItem value="Online">{t('users.form.tutor.tabSubjects.selectFilterByModality.options.online')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box width={{ xs: [2], md: true }} mt={{xs: "-8px", md: "0px"}} flexBasis={{md:"0"}} flexGrow={{md:"2"}}  display="flex" alignItems="center" flexWrap="wrap" justifyContent={{xs:"flex-start", md: "flex-end"}}>
                                {/*<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ i18n.language==='en'?enLocale:esLocale }>
                                   <InputLabel className="colorsquare pink" htmlFor="" style={{fontSize: 12, marginBottom: 7, position: 'relative', width: '100%'}}>{t('statistics.startDate')}</InputLabel>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        value={dateRange.start}
                                        maxDate={dateRange.end}
                                        onChange={ e => handleDateChange(e,'start')}
                                        className="date-picker"
                                        label={''}
                                        format="dd/MM/yyyy"
                                    />
                                </MuiPickersUtilsProvider>*/}


                                {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language === 'en' ? enLocale : esLocale}>
                                    <DateRangePicker
                                            renderInput={(startProps, endProps) => (
                                                <React.Fragment>
                                                    <Box>
                                                        <InputLabel className="colorsquare pink" htmlFor="" style={{ fontSize: 12, marginBottom: 7, position: 'relative', width: '100%' }}>{t('statistics.startDate')}</InputLabel>
                                                        <input ref={startProps.inputRef} {...startProps.inputProps} className="date-picker" />
                                                    </Box>
                                                    <Box
                                                        position="relative"
                                                        width="25px"
                                                    >
                                                        <span style={{
                                                            position: 'absolute',
                                                            top: 15,
                                                            paddingLeft: 4
                                                        }}>{t('statistics.tabs.tutorials.period.customDatePicker.to')}</span>
                                                    </Box>
                                                    <Box>
                                                        <InputLabel className="colorsquare pink" htmlFor="" style={{ fontSize: 12, marginBottom: 7, position: 'relative', width: '100%' }}>{t('statistics.endDate')}</InputLabel>
                                                        <input ref={endProps.inputRef} {...endProps.inputProps} className="date-picker" />
                                                    </Box>
                                                </React.Fragment>
                                            )}
                                            value={datesRange}
                                            allowSameDateSelection={true}
                                            maxDate={new Date()}
                                            inputFormat={t('dateFormat')}
                                            PopperProps={{
                                                className: "popec",
                                                datatemp: (showErrorIncorrectRange ? '*' + t('statistics.tabs.tutorials.period.customDatePicker.alerts.exceededRange') : '')
                                            }}
                                            onChange={(newValue) => {

                                                let newDateIniTemp = moment(newValue[0]),
                                                    newDateEndTemp = moment(newValue[1]),
                                                    dateIniTemp = moment(datesRange[0]).format('YYYY-MM-DD'),
                                                    tempD, iniDateMore6months;

                                                setErrorIncorrectRange(false);

                                                if (newDateIniTemp.format('YYYY-MM-DD') !== dateIniTemp) { // Initial date was changed

                                                    if (newValue[1] !== null && moment(newValue[1]).isValid()) {

                                                        tempD = new Date(newValue[1]);
                                                        tempD.setMonth(tempD.getMonth() - 6);
                                                        iniDateMore6months = moment(tempD).format('YYYY-MM-DD');

                                                        if ((newDateIniTemp.format('YYYY-MM-DD') < iniDateMore6months) &&
                                                            newDateIniTemp.isValid()
                                                        ) {
                                                            newValue[1] = null;
                                                            setErrorIncorrectRange(true);
                                                        }

                                                    }

                                                } else { // Final date was changed

                                                    if (newValue[0] !== null && moment(newValue[0]).isValid()) {

                                                        tempD = new Date(newValue[0]);
                                                        tempD.setMonth(tempD.getMonth() + 6);
                                                        iniDateMore6months = moment(tempD).format('YYYY-MM-DD');

                                                        if ((newDateEndTemp.format('YYYY-MM-DD') > iniDateMore6months) &&
                                                            newDateEndTemp.isValid()
                                                        ) {

                                                            newValue[0] = newValue[1];
                                                            // newValue[1] = null;
                                                            setErrorIncorrectRange(true);
                                                        }

                                                    }

                                                }
                                                setDatesRange(newValue);
                                            }}
                                        /> 
                                </LocalizationProvider>
                                 */}
                                <InputLabel className="colorsquare pink" htmlFor="" style={{ fontSize: 12, marginBottom: 7, position: 'relative', width: '100%' }}>{t('statistics.startDate')}</InputLabel>
                                <RangePicker
                                    className="date-picker"
                                    locale={i18n.language === 'en' ? enLocale : esLocale}
                                    disabledDate={disabledDate}
                                    onChange={(newValue) => {

                                        let newDateIniTemp = moment(newValue[0]),
                                            newDateEndTemp = moment(newValue[1]),
                                            dateIniTemp = moment(datesRange[0]).format('YYYY-MM-DD'),
                                            tempD, iniDateMore6months;

                                        setErrorIncorrectRange(false);
                                        if (newDateIniTemp.format('YYYY-MM-DD') !== dateIniTemp) { // Initial date was changed

                                            if (newValue[1] !== null && moment(newValue[1]).isValid()) {

                                                tempD = new Date(newValue[1]);
                                                tempD.setMonth(tempD.getMonth() - 6);
                                                iniDateMore6months = moment(tempD).format('YYYY-MM-DD');

                                                if ((newDateIniTemp.format('YYYY-MM-DD') < iniDateMore6months) &&
                                                    newDateIniTemp.isValid()
                                                ) {
                                                    newValue[1] = null;
                                                    setErrorIncorrectRange(true);
                                                }

                                            }

                                        } else { // Final date was changed

                                            if (newValue[0] !== null && moment(newValue[0]).isValid()) {

                                                tempD = new Date(newValue[0]);
                                                tempD.setMonth(tempD.getMonth() + 6);
                                                iniDateMore6months = moment(tempD).format('YYYY-MM-DD');

                                                if ((newDateEndTemp.format('YYYY-MM-DD') > iniDateMore6months) &&
                                                    newDateEndTemp.isValid()
                                                ) {

                                                    newValue[0] = newValue[1];
                                                    // newValue[1] = null;
                                                    setErrorIncorrectRange(true);
                                                }

                                            }

                                        }
                                        setDatesRange(newValue);
                                    }}
                                />
                            </Box>

                            <Box width={{ xs: [1], md: [1/12] }} style={{marginTop: 9, minWidth:120}} display="flex" alignItems="center" flexWrap="wrap" justifyContent="flex-start">
                                <Button variant="contained" color="primary" onClick={ () => {getDatas(t, 1)} }
                                    style={{width:"100%"}}
                                    disabled={disableFilter}
                                    classes={{
                                        root: '',
                                        disabled: 'disableFilter'
                                    }}>
                                    {!disableFilter?<i className="icon-magnifier"></i>:''}&nbsp;
                                    {!disableFilter?t('statistics.filter'):t('statistics.filterLoading')}
                                </Button>
                            </Box>

                        </Toolbar>
                    </AppBar>

                    {/* ==================== period =================== */}
                    <Box bgcolor="white" display="flex" flexWrap="wrap" alignItems="center" mt={5} mb={5} border={1} pt={4} pb={4} borderRadius="4px" borderColor="#e8e8e8" className="preriods">
                        <Grid item md={true} xs={6} className="report">
                            <Box pl={4} pr={4} mt={4} mb={3} display="flex" alignItems="center" justifyContent="center" borderRight={1} borderColor="#e8e8e8">
                                <Typography className="info" component="div">
                                    <Box component="span" className="title" width="100%" fontSize={{ xs: '16px', sm: '19px' }} color="text.primary" display="block">{t('statistics.tabs.tutorials.period.completeLessons.t1')}</Box>
                                    <Box component="span" className="subtitle1" width="100%" fontSize={{ xs: '14px', sm: 'body1.fontSize' }} color="text.secondary"  display="block">{t('statistics.tabs.tutorials.period.completeLessons.t2')}</Box>
                                </Typography>
                                <Typography component="div">
                                    <Box component="span" className="no" fontSize={{ xs: '1.8rem', sm: 'h3.fontSize' }} fontWeight="300" pl={1} color="primary.main">{ period.lessonsTotals }</Box>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={true} xs={6} className="report">
                            <Box pl={4} mt={4} mb={4} pr={3} display="flex" alignItems="center" justifyContent="center" borderRight={{ xs: 0, md: 1 }} borderColor="#e8e8e8">
                                <Typography className="info" component="div">
                                    <Box component="span" className="title" width="100%" fontSize={{ xs: '16px', sm: '19px' }} color="text.primary" display="block">{t('statistics.tabs.tutorials.period.tutoredHours.t1')}</Box>
                                    <Box component="span" className="subtitle1" width="100%" fontSize={{ xs: '14px', sm: 'body1.fontSize' }} color="text.secondary"  display="block">{t('statistics.tabs.tutorials.period.tutoredHours.t2')}</Box>
                                </Typography>
                                <Typography component="div">
                                    <Box component="span" className="no" fontSize={{ xs: '1.8rem', sm: 'h3.fontSize' }} fontWeight="300" pl={1} color="primary.main">{period.hoursTotals || 0}</Box>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={true} xs={6} className="report">
                            <Box pl={4} mt={4} mb={4} pr={3} display="flex" alignItems="center" justifyContent="center" borderRight={1} borderColor="#e8e8e8">
                                <Typography className="info" component="div">
                                    <Box component="span" className="title" width="100%" fontSize={{ xs: '16px', sm: '19px' }} color="text.primary" display="block">{t('statistics.tabs.tutorials.period.canceledLessons.t1')}</Box>
                                    <Box component="span" className="subtitle1" width="100%" fontSize={{ xs: '14px', sm: 'body1.fontSize' }} color="text.secondary"  display="block">{t('statistics.tabs.tutorials.period.canceledLessons.t2')}</Box>
                                </Typography>
                                <Typography component="div">
                                    <Box component="span" className="no" fontSize={{ xs: '1.8rem', sm: 'h3.fontSize' }} fontWeight="300" pl={1} color="primary.main">{ period.lessonsCancelled }</Box>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={true} xs={6} className="report">
                            <Box pl={4} mt={4} mb={4} pr={3} display="flex" alignItems="center" justifyContent="center" borderRight={{ xs: 0, md: 1 }} borderColor="#e8e8e8">
                                <Typography className="info" component="div">
                                    <Box component="span" className="title" width="100%" fontSize={{ xs: '16px', sm: '19px' }} color="text.primary" display="block">{t('statistics.tabs.tutorials.period.autocanceledLessons.t1')}</Box>
                                    <Box component="span" className="subtitle1" width="100%" fontSize={{ xs: '14px', sm: 'body1.fontSize' }} color="text.secondary"  display="block">{t('statistics.tabs.tutorials.period.autocanceledLessons.t2')}</Box>
                                </Typography>
                                <Typography component="div">
                                    <Box component="span" className="no" fontSize={{ xs: '1.8rem', sm: 'h3.fontSize' }} fontWeight="300" pl={1} color="primary.main">{ period.lessonsAutocancelled }</Box>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={true} xs={12} className="period">
                            <Box pl={3} pr={3} display="flex" flexWrap="wrap" justifyContent="center">
                                <Typography className="info" component="div">
                                    <Box fontSize="13px" color="text.primary" width="100%" component="h5" mb={1.5} mt={0} className="title">{t('statistics.tabs.tutorials.period.period')}</Box>
                                    <Box fontSize={{ xs: '14px', sm: 'body1.fontSize' }} color="text.primary" width="100%" component="span" display="block" mb={4} className="subtitle">{ period.period }</Box>
                                    <Button disabled={disableDownload} variant="contained" color="primary" onClick={ e => download(t,e) }>{!disableDownload?t('statistics.tabs.tutorials.period.btnReport'):t('statistics.tabs.tutorials.period.btnReportLoading')}</Button>
                                </Typography>
                            </Box>
                        </Grid>
                    </Box>

                    {/* ==================== Btns general datas =================== */}
                    <Box fontSize="20px" fontWeight={500} color="text.primary" mb={4} mt={0.6} pt={2.4} component="h3">{t('statistics.tabs.tutorials.generalDatas.title')}</Box>
                    <Box display="flex" ml={-1.3} mr={-1.3} mb={2} flexWrap="wrap" className="general-datas">
                        {generalDatas.map( (data,index ) =>
                            <Box pl={1.3} pr={1.3} mb={3} width={{ sm: [1/2], md: [1/3], lg: [1/4] }} key={index} onClick={ () => setAnchorActive(data.anchor) }>
                                <AnchorLink href={ '#'+data.anchor} offset='60'>
                                    <Card className="card-data">
                                        <CardContent className="content">
                                            <Typography component="div">
                                                <div className="point" style={{ backgroundColor : data.color }}></div>
                                                <Box fontSize="20px" pr={1} component="i" className={'icon-'+data.icon+' icon' }></Box>
                                                <Box fontSize={customFontWeight(data.title)} color="text.primary" mb={2} mt={0.6} pt={2.4} component="p">{t(data.title)}</Box>
                                                <Box fontSize="caption.fontSize" color="primary.main" mb={1} fontWeight="500" component="span" display="block" width="100%" dangerouslySetInnerHTML={{__html: data.data}}></Box>
                                                <Box fontSize="caption.fontSize" color="text.primary" textAlign="right" fontWeight="500" component="span" display="block" width="100%">{t(data.textTotal)}: {data.total}</Box>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </AnchorLink>
                            </Box>
                        )}
                    </Box>

                    {/* ==================== Tutores =================== */}
                    <Box fontSize="20px" fontWeight={500} color="text.primary" mb={4} mt={0.6} pt={2.4} component="h3">{t('statistics.tabs.tutorials.graphicsTutors.title')}</Box>
                    <Box display="flex" ml={-1.3} mr={-1.3} mb={2} flexWrap="wrap" className="graphics">
                        {dataTutores.map( (data,index) => {
                            const color =  getColor(data.index);
                            const responsive = responsiveGraphic(dataTutores,index);

                            return <Box pl={1.3} pr={1.3} mb={3} width={responsive} key={index}>
                                <Box fontSize="16px" fontWeight={400} color="text.primary" mb={4} mt={0.6} pt={2.4} component="h3">{t(data.title)}</Box>
                                <Box bgcolor="#fff" borderRadius="4px" border={1} pl={8} pr={8} pt={4} pb={4} textAlign="center" className="graphic" id={data.index} {... anchorActive === data.index && { style : { borderColor : color, borderWidth: '2px' }} }>
                                    <Typography component="div" className="title">
                                        <Box fontSize="14px" color="text.primary" component="span" mb={3} pl={5} className="text"><div className="point" style={{ backgroundColor : color }}></div>{t(data.label)}</Box>
                                    </Typography>
                                    <Box component="div" mt={3} className={ fullWidthGraphics || responsive.xs==='100%'?'bar':'horizontal-bar' }>
                                        { fullWidthGraphics || responsive.xs==='100%' ? <Bar labels={data.labels} data={data.data} color={color} label={t(data.label)} labelsTitle={data.labelsTitle}></Bar> :
                                            <HorizontalBar labels={data.labels} data={data.data} color={color} label={t(data.label)} labelsTitle={data.labelsTitle}></HorizontalBar> }
                                    </Box>
                                </Box>
                            </Box>
                        })}
                    </Box>

                    {/* ==================== Students =================== */}
                    <Box fontSize="20px" fontWeight={500} color="text.primary" mb={4} mt={0.6} pt={2.4} component="h3">{t('statistics.tabs.tutorials.graphStudents.title')}</Box>
                    <Box fontSize="16px" fontWeight={400} color="text.primary" mb={4} mt={0.6} pt={2.4} component="h3">{t(dataEstudiantes.title)}</Box>
                    <Box bgcolor="#fff" borderRadius="4px" textAlign="center" border={1} mt={3} pl={8} pr={8} pt={4} pb={4} mb={5} className="graphic" id={dataEstudiantes.index} {... anchorActive === dataEstudiantes.index && { style : { borderColor : getColor(dataEstudiantes.index), borderWidth: '2px' }} }>
                        <Typography component="div" className="title">
                            <Box fontSize="14px" color="text.primary" component="span" mb={3} pl={5} className="text"><div className="point" style={{ backgroundColor : getColor(dataEstudiantes.index) }}></div>{t(dataEstudiantes.label)}</Box>
                        </Typography>
                        <Box component="div" mt={3} className="bar">
                            <Bar labels={dataEstudiantes.labels} data={dataEstudiantes.data} color={getColor(dataEstudiantes.index)} label={t(dataEstudiantes.label)} labelsTitle={dataEstudiantes.labelsTitle}></Bar>
                        </Box>
                    </Box>

                    {/* ==================== Matters =================== */}
                    <Box fontSize="20px" fontWeight={500} color="text.primary" mb={4} mt={0.6} pt={2.4} component="h3">{t('statistics.tabs.tutorials.graphsSubjects.title')}</Box>
                    <Box display="flex" ml={-1.3} mr={-1.3} mb={2} flexWrap="wrap" className="graphics">
                        {dataMaterias.map( (data,index) => {
                            const color = data.index==='topHoursSubjects'?'#00C0FF':getColor(data.index);
                            const responsive = responsiveGraphic(dataMaterias,index);

                            return <Box pl={1.3} pr={1.3} mb={3} width={responsive} key={index}>
                                <Box fontSize="16px" fontWeight={400} color="text.primary" mb={4} mt={0.6} pt={2.4} component="h3">{t(data.title)}</Box>
                                <Box bgcolor="#fff" borderRadius="4px" border={1} pl={8} pr={8} pt={4} pb={4} textAlign="center" className="graphic" id={data.index} {... anchorActive === data.index && { style : { borderColor : color, borderWidth: '2px' }} }>
                                    <Typography component="div" className="title">
                                        <Box fontSize="14px" color="text.primary" component="span" mb={3} pl={5} className="text"><div className="point" style={{ backgroundColor : color }}></div>{t(data.label)}</Box>
                                    </Typography>
                                    <Box component="div" mt={3} className={ fullWidthGraphics || responsive.xs==='100%'?'bar':'horizontal-bar' }>
                                        { fullWidthGraphics || responsive.xs==='100%' ? <Bar labels={data.labels} data={data.data} color={color} label={t(data.label)} labelsTitle={data.labelsTitle}></Bar> :
                                            <HorizontalBar labels={data.labels} data={data.data} color={color} label={t(data.label)} labelsTitle={data.labelsTitle} className="horizontal-bar"></HorizontalBar> }
                                    </Box>
                                </Box>
                            </Box>
                        })}
                    </Box>

                    {/* ==================== Días con mayor numero de tutorías =================== */}
                    <Box fontSize="20px" fontWeight={500} color="text.primary" mb={4} mt={0.6} pt={2.4} component="h3">{t('statistics.tabs.tutorials.days.title')}</Box>
                    <Box display="flex" ml={-1.3} mr={-1.3} mb={2} flexWrap="wrap">
                        {/* ==================== impartidas =================== */}
                        <Box pl={1.3} pr={1.3} width={{ xs: [1], md : [1/2]}}>
                            <Box fontSize="16px" fontWeight={400} color="text.primary" mb={4} mt={0.6} pt={2.4} component="h3">{t(daysWithBestTutorialsImparted.title)}</Box>
                            <Box bgcolor="#fff" borderRadius="4px" textAlign="center" border={1} mt={3} pl={8} pr={8} pt={4} pb={4} mb={5} className="graphic" id={daysWithBestTutorialsImparted.index} {... anchorActive === daysWithBestTutorialsImparted.index && { style : { borderColor : getColor(daysWithBestTutorialsImparted.index), borderWidth: '2px' }} }>
                                <Typography component="div" className="title">
                                    <Box fontSize="14px" color="text.primary" component="span" mb={3} pl={5} className="text"><div className="point" style={{ backgroundColor: getColor(daysWithBestTutorialsImparted.index) }}></div>{t(daysWithBestTutorialsImparted.label)}</Box>
                                </Typography>
                                <Box component="div" mt={3} className="line">
                                    <Line labels={daysWithBestTutorialsImparted.labels} data={daysWithBestTutorialsImparted.data} color={getColor(daysWithBestTutorialsImparted.index)} label={t(daysWithBestTutorialsImparted.label)}></Line>
                                </Box>
                            </Box>
                        </Box>
                        {/* ==================== Canseladas =================== */}
                        <Box pl={1.3} pr={1.3} width={{ xs: [1], md : [1/2]}}>
                            <Box fontSize="16px" fontWeight={400} color="text.primary" mb={4} mt={0.6} pt={2.4} component="h3">{t(daysWithBestTutorialsCanseled.title)}</Box>
                            <Box bgcolor="#fff" borderRadius="4px" textAlign="center" border={1} mt={3} pl={8} pr={8} pt={4} pb={4} mb={5} className="graphic" id={daysWithBestTutorialsCanseled.index} {... anchorActive === daysWithBestTutorialsCanseled.index && { style : { borderColor : getColor(daysWithBestTutorialsCanseled.index), borderWidth: '2px' }} }>
                                <Typography component="div" className="title">
                                    <Box fontSize="14px" color="text.primary" component="span" mb={3} pl={5} className="text"><div className="point" style={{ backgroundColor: getColor(daysWithBestTutorialsCanseled.index) }}></div>{t(daysWithBestTutorialsCanseled.label)}</Box>
                                </Typography>
                                <Box component="div" mt={3} className="line">
                                    <Line labels={daysWithBestTutorialsCanseled.labels} data={daysWithBestTutorialsCanseled.data} color={getColor(daysWithBestTutorialsCanseled.index)} label={t(daysWithBestTutorialsCanseled.label)}></Line>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {/* ==================== Tutorías solicitadas =================== */}
                    <Box display="flex" ml={-1.3} mr={-1.5} mb={2} flexWrap="wrap" className="requested-tutorials">
                        {/* ==================== Por carrera =================== */}
                        <Box pl={1.3} pr={1.3} width={{ xs: [1], md : [1/2]}} flexGrow={1} className="content-graphic">
                            <Box fontSize="16px" fontWeight={400} color="text.primary" mb={4} mt={3} pl={5} position="relative" component="h3"><div className="point" style={{ backgroundColor: getColor(requestedTutorialsByCareer.index) }}></div>{t(requestedTutorialsByCareer.title)}</Box>
                            <Box bgcolor="#fff" borderRadius="4px" textAlign="center" border={1} mt={3} pl={8} pr={8} pt={4} pb={4} mb={5} className="graphic content-doughnut" id={requestedTutorialsByCareer.index} {... anchorActive === requestedTutorialsByCareer.index && { style : { borderColor : getColor(requestedTutorialsByCareer.index), borderWidth: '2px' }} }>
                                <Box display="flex" flexWrap="wrap" component="div" mt={3} className="doughnut">
                                    <Doughnut data={requestedTutorialsByCareer.data} labels={requestedTutorialsByCareer.labels} dataSelect={requestedTutorialsByCareer.selectDatas} idSelect={requestedTutorialsByCareer.activeSelect} handleDataSelect={ id => handleDataSelect(id,'career')} idName="career"></Doughnut>
                                </Box>
                            </Box>
                        </Box>
                        {/* ==================== Por semestre =================== */}
                        <Box pl={1.3} pr={1.3} width={{ xs: [1], md : [1/2]}} flexGrow={1} className="content-graphic">
                            <Box fontSize="16px" fontWeight={400} color="text.primary" mb={4} mt={3} pl={5} position="relative" component="h3"><div className="point" style={{ backgroundColor: getColor(requestedTutorialsBySemester.index) }}></div>{t(requestedTutorialsBySemester.title)}</Box>
                            <Box bgcolor="#fff" borderRadius="4px" textAlign="center" border={1} mt={3} pl={8} pr={8} pt={4} pb={4} mb={5} className="graphic content-doughnut" id={requestedTutorialsBySemester.index} {... anchorActive === requestedTutorialsBySemester.index && { style : { borderColor : getColor(requestedTutorialsBySemester.index), borderWidth: '2px' }} }>
                                <Box display="flex" flexWrap="wrap" component="div" mt={3} className="doughnut">
                                    <Doughnut data={requestedTutorialsBySemester.data} labels={requestedTutorialsBySemester.labels} dataSelect={requestedTutorialsBySemester.selectDatas} idSelect={requestedTutorialsBySemester.activeSelect} handleDataSelect={ id => handleDataSelect(id,'semester')} idName="semester"></Doughnut>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <AlertMessage open={alertMsg.open} variant={alertMsg.className} message={alertMsg.message} eventClose={closeMessage} />
                </Grid>
            }
        }
    </Translation>
}

export default Statistics;