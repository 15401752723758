import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";

// handle of languages
import { Translation } from 'react-i18next';

const footerStyle = theme => ({
  footer: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
    fontSize: 14,
    color: "#000",
    textAlign: 'center'
  },
  a: {
    color: "#3f51b5",
    textDecoration: "none",
    backgroundColor: "transparent",
    '&:hover,&:focus': {
      color: "#3f51b5"
    }
  },
  container: {
  }
});

class Footer extends Component {

	render(){

		const { classes } = this.props;

		return (
			    <Translation>
                    {
                        (t, { i18n }) => {
			            return <footer className={classes.footer}>
		                  <div className={classes.container}>
		                    <p>
			                    <span>
			                        &copy; {1900 + new Date().getYear()}{" "}
    			                    <a href="https://linc.io" target="_blank" rel="noopener noreferrer" className={classes.a}>
    			                        {t('footer.copyright')}
    			                    </a>
			                    </span>
			                </p>
		                  </div>
		                </footer>
		            }
                }
            </Translation>
		);

	}

}

export default withStyles(footerStyle)(Footer);