
/*========= Config ===========*/

let productionMode = true,
    appVersion = '1.7.19',
    local = false;

/*===========================*/
// console.log(process.env.NODE_ENV);
// Production Mode Default
let urlApi = 'https://institutions-api.linc.mx/api/';

if (!productionMode) { // Development Mode

	urlApi = 'http://18.233.11.113:3000/api/';

}

if (local) { // Local Development Mode

	urlApi = 'http://localhost:3000/api/';

}

export {
	urlApi,
	appVersion
}