import React, { useState, useEffect  } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { AutoSizer, Column, Table } from 'react-virtualized';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
// handle of languages
import { Translation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

// Api
import SubjectsApi from "../../../api/Subjects/SubjectsApi";

// Models
import UserProfile from "../../../models/UserProfile";
import Link from '@material-ui/core/Link';
// import {styles} from "../../assets/jss/modules/mainStyles";

const Api = new SubjectsApi();
const userProfile = new UserProfile();

const styles = (theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  table: {
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    '& .ReactVirtualized__Table__headerRow': {
      flip: false,
      paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
    },
  },
  tableRow: {
    cursor: 'pointer'
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  customTableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: "#f9f9f9",
    }
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: 'initial',
  },
  customHeaderClass: {
    backgroundColor: "#666",
    color: "#fff"
  }
});

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#fff',
    color: theme.palette.common.white,
    textTransform: "uppercase"
  }
}))(TableCell);

class MuiVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };

  getRowClassName = ({ index }) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  returnRowsCells = (columns, row) => {
    const {classes, rowHeight, onRowClick} = this.props;

  	let cells = [];
  	
  	for (var [i, inst] of columns.entries()) {

      if (inst.dataKey !== 'activeAlert') {
    	
        cells.push(<CustomTableCell 
          component="div" 
          key={i}
          className={clsx(classes.tableCell, classes.flexContainer, {
            [classes.noClick]: onRowClick == null,
          })}
          style={{height: rowHeight, fontSize: 12, width:'25%'}}
        >
          {row[inst.dataKey]}
        </CustomTableCell>);
      
      }
        
    }
    
    return cells;
  
  }

  rowRenderer = ({ key, index, isScrolling, isVisible, style }) => {
    const { columns, classes, rowHeight, onRowClick, allRows } = this.props;
    let st = {};

    if (allRows[index].activeAlert)
      style.backgroundColor = "#fffff2";

    style.display = 'flex';
    style.width = '100%';
    style.paddingRight = 0;
   
    return (
      <TableRow
       key={key}
       component="div"
       style={style}
       className={classes.customTableRow}
      >
        {this.returnRowsCells(columns, allRows[index])}
      </TableRow>
    );
  };

  headerRowRenderer = ({className, columns, style}) => {
    style.paddingRight = 0;
    return (<div
            className={className}
            role='row'
            style={style}
       >
      {columns}
    </div>);
  };

  headerRenderer = ({ label, columnIndex }) => {
    const { headerHeight, columns, classes } = this.props;
    let customStyle = { height: headerHeight };
    if (columns[columnIndex].dataKey !== 'activeAlert') {

      if (columns[columnIndex].dataKey === 'fullName')
         customStyle.borderTopLeftRadius = 5;

      if (columns[columnIndex].dataKey === 'options')
         customStyle.borderTopRightRadius = 5;   
    
         return (
        <TableCell
          component="div"
          className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
          style={customStyle}
          align={columns[columnIndex].numeric || false ? 'right' : 'left'}
        >
          <span>{label}</span>
        </TableCell>
      );
    } else {
      return '';
    }
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, dataSubject, ...tableProps } = this.props;
    return (
      <Translation>
             {
                (t, { i18n }) => {
                    
                    return ( 
                        <React.Fragment> 
                            <AutoSizer
                            style={{width: '100%'}}
                            >
                              {({ height, width }) => (
                                <Grid container className={classes.container}>

                                <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{marginBottom:10}}>
                                  <Typography variant="subtitle1">
                                    {dataSubject.name} 
                                    {dataSubject.limit !== ''?<span style={{marginLeft: 12}}><i className="icon-users2" style={{fontSize:20}}></i> {dataSubject.limit}</span>:''}
                                      <span className={"customChipSpan "+(dataSubject.type=='Grupal'?'group':'individual')} style={{marginLeft: 12}}>
                                      <i className={(dataSubject.modality==='Online'?'icon-online custom-icon-online':'icon-in-person custom-icon-in-person')}></i> {dataSubject.translatedModality}</span> 
                                      
                                  </Typography>
                                </Grid>

                                <Grid item 
                                      className={classes.grid} 
                                      style={{ 
                                        border: '1px solid rgb(232,232,232)',
                                        boxShadow: 'initial',
                                        borderTopLeftRadius: 5,
                                        borderTopRightRadius: 5
                                      }} 
                                      xs={12} sm={12} md={12}
                                >
                                <Table
                                  height={height}
                                  width={width}
                                  rowHeight={rowHeight}
                                  gridStyle={{
                                    direction: 'inherit',
                                  }}
                                  headerHeight={headerHeight}
                                  className={classes.table}
                                  style={{minWidth: 800}}
                                  {...tableProps}
                                  rowClassName={this.getRowClassName}
                                  rowRenderer={this.rowRenderer}
                                  headerClassName={classes.customHeaderClass}
                                  headerRowRenderer={this.headerRowRenderer}
                                >
                                  {columns.map(({ dataKey, ...other }, index) => {
                                    return (
                                      <Column
                                        key={dataKey}
                                        headerRenderer={(headerProps) =>
                                          this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                          })
                                        }
                                        className={classes.flexContainer}
                                        dataKey={dataKey}
                                        {...other}
                                      />
                                    );
                                  })}
                                </Table>

                                </Grid>
                                </Grid>
                              )}
                            </AutoSizer>
            
                        </React.Fragment> 
                    )
                }
            }
           </Translation>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number.isRequired,
      widthString: PropTypes.string
    }),
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
  allRows: PropTypes.array,
  texts: PropTypes.object
};

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

// ---

export default function ReactVirtualizedTable({subjectId}) {

  const [tutors, setTutors] = useState([]);
  const [dataSubject, setSubjet] = useState({});

  const getOneRow = (id) => {
      
    Api.getTutors(
         userProfile.getToken(),
         id).then(response => {
            
         let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
           
         if (responseData.modal) {
            console.log(responseData.msg);
         } else {
         
            for(var ints of responseData.data.tutors) {
              ints.options = [(
                <Link href={"/users/tutors/"+ints.id} key={ints.id+'i'} target="_blank" rel="noopener" rel="noreferrer">
                    <IconButton color="primary" style={{marginTop:0, color: '#3498db', display: 'inline-block'}}>
                          <VisibilityIcon />
                    </IconButton>
                </Link>
               )];
            }
            setTutors(responseData.data.tutors);
            setSubjet(responseData.data.subject);
         }

      }).catch((err) => {Api.closeSession(err)});
    
  };
  
  useEffect(() => {

    getOneRow(subjectId);

 }, [subjectId])

  return (
    <Translation>
      {
        (t, { i18n }) => {

          return (
            <Paper style={{ height: 400, width: '100%', border: 0 }}>
              <VirtualizedTable
                rowCount={tutors.length}
                rowGetter={({ index }) => tutors[index]}
                columns={[
                  {
                    width: 250,
                    label: t('subjects.tutorsModal.table.columns.fullName'),
                    dataKey: 'fullName',
                    widthString: '30%'
                  },
                  {
                    width: 250,
                    label: t('subjects.tutorsModal.table.columns.email'),
                    dataKey: 'email',
                    widthString: '30%'
                  },
                  {
                    width: 250,
                    label: t('subjects.tutorsModal.table.columns.lastActivity'),
                    dataKey: 'lastActivity',
                    widthString: '25%'
                  },
                  {
                    width: 250,
                    label: '',
                    dataKey: 'options',
                    widthString: '15%'
                  },
                  {
                    width: 0,
                    label: '',
                    dataKey: 'activeAlert',
                    widthString: '0%'
                  }
                ]}
                allRows={tutors}
                dataSubject={dataSubject}
              />
            </Paper>
          );

        }
     }
    </Translation>
  );
}