import * as types from '../actions/global';

const initialState = {
	color: '#2188FC',
	section: {
		title: '',
		icon: ''
	},
	message: {
		status: false,
		message: '',
		type: ''
	}
}

const reducer = ( state = initialState, { type, payload }) => {
	
	switch(type){
		case types.GET_SECTION_TITLE:
		    return { ...state, section : payload }
		case types.MAIN_COLOR:
		    return { ...state, color : payload }
		case types.GET_MESSAGE: {
		    return { ...state, message : { ...state.message, message : payload.message, type : payload.type } }
		}
		case types.STATUS_MESSAGE:{
		    return { ...state, 'message' : { ...state.message, status : payload } }        
		}
		default:
		    return state
	}
}

export default reducer;