import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { getSectionTitle } from '../../redux/actions/global';
import { activeSub, compactMenu } from '../../redux/actions/menu';
import { Toolbar, IconButton,Box, List, ListItem, ListItemIcon,Collapse, ListItemText } from '@material-ui/core'
import { createTheme } from '@mui/material/styles';
const defaultTheme = createTheme();

const Menu = ({ logo, bgColor, getSectionTitle, title, compactMenu, menu, activeSub, history, match }) => { 
    
    const [activeSubMenuCompact, setActiveSubMenuCompact] = useState(false);

    const handleCompact = () => {
        setActiveSubMenuCompact(false);
        if (menu.compact) {
            compactMenu(false);
        }else{
            compactMenu(true);
            activeSub('');
        }
    }

    const handleBtnACtive = (id) => {
        return menu.mainBtnActive===id?activeSub(''):activeSub(id);
    }

    const hoverMEnu = (band) => {
        if (menu.compact && document.body.clientWidth > defaultTheme.breakpoints.values.md) {
            setActiveSubMenuCompact(band);
            activeSub('');
        }
    }

    const show = (items) => {
        let f = false;
        for (var k in items) {
            if (items[k] !== null && items[k].show === 'SI') { f = true; break; }
        }
        return f;
    }

    useEffect(()=>{
        const path = history.location.pathname;
        if (path !== "/")
            menu.menu.forEach( item => {
                if (item.url === path) 
                    getSectionTitle({ title : item.caption, icon : item.icon });
                else if(item.subSections)
                    item.subSections.forEach( itemSub => {
                        if (itemSub.url === path)
                            getSectionTitle({ title : itemSub.caption, icon : itemSub.icon });
                    });
            }); 
    },[menu.menu]);

    return <div className="menu">
        {/* ================== Logo Content ================== */}
    	<Toolbar  style={{ ...bgColor, paddingLeft: 0, paddingRight: 0 }}>
    	    <Box className="content-img" pl={3} pr={3} { ... menu.compact && { className:  'content-img compact' } }> {/* Lo estilos de la clase compact estan en blobal.scss */}
    	    	<img src={logo} className="logo" alt='' />
    	    	<IconButton className="btn-statusMenu" style={bgColor} onClick={ () => handleCompact()  } disableTouchRipple={true}>
    		        <i className="icon-menu" ></i>
    		    </IconButton>
    	    </Box>
    	</Toolbar>

        {/* ================== Menu ================== */}
        <nav className="nav-menu" { ... menu.compact && { className:  ['nav-menu compact'] } }>
        	<List>
        		{menu.menu.map( btnMenu => {
        			return btnMenu !== null && btnMenu.show === 'SI'? <Box position="relative" component="li" className="content-btn" onMouseEnter={ () => hoverMEnu(true) } onMouseLeave={() => hoverMEnu(false)} key={'btn'+btnMenu.id}>
        		    	        <ListItem button className="btn-menu" disableTouchRipple={menu.compact}
                                          { ... typeof btnMenu.subSections !== 'undefined' && show(btnMenu.subSections) && { onClick : () => handleBtnACtive('btn'+btnMenu.id) } } 
                                          { ... (typeof btnMenu.subSections === 'undefined' || !show(btnMenu.subSections)) && { component : React.forwardRef( (props,ref) => <NavLink activeClassName='btn-active' exact={btnMenu.url==='/'?true:false} to={btnMenu.url} {...props} innerRef={ref}  onClick={ () => getSectionTitle({ title : btnMenu.url!=="/"?btnMenu.caption:'', icon : btnMenu.url!=="/"?btnMenu.icon:'' }) }  />) }  } 
                                          >
                                    <ListItemIcon className="icon">
                                        <i className={btnMenu.icon}></i>
                                    </ListItemIcon>      
        		    	            <ListItemText primary={btnMenu.caption} className={'btn-text text '+(menu.mainBtnActive==='btn'+btnMenu.id?'text-bold':'')}/>
                                    { typeof btnMenu.subSections !== 'undefined' && show(btnMenu.subSections) ? <ListItemIcon><i className={ menu.mainBtnActive==='btn'+btnMenu.id?'icon-chevron-up chevron':'icon-chevron-down chevron' }></i></ListItemIcon> : '' }
        		    	        </ListItem>
        		    	        { typeof btnMenu.subSections !== 'undefined' && show(btnMenu.subSections) ? <Collapse in={menu.mainBtnActive==='btn'+btnMenu.id || (activeSubMenuCompact)} timeout="auto" unmountOnExit className="sub-menu"> 
                                { menu.compact ? <ListItem button className="btn-menu" disableTouchRipple={menu.compact}>
                                    <ListItemText className="btn-text" primary={btnMenu.caption} />
                                </ListItem>: '' } 
        		    	        {btnMenu.subSections.map( btnSub => {
        		    	        	return btnSub !== null && btnSub.show === "SI" ? <ListItem button key={'btnSub'+btnSub.id} component={React.forwardRef( (props,ref) => <NavLink activeClassName='btn-active' exact={false} to={btnSub.url} {...props} innerRef={ref} onClick={ () => getSectionTitle({ title : btnSub.caption, icon : btnSub.icon }) } />)} className="btn-menu" disableTouchRipple={menu.compact}>
                                        <ListItemIcon className="icon">
                                            <i className={btnSub.icon}></i>
                                        </ListItemIcon> 
        		    		            <ListItemText className="btn-text" primary={btnSub.caption} />
        		    		        </ListItem> : ''
        		    	        })}
        		    	        </Collapse> : <Collapse in={menu.compact && activeSubMenuCompact} timeout="auto" unmountOnExit className="sub-menu"> 
                                { menu.compact ? <ListItem button className="btn-menu" component={React.forwardRef( (props,ref) => <NavLink exact={true} to={btnMenu.url} {...props} />)} disableTouchRipple={menu.compact}>
                                        <ListItemText className="btn-text" primary={btnMenu.caption} />
                                    </ListItem>: '' } 
                                </Collapse>}
                            </Box> : ''
        		})}
            </List>	
        </nav>
    </div>

}

const mapStateToProps = state => ({
    menu: state.menu
});

const mapDispatchToProps = {
    getSectionTitle,
    activeSub,
    compactMenu
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu))