import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';

// Components
import Datagrid from "../../components/Datagrid/Grid";
import SearcherInput from "../../components/Inputs/SearcherInput";
import AlertDelete from "../../components/Modal/AlertDelete";
import AlertMessage from "../../components/Modal/AlertMessage";
import GenericModal from "../../components/Modal/GenericModal";

// handle of languages
import { Translation } from 'react-i18next';

// Api
import SubjectsApi from "../../api/Subjects/SubjectsApi";

// Models
import UserProfile from "../../models/UserProfile";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

import Tutors from "./modals/Tutors";

const Api = new SubjectsApi();
const userProfile = new UserProfile();

let alertMessage = '';
let alertClassName = 'success';
let idToDelete = 0;
let status = 'Activo';
let type = 'Grupal';
let rowsPerPage = 20;
let totalRows = 0;
let currentPage = 0;
let textToSearch = '';
let subjectId = 0;
let columns = {
    'name': {}, 
    'modality': {},
    'limit': {}, 
    'category':{},
    'tutors': {}
};

class GroupSubjects extends Component {

  constructor(){
	  super();
	  this.state = {
	  	data: [],
	  	openAlert: false,
	  	openAlertMessage: false,
      openModal: false
	  }

	  Api.limit = rowsPerPage;
  }

  getRowsPerPage = (page) => {
      
	  Api.search(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   page, 
	  	   textToSearch,
	  	   status,
         type).then(response => {

	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

	       if (responseData.modal) {
	           console.log(responseData.message);
	       } else {
	       	
	        totalRows = responseData.data.total;
	        this.setState({data: responseData.data.rows});
	       
	       }

      }).catch((err) => {Api.closeSession(err)});
    
  };

  del = () => {

	  Api.delete(
	  	   userProfile.getToken(), 
	  	   idToDelete,
	  	   userProfile.getUser().universityId,
	  	   currentPage, 
	  	   textToSearch,
	  	   status,
         type).then(response => {

	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
	       	
	        if (responseData.modal) {
	           alertMessage = responseData.message;
	           alertClassName = responseData.data.className;
	           this.setState({ openAlertMessage: true });
	        }

	        totalRows = responseData.data.total;
	        this.setState({data: responseData.data.rows});
	       

      }).catch((err) => {Api.closeSession(err)});
    
  };

  handleChangePage = (event, page) => {
    currentPage = page;
    this.getRowsPerPage(page);
  };

  searchRows = (e, search) => {
    textToSearch = search.value;
    currentPage = 0;
    this.getRowsPerPage(currentPage);
  };

  filterByStatus = (event, tab) => {
    status = tab;
    currentPage = 0;
    this.getRowsPerPage(currentPage);
  };

  showAlert = (id, e) => {
    idToDelete = id; // e.target.id;
    this.setState({ openAlert: true });
  };

  closeAlert = () => {
    this.setState({ openAlert: false });
  };

  deleteRow = () => {
    this.setState({ openAlert: false });
    this.del();
    idToDelete = 0;
  };

  closeMessage = (event, reason) => {
    this.setState({ openAlertMessage: false });
  };

  showModal = (id, e) => {
    subjectId = id;
    this.setState({ openModal: true });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  translateColumns = (tr) => {

    columns.name.label = tr('subjects.group.table.columns.name');
    columns.modality.label = tr('subjects.individual.table.columns.modality');
    columns.limit.label = tr('subjects.group.table.columns.limit');
    columns.category.label = tr('subjects.group.table.columns.category');
    columns.tutors.label = tr('subjects.individual.table.columns.tutors');
    
    if (typeof userProfile.getPermissions()['subjects_write'] !== 'undefined')
      columns.opciones = {label: tr('subjects.individual.table.columns.options')};

    return columns;
  
  }

  componentWillMount(){
  	this.getRowsPerPage(currentPage);
  }

  componentDidMount(){
    
    let alert = userProfile.getAlert();
    if (alert && alert !== null) {
        
        alertMessage = alert.msg;
        alertClassName = alert.class; 
        this.setState({ openAlertMessage: true });

    }

  }

  render() {
    
  	const { classes } = this.props;
    let { data, openAlert, openAlertMessage, openModal } = this.state;

    data = data.map((val, key) => {

      if (val.tTutors > 0) {

        val.tutors = (<div id="cntLabel"><span>{val.tTutors}</span> 
                    <IconButton 
                      key={val.id+'i'} 
                      color="primary" 
                      className={classes.button} 
                      style={{marginTop:0, color: '#3498db', display: 'inline-block'}} 
                      onClick={this.showModal.bind(this, val.id)}
                    >
                       <VisibilityIcon />
                    </IconButton></div>); 

      } else {

        val.tutors = (<span>{val.tTutors}</span>); 

      }      
      
      if (typeof val.modality === 'string' || val.modality instanceof String)
         val.modality = (<span className="chipSpan group"><i className={(val.originalModality==='Online'?'icon-online custom-icon-online':'icon-in-person custom-icon-in-person')}></i> {val.modality}</span>);

      if (typeof userProfile.getPermissions()['subjects_write'] !== 'undefined')  
          val.opciones = (<div>
                           <IconButton key={val.id+'e'} className={classes.button} 
                            classes={{
                              root: classes.zeroPadTableBtn
                            }}
                            style={{marginTop:0, color: '#3498db', display: 'inline-block'}} component={Link} to={"/subjects/"+val.id+"/Grupal"}>
                            <EditIcon />
                           </IconButton>
                           <IconButton key={val.id+'d'} className={classes.button} 
                            classes={{
                              root: classes.zeroPadTableBtn
                            }}
                            style={{marginTop:0, color: '#d9534f', display: 'inline-block'}} onClick={this.showAlert.bind(this, val.id)}>
                            <ClearIcon />
                           </IconButton>
                         </div>);
                                        
          return val;
    });

    return (
      
      <div>
        <Translation>
         {
          (t, { i18n }) => {

          return (
            <React.Fragment>
              
                  <Grid container className={classes.container}>
          	        <Grid item className={classes.grid} xs={12} sm={12} md={12}>
          	          <Paper className={classNames(classes.root, classes.paperCss)}>
          		         <Tabs
          		          value={status}
          		          onChange={this.filterByStatus}
          		          indicatorColor="primary"
          		          textColor="primary"
          		          >
          		          <Tab icon={<span className="fa fa-th"></span>} value={"Activo"} label={t('subjects.individual.filtersOptions.active')} />
          		          <Tab icon={<span className="fa fa-power-off"></span>} value={"Inactivo"} label={t('subjects.individual.filtersOptions.inactive')} />
          		        </Tabs>
                      {typeof userProfile.getPermissions()['subjects_write'] !== 'undefined' &&
                      <Button color="primary" variant="contained" component={Link} to="/subjects/add/Grupal" className={classes.btnAdd}>
                        {t('subjects.individual.actionsButtons.add')}
                      </Button>}
          		      </Paper>
          	        </Grid>
          	        <Grid item className={classes.grid} xs={12} sm={12} md={12}>
          		      <SearcherInput eventChange={this.searchRows} textToSearch={textToSearch} placeholder={t('subjects.search.placeholder')} />
                        <Datagrid data={data} page={currentPage} totalRows={totalRows} rowsPerPage={rowsPerPage} columns={this.translateColumns(t)} eventOnChange={this.handleChangePage} />
          	        </Grid>
          	    </Grid>
                <AlertDelete open={openAlert} eventClose={this.closeAlert} eventOk={this.deleteRow} />
                <AlertMessage open={openAlertMessage} variant={alertClassName} message={alertMessage} eventClose={this.closeMessage} />
                <GenericModal open={openModal} title={t('subjects.tutorsModal.title')} content={<Tutors subjectId={subjectId} />} eventClose={this.closeModal}/>
              </React.Fragment>
             )
            }
          }
        </Translation>

      </div>

    );

  }

}

export default withStyles(styles, { withTheme: true })(GroupSubjects);
