import React from 'react';
// Router
import { withRouter } from 'react-router-dom';
// Material UI
import { Grid, Box, Button } from '@material-ui/core';
// handle of languages
import { Translation } from 'react-i18next';

const Promotion = ({history}) => {

    const maxW = 370;

    return <Translation>
        {
    	    (t, { i18n }) => <Grid id="promotion" container>

    	        <Box p={6} width={1}> 
    	            <Box mb={6}  ml={-3} mr={-3} display="flex" flexWrap="wrap" justifyContent="center" >
                        <Box flexGrow={1} pl={3} pr={3} width={1} maxWidth={maxW*2}>
    	                    <Box fontSize={{ xs : 14, sm : 15 }} pl={2} pr={2} pt={1} pb={1} borderLeft="4px solid #2188FC" component="p">{t('promotion.descrip')}</Box>
                        </Box>
    	            </Box>   
                    <Box ml={-3} mr={-3} display="flex" flexWrap="wrap" justifyContent="center">
                        <Box flexGrow={1} width={{ xs : 1, md : 1/2 }} maxWidth={maxW} pl={3} pr={3} mb={6}>
                            <Box width={1}>
                                <Box className="btn-promotion" width={1} component="button" onClick={() => history.push('/promotion/audience')}>
                                    <Box height={150} display="flex" justifyContent="center" alignItems="center" mb={5}>
                                        <Box className="icon-mic" fontSize={120} color="text.primary" component="i"></Box>
                                    </Box>
                                    <Box fontSize={14} color="text.primary" component="span">{t('promotion.seeMore')}</Box>
                                </Box>
                                <Box fontSize={{ xs : 16, sm : 18}} fontWeight={400} mt={{ xs : 3, md : 7}} mb={2} color="text.primary" component="h3">{t('promotion.sections.promotion.title')}</Box>
                                <Box fontSize={14} color="text.primary" component="p">{t('promotion.sections.promotion.descrip')}</Box>
                            </Box>
                        </Box>
                        <Box flexGrow={1} width={{ xs : 1, md : 1/2 }} maxWidth={maxW} pl={3} pr={3} mb={6}>
                            <Box width={1}>
                                <Box className="btn-promotion" width={1} component="button" onClick={() => history.push('/promotion/formats')}>
                                    <Box height={150} display="flex" justifyContent="center" alignItems="center" mb={5}>
                                        <Box maxWidth="80%" maxHeight="90%" component="img" src={require('../../assets/img/promotion/brain.png')}></Box>
                                    </Box>
                                    <Box fontSize={14} color="text.primary" component="span">{t('promotion.seeMore')}</Box>
                                </Box>
                                <Box fontSize={{ xs : 16, sm : 18}} fontWeight={400} mt={{ xs : 3, md : 7}} mb={2} color="text.primary" component="h3">{t('promotion.sections.linc.title')}</Box>
                                <Box fontSize={14} mt={0} color="text.primary" component="p">{t('promotion.sections.linc.descrip')}</Box>
                            </Box>
                        </Box>
                    </Box> 
                </Box>
      
    	    </Grid>
        }
    </Translation>
}
export default withRouter(Promotion);