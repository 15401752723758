import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AlertMessage from "../../components/Modal/AlertMessage";
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {styles} from "../../assets/jss/modules/mainStyles";
import { connect } from 'react-redux';
import { Link, withRouter, useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import ListSubheader from '@material-ui/core/ListSubheader';
import Chip from '@material-ui/core/Chip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Alert, AlertTitle } from '@material-ui/lab';

// handle of languages
import { Translation, useTranslation } from 'react-i18next';

// Api
import TutorsApi from "../../api/Users/TutorsApi";
import AdministratorApi from "../../api/Administrators/AdministratorApi";
import LoadingImport from "../../components/Modal/LoadingFullPage";

import UserProfile from "../../models/UserProfile";

import { getSectionTitle } from '../../redux/actions/global';

const minColumns =  4;
const maxRows =  1000;
const maxSize = 2000000;
const mimesAllowed = [
  "text/csv",
  "application/octet-stream",
  "application/csv",
  "application/x-csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "text/tab-separated-values"
];
const regex = /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/;
const unmappedBackg = '#f3dfdf';
const mappedBackg = '#90D89C';

const Api = new TutorsApi();
const ApiAdmin = new AdministratorApi();
const userProfile = new UserProfile();

const ImportTutors = ({classes, getSectionTitle}) => {
  return <App classes={classes} getSectionTitle={getSectionTitle} />;
};
 
const App = ({classes, getSectionTitle}) => {
 
  const [columns, setColumns] = useState([]);
  const [cFile, setcFile] = useState();
  const [alertMsg, setAlertMsg] = useState({
    open: false,
    className: 'success',
    message: ''
  });
  const [firtsStep, setFirtsStep] = useState(true);
  const [secondStep, setSecondStep] = useState(false);
  const [thirdStep, setThirdStep] = useState(false);
  const [successView, setSuccessView] = useState(false);
  const [successView2, setSuccessView2] = useState(false); // temp
  const [errosView, setErrosView] = useState(false);
  const [selectedBirthday, setSelectedBirthday] = useState(false);
  const [requestPassword, setRequestPassword] = useState(1);
  const [sendEmail, setSendEmail] = useState(1);
  const [dateFormat, setDateFormat] = useState('');
  const [requiredFields, setRequiredFields] = useState({
    name: {
      value:'',
      disabled: false
    },
    last_name: {
      value:'',
      disabled: false
    },
    email: {
      value:'',
      disabled: false
    },
    gender: {
      value:'',
      disabled: false
    }
  });
  const [optionalFields, setOptionalFields] = useState({
    password: {
      value:'',
      disabled: false
    },
    phone: {
      value:'',
      disabled: false
    },
    birthday: {
      value:'',
      disabled: false
    }
  });
  const [selectedColumns, setSelectedColumns] = useState({});
  const [checkedColumns, setCheckedColumns] = useState({});
  const [currentStyles, setCurrentStyles] = useState({});
  const [openLoading, setOpenLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [createdTutors, setCreatedTutors] = useState([]);
  const [ignoredTutors, setIgnoredTutors] = useState([]);
  const [updatedTutors, setUpdatedTutors] = useState([]);
  const [t, i18n] = useTranslation('translations');
  const history = useHistory();

  // process CSV data
  const processData = (dataString, tr) => {

    let alertText = '';

    // Get all lines
    const dataStringLines = dataString.split(/\r\n|\n/);
    // Get all columns of the firts row line
    let headers = dataStringLines[0].split(regex), tempHeaders;
    
    tempHeaders = headers.filter(x => x.trim().length > 0);

    if (tempHeaders && tempHeaders.length < minColumns) {

      alertText = tr('users.importTutors.step1.alerts.minimumColumns').replace(':minColumns', minColumns);

      setAlertMsg({
        open: true,
        className: 'warning',
        message: alertText
      });

    } else {

      const rows = dataStringLines.filter(x => x.replaceAll(',', '').trim().length > 0);

      if ((rows.length-1) > maxRows) {

        alertText = tr('users.importTutors.step1.alerts.maxRows').replace(':maxRows', maxRows);

        setAlertMsg({
          open: true,
          className: 'warning',
          message: alertText
        });
  
      } else {

        var foundAccount = false, foundIndexFirst = 0, foundAccountFirst = '';

        for (var key in rows) {
          let splitRow = rows[key].split(","); 
          if (!foundAccount || key === 0) {
            for(var k in splitRow) {
              if (ApiAdmin.validateEmail(splitRow[k].trim())) {
                foundAccount = true;
                foundIndexFirst = key;
                foundAccountFirst = splitRow[k].trim();
                break;
              }
            }
          }

        }

        if (!foundAccount) {

          setAlertMsg({
            open: true,
            className: 'warning',
            message: tr('users.importTutors.step1.alerts.minOneEmail')
          });
    
        } else {

          let columnsValues, allRepeats = 3, allSheet = {};

          let spliCRow = rows[foundIndexFirst].split(','),
          foundValues = spliCRow.filter(x => x.trim() !== '');

          if (foundValues.length > 1) {
          
            for (var x = 0; x < headers.length; x++) {

              allSheet[x] = {text: headers[x], values: []};

              allRepeats = ((rows.length-1)>=allRepeats)?allRepeats:rows.length-1;
              columnsValues = [];

              for (var j = 1; j <= allRepeats; j++) {
                
                const row = rows[j].split(regex);
                columnsValues.push(row[x]);

              }

              allSheet[x].values = columnsValues;

            }

            if (Object.keys(allSheet).length > 0) {
              
              let selectedColumnsTemp = {}, 
                  checkedColumnsTemp = {},
                  currentStyleTemp = {};
              
              for (var i=0; i < Object.keys(allSheet).length; i++) {
                selectedColumnsTemp["foundColumn_"+i] = -1;
                checkedColumnsTemp[i] = false;
                currentStyleTemp[i] = {
                  backgroundColor:unmappedBackg,
                  color: '#294661'
                };
              }

              setSelectedColumns(selectedColumnsTemp);
              setCheckedColumns(checkedColumnsTemp);
              setCurrentStyles(currentStyleTemp);

            }

            setFirtsStep(false);
            setSecondStep(true);
            setColumns(allSheet);
         
          } else {

            alertText = tr('users.importTutors.step1.alerts.missingValues').replace(':email', foundAccountFirst);

            setAlertMsg({
              open: true,
              className: 'warning',
              message: alertText
            });

          }

        }

      }

    }

  }
 
  // handle file upload
  const handleFileUpload = (e, tr) => {

    if (typeof e.target.files[0] !== 'undefined') {

      const size = e.target.files[0].size,
            name = e.target.files[0].name,
            type = e.target.files[0].type;

      if (size > maxSize) {

        setAlertMsg({
          open: true,
          className: 'warning',
          message: tr('users.importTutors.step1.alerts.maxSize')
        });

        setcFile('');

      } else {

        const splitName = name.split(".");
        const lastItem = splitName[splitName.length - 1];

        if (!mimesAllowed.includes(type) && lastItem.toLowerCase() !== 'csv') {

          setAlertMsg({
            open: true,
            className: 'warning',
            message: tr('users.importTutors.step1.alerts.imagesAllowed')
          });

          setcFile('');

        } else {

          setcFile(e.target.files[0]);

        }

      }
    
    } else { setcFile(''); }
    
  }

  const continueSecondStep = (e, tr) => {

    setAlertMsg({open: false})

    if (cFile instanceof Blob){

      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        processData(data, tr);
      };
      reader.readAsText(cFile, 'UTF-8'); // 'Shift_JIS'
      // reader.readAsBinaryString(cFile);
    
    } else {

      setAlertMsg({
        open: true,
        className: 'warning',
        message: tr('users.importTutors.step1.alerts.emptyFile')
      });

    }

  }

  const continueThirdStep = e => {

    const showDatesFormat = Object.keys(selectedColumns).filter(
      k => selectedColumns[k] === 'birthday'
    );

    if (showDatesFormat.length > 0) {
      setSelectedBirthday(true);
      setDateFormat(1);
    }
    
    setSecondStep(false);
    setThirdStep(true);
  }

  const sendData = e => {

    let dat = new FormData();
    
    dat.append('csvFile', cFile);
    dat.append('selectedColumns', JSON.stringify(selectedColumns));
    dat.append('sendEmail', sendEmail);
    dat.append('requestPassword', requestPassword);
    dat.append('dateFormat', dateFormat);

    setOpenLoading(true);

    Api.importTutors(dat).then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      setOpenLoading(false);
      setAlertMsg({
        open: true,
        className: data.className,
        message: data.message
      });
      setThirdStep(false);

      /*if (typeof data.errors !== 'undefined') {
        setErrosView(true);
        setErrorMessages(data.errors);
      } else {
        setSuccessView2(true);
        setCreatedTutors(data.createdTutors);
        setIgnoredTutors(data.ignoredTutors);
        setUpdatedTutors(data.updatedTutors);
      }*/
      setSuccessView(true);

    }).catch((err) => {
      setOpenLoading(false);
      Api.closeSession(err);
    });
  
  }

  const goBack = e => {
    
    var setRequiredFieldsAsDisabled = {};
    var setOptionalFieldsAsDisabled = {};
    
    setFirtsStep(true);
    setSecondStep(false);

    // first step
    setcFile('');
    setColumns([]);
    setSelectedColumns({});
    setCheckedColumns({});
    setCurrentStyles({});

    // second step
    setSelectedBirthday(false);

    for(var k in requiredFields)
      setRequiredFieldsAsDisabled[k] = {...requiredFields[k], disabled: false}; 

    for(var k in optionalFields)
      setOptionalFieldsAsDisabled[k] = {...optionalFields[k], disabled: false};

    // second step  
    setRequiredFields(setRequiredFieldsAsDisabled);
    setOptionalFields(setOptionalFieldsAsDisabled);

  }

  const goSecondStep = e => {
    
    var setRequiredFieldsAsDisabled = {};
    var setOptionalFieldsAsDisabled = {};
    
    setThirdStep(false);
    setSecondStep(true);
    
    // first step
    /*setcFile('');
    setColumns([]);
    setSelectedColumns({});
    setCheckedColumns({});
    setCurrentStyles({});*/

    // second step
    setSelectedBirthday(false);
    
    // third step
    setRequestPassword(1);
    setSendEmail(1);

    /*for(var k in requiredFields)
      setRequiredFieldsAsDisabled[k] = {...requiredFields[k], disabled: false}; 

    for(var k in optionalFields)
      setOptionalFieldsAsDisabled[k] = {...optionalFields[k], disabled: false};*/   

    // second step  
    // setRequiredFields(setRequiredFieldsAsDisabled);
    // setOptionalFields(setOptionalFieldsAsDisabled);

  }

  const backToTheBeginning = e => {
    
    var setRequiredFieldsAsDisabled = {};
    var setOptionalFieldsAsDisabled = {};
    
    setErrosView(false);
    setSuccessView(false);
    setFirtsStep(true);

    // first step
    setcFile('');
    setColumns([]);
    setSelectedColumns({});
    setCheckedColumns({});
    setCurrentStyles({});

    // second step
    setSelectedBirthday(false);
    
    // third step
    setRequestPassword(1);
    setSendEmail(1);

    // response view
    setErrorMessages({});
    setCreatedTutors([]);
    setIgnoredTutors([]);
    setUpdatedTutors([]);

    for(var k in requiredFields)
      setRequiredFieldsAsDisabled[k] = {...requiredFields[k], disabled: false}; 

    for(var k in optionalFields)
      setOptionalFieldsAsDisabled[k] = {...optionalFields[k], disabled: false};   

    // second step  
    setRequiredFields(setRequiredFieldsAsDisabled);
    setOptionalFields(setOptionalFieldsAsDisabled);

  }

  const closeMessage = (event) => {
    setAlertMsg({...alertMsg, open: false});
  };

  const updateField = e => {
    
    let cSelected = e.target.value;
    
    if (e.target.value === '')
      cSelected = selectedColumns[e.target.name];
    
    let cValue = '', 
        fieldsType = '', 
        requiredData = {...requiredFields}, 
        optionalData = {...optionalFields}, 
        objectToUpdate = '';
    
    // Enable the current value (if it have one value)
    if (typeof selectedColumns[e.target.name] !== 'undefined') {

      cValue = selectedColumns[e.target.name];

      if(typeof requiredFields[cValue] !== 'undefined') {
        fieldsType = 'required';
      } else if(typeof optionalFields[cValue] !== 'undefined') {
        fieldsType = 'optional';
      }

    }

    // Disable the new value (selected value)
    if(typeof requiredFields[e.target.value] !== 'undefined') {

      requiredData[e.target.value] = {...requiredFields[e.target.value],disabled: true};

      objectToUpdate = 'required';
      
      // Without current value (disabled new value)
      if(fieldsType === '') setRequiredFields(requiredData);

    } else if(typeof optionalFields[e.target.value] !== 'undefined') {
      
      optionalData[e.target.value] = {...optionalFields[e.target.value],disabled: true};
      objectToUpdate = 'optional';

      // Without current value (disabled new value)
      if(fieldsType === '') setOptionalFields(optionalData);

    } else {
      // 
    }

    // Enable required old value
    if (fieldsType === 'required') {
      
      requiredData[cValue] = {
        ...requiredFields[cValue],
        disabled: false
      };

      setRequiredFields(requiredData);

      if(objectToUpdate === 'optional') setOptionalFields(optionalData);

    }

    // Enable required old value
    if (fieldsType === 'optional') {

      optionalData[cValue] = {
        ...optionalFields[cValue],
        disabled: false
      };

      setOptionalFields(optionalData);

      if(objectToUpdate === 'required') setRequiredFields(requiredData);

    }

    setSelectedColumns({
      ...selectedColumns,
      [e.target.name]: cSelected
    });

    let cIndex = e.target.name.split("_")[1];
    
    if (cSelected !== '-1') {

      setCurrentStyles({
        ...currentStyles,
        [cIndex]: {
          ...currentStyles[cIndex],
          backgroundColor:mappedBackg,
        }
      });

    } else {

      setCurrentStyles({
        ...currentStyles,
        [cIndex]: {
          ...currentStyles[cIndex],
          backgroundColor:unmappedBackg,
        }
      });

    }


  };

  const updateCheck = (index, e) => {

    const cName = 'foundColumn_'+index;
    const cValue = selectedColumns[cName];

    if (cValue !== '' && cValue !== -1) {

      if(typeof requiredFields[cValue] !== 'undefined') {

        setRequiredFields({
          ...requiredFields,
          [cValue]: {
            ...requiredFields[cValue],
            disabled: false
          }
        });

      } else if(typeof optionalFields[cValue] !== 'undefined') {
        
        setOptionalFields({
          ...optionalFields,
          [cValue]: {
            ...optionalFields[cValue],
            disabled: false
          }
        });

      }

    }

    if (!checkedColumns[index]) {
      setCurrentStyles({
        ...currentStyles,
        [index]: {
          ...currentStyles[index],
          backgroundColor:'#e9ecef',
          color: 'rgba(0, 0, 0, 0.38)'
        }
      });
      setSelectedColumns({
        ...selectedColumns,
        ['foundColumn_'+index]: -1
      });
    } else {
      setCurrentStyles({
        ...currentStyles,
        [index]: {
          ...currentStyles[index],
          backgroundColor:unmappedBackg,
          color: '#294661'
        }
      });
    }

    setCheckedColumns({...checkedColumns, [index]: !checkedColumns[index]});
  }

  const handleChangeSendEmail = e => {

    setSendEmail(e.target.value);

  }

  const handleChangeRequestPassword = e => {

    setRequestPassword(e.target.value);

  }

  const handleChangeDateFormat = e => {

    setDateFormat(e.target.value);

  }

  const goToStart = () => {
    history.push("/users/tutors"); 
  }

  useEffect(() => {
    getSectionTitle({ title : t('users.importTutors.title'), icon : 'icon-briefcase' });
  })
 
  return (
    <div>
      <Translation>
	     {
	      (t, { i18n }) => {
	      	 return (
	      	   <React.Fragment>
                { firtsStep ?<div>
                  <h3 style={{fontWeight:400}}>{t('users.importTutors.step1.title')}</h3>
                  <Grid container className={classes.container} style={{marginTop:24}}>
                    <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{fontSize: 15}}>

                      <p>{t('users.importTutors.step1.description.p1')}</p>
                      <p>{t('users.importTutors.step1.description.p2')}</p>
                      <p>{t('users.importTutors.step1.description.p3')}</p>
                      <p>{t('users.importTutors.step1.description.p4')}</p>

                    </Grid>
                  </Grid> 
                  <Paper variant="outlined" square style={{
                    marginTop: 30,
                    paddingLeft: 20,
                    paddingRight: 11,
                    paddingBottom: 8,
                    backgroundColor: 'rgb(232, 244, 253)'
                  }}>
                    <h5>
                      <span className="fa fa-info-circle" style={{paddingRight: 13, fontSize: 20, color: '#2196f3'}}></span>
                      <span style={{fontWeight: 600, color: 'rgb(13, 60, 97)'}}>{t('users.importTutors.step1.help')}</span> 
                      <a href="https://media.linc.mx/docs/import/tutors/sample_import_file.csv"
                        target="_blank"
                        style={{
                          color: 'blue',
                          textDecoration: 'underline',
                          paddingLeft: 5
                        }}
                      >{t('users.importTutors.step1.link.donwloadFile')}</a> {t('users.importTutors.step1.link.textEnd')}
                    </h5>
                    
                  </Paper>
                  <Grid container className={classes.container} style={{marginTop:20}}>
                    <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{marginTop: 20}}> 
                      <input
                        type="file"
                        accept=".csv"
                        onChange={e => handleFileUpload(e, t)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.container} style={{marginTop:40}}>
                    <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{textAlign:'center'}}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={e => continueSecondStep(e, t)}
                      >
                        {t('users.importTutors.step1.btnContinue')}
                      </Button>
                    </Grid>
                  </Grid>
                </div>:''}
                { secondStep ?<div>
                  <h3 style={{fontWeight:400}}>{t('users.importTutors.step2.title')}</h3>
                  <Grid container className={classes.container} style={{marginTop:24}}>
                    <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{fontSize: 15}}>

                      <p>{t('users.importTutors.step2.instructions')}</p>

                    </Grid>
                  </Grid> 
                  {Object.keys(columns).map((k, i) => (
                  <Paper 
                    variant="outlined" 
                    square key={k} 
                    style={{
                      marginTop: 30, 
                      border: '1px solid #999',
                      color: currentStyles[i].color,
                    }}>
                    <div 
                      style={{
                        backgroundColor: currentStyles[i].backgroundColor,
                        border: '1px solid rgba(0, 0, 0, 0)'
                      }}
                    >
                      <h5 
                        style={{
                          height: 26, 
                          paddingLeft: 20,
                          marginTop: 10,
                          marginBottom: 10,
                          fontWeight: 'bold'
                        }}
                      >{columns[k].text}</h5>
                    </div>
                    <Paper 
                      variant="outlined" 
                      square
                      classes={{
                        outlined: classes.importBoxContentBorder, 
                      }} 
                      style={{
                        borderLeftWidth: 0,
                        borderRightWidth: 0
                      }}
                    >
                      <div 
                        style={{
                          paddingLeft: 20,
                          paddingLeft: 20,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <div>
                          <span>{t('users.importTutors.step2.foundColumns.assignedTo')}:</span>
                            <Select 
                              value={selectedColumns["foundColumn_"+i]}
                              disabled={checkedColumns[i]}
                              style={{
                                marginLeft: 20,
                                marginTop: 10,
                                marginBottom: 10,
                                minWidth: 150
                              }}
                              onChange={updateField}
                              name={"foundColumn_"+i}
                              id={"foundColumn_"+i}
                            >
                              <MenuItem key="-1" value="-1">
                                <em style={{color:'gray'}}>{t('users.importTutors.step2.foundColumns.fieldSelect.defaultValue')}</em>
                              </MenuItem>
                              <ListSubheader
                                style={{
                                  color: '#000',
                                  fontSize: 17,
                                  lineHeight: '40px',
                                  pointerEvents: 'none'
                                }}
                                value={''}
                              >{t('users.importTutors.step2.foundColumns.fieldSelect.tag.requiredFields')}</ListSubheader>
                              {Object.keys(requiredFields).map((k, i) => (
                                <MenuItem key={k+i} style={{paddingLeft: 24}} value={k} disabled={requiredFields[k].disabled}>
                                  {t('users.importTutors.step2.foundColumns.fieldSelect.requiredFields.'+k)}
                                </MenuItem>
                              ))}
                              <ListSubheader
                                style={{
                                  color: '#000',
                                  fontSize: 17,
                                  lineHeight: '40px',
                                  pointerEvents: 'none'
                                }}
                                value={''}
                              >{t('users.importTutors.step2.foundColumns.fieldSelect.tag.optionalFields')}</ListSubheader>
                              {Object.keys(optionalFields).map((k, i) => (
                                <MenuItem key={k+i} value={k} style={{paddingLeft: 24}} disabled={optionalFields[k].disabled}>
                                  {t('users.importTutors.step2.foundColumns.fieldSelect.optionalFields.'+k)}
                                </MenuItem>
                              ))}
                            </Select>
                        </div>
                        <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkedColumns[i]}
                              onChange={e => updateCheck(i, e)}
                              classes={{
                                root: classes.customCheck,
                                checked: classes.checked,
                              }}
                            />
                          }
                          label={t('users.importTutors.step2.foundColumns.ignore')}
                          style={{marginLeft: -6}}
                        /></div>
                      </div>
                    </Paper>
                    <div style={{padding:15}}>
                    {columns[k].values.map((sv, sk) => ( 
                      <Typography key={sk} gutterBottom>
                        {sv}
                      </Typography>
                    ))} 
                      <Typography gutterBottom>
                        ....
                      </Typography>
                    </div>
                  </Paper>
                  ))}
                  <Grid container className={classes.container} style={{marginTop:40}}>
                    <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                      <div>
                          <p style={{fontWeight: 500, fontSize: 15}}>{t('users.importTutors.step2.titleBottomRequiredFields')}:</p> 
                          {Object.keys(requiredFields).map((k, i) => (
                            <Chip 
                              key={k+i} 
                              label={t('users.importTutors.step2.foundColumns.fieldSelect.requiredFields.'+k)}
                              style={{
                                marginLeft: 5,
                                backgroundColor: requiredFields[k].disabled?mappedBackg:unmappedBackg
                              }} 
                            />
                           ))}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.container} style={{marginTop:40}}>
                    <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{textAlign:'center'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={goBack}
                      >
                        {t('users.importTutors.step2.btnBack')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={
                          Object.keys(requiredFields).filter(
                             k => requiredFields[k].disabled === false
                          ).length > 0 ?true:false
                        }
                        style={{marginLeft: 10}}
                        onClick={continueThirdStep}
                      >
                        {t('users.importTutors.step2.btnContinue')}
                      </Button>
                    </Grid>
                  </Grid>
                </div>:''}
                { thirdStep ?<div>
                  <h3 style={{fontWeight:400}}>{t('users.importTutors.step3.title')}</h3>
                  <Grid container className={classes.container} style={{marginTop:24}}>
                    <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{fontSize: 15}}>

                      <p>{t('users.importTutors.step3.option1.description')}</p>
                      <div>
                        <RadioGroup row aria-label="position" name="position" defaultValue="1">
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" onChange={handleChangeSendEmail} name="sendEmail" />}
                            label={t('users.importTutors.step3.option1.response1')}
                          />
                          <FormControlLabel 
                            value="0" 
                            control={<Radio color="primary" onChange={handleChangeSendEmail} name="sendEmail" />} 
                            label={t('users.importTutors.step3.option1.response2')} 
                          />
                        </RadioGroup>
                      </div>

                    </Grid>
                  </Grid> 
                  <Grid container className={classes.container} style={{marginTop:24}}>
                    <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{fontSize: 15}}>

                      <p>{t('users.importTutors.step3.option2.description')}</p>
                      <div>
                        <RadioGroup row aria-label="position" name="position" defaultValue="1">
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" onChange={handleChangeRequestPassword} name="requestPassword" />}
                            label={t('users.importTutors.step3.option2.response1')}
                          />
                          <FormControlLabel 
                            value="0" 
                            control={<Radio color="primary" onChange={handleChangeRequestPassword} name="requestPassword" />} 
                            label={t('users.importTutors.step3.option2.response2')} 
                          />
                        </RadioGroup>
                      </div>

                    </Grid>
                  </Grid> 
                  {selectedBirthday?<Grid container className={classes.container} style={{marginTop:24}} >
                    <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{fontSize: 15}}>

                      <p>{t('users.importTutors.step3.option3.description')}</p>
                      <div>
                        <RadioGroup row aria-label="position" name="position" defaultValue="1">
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" onChange={handleChangeDateFormat} name="dateFormat" />}
                            label="mm/dd/yyyy"
                          />
                          <FormControlLabel 
                            value="2" 
                            control={<Radio color="primary" onChange={handleChangeDateFormat} name="dateFormat" />} 
                            label="dd/mm/yyyy" 
                          />
                        </RadioGroup>
                      </div>

                    </Grid>
                  </Grid>:''}
                  <Grid container className={classes.container} style={{marginTop:40}}>
                    <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{textAlign:'center'}}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={goSecondStep}
                      >
                        {t('users.importTutors.step3.btnBack')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{marginLeft: 10}}
                        onClick={sendData}
                      >
                        {t('users.importTutors.step3.btnStart')}
                      </Button>
                    </Grid>
                  </Grid>
                </div>:''}
                {successView2?<div>
                  <h3 style={{fontWeight:400}}>{t('users.importTutors.successView.title')}</h3>
                  
                  {createdTutors.length>0 && <Grid container className={classes.container} style={{marginTop:24}}>
                      <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{fontSize: 15}}>
                        <p>{t('users.importTutors.successView.subtitle.createdTutors').replace(":total",createdTutors.length)}</p>
                      </Grid>
                      <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                        {createdTutors.map((v, i) => (
                          <p key={i}>
                            <strong>{i+1}.- </strong>
                            <Link 
                              to={"/users/tutors/"+v.id}
                              style={{
                                color: 'blue',
                                textDecoration: 'underline',
                                paddingLeft: 5
                              }}
                              target="_blank"
                            >{v.name+' '+v.lastName} ({v.email})</Link>
                          </p>
                        ))}  
                      </Grid>
                    </Grid>}

                    {updatedTutors.length>0 && <Grid container className={classes.container} style={{marginTop:24}}>
                      <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{fontSize: 15}}>
                        <p>{t('users.importTutors.successView.subtitle.updatedTutors').replace(":total",updatedTutors.length)}</p>
                      </Grid>
                      <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                        {updatedTutors.map((v, i) => (
                          <p key={i}>
                            <strong>{i+1}.- </strong>
                            <Link 
                              to={"/users/tutors/"+v.id} 
                              style={{
                                color: 'blue',
                                textDecoration: 'underline',
                                paddingLeft: 5
                              }}
                              target="_blank"
                            >{v.name+' '+v.last_name} ({v.email})</Link>
                          </p>
                        ))}  
                      </Grid>
                    </Grid>}  

                    {ignoredTutors.length>0 && <Grid container className={classes.container} style={{marginTop:24}}>
                      <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{fontSize: 15}}>
                         <p>{t('users.importTutors.successView.subtitle.ignoredTutors').replace(":total",ignoredTutors.length)}</p>
                      </Grid>
                      <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                        {ignoredTutors.map((v, i) => (
                          <p key={i}>
                            <strong>{i+1}.- </strong>
                            <span>{v.name+' '+v.last_name} ({v.email})</span>
                          </p>
                        ))}  
                      </Grid>
                    </Grid>}

                    <Grid container className={classes.container} style={{marginTop:40}}>
                      <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{textAlign:'center'}}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={backToTheBeginning}
                        >
                          {t('users.importTutors.successView.btnBack')}
                        </Button>
                      </Grid>
                    </Grid>

                </div>:''}
                {errosView?<div>
                  <h3 style={{fontWeight:400}}>{t('users.importTutors.errosView.title')}</h3>
                  <Grid container className={classes.container} style={{marginTop:24}}>
                    <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{fontSize: 15}}>

                      <p>{t('users.importTutors.errosView.subtitle')}</p>

                    </Grid>
                    <Grid item className={classes.grid} style={{marginTop:15}} xs={12} sm={12} md={12}>

                      {Object.keys(errorMessages).map((k, i) => (

                        Object.keys(errorMessages[k]).map((sk, si) => (

                          <Alert severity="error" key={si} style={{marginTop:10}}>
                            <AlertTitle>{t('users.importTutors.errosView.errorPosition').replace(":line", k).replace(":column", sk)}</AlertTitle>
                            {errorMessages[k][sk].map((v, ssi) => (
                              <p key={ssi}>{v}</p>
                            ))}
                          </Alert>

                        ))

                      ))} 

                    </Grid>
                  </Grid>

                  <Grid container className={classes.container} style={{marginTop:40}}>
                    <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{textAlign:'center'}}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={backToTheBeginning}
                      >
                        {t('users.importTutors.errosView.btnTryAgain')}
                      </Button>
                    </Grid>
                  </Grid>

                </div>:''}
                {successView?<div>

                  <Paper variant="outlined" square style={{
                    marginTop: 30,
                    paddingLeft: 20,
                    paddingRight: 11,
                    paddingBottom: 8,
                    textAlign: 'center',
                    paddingBottom: 30
                  }}>

                    <div style={{marginTop: 25}}>
                      <i className="far fa-clock" style={{fontSize: 50}}></i>
                    </div>
                    <h5 style={{fontWeight: 600}}>
                      {t('users.importTutors.successView.caption')}
                    </h5>
                    <Grid container className={classes.container} style={{marginTop:15}}>
                      <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{fontSize: 15}}>

                        <p>{t('users.importTutors.successView.description.p1')}</p>
                        <p>{t('users.importTutors.successView.description.p2')}</p>
                        <p>{t('users.importTutors.successView.description.p3')}<span style={{fontWeight:500}}>{userProfile.getUser().email}</span></p>

                      </Grid>
                    </Grid> 

                    <Grid container className={classes.container} style={{marginTop:40}}>
                      <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{textAlign:'center'}}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={goToStart}
                        >
                          {t('users.importTutors.successView.btnBack')}
                        </Button>
                      </Grid>
                    </Grid>
                    
                  </Paper>

                </div>:''}
                <AlertMessage open={alertMsg.open} variant={alertMsg.className} message={alertMsg.message} eventClose={closeMessage} />
                <LoadingImport open={openLoading} />
             </React.Fragment>
             )
          }
        }
      </Translation>
    </div>
  );
}

const mapDispatchToProps = {
  getSectionTitle
};
 
// export default withStyles(styles, { withTheme: true })(ImportTutors);
export default withRouter(connect('',mapDispatchToProps)(withStyles(styles, { withTheme: true })(ImportTutors)));