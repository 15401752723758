import React, { useState } from "react";
// Router
import { withRouter } from 'react-router-dom';
// Material UI
import { Grid, Box, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { getSectionTitle } from '../../../../redux/actions/global';
// handle of languages
import { Translation } from 'react-i18next';

const Segment = ({history,match,getSectionTitle}) => {

    const [languages] = useState(['en','es']);

    const [images] = useState(() => {
        const items = {'institution':{},'students':{},'tutors':{}};
        return languages.reduce( (arrayData,lang) => {
            const ln = lang.toUpperCase();
            return { ...arrayData , institution : { ...arrayData.institution, [lang] : { min : { 1 : require(`../../../../assets/img/promotion/mini/${ln}/institutions-1.png`),
                                                                                                 2 : require(`../../../../assets/img/promotion/mini/${ln}/institutions-2.png`),
                                                                                                 3 : require(`../../../../assets/img/promotion/mini/${ln}/institutions-3.png`) },
                                                                                         preview : { 1 : require(`../../../../assets/img/promotion/preview/${ln}/institutions-1.png`),
                                                                                                     2 : require(`../../../../assets/img/promotion/preview/${ln}/institutions-2.png`),
                                                                                                     3 : require(`../../../../assets/img/promotion/preview/${ln}/institutions-3.png`) }  } },
                                    students : { ...arrayData.students, [lang] : { min : { 1 : require(`../../../../assets/img/promotion/mini/${ln}/students-1.png`),
                                                                                                 2 : require(`../../../../assets/img/promotion/mini/${ln}/students-2.png`),
                                                                                                 3 : require(`../../../../assets/img/promotion/mini/${ln}/students-3.png`) },
                                                                                         preview : { 1 : require(`../../../../assets/img/promotion/preview/${ln}/students-1.png`),
                                                                                                     2 : require(`../../../../assets/img/promotion/preview/${ln}/students-2.png`),
                                                                                                     3 : require(`../../../../assets/img/promotion/preview/${ln}/students-3.png`) }  } },
                                    tutors : { ...arrayData.tutors, [lang] : { min : { 1 : require(`../../../../assets/img/promotion/mini/${ln}/tutors-1.png`),
                                                                                                 2 : require(`../../../../assets/img/promotion/mini/${ln}/tutors-2.png`),
                                                                                                 3 : require(`../../../../assets/img/promotion/mini/${ln}/tutors-3.png`) },
                                                                                         preview : { 1 : require(`../../../../assets/img/promotion/preview/${ln}/tutors-1.png`),
                                                                                                     2 : require(`../../../../assets/img/promotion/preview/${ln}/tutors-2.png`),
                                                                                                     3 : require(`../../../../assets/img/promotion/preview/${ln}/tutors-3.png`) }  } } }
        },items);
    });

    let [items] = useState({
        institution : {
            title : 'promotion.sections.promotion.items.institution.items.title',
            digital : 'promotion.sections.promotion.items.institution.items.AllfileDigital',
            print : 'promotion.sections.promotion.items.institution.items.AllfilePrint',
            cards : [
                {
                    title: 'promotion.sections.promotion.items.institution.items.item1.title',
                    descript: 'promotion.sections.promotion.items.institution.items.item1.descrip',
                    print: 'promotion.sections.promotion.items.institution.items.item1.filePrint',
                    digital: 'promotion.sections.promotion.items.institution.items.item1.fileDigital'
                },
                {
                    title: 'promotion.sections.promotion.items.institution.items.item2.title',
                    descript: 'promotion.sections.promotion.items.institution.items.item2.descrip',
                    print: 'promotion.sections.promotion.items.institution.items.item2.filePrint',
                    digital: 'promotion.sections.promotion.items.institution.items.item2.fileDigital'
                },
                {
                    title: 'promotion.sections.promotion.items.institution.items.item3.title',
                    descript: 'promotion.sections.promotion.items.institution.items.item3.descrip',
                    print: 'promotion.sections.promotion.items.institution.items.item3.filePrint',
                    digital: 'promotion.sections.promotion.items.institution.items.item3.fileDigital'
                }
            ] 
        },
        students : {
            title : 'promotion.sections.promotion.items.students.items.title',
            digital : 'promotion.sections.promotion.items.students.items.AllfileDigital',
            print : 'promotion.sections.promotion.items.students.items.AllfilePrint',
            cards : [
                {
                    title: 'promotion.sections.promotion.items.students.items.item1.title',
                    descript: 'promotion.sections.promotion.items.students.items.item1.descrip',
                    print: 'promotion.sections.promotion.items.students.items.item1.filePrint',
                    digital: 'promotion.sections.promotion.items.students.items.item1.fileDigital'
                },
                {
                    title: 'promotion.sections.promotion.items.students.items.item2.title',
                    descript: 'promotion.sections.promotion.items.students.items.item2.descrip',
                    print: 'promotion.sections.promotion.items.students.items.item2.filePrint',
                    digital: 'promotion.sections.promotion.items.students.items.item2.fileDigital'
                },
                {
                    title: 'promotion.sections.promotion.items.students.items.item3.title',
                    descript: 'promotion.sections.promotion.items.students.items.item3.descrip',
                    print: 'promotion.sections.promotion.items.students.items.item3.filePrint',
                    digital: 'promotion.sections.promotion.items.students.items.item3.fileDigital'
                }
            ]
        },
        tutors : {
            title : 'promotion.sections.promotion.items.tutors.items.title',
            digital : 'promotion.sections.promotion.items.tutors.items.AllfileDigital',
            print : 'promotion.sections.promotion.items.tutors.items.AllfilePrint',
            cards : [
                {
                    title: 'promotion.sections.promotion.items.tutors.items.item1.title',
                    descript: 'promotion.sections.promotion.items.tutors.items.item1.descrip',
                    print: 'promotion.sections.promotion.items.tutors.items.item1.filePrint',
                    digital: 'promotion.sections.promotion.items.tutors.items.item1.fileDigital'
                },
                {
                    title: 'promotion.sections.promotion.items.tutors.items.item2.title',
                    descript: 'promotion.sections.promotion.items.tutors.items.item2.descrip',
                    print: 'promotion.sections.promotion.items.tutors.items.item2.filePrint',
                    digital: 'promotion.sections.promotion.items.tutors.items.item2.fileDigital'
                },
                {
                    title: 'promotion.sections.promotion.items.tutors.items.item3.title',
                    descript: 'promotion.sections.promotion.items.tutors.items.item3.descrip',
                    print: 'promotion.sections.promotion.items.tutors.items.item3.filePrint',
                    digital: 'promotion.sections.promotion.items.tutors.items.item3.fileDigital'
                }
            ]
        }
    });

    
    const [currentItem] = useState(match.params.segment);

    const [file] = useState(match.params.download);

	const maxW = 370;

    const handleTitle = (t) => {
        getSectionTitle({ title : t(items[currentItem].title), icon : 'icon-mic' });
        return t('promotion.sections.promotion.items.segment');
    } 

    return <Translation>
        {
    	    (t, { i18n }) => <Grid id="promotion" container>

                <Box p={6} width={1}>
                    <Box mb={8}  ml={-3} mr={-3} display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
                        <Box flexGrow={1} pl={3} pr={3} mb={{ xs : 4, sm : 0 }} width={{ xs : 1, sm : 1/2 }} maxWidth={maxW*1.5}>
                            <Box className="btn-back" fontSize={20} color="text.main" component="button" onClick={() => history.push('/promotion/audience')}>
                                <Box className="icon-arrow-left" mr={4} fontSize={18} color="text.main" component="i"></Box>{handleTitle(t)}
                            </Box>
                        </Box>
                        <Box flexGrow={1} pl={3} pr={3} width={{ xs : 1, sm : 1/2 }} maxWidth={maxW*1.5} textAlign="right">
                            <a href={t(items[currentItem][file])} target="blank_">
                                <Button className="btn-downloadAll" variant="contained" color="primary">{t('promotion.sections.promotion.items.btnDownloadAll')}</Button>
                            </a>
                        </Box>
                    </Box>
                    <Box ml={-3} mr={-3} display="flex" flexWrap="wrap" justifyContent="center">
                        {   items[currentItem].cards.map( (item,index) => <Box flexGrow={1} width={{ md : 1/2, lg : 1/3 }} maxWidth={maxW} pl={3} pr={3} mb={6} key={index}>
                                <Box className="segment" width={1} height={1} display="flex" flexWrap="wrap">
                                    <Box>
                                        <Box component="img" maxWidth="100%" src={images[currentItem][i18n.language].min[index+1]}></Box>
                                        <Box width={1} pl={3} pr={3} fontSize={{ xs : 16, sm : 18 }} fontWeight={400} mt={{ xs : 1, sm : 2.5 }} mb={2} color="text.main" component="h3">{t(item.title)}</Box>
                                        <Box width={1} pl={3} pr={3} fontSize={14} mt={0} mb={2} color="text.main" component="p">{t(item.descript)}</Box>
                                    </Box>
                                    <Box width={1} pb={6} pl={3} pr={3} display="flex" flexWrap="wrap" alignSelf="flex-end" alignItems="center">
                                        <Box flexGrow={1} mb={1} width={1}>
                                            <Box fontSize={12} fontWeight={500} display="block" component="span">{t('promotion.sections.promotion.items.tutors.items.formats')}</Box>
                                        </Box>
                                        <Box flexGrow={1} width={1/2}>
                                            <a href={t(item[file])} download={t(item.title)} target="blank_">
                                                { file==='print'?<Box width={26} mr={1} display="inline-block" src={require('../../../../assets/img/promotion/adobe-illustrator-cc.png')} component="img"></Box>:
                                                                 <Box width={26} mr={1} display="inline-block" src={require('../../../../assets/img/promotion/Adobe-Photoshop.png') } component="img"></Box>
                                                }
                                            </a>
                                            {/*<a href="">
                                                <Box width={26} mr={1} display="inline-block" src={''} component="img"></Box>
                                            </a>*/}
                                        </Box>
                                        <Box flexGrow={1} width={1/2} textAlign="right">
                                            <Box className="btn-view" fontSize={12} fontWeight={500} component="button">
                                                {t('promotion.sections.promotion.items.tutors.items.preview')}<Box fontSize={18} className="icon-eye" component="i"></Box>
                                                <Box className="preview">
                                                    <Box maxWidth={{ xs: 220, sm : 320 }} src={images[currentItem][i18n.language].preview[index+1]} component="img"></Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box> 
                        ) }
                    </Box>
                </Box>
      
    	    </Grid>
        }
    </Translation>

}

const mapDispatchToProps = {
    getSectionTitle
};
export default withRouter(connect('', mapDispatchToProps)(Segment))