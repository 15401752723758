import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';

import Main from './Main';

const LoginLayout = (props) => {
	return (
		  <Fragment>
        <CssBaseline />
          <Main {...props} />
      </Fragment>
	)
};

const ContentLoginLayout = ({children, ...rest}) => {
  return (
    <LoginLayout>
      {children}
    </LoginLayout>
  )
};

const LoginRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={matchProps => (
      <ContentLoginLayout>
          <Component {...matchProps} />
      </ContentLoginLayout>
    )} />
  )
};


export default LoginRoute;