import {primaryColor} from "../stylesVariables/globals";
// Colors
import amber from '@material-ui/core/colors/amber';

const styles = theme => ({
  grid: {
    padding: "0 10px !important"
  },
  zeroPadding: {
    paddingLeft: "0 !important",
    position: 'relative',
    left: 0,
    minHeight: '100vh',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      display: 'block'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block'
    }
  },
  gridLogin: {
    padding: "0 43px !important",
    display: 'grid'
  },
  button: {
    color: '#fff',
    margin: 0,
    backgroundColor: primaryColor,
    width: '100%',
    textTransform: 'none',
    borderRadius: 2,
    fontWeight: 'normal',
    '&:hover': {
      backgroundColor: primaryColor,
      opacity: 0.7
    },
    '&:disabled': {
      backgroundColor: '#D8D8D8',
      color: "#333",
    },
    paddingTop: 9,
    paddingBottom: 8,
    fontSize: 16
  },
  subTitleLogin: {
    color: "#666",
    fontSize: "18px",
    margin: 0,
    marginTop: 8
  },
  welcomeTitleLogin: {
    color: "#000",
    fontWeight: "bold",
    fontSize: 26,
    margin: 0
  },
  containerCenter:{
  	justifyContent: "flex-end"
  },
  cardContet:{
  	display: "flex",
    position: "relative",
    flexDirection: "column",
    overflow: "visible"
  },
  error:{
    color: "#F38F1A"
  },
  icon: {
    margin: "0px",
    width: "auto"
  },
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    textAlign: 'center',
    position: 'relative'
  },
  warning: {
    backgroundColor: amber[700],
    width: '100%'
  },
  message: {
    display: 'flex',
    marginLeft: '10px',
    textAlign: 'left'
  },
  marginLeftTitle: {
    marginLeft: '8px'
  },
  cntLogoLogin: {
    marginTop: 50,
  },
  logoLogin: {
    display: "inline-block",
    paddingBottom: 7,
    paddingTop: 20
  },
  homeBanner: {
    display: "inline-block",
    paddingRight: 10,
    position: 'absolute',
    top: '-100%',
    right: '-100%',
    bottom: '-100%',
    left: '-100%',
    margin: 'auto',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  cntRecoveryPass:{
    textAlign: 'right',
    paddingTop:0,
    paddingBottom: 15
  },
  recoveryPass:{
    fontSize: 12,
    fontWeight: 500,
    color: '#000',
    '&:hover': {
      color: '#000',
      opacity: 0.8
    }
  },
  ruleRestorePass:{
    fontSize: 12,
    color: '#979797'
  },
  cntFooter: {
    display: 'block',
    alignSelf: 'flex-end'
  }
});

export default styles;