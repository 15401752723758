import React, { useState, useEffect } from 'react';
import {Doughnut} from 'react-chartjs-2';
import { Box, Input, Select, MenuItem } from '@material-ui/core'
// handle of languages
import { Translation } from 'react-i18next'

const Graphic = ({ labels, data, dataSelect=[], idSelect='', handleDataSelect, idName }) => {

    const [initialState, setInitialState] = useState({});

    useEffect(() => { // componentDidMount
      setInitialState({
        labels: labels,
        datasets: [{
          data: data,
            backgroundColor: [
            '#EA5F82',
            '#F9CD55',
            '#3CA2EB',
            '#72BD00',
            '#B14997',
            '#FC7500'
          ]
        }]
      }); 
    },[labels,data]);

    return  <Translation>
      { 
        (t, { i18n }) => {
              return <React.Fragment>
                <Box width={{   xs: [1], sm : [1/2]}} pr={{ xs: 0, sm: 3 }} mb={{ xs: 4, sm: 0 }}>
                    <Select
                      value={idSelect}
                      onChange={ e => handleDataSelect(e.target.value)}
                      style={{ width: '100%' }}
                      input={<Input name={idName} id={idName+"-helper"} />}
                    >
                        {dataSelect.map( ({ name, period , top, total, id }) => {
                        	return <MenuItem value={id} key={id}>{(name || period)+' ('+(top || total)+')'}</MenuItem>
                        } )}
                    </Select>
                    <Box display="flex" textAlign="left" mb={2} mt={3}>
                        <Box width={{xs:[1/2]}}>
                            <Box component="span" fontWeight="500" fontSize="14px">{t('statistics.tabs.tutorials.topTutorials.titleSubject')}</Box>
                    	</Box>
                    	<Box width={{xs:[1/2]}} textAlign="right">
                            <Box component="span" fontWeight="500" fontSize="14px">{t('statistics.tabs.tutorials.topTutorials.titleTutorials')}</Box>
                    	</Box>
                    </Box>	
                    {labels.map( (matter,index) => {
                    	return <Box display="flex" textAlign="left" pt={1} pb={1} className="matter" key={index}>
                    	           <Box width='85%'>
                    	                <Box component="p" position="relative" mb={0} mt={0}>
                    	                    <span className="color" style={{ backgroundColor: initialState.datasets[0].backgroundColor[index] }}></span>
                    	                    <Box display="block" fontSize="14px" fontWeight="normal" className="text" component="span">{matter}</Box>
                    	                </Box>
                    	           </Box>
                    	           <Box width='15%' textAlign="right">
                    	                <Box fontSize="14px" fontWeight="normal" component="span" display="block">{data[index]}</Box>
                    	           </Box>
                    	       </Box>
                    })}
                </Box> 
                <Box display="flex" alignItems="center" justifyContent="center" width={{ xs: [1], sm : [1/2]}} pl={{ xs: 0, sm: 3 }}>
                    <Doughnut data={ initialState }
                              options={{  responsive: true,
                             	         legend: {display: false,
                                                 fullWidth: false,
                                                 position: 'top',
                                             } }}
                               width={ 100 }
                               height={ 100 }               />
                </Box>
              </React.Fragment>   
        }         
      }
    </Translation>                         
}

export default Graphic