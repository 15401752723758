import axios from 'axios';
import BaseApi from "../BaseApi";
import UserProfile from "../../models/UserProfile";

const userProfile = new UserProfile();

class UniversitiesApi extends BaseApi {

  constructor(){
    super();
    this.api = this.url + 'universities/';
  }

  getData() {

    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.api+'generaldata';
    
    return axios.get(url,{headers: this.headers});

  }

  getDomains() {

    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.url + 'institutions/domains';
    
    return axios.get(url,{headers: this.headers});

  }

  update(info) {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'update', 
        data = {
          ...info
      }

    return axios.request({method:'patch', url:url, headers: this.headers, data:data});

  }

  updateAccessControl(info) {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.url + 'institutions/domains', 
        data = {
          ...info
      }

    return axios.request({method:'patch', url:url, headers: this.headers, data:data});

  }

  updateManagement(info) {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'updatemanagement', 
        data = {
          ...info
      }

    return axios.request({method:'patch', url:url, headers: this.headers, data:data});

  }


  uploadCustomization(info) {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    // this.headers['Content-Type'] = 'multipart/form-data';
    let url = this.api+'uploadadminlogo';

    return axios.request({method:'post', url:url, headers: this.headers, data:info});

  }

  removeAdminLogo() {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'removeadminlogo';

    return axios.request({method:'patch', url:url, headers: this.headers, data:{}});

  }

  updateColor(color) {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'updatecolor';

    return axios.request({method:'patch', url:url, headers: this.headers, data:{color:color}});

  }

  uploadHomeLogo(info) {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'uploadhomelogo';

    return axios.request({method:'post', url:url, headers: this.headers, data:info});

  }

  removeHomeLogo() {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'removehomelogo';

    return axios.request({method:'patch', url:url, headers: this.headers, data:{}});

  }

  uploadLoginLogo(info) {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'uploadloginlogo';
    return axios.request({method:'post', url:url, headers: this.headers, data:info});

  }

  removeLoginLogo() {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'removeloginlogo';

    return axios.request({method:'patch', url:url, headers: this.headers, data:{}});

  }

  uploadEmailLogo(info) {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'uploademaillogo';
    
    return axios.request({method:'post', url:url, headers: this.headers, data:info});

  }

  removeEmailLogo() {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'removeemaillogo';

    return axios.request({method:'patch', url:url, headers: this.headers, data:{}});

  }

  uploadDashboardLogo(info) {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'uploaddashboardlogo';

    return axios.request({method:'post', url:url, headers: this.headers, data:info});

  }

  removeDashboardLogo() {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'removedashboardlogo';

    return axios.request({method:'patch', url:url, headers: this.headers, data:{}});

  }

  updateInstitution(info) {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.url + 'institutions/update', 
        data = {
          ...info
        };

    return axios.request({method:'patch', url:url, headers: this.headers, data:data});

  }

  updateSSOData(info) {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.url + 'institutions/sso', 
        data = {
          ...info
        };

    return axios.request({method:'patch', url:url, headers: this.headers, data:data});

  }

  updateAuthType(type) {
    
    this.headers.Authorization = userProfile.getToken();
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.url + 'institutions/authentication', 
        data = {
          authType: type
        };

    return axios.request({method:'patch', url:url, headers: this.headers, data:data});

  }

}

export default UniversitiesApi;