import React, { Component } from 'react';

import axios from 'axios';

// Session
import UserProfile from "../../models/UserProfile";
import user from "../../models/userData";

import {urlApi} from "../../variables/globals";
import commonHelper from '../../helpers/common';

import CircularProgress from '@material-ui/core/CircularProgress';


import i18nHelper from '../../helpers/i18n';

const userProfile = new UserProfile();
const i18nH = new i18nHelper();
const common = new commonHelper();

class Start extends Component {

  constructor(props) {
    
    super(props);
    
    this.state = {
      loading: false
    }

  }

  login(token){
    
    axios.get(urlApi+'administrators/start',{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'Accept-Language': i18nH.getLanguage()
        }
    }).then(response => {
           
         let responseData = response.data.data;

          userProfile.saveUserData(responseData.data);
          user.closeSession = true;
          i18nH.changeLanguage(responseData.data.language);
          // window.location.href = "/";
          window.location.href = common.getStartUrl(responseData.data.menu);

    }).catch((err) => {console.log(err); this.props.history.push('/login');});
  }

  componentWillMount(){
    if (typeof this.props.match.params.token !== 'undefined' && this.props.match.params.token.trim() !== '')
        this.login(this.props.match.params.token.trim());
  }

  render() {
  
    return (<div style={{textAlign: 'center'}}><CircularProgress className="" style={{marginTop:'20%'}} /></div>);

  }

}

export default Start;