import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';

import Main from './Main';

const DashboardLayout = (props) => {
	return (
		  <Fragment>
        <CssBaseline />
          <Main {...props} />
      </Fragment>
	)
};

const ContentDashboardLayout = ({children, ...rest}) => {
  return (
    <DashboardLayout>
      {children}
    </DashboardLayout>
  )
};

const DashboardRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={matchProps => (
      <ContentDashboardLayout>
          <Component {...matchProps} />
      </ContentDashboardLayout>
    )} />
  )
};


export default DashboardRoute;