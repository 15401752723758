import React from 'react';
import {render} from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { MuiThemeProvider } from '@material-ui/core/styles';

// import { I18nextProvider } from "react-i18next";

import "./assets/scss/index.scss";

import Main from './routes';
import * as serviceWorker from './serviceWorker';
import theme from './theme';

const Root = () => {
    return <MuiThemeProvider theme={theme}>
	     <Provider store={store}>
		    <Router >
	            <Main />
	        </Router>
	    </Provider>
	</MuiThemeProvider>
}
render(
	<Root />, 
	document.getElementById('root'));

serviceWorker.register({
	onUpdate: registration => {
		if (registration && registration.waiting) {
		registration.waiting.postMessage({ type: 'SKIP_WAITING' });
		}
		window.location.reload();
	}
});