import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import parse from 'html-react-parser';

// handle of languages
import { Translation } from 'react-i18next';

class AlertDelete extends Component {

    closeAlert = (event, reason) => {

		if (reason === "backdropClick") {
			return false;
		}
	  
		if (reason === "escapeKeyDown") {
			return false;
		} else {
	        this.props.eventClose();
		}
	};

	clickAction = () => {
	   this.props.eventOk();
	};

	render(){
		
		let {title, content, open} = this.props;

		return (
			<Translation>
            {
              (t, { i18n }) => {
                
                return (
					<Dialog
			          open={open}
			          onClose={this.closeAlert}
			          aria-labelledby="alert-dialog-title"
			          aria-describedby="alert-dialog-description"
			        >
			          <DialogTitle>{(typeof title !== 'undefined' && title !== '')?title:t('modalDelete.defaultTitle')}</DialogTitle>
			          <DialogContent>
			            <DialogContentText style={{color:'#717171'}}>
			              {(typeof content !== 'undefined' && content !== '')?parse(content):t('modalDelete.defaultContent')}
			            </DialogContentText>
			          </DialogContent>
			          <DialogActions>
			            <Button onClick={this.closeAlert} color="primary">
			              {t('modalDelete.buttons.cancel')}
			            </Button>
			            <Button onClick={this.clickAction} color="primary" autoFocus>
			              {t('modalDelete.buttons.ok')}
			            </Button>
			          </DialogActions>
			        </Dialog>

	              )
	            }
	          }
	        </Translation>
		);
	}
}

export default withStyles({ withTheme: true })(AlertDelete);