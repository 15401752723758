import React, { Component } from 'react';
import classNames from 'classnames';

// Libs
import ReeValidate from 'ree-validate';

import axios from 'axios';
import { Link } from 'react-router-dom';

// Session
import UserProfile from "../../models/UserProfile";
import user from "../../models/userData";

import {urlApi} from "../../variables/globals";

// Material ui Components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// Components
import CustomInput from "../../components/Inputs/CustomInput";
import Translate from '../../layouts/Login/Translate';
import Footer from '../../layouts/Login/Footer';
import Msg from './components/Msg';

// handle of languages
import { Translation } from 'react-i18next';
import i18nHelper from '../../helpers/i18n';
 
import commonHelper from '../../helpers/common';

// Styles
import styles from "../../assets/jss/modules/loginStyles";

import logo from "../../assets/img/logo-horizontal.svg";

import Banners from "./components/LoginBanners";

const userProfile = new UserProfile();
const i18nH = new i18nHelper();
const common = new commonHelper();

class Login extends Component {

  constructor(props) {
    
    super(props);
    
    this.validator = new ReeValidate.Validator({
      email: 'required|email',
      password: 'required|min:3',
    })
    
    this.state = {
      formData: {
        email: '',
        password: '',
      },
      loading: false,
      disabledButton: true,
      message: '',
      errors: this.validator.errors,
      showPassword: false
    }
    
    this.onChange = this.onChange.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);

  }

  login(formData){
    axios.request({
           method:'post',
           url:urlApi+'administrators/lgn',
           data:formData,
           headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18nH.getLanguage()
         }
    }).then(response => {  
         this.handleLoading();
         let responseData = response.data.data;
         
         if (responseData.modal) {
             this.setState({ message: responseData.message });
         } else {
            userProfile.saveUserData(responseData.data);
            user.closeSession = true;
            i18nH.changeLanguage(responseData.data.language);
            //this.props.history.push('/');
            window.location.href = common.getStartUrl(responseData.data.menu);
         }

    }).catch((err) => {
      this.handleLoading(); 
      if (err.response.status === 429) {
         this.setState({ message: 'error.429' });
      }
      console.log(err.message);
    });
  }
  
  onChange(e) {

    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    
    // reset errors for url field
    errors.remove(name);
    
    // update form data
    this.setState({ formData: { ...this.state.formData, [name]: value } });
    
    this.validator.validate(name, value).then(() => {
        
        let dis = false;
        if (errors.items.length > 0 || 
          (this.state.formData.password.trim() === "" || this.state.formData.email.trim() === "")) dis = true;

        this.setState({ errors });
        this.setState({ disabledButton: dis});
    
    });

  }

  handleLoading = () => {
    this.setState({ loading: !this.state.loading });
    this.setState({ disabledButton: !this.state.disabledButton });
  };
  
  submit(formData) {
    this.handleLoading();
    this.login(formData);
  }
  
  validateAndSubmit(e) {

    e.preventDefault()
    
    const { formData } = this.state;
    const { errors } = this.validator;

    this.validator.validateAll(formData)
      .then(success => {
        if (success) {
          this.submit(formData)
        } else {
          this.setState({ errors })
        }
      })
    
  }

  clearMsg = () => {
    this.setState({ message: '' });
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  componentWillMount(){
   i18nH.setLanguage();
  }

  render() {

  	const { classes } = this.props;
    const { errors } = this.state;
  
    return (
      
       <form onSubmit={this.validateAndSubmit}>
         <Translation>
          {
            (t, { i18n }) => {
               return (
                  <Grid container className={classes.containerCenter}>
                      
                      <Banners />
                      
                      <Grid item className={classes.gridLogin} xs={12} sm={12} md={4}>
                        
                        
                        <Grid item className={classNames(classes.grid, classes.cntLogoLogin)} xs={12} sm={12} md={12}>
                        
                          <img src={logo} alt="logo" className={classes.logoLogin} />
                          <Grid container style={{marginTop: 30}}>

                            <Grid item className={classNames(classes.center)} xs={12} sm={12} md={12}>
                              <h4 className={classes.welcomeTitleLogin}>{t('login.title')}</h4>
                              <p className={classes.subTitleLogin}>{t('login.subtitle')}</p>  
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <Grid container>
                                <Msg message={this.state.message} loading={this.state.loading} clear={this.clearMsg}/>
                              </Grid>  
                              <Grid container>
                                <Grid item className="" xs={12} sm={12} md={12} style={{paddingTop: 0}}>
                                    <CustomInput
                                      formControlProps={{
                                        fullWidth: true,
                                        style: ({marginTop: 20})
                                      }}
                                      inputProps={{
                                        name:"email",
                                        placeholder:(t('login.inputEmailPlaceholder')),
                                        onChange:(this.onChange)
                                      }}
                                      error={errors.has('email')}
                                    />
                                  { errors.has('email') &&
                                    <label className={classes.error} htmlFor="email"></label>
                                  }
                                </Grid>
                              </Grid>
                              <Grid container>  
                                <Grid item className="" xs={12} sm={12} md={12} style={{paddingTop:0}}>
                                  <CustomInput
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      type:(this.state.showPassword ? "text" : "password"),
                                      name:"password",
                                      placeholder:(t('login.inputPasswordPlaceholder')),
                                      onChange:(this.onChange),
                                      style:({paddingTop:0}),
                                      endAdornment:(
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                          >
                                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }}
                                    error={errors.has('password')}
                                    customInputProps={{minLength: "3",maxLength: "50"}}
                                  />
                                  { errors.has('password') &&
                                    <label className={classes.error} htmlFor="password"></label>
                                  }
                                </Grid>
                              </Grid>  
                              <Grid container>
                                <Grid item className={classes.cntRecoveryPass} xs={12} sm={12} md={12}>
                                  <Link to="/recoverpassword" className={classes.recoveryPass}>{t('login.linkPassword')}</Link>
                                </Grid>
                              </Grid>
                              <Grid container>  
                                <Grid item xs={12} sm={12} md={12}>
                                  <Button variant="contained" disabled={this.state.disabledButton} className={classes.button} type="submit">{t('login.btnLogIn')}</Button>
                                </Grid>
                              </Grid>  
                            </Grid>
                            
                          </Grid>
                        </Grid>

                        <Grid container className={classes.cntFooter}>
                          <Translate t={t} />
                          <Footer />
                        </Grid>

                      </Grid>

                  </Grid>
             )
           }
         }
        </Translation>
      </form> 

    );

  }

}

export default withStyles(styles, { withTheme: true })(Login);