import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IOSSwitch from '../../components/Checked/IOSSwitch';

// Libs
import ReeValidate from 'ree-validate';

// handle of languages
import { Translation, withTranslation } from 'react-i18next';

// Components
import CardHeader from "../../components/Card/CardHeader";
import CustomInput from "../../components/Inputs/CustomInput";

// Api
import CategoriesApi from "../../api/Subjects/CategoriesApi";

import { getSectionTitle } from '../../redux/actions/global';

// Models
import UserProfile from "../../models/UserProfile";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

import {colors} from '../../assets/json/colors.json';

const Api = new CategoriesApi();
const userProfile = new UserProfile();
let titleIcon = 'fa fa-plus';
let titleKey = 'titleAdd';
let lan = '';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class Category extends Component {

  constructor(){
	  
	super();

	this.validator = new ReeValidate.Validator({
      name: 'required',
      color: 'required',
      iconId: 'required',
      status: 'required'
    });

	this.state = {
	 	formData: {
	      id: 0,
	  	  name: '',
	      iconId: '',
	      color: '',
		  status: 'Activo',
		  allPlaces: true
		},
		places: [],
		selectedPlaces: [],
		defaultPlaces: [],
	    icons: [],
	    disabledButton: false,
	    errors: this.validator.errors,
		backColor: 'none',
		currentTab: 'one'
    };

  }

  changeTab = (event, currentTab) => {
    this.setState({ currentTab });
  };

  activeCutomPlaces = name => event => {
    this.setState({ formData: { ...this.state.formData, [name]: event.target.checked } });
  };

  onChangePlace = (event , value) => {
    this.setState({selectedPlaces:value});
  };

  getOneRow = (id) => {
      
	  Api.getCategoryById(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   id).then(response => {
            
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
           
	       if (responseData.modal) {
	           this.props.history.push("/categories");
	       } else {
	       	
	       	this.setState({
				   icons:responseData.data.icons, 
				   places:responseData.data.places,
				   defaultPlaces:responseData.data.places.reduce(function(all, v){
					   let f = false;
					   for (var k in responseData.data.selectedPlaces)
                           if (v.id === responseData.data.selectedPlaces[k].id) f = true;  
						
						if (f) all.push(v);
						return all;
						       
				   }, [])
				});
			   this.setState({formData:{...responseData.data.row}, backColor: responseData.data.row.color});
	       
	       }

      }).catch((err) => {Api.closeSession(err)});
    
  };

  getIcons = () => {
      
	  Api.getIcons(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId).then(response => {

	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
	       	
	       this.setState({icons:responseData.data.icons, places:responseData.data.places});

      }).catch((err) => {Api.closeSession(err)});
    
  };

  save = (data) => {
      
	  Api.save(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   data).then(response => {
           
           this.handleLoading();
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

	       if (responseData.modal) {
	       	   userProfile.addAlert({msg: responseData.message, class: responseData.data.className});
	           this.props.history.push("/categories");
	       } else {
	       	   console.log(responseData.message);
	       }

      }).catch((err) => {this.handleLoading();Api.closeSession(err)});
    
  };

  update = (data) => {
      
	  Api.update(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   data).then(response => {
           
           this.handleLoading();
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

	       if (responseData.modal) {
	       	   userProfile.addAlert({msg: responseData.message, class: responseData.data.className});
	           this.props.history.push("/categories");
	       } else {
	       	   console.log(responseData.message);
	       }

      }).catch((err) => {this.handleLoading();Api.closeSession(err)});
    
  };

  handleChange = e => {

	const name = e.target.name;
	const value = e.target.value;
	const { errors } = this.validator;
	
	errors.remove(name);

	if (name === 'color') this.setState({backColor: value});

	this.setState({ formData: { ...this.state.formData, [name]: value } });
   
  };

  handleLoading = () => {
    this.setState({ disabledButton: !this.state.disabledButton });
  };

  submit = formData => {

	formData.places = this.state.selectedPlaces;
    
	this.handleLoading();
	
	
	if (this.state.formData.id > 0) {
		formData.id = this.state.formData.id;
		this.update(formData);
	} else {
		this.save(formData);
	}
   
  };

  validateAndSubmit = e => {

	    e.preventDefault()
	    
	    const { formData } = this.state;
	    const { errors } = this.validator;

	    this.validator.validateAll(formData)
	      .then(success => {
	        if (success) {
	          this.submit(formData)
	        } else {
	          this.setState({ errors })
	        }
	    })
    
  }

  findErrorInFields = (fields, field) => {
      
      const error = fields.filter((v, i) => {
           return v.field === field;
      });

      if (error.length > 0) return '';

      return error;
  }

  /*componentWillUnmount() {

	const { getSectionTitle, t, i18n } = this.props;

	console.log(i18n.language);
    getSectionTitle({ title : t('users.importTutors.title'), icon : 'icon-icons' });
	lan = i18n.language;
	

  }*/

  componentWillMount(){
  	
	const id = this.props.match.params.id;
	const { getSectionTitle, t, i18n } = this.props;
	getSectionTitle({ title : t('categories.form.title'), icon : 'icon-icons' });

	if (id !== 'add') {
		
		titleIcon = 'fa fa-pen';
		titleKey = 'titleModify';
		
		if (isNaN(id)) {
			this.props.history.push("/categories");
		} else {
			this.getOneRow(this.props.match.params.id);
		}

	} else {
		titleIcon = 'fa fa-plus';
		titleKey = 'titleAdd';
		this.getIcons();
	}

  }

  render() {

  	const { classes } = this.props;
  	let {formData, icons, errors, backColor, currentTab, places, defaultPlaces} = this.state;

    return (
      
      <div>
        <Translation>
	    {
	      (t, { i18n }) => {
	      	   return (
		       <form onSubmit={this.validateAndSubmit}>
		        <Grid container className={classes.container}>
		            <Grid item className={classes.grid} xs={12} sm={12} md={3}>
			       
				        <Grid item className={classNames(classes.gridHeader, classes.gridHeaderPadding)} xs={12} sm={12} md={12}>

			              <Button color="default" component={Link} to="/categories" className={classes.btnBack}>
			                <span className={classNames(classes.iconBack, "fa fa-arrow-left")}></span> 
			                {t('categories.form.back')}
			              </Button>
				        </Grid>
				  
				    </Grid>  
			        <Grid item className={classes.grid} xs={12} sm={12} md={12}>
				      <Card className={classes.cardContet}>
			            <CardHeader className={classes.cardHeader}>
			              <h4 className={classes.cardTitleWhite}>
			                 <span className={classNames(classes.iconBack, titleIcon)}></span> 
			                 {t('categories.form.'+titleKey)}
			              </h4>
			              <p className={classes.cardCategoryWhite}>{t('categories.form.subtitle')}</p>
			            </CardHeader>
						<AppBar position="static" style={{backgroundColor: "#f5f5f5", color: "#333"}}>
							<Tabs value={currentTab} onChange={this.changeTab} indicatorColor="primary" textColor="primary">
								<Tab value="one" label={t('users.form.tabs.general.title')} />
								<Tab value="two" label={t('users.form.tabs.places')} />
							</Tabs>
						</AppBar>
						{currentTab === 'one' &&
						  <CardContent>		
							<Grid container>
								<Grid item className={classes.grid} xs={12} sm={12} md={6}>
								<CustomInput
									labelText={t('categories.form.fields.name')}
									id="name"
									formControlProps={{
									fullWidth: true
									}}
									inputProps={{
									name:"name",
									value:formData.name,
									onChange:(this.handleChange),
									error:errors.has('name')
									}}
									customInputProps={{maxLength: "62"}}
									
								/>
								{ errors.has('name') &&
									<label className={classes.error} htmlFor="name">{ 
										// console.log(this.findErrorInFields(errors.items, 'name'))
									}</label>
								}
								</Grid>
								<Grid item className={classes.grid} xs={12} sm={12} md={6}>
									<FormControl fullWidth={true} className={classes.formControl}>
									<InputLabel htmlFor="iconId">{t('categories.form.fields.icon.name')}</InputLabel>
									<Select
										value={formData.iconId}
										onChange={this.handleChange}
										inputProps={{
										name: 'iconId',
										id: 'iconId'
										}}
										error={errors.has('iconId')}
									>
										<MenuItem value="">
										<em>{t('categories.form.fields.icon.defaultValue')}</em>
										</MenuItem>
										{icons.map((i, k) => {
											return (
												<MenuItem value={i.id} key={k}>&nbsp;<span className={"icon-"+i.iconName}></span></MenuItem>
												)
										})}
									</Select>
									</FormControl>
									{ errors.has('iconId') &&
									<label className={classes.error} htmlFor="iconId"></label>
									}
								</Grid>
							</Grid>
							<Grid container>
			                <Grid item className={classes.grid} xs={12} sm={12} md={6}>
			                    <FormControl fullWidth={true} className={classes.formControl}>
						          <InputLabel htmlFor="color">{t('categories.form.fields.color.name')}</InputLabel>
						          <Select
						            value={formData.color}
						            onChange={this.handleChange}
						            inputProps={{
						              name: 'color',
						              id: 'color'
						            }}
						            style={{backgroundColor: backColor}}
						            error={errors.has('color')}
						          >
						            <MenuItem value=""><em>{t('categories.form.fields.color.defaultValue')}</em></MenuItem>
						            {colors.map((c, k) => {
						            	return (
						            		  <MenuItem value={c.color} style={{backgroundColor: c.color}} key={k}></MenuItem>
						            		)
						            })}
						          </Select>
						        </FormControl>
						        { errors.has('color') &&
		                          <label className={classes.error} htmlFor="color"></label>
		                        }
			                </Grid>
			                <Grid item className={classes.grid} xs={12} sm={12} md={6}>
			                    <FormControl fullWidth={true} className={classes.formControl}>
						          <InputLabel htmlFor="status">{t('categories.form.fields.status')}</InputLabel>
						          <Select
						            value={formData.status}
						            onChange={this.handleChange}
						            inputProps={{
						              name: 'status',
						              id: 'status'
						            }}
						          >
						            <MenuItem value="Activo">{t('selectStatus.active')}</MenuItem>
						            <MenuItem value="Inactivo">{t('selectStatus.inactive')}</MenuItem>
						          </Select>
						        </FormControl>
			                </Grid>
			              </Grid>
			              </CardContent>	
						}
						{currentTab === 'two' &&
						  <CardContent>
							<Grid container>
								<Grid item className={classes.grid} xs={12} sm={12} md={12}>
								    <label className={classes.assignPlacesTitle} style={{fontWeight:500}}>
									  {t('categories.form.assignPlaces.title.firstPart')}
									</label>
									<label className={classes.assignPlacesTitle}>
									  {t('categories.form.assignPlaces.title.secondPart')}
									</label>
								</Grid>
							</Grid>
							<Grid container style={{marginTop:25}}>
								<Grid item className={classes.grid} xs={12} sm={12} md={12}>
								    <label className={classes.assignPlacesTitle}>
									  {t('categories.form.assignPlaces.subtitle')}
									</label>
								</Grid>
							</Grid>
							<Grid container style={{marginTop:25}}>
								<Grid item className={classes.grid} xs={12} sm={12} md={4}>
								   <span className={classes.categoryLabel}> 
								      {t('categories.form.assignPlaces.caption')}
								   </span>
								   <p>{t('categories.form.assignPlaces.disableLabel')}</p>
								</Grid>
								<Grid item className={classes.grid} xs={12} sm={12} md={8}>
								    <FormControl fullWidth={true} className={classes.formControl}>
										<FormControlLabel
											control={
												<IOSSwitch 
													checked={formData.allPlaces}
													onChange={this.activeCutomPlaces("allPlaces")}
													value="allPlaces"
													name="allPlaces" 
												/>
											}
											label={''}
										/>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container style={{marginTop:20, display: ((formData.allPlaces)?'none':'flex')}}>
								<Grid item className={classes.grid} xs={12} sm={12} md={12}>
								    <label className={classes.assignPlacesTitle}>
									  {t('categories.form.assignPlaces.autocomplete.title')}
									</label>
								</Grid>
							</Grid>
							<Grid container style={{marginTop:25, display: ((formData.allPlaces)?'none':'flex')}}>
								<Grid item className={classes.grid} xs={12} sm={12} md={12}>
									<Autocomplete
										multiple
										options={places}
										disableCloseOnSelect
										getOptionLabel={option => option.name}
										defaultValue={defaultPlaces}
										renderOption={(option, { selected }) => (
											<React.Fragment>
												<Checkbox
													icon={icon}
													checkedIcon={checkedIcon}
													style={{ marginRight: 8 }}
													checked={selected}
												/>
												{option.name}
											</React.Fragment>
										)}
										renderInput={params => (
											<TextField
												{...params}
												variant="outlined"
												label=""
												placeholder={t('categories.form.assignPlaces.autocomplete.placeholder')}
												fullWidth
											/>
										)}
										filterSelectedOptions={false}
										onChange={this.onChangePlace}
									/>
								</Grid>
							</Grid>	
						  </CardContent>
					    }
			            <CardActions>
			              <Button variant="contained" color="primary" className={classes.buttonBottom} disabled={this.state.disabledButton} type="submit">
			                 {t('categories.form.btnSave')}
			              </Button>
			            </CardActions>
			          </Card>
			        </Grid>
		          </Grid>  
		        </form>  

            )
          }
        }
        </Translation>

      </div>

    );

  }

}

const mapStateToProps = state => ({
    titleSection: state.global.section
});

const mapDispatchToProps = {
	getSectionTitle
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)( withTranslation('translations') (withStyles(styles, { withTheme: true })(Category)) ) );
