import React, {useState,useEffect} from 'react';
import {Line} from 'react-chartjs-2';

const Graphic = ({ labels, data, color, label }) => {

    const [initialState,setInitialState] = useState({});

    useEffect(() => { // componentDidMount
        setInitialState({
            labels: labels,
            datasets: [
                {
                    label: label,
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: color,
                    borderColor: color,
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: color,
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: color,
                    pointHoverBorderColor: color,
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: data
                }
            ]
        })
    },[labels,data,color,label])
 
    return  <Line data={initialState}
                  height={250}
                  options={{ legend: {display: false},
                             maintainAspectRatio: false,
                             scales: { yAxes: [{ ticks: { beginAtZero: true, min: 0 } }] } }} />

}

export default Graphic;
