import {urlApi} from "../variables/globals";
import UserProfile from "../models/UserProfile";

const userProfile = new UserProfile();

class BaseApi {

  constructor(){
    this.url = urlApi;
    this.headers =  {
        'Content-Type': 'application/json',
        'Authorization': '',
        'Accept-Language': userProfile.getLanguage()
    };
  }
  
  closeSession(err) {
      
    if (typeof err.response !== 'undefined') {
      
      if (typeof err.response.data !== 'undefined') {

        if (typeof err.response.data.error !== 'undefined') {
        
          if (err.response.data.error.code === 'AUTHORIZATION_REQUIRED' || err.response.data.error.code === 'INVALID_TOKEN') {
              userProfile.destroySession();
              // this.setState({closeSession:true});
              window.location.href = '/login';
          } else {
              console.log(err.response.data.error);
          }

        } else {
          console.log(err.response.data);
        }  

      } else {
          console.log(err.response);
      }

    } else {
       console.log(err);
    }

  }
  

}

export default BaseApi;