import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField, Link as LinkUI } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IOSSwitch from '../../components/Checked/IOSSwitch';

// Libs
import ReeValidate from 'ree-validate';

// handle of languages
import { Translation } from 'react-i18next';

// Components
import CardHeader from "../../components/Card/CardHeader";
import CustomInput from "../../components/Inputs/CustomInput";
import AlertMessage from "../../components/Modal/AlertMessage";

// Api
import SubjectsApi from "../../api/Subjects/SubjectsApi";

// Models
import UserProfile from "../../models/UserProfile";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

const Api = new SubjectsApi();
const userProfile = new UserProfile();
let titleKey = 'titleAdd';
let titleIcon = 'fa fa-plus';
let alertClassName = 'warning';
let alertInfoClassName = 'info';
let redirectTo = 'subjects';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class Category extends Component {

   constructor(){
	  
	super();

	this.validator = new ReeValidate.Validator({
      name: 'required',
      categoryId: 'required',
      status: 'required',
      limit: 'required|numeric'
    });

	this.state = {
	 	formData: {
	      id: 0,
	  	  name: '',
	      categoryId: '',
	      status: 'Activo',
		  type: 'Individual',
		  modality: 'Presencial',
	      durationTime: 60,
	      toleranceTime : 10,
	      toleranceTimeFormat: 'min',
	      toleranceType : 'Anticipacion',
		  limit: 0,
		  allPlaces: true
	    }, 
	    categories: {
	    	categories : [], 
	    	timeLapses : [], 
	    	toleranceTimeFormats : [], 
	    	toleranceTypes : []
		},
		places: [],
		selectedPlaces: [],
		defaultPlaces: [],
	    errorTolerance: '',
	    disabledButton: false,
	    display: 'none',
	    errors: this.validator.errors,
		openGroupAlertMessage: false,
		openOnlineAlertMessage: false,
		openTempOnlineAlertMessage: false,
		currentTab: 'one',
		alertMsg: {
			open: false,
			className: 'success',
			message: ''
		}
    };

  }

  getOneRow = (id) => {
      
	  Api.getById(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   id).then(response => {
            
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
           
	       if (responseData.modal) {
	           this.props.history.push("/subjects");
	       } else {

	       	if (responseData.data.row.type === 'Grupal')
	       		this.setState({display:'block'});
	
	       	this.setState({ formData: responseData.data.row });
	       	this.setState({ 
				   categories: responseData.data, 
				   places:responseData.data.places,
				   defaultPlaces:responseData.data.places.reduce(function(all, v){
						let f = false;
						for (var k in responseData.data.selectedPlaces)
							if (v.id === responseData.data.selectedPlaces[k].id) f = true;  
						
						if (f) all.push(v);
						return all;
								
					}, [])
			});       	
	       
	       }

      }).catch((err) => {Api.closeSession(err)});
    
  };

  getCategories = () => {
      
	  Api.getCategories(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId).then(response => {

	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
	       	
	       this.setState({categories:responseData.data, places:responseData.data.places});

      }).catch((err) => {Api.closeSession(err)});
    
  };

  save = (data) => {
  	  data.toleranceTime = parseInt(data.toleranceTime);
	  Api.save(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   data).then(response => {
           
           this.handleLoading();
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
           
	       if (responseData.modal) {

				if (responseData.data.className !== 'warning') {

					userProfile.addAlert({msg: responseData.message, class: responseData.data.className});

					if (data.type === 'Individual') {
					  this.props.history.push("/subjects");
					} else { this.props.history.push("/groupsubjects"); }
				  
				} else {
					
					this.setState({
						alertMsg: {
						   open: true,
						   className: responseData.data.className,
						   message: responseData.message
						}
					});

				}
	       
	       } else {
	       	   console.log(responseData.message);
	       }

      }).catch((err) => {this.handleLoading();Api.closeSession(err)});
    
  };

  update = (data) => {
      data.toleranceTime = parseInt(data.toleranceTime);
	  Api.update(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   data).then(response => {
           
           this.handleLoading();
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

	       if (responseData.modal) {

			if (responseData.data.className !== 'warning') {
	       	   
	       	   userProfile.addAlert({msg: responseData.message, class: responseData.data.className});
	           
	           if (data.type === 'Individual') {
                  this.props.history.push("/subjects");
	       	   } else { this.props.history.push("/groupsubjects"); }

			} else {
					
				this.setState({
					alertMsg: {
					   open: true,
					   className: responseData.data.className,
					   message: responseData.message
					}
				});

			}	  
	           
	       } else {
	       	   console.log(responseData.message);
	       }

      }).catch((err) => {this.handleLoading();Api.closeSession(err)});
    
  };

  handleChange = e => {

	const name = e.target.name;
    let value = e.target.value;
    const { errors } = this.validator;
    
    errors.remove(name);

    if (name === 'type' && value === 'Grupal') {
       this.setState({display: 'block'});
    } else if (name === 'type') {
       let cotyFormData = this.state.formData;
       cotyFormData.limit = 0;
       this.setState({display: 'none', formData: cotyFormData});
    }
    
    this.setState({ formData: { ...this.state.formData, [name]: value } });
   
  };

  handleTolerance = e => {
      this.setState({ formData: { ...this.state.formData, toleranceTime : e.target.value } });
  }

  handleErrorTolerance = async (e,inpT='') =>{
  	  if (inpT === '')
  	      await this.handleChange(e);
  	  else
  	  	  await this.handleTolerance(e);

  	  const { formData } = this.state;
  	  const { toleranceType, toleranceTimeFormat, toleranceTime, durationTime } = formData;
  	  let errorMsg = '';

  	  switch(toleranceType){
  	  	  case 'Anticipacion' :{
              if ((toleranceTimeFormat === 'min' && toleranceTime>4320) | (toleranceTimeFormat === 'hr' && toleranceTime>72))
                  errorMsg = 'subjects.form.toleranceErrors.beforeMax';
              else if((toleranceTimeFormat === 'min' && toleranceTime<10) | (toleranceTimeFormat === 'hr' && toleranceTime<1) | (toleranceTime===''))
              	  errorMsg = 'subjects.form.toleranceErrors.beforeMin'
              break;
  	  	  }
  	  	  case 'Posterior' : {
  	  	  	  if ((toleranceTimeFormat === 'min' && toleranceTime>=durationTime) | (formData.toleranceTimeFormat === 'hr' && formData.toleranceTime>=(formData.durationTime/60)))
                  errorMsg = 'subjects.form.toleranceErrors.afterMax';
              else if((toleranceTimeFormat === 'min' &&  toleranceTime<0) | (toleranceTimeFormat === 'hr' &&  toleranceTime<0) | (toleranceTime===''))
              	  errorMsg = 'subjects.form.toleranceErrors.afterMin'
              break;
		  }
		  default : {}
			  
  	  }

  	  this.setState({ errorTolerance: errorMsg });
  }

  handleLoading = () => {
    this.setState({ disabledButton: !this.state.disabledButton });
  };

  changeTab = (event, currentTab) => {
    this.setState({ currentTab });
  };

  activeCutomPlaces = name => event => {
    this.setState({ formData: { ...this.state.formData, [name]: event.target.checked } });
  };

  onChangePlace = (event , value) => {
    this.setState({selectedPlaces:value});
  };

  submit = formData => {
    
    this.handleLoading();
    
	formData.limit = parseInt(formData.limit,10);
	formData.places = this.state.selectedPlaces;

	/*if (formData.modality === 'Online') {

		this.handleLoading();
    	this.setState({ openTempOnlineAlertMessage: true });
	
	} else */
	if (formData.type === 'Grupal' && formData.modality === 'Online' && formData.limit > 15) {
        
        this.handleLoading();
    	this.setState({ openOnlineAlertMessage: true });

	} else if (formData.type === 'Grupal' && formData.limit <= 1) {
        
		this.handleLoading();
    	this.setState({ openGroupAlertMessage: true });

    } else {

	    if (this.state.formData.id > 0) {
	    	formData.id = this.state.formData.id;
	    	this.update(formData);
	    } else {
	    	this.save(formData);
	    }

    }
   
  };

  validateAndSubmit = e => {

    e.preventDefault()
    
    const { formData } = this.state;
    const { errors } = this.validator;

    this.validator.validateAll(formData)
      .then(success => {
        if (success) {
          this.submit(formData)
        } else {
          this.setState({ errors })
        }
      })
    
  }

  componentWillMount(){
  	
  	const id = this.props.match.params.id;
  	let type = 'Individual';

  	redirectTo = 'subjects';

  	if (typeof this.props.match.params.type !== 'undefined') {
       type = this.props.match.params.type;
  	}

  	if (type === 'Grupal')
  		redirectTo = 'groupsubjects';

  	if (id !== 'add') {
  		
  		titleKey = 'titleModify';
  		titleIcon = 'fa fa-pen'
	  	
	  	if (isNaN(id)) {
			this.props.history.push("/"+redirectTo);
		} else {
			this.getOneRow(this.props.match.params.id);
		}

    } else {
    	titleIcon = 'fa fa-plus';
	  	titleKey = 'titleAdd';
    	this.getCategories();
    }

  }

  closeMessage = (event) => {
    this.setState({ 
		openGroupAlertMessage: false, 
		openOnlineAlertMessage: false, 
		openTempOnlineAlertMessage: false 
	});
  };

  closeMessageGeneral = (event) => {
    this.setState({ 
		alertMsg: {
			...this.state.alertMsg,
			open: false 
		}
	});
  };

  render() {

  	const { classes } = this.props;
	  const { formData, errors, display, openGroupAlertMessage, openOnlineAlertMessage, openTempOnlineAlertMessage,
		      currentTab, places, defaultPlaces } = this.state;
  	const { categories, timeLapses, toleranceTimeFormats, toleranceTypes } = this.state.categories

    return (
      
      <div>
       <Translation>
	    {
	      (t, { i18n }) => {
	      	   return (
	      	   <React.Fragment>	
		       <form onSubmit={this.validateAndSubmit}>
		         <Grid container className={classes.container}>
		            <Grid item className={classes.grid} xs={12} sm={12} md={3}>
			       
				        <Grid item className={classNames(classes.gridHeader, classes.gridHeaderPadding)} xs={12} sm={12} md={12}>

			              <Button color="default" component={Link} to={'/'+redirectTo} className={classes.btnBack}>
			                <span className={classNames(classes.iconBack, "fa fa-arrow-left")}></span> 
			                {t('categories.form.back')}
			              </Button>
				       
				        </Grid>
				  
				    </Grid>  
			        <Grid item className={classes.grid} xs={12} sm={12} md={12}>
				      <Card className={classes.cardContet}>
			            <CardHeader className={classes.cardHeader}>
			              <h4 className={classes.cardTitleWhite}>
			                 <span className={classNames(classes.iconBack, titleIcon)}></span> 
			                 {t('subjects.form.'+titleKey)}
			              </h4>
			              <p className={classes.cardCategoryWhite}>{t('categories.form.subtitle')}</p>
			            </CardHeader>
						<AppBar position="static" style={{backgroundColor: "#f5f5f5", color: "#333"}}>
							<Tabs value={currentTab} onChange={this.changeTab} indicatorColor="primary" textColor="primary">
								<Tab value="one" label={t('users.form.tabs.general.title')} />
								<Tab value="two" label={t('users.form.tabs.places')} />
							</Tabs>
						</AppBar>
						{currentTab === 'one' &&
			            <CardContent>
			              <Grid container>
			                <Grid item className={classes.grid} xs={12} sm={12} md={6}>
			                  <CustomInput
			                    labelText={t('subjects.form.fields.name')}
			                    id="name"
			                    formControlProps={{
			                      fullWidth: true
			                    }}
			                    inputProps={{
			                      name:"name",
			                      value:formData.name,
			                      onChange:(this.handleChange),
			                      error:errors.has('name')
			                    }}
			                    customInputProps={{maxLength: "62"}}
			                    
			                  />
			                  { errors.has('name') &&
		                        <label className={classes.error} htmlFor="name"></label>
		                      }
			                </Grid>
			                <Grid item className={classes.grid} xs={12} sm={12} md={6}>
				                <FormControl fullWidth={true} className={classes.formControl}>
						          <InputLabel htmlFor="iconId">{t('subjects.form.fields.category.name')}</InputLabel>
						          <Select
						            value={formData.categoryId}
						            onChange={this.handleChange}
						            inputProps={{
						              name: 'categoryId',
						              id: 'categoryId'
						            }}
						            error={errors.has('categoryId')}
						          >
						            <MenuItem value="">
						              <em>{t('subjects.form.fields.category.defaultValue')}</em>
						            </MenuItem>
						            {categories.map((i, k) => {
						            	return (
						            		  <MenuItem value={i.id} key={k}>{i.name}</MenuItem>
						            		)
						            })}
						          </Select>
						        </FormControl>
						        { errors.has('categoryId') &&
		                          <label className={classes.error} htmlFor="categoryId"></label>
		                        }
					        </Grid>
			              </Grid>
			              <Grid container>
			                <Grid item className={classes.grid} xs={12} sm={12} md={6}>
			                    <FormControl fullWidth={true} className={classes.formControl}>
						          <InputLabel htmlFor="status">{t('subjects.form.fields.status')}</InputLabel>
						          <Select
						            value={formData.status}
						            onChange={this.handleChange}
						            inputProps={{
						              name: 'status',
						              id: 'status'
						            }}
						          >
						            <MenuItem value="Activo">{t('selectStatus.active')}</MenuItem>
						            <MenuItem value="Inactivo">{t('selectStatus.inactive')}</MenuItem>
						          </Select>
						        </FormControl>
			                </Grid>
							<Grid item className={classes.grid} xs={12} sm={12} md={6}>
			                    <FormControl fullWidth={true} className={classes.formControl}>
						          <InputLabel htmlFor="duration">{t('subjects.form.fields.duration')}</InputLabel>
						          <Select
						            value={formData.durationTime}
						            onChange={ e => this.handleErrorTolerance(e,'')}
						            inputProps={{
						              name: 'durationTime',
						              id: 'durationTime'
						            }}
						          >
									{timeLapses.map( item => {
                                        return <MenuItem value={item.val} key={'duration-'+item.val}>{item.text}</MenuItem>
						            }) }
						          </Select>
						        </FormControl>
			                </Grid>
						  </Grid> 
						  <Grid container>	
			                <Grid item className={classes.grid} xs={12} sm={12} md={6}>
			                    <FormControl fullWidth={true} className={classes.formControl}>
						          <InputLabel htmlFor="type">{t('subjects.form.fields.type')}</InputLabel>
						          <Select
						            value={formData.type}
						            onChange={this.handleChange}
						            inputProps={{
						              name: 'type',
						              id: 'type'
						            }}
						          >
						            <MenuItem value="Individual">{t('selectType.individual')}</MenuItem>
						            <MenuItem value="Grupal">{t('selectType.group')}</MenuItem>
						          </Select>
						        </FormControl>
			                </Grid>
							<Grid item className={classes.grid} xs={12} sm={12} md={6}>
			                    <FormControl fullWidth={true} className={classes.formControl}>
						          <InputLabel htmlFor="modality">{t('subjects.form.fields.modality')}</InputLabel>
						          <Select
						            value={formData.modality}
						            onChange={this.handleChange}
						            inputProps={{
						              name: 'modality',
						              id: 'modality'
						            }}
						          >
						            <MenuItem value="Presencial">{t('selectModality.inperson')}</MenuItem>
						            <MenuItem value="Online">{t('selectModality.online')}</MenuItem>
						          </Select>
						        </FormControl>
			                </Grid>
						  </Grid>	
						  <Grid container>
			                <Grid item container className="input-tolerance" xs={12} sm={12} md={6} direction="row" alignItems="center">
			                    <InputLabel className="label">{t('subjects.form.fields.tolerance')} <LinkUI href={t('subjects.form.tooltipLink')} target="blank_" className="most-info"><i className="fa fa-info-circle" style={{fontSize: 15, marginLeft: '3px', color: '#2196f3'}}></i></LinkUI></InputLabel>
                                <Grid container>
								  <Grid item className={classes.grid} xs={12} sm={12} md={true}>
									<TextField
										value={formData.toleranceTime}
										error={ this.state.errorTolerance!==''?true:false }
										className="number"
										type="number"
										onChange= { e => this.handleErrorTolerance(e,'toleranceTime') }
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											name: 'toleranceTime',
											id: 'toleranceTime',
											min: 0
										}}
										helperText={this.state.errorTolerance!==''?t(this.state.errorTolerance):''}
									/>
								  </Grid>	
								  <Grid item className={classes.grid} xs={12} sm={12} md={true}>
									<Select
										value={formData.toleranceTimeFormat}
										onChange={ e => this.handleErrorTolerance(e,'')}
										className="type-num"
										inputProps={{
											name: 'toleranceTimeFormat',
											id: 'toleranceTimeFormat'
										}}
									>
										{ toleranceTimeFormats.map( item => {
											return <MenuItem value={item.val} key={'duration-'+item.val}>{item.text}</MenuItem>
										}) }
									</Select>
								   </Grid>	
								  <Grid item className={classes.grid} xs={12} sm={12} md={true}>
									<Select
										value={formData.toleranceType}
										onChange={ e => this.handleErrorTolerance(e,'')}
										className="tolerance"
										inputProps={{
											name: 'toleranceType',
											id: 'toleranceType'
										}}
									>
										{ toleranceTypes.map( item => {
											return <MenuItem value={item.val} key={'duration-'+item.val}>{item.text}</MenuItem>
										}) }
									</Select>
								  </Grid>	
								  <Grid item className={classNames(classes.grid, 'span-content')} xs={12} sm={12} md={true}>
									  <span>{t('subjects.form.fields.msgTolerance')}</span>
								   </Grid>	
							    </Grid>
			                </Grid>
			                <Grid item className={classes.grid} xs={12} sm={12} md={6} style={{display:display}}>
						        <TextField
						          fullWidth={true}
						          id="limit"
						          name="limit"
						          label={t('subjects.form.fields.limit')}
						          value={formData.limit}
						          onChange={this.handleChange}
						          type="number"
						          className={classes.TextField}
						          InputLabelProps={{
						            shrink: true,
						          }}
						          style={{marginTop: "27px"}}
						        />
						        { errors.has('limit') &&
		                          <label className={classes.error} htmlFor="limit"></label>
		                        }
			                </Grid> 
						  </Grid>
			            </CardContent>}
						{currentTab === 'two' &&
						  <CardContent>
							<Grid container>
								<Grid item className={classes.grid} xs={12} sm={12} md={12}>
								    <label className={classes.assignPlacesTitle} style={{fontWeight:500}}>
									  {t('subjects.form.assignPlaces.title')}
									</label>
								</Grid>
							</Grid>
							<Grid container style={{marginTop:25}}>
								<Grid item className={classes.grid} xs={12} sm={12} md={12}>
								    <label className={classes.assignPlacesTitle}>
									  {t('subjects.form.assignPlaces.subtitle')}
									</label>
								</Grid>
							</Grid>
							<Grid container style={{marginTop:25}}>
							    <Grid container>
									<Grid item className={classes.grid} xs={12} sm={12} md={4}>
										<span className={classes.categoryLabel}> 
										  {t('subjects.form.assignPlaces.caption')}
										</span>
										<p>{t('subjects.form.assignPlaces.disableLabel')}</p>
									</Grid>
									<Grid item className={classes.grid} xs={12} sm={12} md={8}>
										<FormControl fullWidth={true} className={classes.formControl}>
											<FormControlLabel
												control={
													<IOSSwitch 
														checked={formData.allPlaces}
														onChange={this.activeCutomPlaces("allPlaces")}
														value="allPlaces"
														name="allPlaces" 
													/>
												}
												label={''}
											/>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							<Grid container style={{marginTop:20, display: ((formData.allPlaces)?'none':'flex')}}>
								<Grid item className={classes.grid} xs={12} sm={12} md={12}>
								    <label className={classes.assignPlacesTitle}>
									  {t('subjects.form.assignPlaces.autocomplete.title')}
									</label>
								</Grid>
							</Grid>
							<Grid container style={{marginTop:25, display: ((formData.allPlaces)?'none':'flex')}}>
								<Grid item className={classes.grid} xs={12} sm={12} md={12}>
									<Autocomplete
										multiple
										options={places}
										disableCloseOnSelect
										getOptionLabel={option => option.name}
										defaultValue={defaultPlaces}
										renderOption={(option, { selected }) => (
											<React.Fragment>
												<Checkbox
													icon={icon}
													checkedIcon={checkedIcon}
													style={{ marginRight: 8 }}
													checked={selected}
												/>
												{option.name}
											</React.Fragment>
										)}
										renderInput={params => (
											<TextField
												{...params}
												variant="outlined"
												label=""
												placeholder={t('categories.form.assignPlaces.autocomplete.placeholder')}
												fullWidth
											/>
										)}
										filterSelectedOptions={false}
										onChange={this.onChangePlace}
									/>
								</Grid>
							</Grid>	
						  </CardContent>
					    }
			            <CardActions>
			              <Button variant="contained" color="primary" className={classes.buttonBottom} disabled={this.state.disabledButton || this.state.errorTolerance!==''} type="submit" style={{marginTop:20}}>
			                 {t('categories.form.btnSave')}
			              </Button>
			            </CardActions>
			          </Card>
			        </Grid>
		          </Grid>  
		       </form>  
		       <AlertMessage open={openGroupAlertMessage} variant={alertClassName} message={t('subjects.form.alertMessages.limitValitation')} eventClose={this.closeMessage} />
			   <AlertMessage open={openOnlineAlertMessage} variant={alertClassName} message={t('subjects.form.alertMessages.onlineLimitValitation')} eventClose={this.closeMessage} />
			   <AlertMessage open={openTempOnlineAlertMessage} variant={alertInfoClassName} message={t('subjects.form.alertMessages.onlineTempLimitValitation')} eventClose={this.closeMessage} />
			   <AlertMessage open={this.state.alertMsg.open} variant={this.state.alertMsg.className} message={this.state.alertMsg.message} eventClose={this.closeMessageGeneral} />
		       </React.Fragment>
            )
          }
        }
        </Translation>
      </div>

    );

  }

}

export default withStyles(styles, { withTheme: true })(Category);
