import React, { useState, useEffect  } from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Radio from '@mui/material/Radio';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import NoSsr from '@material-ui/core/NoSsr';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';

import Selectr from 'react-select';

// Components
import CustomInput from "../../components/Inputs/CustomInput";
import AlertMessage from "../../components/Modal/AlertMessage";
import AlertDelete from "../../components/Modal/AlertDelete";
import GenericModal from "../../components/Modal/GenericModal";

// handle of languages
import { Translation } from 'react-i18next';
import i18nHelper from '../../helpers/i18n';
import handleFilesHelper from '../../helpers/handleFiles';

import { TwitterPicker } from 'react-color';

// Api
import UniversitiesApi from "../../api/Universities/UniversitiesApi";

// Models
import UserProfile from "../../models/UserProfile";

import RemoveAssignment from "./modals/RemoveAssignment";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

import pictureEmpty from "../../assets/img/empty.png";
var moment = require('moment-timezone');

let timezonesByLan = {
   'es': {file: require('../../assets/json/timezones/es.json')},
   'en': {file: require('../../assets/json/timezones/en.json')}
};
const i18nH = new i18nHelper();
const Api = new UniversitiesApi();
const userProfile = new UserProfile();
const Files = new handleFilesHelper();
const mimesAllowed = ['image/png', 'image/jpeg', 'image/jpg'];
const maxSize = 2000000;
const classSelected = 'selected';
const classNoSelected = 'no-selected';
const minDimentions = {
  'adminLogo': {
    width: 0,
    height: 128
  },
  'appLogo': {
    width: 400,
    height: 200
  },
  'homeLogo': {
    width: 400,
    height: 0
  },
  'loginLogo': {
    width: 0,
    height: 150
  },
  'emailLogo': {
    width: 400,
    height: 0
  },
  'dashboardLogo': {
    width: 220,
    height: 0
  }
};

const redimentions = {
  'adminLogo': {
    width: 0,
    height: 128,
    value: 128
  },
  'homeLogo': {
    width: 400,
    height: 0,
    value: 400
  },
  'loginLogo': {
    width: 0,
    height: 150,
    value: 150
  },
  'emailLogo': {
    width: 400,
    height: 0,
    value: 400
  },
  'dashboardLogo': {
    width: 220,
    height: 0,
    value: 220
  }
};

const texts = {
  'adminLogo': {
    attr: "height"
  },
  'homeLogo': {
    attr: "width"
  },
  'loginLogo': {
    attr: "height"
  },
  'emailLogo': {
    attr: "width"
  },
  'dashboardLogo': {
    attr: "width"
  }
};

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const Configuration = ({classes}) => {
  return <App classes={classes} />;
};

const App = ({classes}) => {

  const [pathAssets, setPathAssets] = useState('');
  const [currentTab, setTab] = useState('one');
  const [institutionsType, setInstitutionsType] = useState({});
  const [studentsRange, setstudentsRange] = useState([]);
  const [countries, setcountries] = useState([]);
  const [languages, setlanguages] = useState([]);
  const [domains, setDomains] = useState('');
  const [formData, setValues] = useState({
    campus: '',
    institutionTypeId: '',
    studentsRangeId: '',
    countryId: '',
    state: '',
    languageId: '',
    timezone: {}
  });
  const [institutionData, setData] = useState({
    name: '',
    subdomain: '',
    keywords: ''
  });
  const [managementData, setManagementData] = useState({
    hideRating: false,
    groupLessons: false,
    individualLessons: false,
    requiredComments: false
  });
  const [adminCustom, setAdminCustom] = useState({
    logoFile: {name: ''},
    logoImg: pictureEmpty,
    color: ''
  });
  const [homeLogo, setHomeLogo] = useState({
    logoFile: {name: ''},
    logoImg: pictureEmpty
  });
  const [loginLogo, setLoginLogo] = useState({
    logoFile: {name: ''},
    logoImg: pictureEmpty
  });
  const [emailLogo, setEmailLogo] = useState({
    logoFile: {name: ''},
    logoImg: pictureEmpty
  });
  const [dashboardLogo, setDashboardLogo] = useState({
    logoFile: {name: ''},
    logoImg: pictureEmpty
  });
  const [ssoData, setSSOData] = useState({
    emailIdAttr: '',
    issuer: '',
    entryPoint: '',
    logoutUrl: '',
    cert: ''
  });
  const [errors, setErros] = useState({
    campus: false,
    state: false,
    timezone: false
  });
  const [institutionErrors, setInstitutionErros] = useState({
    name: false,
    subdomain: false
  });
  const [ssoErrors, setSSOErros] = useState({
    emailIdAttr: false,
    issuer: false,
    entryPoint: false,
    logoutUrl: false,
    cert: false
  });
  const [alertMsg, setAlertMsg] = useState({
    open: false,
    className: 'success',
    message: ''
  });
  const [alertRemove, setAlertRemove] = useState({
    funct: '',
    message: ''
  });
  const [disableButtons, setDisableButtons] = useState({
    institutionData: false,
    general: false,
    management: false,
    adminLogo: true,
    adminLogoDel: true,
    homeLogo: true,
    homeLogoDel: true,
    loginLogo: true,
    loginLogoDel: true,
    emailLogo: true,
    emailLogoDel: true,
    dashboardLogo: true,
    dashboardLogoDel: true,
    color: false,
    access: false,
    sso: false
  });
  const [loadingPicture, setloadingPicture] = useState({
    adminLogo: false,
    loginLogo: false,
    homeLogo: false,
    emailLogo: false,
    dashboardLogo: false
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [authType, setAuthType] = useState('email');
  const [disableAuthType, setDisableAuthType] = useState({
    email: false, 
    sso: false
  });
  const [markedAuthType, setMarkedAuthType] = useState({
    email: classNoSelected, 
    sso: classNoSelected
  });

  const selectStyles = {
        input: base => ({
          ...base,
          color: 'blue',
          '& input': {
            font: 'inherit',
          },
        }),
  };

  const changeTab = (event, tab) => {  
    setTab(tab);
  }

  const selectPicture = (t, photoType, cntType, set, e) => {

    let cPicture = e.target.files[0];
  
    if (typeof cPicture !== 'undefined' && cPicture !== '') {
      
      if ((!mimesAllowed.includes(cPicture.type)) || (cPicture.size > maxSize)) {
         
         disableButton(photoType);
         cntType.logoFile = {name: ''};
         set({...cntType});

         if (!mimesAllowed.includes(cPicture.type))
           setAlertMsg({
              open: true,
              className: 'warning',
              message: t('configuration.cntAdminSystem.alerts.onSelect.imagesAllowed')
           });

         if (cPicture.size > maxSize)
          setAlertMsg({
              open: true,
              className: 'warning',
              message: t('configuration.cntAdminSystem.alerts.onSelect.maxSize')
          });

      } else {

        Files.resize(cPicture, redimentions[photoType].width, redimentions[photoType].height, function (response) {
                
            if (response.img.width < minDimentions[photoType].width || response.img.height < minDimentions[photoType].height) { 
              
              let alertText = t('configuration.cntAdminSystem.alerts.onSelect.incorrectDimentions');
              alertText = alertText.replace(':dim', redimentions[photoType].value)
              .replace(':attr', t('attributes.'+ texts[photoType].attr));
              
              setAlertMsg({
                open: true,
                className: 'warning',
                message: alertText
              });

            } else {

              var dataurl = response.dataurl;
              let fileToSend = Files.dataURItoBlob(dataurl);
              fileToSend.name = cPicture.name;
              
              cntType.logoImg = dataurl;     
              cntType.logoFile = fileToSend;
              set({...cntType});
              enableButton(photoType);

            }

        }, cPicture.type);
         
      }

    }
    
  }

  const updateField = e => {
    
    setValues({
      ...formData,
      [e.target.name]: e.target.value
    });

    setErros({
      ...errors,
      [e.target.name]: false
    });

    if (e.target.name === 'institutionTypeId') {
       setstudentsRange(institutionsType[e.target.value].ranges);
    }

  };

  const updateInstitutionField = e => {
    
    setData({
      ...institutionData,
      [e.target.name]: e.target.value
    });

    setInstitutionErros({
      ...institutionErrors,
      [e.target.name]: false
    });

  };

  const chooseTZ = name => tz => {
    setValues({
      ...formData,
      [name]: tz
    });
    setErros({...errors,[name]: false});
  }

  const updateSSOField = e => {
    
    setSSOData({
      ...ssoData,
      [e.target.name]: e.target.value
    });

    setSSOErros({
      ...ssoErrors,
      [e.target.name]: false
    });

  };

  function isValidUrl(userInput) {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
       return false;
    else
       return true;
  }

  const updateCheck = (name, e) => {
    setManagementData({...managementData, [name]: !managementData[name]});
  }

  const delAdminLogo = (e) => {

    setOpenAlert(false);
    disableButton("adminLogoDel");
    
    Api.removeAdminLogo().then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      enableButton("adminLogoDel");

      if (data.className === 'success') {
         setAdminCustom({
          ...adminCustom,
          logoImg: data.image,

         });
      }

      setAlertMsg({
        open: true,
        className: data.className,
        message: data.message
      });

      window.location.reload();

    }).catch((err) => {enableButton("adminLogoDel");Api.closeSession(err)});

  }

  const delHomeLogo = (e) => {

    setOpenAlert(false);
    disableButton("homeLogoDel");
    
    Api.removeHomeLogo().then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      enableButton("homeLogoDel");

      if (data.className === 'success') {
         setHomeLogo({
          ...homeLogo,
          logoImg: data.image,

         });
      }

      setAlertMsg({
        open: true,
        className: data.className,
        message: data.message
      });

    }).catch((err) => {enableButton("homeLogoDel");Api.closeSession(err)});

  }

  const delLoginLogo = (e) => {

    setOpenAlert(false);
    disableButton("loginLogoDel");
    
    Api.removeLoginLogo().then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      enableButton("loginLogoDel");

      if (data.className === 'success') {
         setLoginLogo({
          ...loginLogo,
          logoImg: data.image,

         });
      }

      setAlertMsg({
        open: true,
        className: data.className,
        message: data.message
      });

    }).catch((err) => {enableButton("loginLogoDel");Api.closeSession(err)});

  }

  const delEmailLogo = (e) => {

    setOpenAlert(false);
    disableButton("emailLogoDel");
    
    Api.removeEmailLogo().then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      enableButton("emailLogoDel");

      if (data.className === 'success') {
         setEmailLogo({
          ...emailLogo,
          logoImg: data.image,

         });
      }

      setAlertMsg({
        open: true,
        className: data.className,
        message: data.message
      });

    }).catch((err) => {enableButton("emailLogoDel");Api.closeSession(err)});

  }

  const delDashboardLogo = (e) => {

    setOpenAlert(false);
    disableButton("dashboardLogoDel");
    
    Api.removeDashboardLogo().then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      enableButton("dashboardLogoDel");

      if (data.className === 'success') {
         setDashboardLogo({
          ...dashboardLogo,
          logoImg: data.image,

         });
      }

      setAlertMsg({
        open: true,
        className: data.className,
        message: data.message
      });

    }).catch((err) => {enableButton("dashboardLogoDel");Api.closeSession(err)});

  }

  const changeColor = (color, e) => {
    adminCustom.color = color.hex;
    setAdminCustom({...adminCustom});
  }

  const updateColor = (e) => {
    
    disableButton("color");
    
    Api.updateColor(adminCustom.color).then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      enableButton("color");

      setAlertMsg({
        open: true,
        className: data.className,
        message: data.message
      });

      window.location.reload();

    }).catch((err) => {enableButton("color");Api.closeSession(err)});

  }

  const closeMessage = (event) => {
    setAlertMsg({...alertMsg, open: false});
  };

  const showAlert = (funct, e) => {
    setAlertRemove({
        funct: funct
    });
    setOpenAlert(true);
  };

  const closeAlert = (event) => {
    setOpenAlert(false);
  };

  const showModal = (e) => {
    setOpenModal(true);
  };

  const closeModal = (e) => {
    setOpenModal(false);
  };

  function disableButton(btnType){
    setDisableButtons({...disableButtons, [btnType]: true});
  }

  function enableButton(btnType){
    setDisableButtons({...disableButtons, [btnType]: false});
  }

  function hideLoading(name){
    loadingPicture[name] = !loadingPicture[name];
    setloadingPicture({...loadingPicture});
  }

  function validateDomain(field){
    const re = /^[a-z0-9][a-z0-9-]{0,61}[a-z0-9](?:\.[a-z]{2,})+$/;
    if (!re.test(field)) return false;
    return true;
  }

  function sendAccessData() {

      Api.updateAccessControl({
        domains: domains
      }).then(response => {

        let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
        let data = responseData.data;

        enableButton("access");
        setAlertMsg({
          open: true,
          className: data.className,
          message: data.message
        })

      }).catch((err) => {enableButton("access");Api.closeSession(err)});

  }

  function sendManagementData() {

      disableButton("management");

      Api.updateManagement(managementData).then(response => {

        let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
        let data = responseData.data;

        enableButton("management");
        setAlertMsg({
          open: true,
          className: data.className,
          message: data.message
        })

      }).catch((err) => {enableButton("management");Api.closeSession(err)});

  }

  const validateControlData = (t, e) => {

    let f = false, tempDomains = domains.split(",");

    if (tempDomains.length > 5) {
      setAlertMsg({
        open: true,
        className: 'warning',
        message: t('configuration.cntAuthentication.alerts.maxDomains')
      });
    } else {

      for (var k in tempDomains) {
        if (!validateDomain(tempDomains[k].trim())) {
            f = true;
            break;
        }
      }

      if (f) {

        setAlertMsg({
            open: true,
            className: 'warning',
            message: t('configuration.cntAuthentication.alerts.incorrectDomain')
        });

      } else {
        
        disableButton("access");
        sendAccessData();

      }

    }

  }

  function sendData() {
      
      Api.update(formData).then(response => {

        let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
        let data = responseData.data;

        enableButton("general");

        if (data.className === 'success') {
            setAlertMsg({
              open: true,
              className: 'success',
              message: data.message
            });
        } else {
          console.log("Error");
        }

      }).catch((err) => {enableButton("general");Api.closeSession(err)});

  }

  function sendInstitutionData() {
      
    Api.updateInstitution(institutionData).then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      enableButton("institutionData");

      // if (data.className === 'success') {
          setAlertMsg({
            open: true,
            className: data.className,
            message: data.message
          });
      /*} else {
        console.log("Error");
      }*/

    }).catch((err) => {enableButton("institutionData");Api.closeSession(err)});

  }

  function sendCusmotization(e) {

    let dat = new FormData();
    
    dat.append('logoAdmin', adminCustom.logoFile, adminCustom.logoFile.name);
    // dat.append('logoAdmin2', adminCustom.logoFile, 'logoAdmin2');

    /*for (var key of data.entries()) {
      console.log(key[0] + ', ' + key[1])
    }*/

    disableButton("adminLogo");
    hideLoading("adminLogo");

    Api.uploadCustomization(dat).then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      // enableButton("adminLogo");
      hideLoading("adminLogo");
      setAdminCustom({
          ...adminCustom,
          logoFile: {name: ''}
      });

      setAlertMsg({
        open: true,
        className: data.className,
        message: data.message
      })

      window.location.reload();

    }).catch((err) => {
      enableButton("adminLogo");
      hideLoading("adminLogo");
      Api.closeSession(err);
    });
    
  }

  function sendHomeLogo(e) {

    let dat = new FormData();
    
    dat.append('homeLogo', homeLogo.logoFile, homeLogo.logoFile.name);

    disableButton("homeLogo");
    hideLoading("homeLogo");

    Api.uploadHomeLogo(dat).then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      hideLoading("homeLogo");
      setHomeLogo({
          ...homeLogo,
          logoFile: {name: ''}
      });

      setAlertMsg({
        open: true,
        className: data.className,
        message: data.message
      })

    }).catch((err) => {
      enableButton("homeLogo");
      hideLoading("homeLogo");
      Api.closeSession(err);
    });
    
  }

  function sendLoginLogo(e) {

    let dat = new FormData();
    
    dat.append('loginLogo', loginLogo.logoFile, loginLogo.logoFile.name);

    disableButton("loginLogo");
    hideLoading("loginLogo");

    Api.uploadLoginLogo(dat).then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      hideLoading("loginLogo");
      setLoginLogo({
          ...loginLogo,
          logoFile: {name: ''}
      });

      setAlertMsg({
        open: true,
        className: data.className,
        message: data.message
      })

    }).catch((err) => {
      enableButton("loginLogo");
      hideLoading("loginLogo");
      Api.closeSession(err);
    });
    
  }

  function sendDashboardLogo(e) {

    let dat = new FormData();
    
    dat.append('dashboardLogo', dashboardLogo.logoFile, dashboardLogo.logoFile.name);

    disableButton("dashboardLogo");
    hideLoading("dashboardLogo");

    Api.uploadDashboardLogo(dat).then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      hideLoading("dashboardLogo");
      setDashboardLogo({
          ...dashboardLogo,
          logoFile: {name: ''}
      });

      setAlertMsg({
        open: true,
        className: data.className,
        message: data.message
      })

    }).catch((err) => {
      enableButton("dashboardLogo");
      hideLoading("dashboardLogo");
      Api.closeSession(err);
    });
    
  }

  function sendEmailLogo(e) {

    let dat = new FormData();
    
    dat.append('emailLogo', emailLogo.logoFile, emailLogo.logoFile.name);

    disableButton("emailLogo");
    hideLoading("emailLogo");

    Api.uploadEmailLogo(dat).then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      hideLoading("emailLogo");
      setEmailLogo({
          ...emailLogo,
          logoFile: {name: ''}
      });

      setAlertMsg({
        open: true,
        className: data.className,
        message: data.message
      })

    }).catch((err) => {
      enableButton("emailLogo");
      hideLoading("emailLogo");
      Api.closeSession(err);
    });
    
  }

  const validateForm = (e) => {
    
    e.preventDefault();

    let labels = {};
  
    // if (formData.name.trim() === '') labels['name'] = true;
    if (formData.campus.trim() === '') labels['campus'] = true;
    if (formData.state.trim() === '') labels['state'] = true;
    if (formData.timezone === null || Object.keys(formData.timezone) === 0) labels['timezone'] = true;

    if (Object.keys(labels).length > 0) {
        setErros({...errors, ...labels});
    } else {
        disableButton("general");
        sendData();
    }
    
  }

  const validateInstitutionForm = (e) => {
    
    e.preventDefault();

    let labels = {};
  
    if (institutionData.name.trim() === '') labels['name'] = true;
    if (institutionData.subdomain.trim() === '') labels['subdomain'] = true;

    if (Object.keys(labels).length > 0) {
        setInstitutionErros({...institutionErrors, ...labels});
    } else {
        disableButton("institutionData");
        sendInstitutionData();
    }
    
  }

  function sendSSOData() {
      
    Api.updateSSOData(ssoData).then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      enableButton("sso");

      if (data.className === 'success') {
          setAlertMsg({
            open: true,
            className: 'success',
            message: data.message
          });
      } else {
        console.log("Error");
      }

    }).catch((err) => {enableButton("sso");Api.closeSession(err)});

  }

  const validateSSOData = (e) => {
    
    // e.preventDefault();

    let labels = {};
  
    if (ssoData.emailIdAttr.trim() === '') labels['emailIdAttr'] = true;
    if (ssoData.issuer.trim() === '') labels['issuer'] = true;
    if (ssoData.entryPoint.trim() === '' ||
        (ssoData.entryPoint.trim() !== '' && !isValidUrl(ssoData.entryPoint.trim())) ) labels['entryPoint'] = true;

    if (ssoData.logoutUrl.trim() !== '' && !isValidUrl(ssoData.logoutUrl.trim())) labels['logoutUrl'] = true;    

    if (ssoData.cert.trim() === '') labels['cert'] = true;

    if (Object.keys(labels).length > 0) {
        setSSOErros({...ssoErrors, ...labels});
    } else {
      disableButton("sso");
      sendSSOData();
    }
    
  }

  function getData() {

      Api.getData().then(response => {

        let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
        let data = responseData.data, label = '', tempTz = '';
        
        for (var k in timezonesByLan[i18nH.getLanguage()].file.timezones) {

          tempTz = timezonesByLan[i18nH.getLanguage()].file.timezones[k].label;
          timezonesByLan[i18nH.getLanguage()].file.timezones[k].label = 
          (
            tempTz.indexOf(moment.utc().tz(timezonesByLan[i18nH.getLanguage()].file.timezones[k].value).format('Z')) != -1
          )?
          timezonesByLan[i18nH.getLanguage()].file.timezones[k].label:
          '(GMT'+moment.utc().tz(timezonesByLan[i18nH.getLanguage()].file.timezones[k].value).format('Z')+') '+
          timezonesByLan[i18nH.getLanguage()].file.timezones[k].label;

           if (timezonesByLan[i18nH.getLanguage()].file.timezones[k].value === data.info.timeZone) {
              label = timezonesByLan[i18nH.getLanguage()].file.timezones[k].label;
              // break;
           }

        }

        setInstitutionsType(data.institutionsType);
        setstudentsRange(data.institutionsType[data.info.institutionTypeId].ranges);
        setcountries(data.countries);
        setlanguages(data.languages);
        // Remove from api
        // setDomains(data.info.universitydomains);

        setValues({
          name: data.info.name,
          campus: data.info.campus,
          institutionTypeId: data.info.institutionTypeId,
          studentsRangeId: data.info.studentsRangeId,
          countryId: data.info.countryId,
          state: data.info.state,
          languageId: data.info.languageId,
          timezone: {value: data.info.timeZone, label: label}
        });

        setManagementData({
          hideRating: (data.info.hideRating === 1)?true:false,
          groupLessons: (data.info.groupLessons === 1)?true:false,
          individualLessons: (data.info.individualLessons === 1)?true:false,
          requiredComments: (data.info.requiredComments === 1)?true:false,
        });

        setAdminCustom({
          ...adminCustom,
          logoImg: data.info.logoAdmin,
          color: data.info.color
        });

        setHomeLogo({
         ...homeLogo,
         logoImg: data.info.homePhoto
        });

        setLoginLogo({
         ...loginLogo,
         logoImg: data.info.icon
        });

        setEmailLogo({
         ...emailLogo,
         logoImg: data.info.emailPhoto
        });

        setDashboardLogo({
         ...dashboardLogo,
         logoImg: data.info.dashboardLogo
        });

        setPathAssets(data.pathAssets);
        setData({...institutionData, name:data.institutionName, subdomain: data.subdomain, keywords: data.keywords});

      }).catch((err) => {Api.closeSession(err)});

  }

  function setDefaultAuthType(authT) {

    let dEmail = true, dSSO = true, cEmail = classNoSelected, cSSO = classNoSelected;

    if (authT === 'email') { 
      dEmail = false;
      cEmail = classSelected;
    } else {
      dSSO = false;
      cSSO = classSelected;
    }
    
    setAuthType(authT);
    setMarkedAuthType({
      ...markedAuthType,
      email: cEmail,
      sso: cSSO
    });
    setDisableAuthType({
      ...disableAuthType, 
      email: dEmail,
      sso: dSSO
    });

  }

  function loadAuthData() {

    Api.getDomains().then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      setDefaultAuthType(data.authType);
      setDomains(data.domains);
      if (data.ssodata) {
        setSSOData(data.ssodata);
      }

    }).catch((err) => {Api.closeSession(err)});  
 
  }

  const updateDomains = e => {
    
    setDomains(
      e.target.value
    );

  };

  function sendAuthType(authT) {
      
    Api.updateAuthType(authT).then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
      let data = responseData.data;

      if (data.className === 'success') {
          /*setAlertMsg({
            open: true,
            className: 'success',
            message: data.message
          });*/
          console.log(data.message);
      } else {
        console.log("Error");
      }

    }).catch((err) => {Api.closeSession(err)});

  }

  const handleChangeAuthType = (event) => {
    
    setDefaultAuthType(event.target.value);
    sendAuthType(event.target.value);
    
  };

  useEffect(() => {

    getData();
    
  }, [])

  return (
    <div>
      <Translation>
         {
          (t, { i18n }) => {
            
            return (
                <React.Fragment>
                  <Grid container className={classes.container}>

                    <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                      <Card className={classes.cardContet}>    
                        <AppBar position="static" style={{backgroundColor: "#f5f5f5", color: "#333"}}>
                            <Tabs value={currentTab} onChange={changeTab} indicatorColor="primary" textColor="primary">
                              <Tab value="one" label={t('configuration.tabs.general')} />
                              {typeof userProfile.getPermissions()['settings_web_portal'] !== 'undefined' &&
                              <Tab value="two" label={t('configuration.tabs.webPortal')} />}
                              {typeof userProfile.getPermissions()['settings_movile_app'] !== 'undefined' &&
                              <Tab value="three" label={t('configuration.tabs.appMovil')} />}
                              {typeof userProfile.getPermissions()['settings_utilities'] !== 'undefined' &&
                              <Tab value="four" label={t('configuration.tabs.utilities')} />}
                              {typeof userProfile.getPermissions()['settings_authentication'] !== 'undefined' &&
                              <Tab value="five" label={t('configuration.tabs.authentication')} onClick={loadAuthData} />}
                            </Tabs>
                        </AppBar>
                        {currentTab === 'one' &&
                        <CardContent>
                          <Grid container>

                          {typeof userProfile.getPermissions()['settings_institution_general_data'] !== 'undefined' &&  
                            <Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>
                              <span className={classes.cntTitle}>{t('configuration.cntInstitutionInformation.title')}</span>
                              <form onSubmit={validateInstitutionForm}>
                                <Grid container style={{marginTop: 20}}>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                    <div className={classes.wrapInput}>
                                      <CustomInput
                                        id="name"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          name:"name",
                                          value:(institutionData.name),
                                          onChange:(updateInstitutionField),
                                          error:institutionErrors.name,
                                        }}
                                        customInputProps={{maxLength: "80"}}
                                      />
                                      <div className={classes.wrapLabel}>
                                        <label className={classes.configurationLabel}>{t('configuration.cntInstitutionInformation.fields.institutionName')}</label>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                    <div className={classes.wrapInput} style={{position:'relative'}}>
                                      <CustomInput
                                        id="subdomain"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          name:"subdomain",
                                          value:(institutionData.subdomain),
                                          onChange:(updateInstitutionField),
                                          error:institutionErrors.subdomain,
                                          startAdornment:(<InputAdornment position="start">https://</InputAdornment>),
                                          endAdornment:(<InputAdornment position="end">.linc.io</InputAdornment>),
                                          disabled:true
                                        }}
                                        customInputProps={{maxLength: "40", minLength: "3"}}
                                      />
                                      <div className={classes.wrapLabel}>
                                        <label className={classes.configurationLabel}>{t('configuration.cntInstitutionInformation.fields.subdomain')}</label>
                                      </div>
                                    </div>
                                    <div style={{marginTop: -10}}>
                                      <label style={{fontSize: 12}}><i className='fa fa-info-circle'></i> {t('configuration.cntInstitutionInformation.fields.labelInfoSubdomain')}
                                      </label>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 0}}>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                    <div>
                                      <label className={classes.controlAccessLabel} style={{color: '#AAAAAA', fontWeight: 400, fontSize: 13}}>{t('users.form.fields.keywords.label')}</label>
                                      <Tooltip title={t('users.form.fields.keywords.tooltip')} classes={{ tooltip: classes.lightTooltip }}>  
                                        <span className="fa fa-info-circle" style={{fontSize: 15, color: '#2196f3'}}></span>
                                      </Tooltip>
                                    </div>
                                    <TextField
                                        fullWidth={true}
                                        id="keywords"
                                        name="keywords"
                                        label={''}
                                        multiline
                                        minRows="2"
                                        value={institutionData.keywords}
                                        className={classes.textArea}
                                        onChange={updateInstitutionField}
                                        inputProps={{maxLength:(100)}}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                                    <Button variant="contained" color="primary" className={classes.buttonBottom} style={{marginTop: 20, marginLeft: 0}} disabled={disableButtons.institutionData} type="submit">
                                      {t('configuration.cntUniversityInformation.btnSave')}
                                    </Button>
                                  </Grid>
                                </Grid> 
                              </form>  
                            </Grid>}  

                          {typeof userProfile.getPermissions()['settings_campus_general_data'] !== 'undefined' &&
                            <Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>
                              <span className={classes.cntTitle}>{t('configuration.cntUniversityInformation.title')}</span>
                              <form onSubmit={validateForm}>
                                <Grid container style={{marginTop: 20}}>
                                    <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                      <div className={classes.wrapInput}>
                                        <CustomInput
                                          id="campus"
                                          formControlProps={{
                                            fullWidth: true
                                          }}
                                          inputProps={{
                                            name:"campus",
                                            value:(formData.campus),
                                            onChange:(updateField),
                                            error:errors.campus
                                          }}
                                          customInputProps={{maxLength: "50"}}
                                        />
                                        <div className={classes.wrapLabel}>
                                          <label className={classes.configurationLabel}>{t('configuration.cntUniversityInformation.fields.campus')}</label>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                      <FormControl fullWidth={true} className={classes.formControl}>
                                        <InputLabel htmlFor="institutionTypeId" className={classes.configurationLabel}>{t('configuration.cntUniversityInformation.fields.educationLevel')}</InputLabel>
                                        <Select
                                          value={formData.institutionTypeId}
                                          inputProps={{
                                            name: 'institutionTypeId',
                                            id: 'institutionTypeId',
                                            onChange:(updateField)
                                          }}
                                        >
                                        {Object.keys(institutionsType).map((v, i) => (
                                              <MenuItem key={institutionsType[v].id} value={institutionsType[v].id}>{institutionsType[v].name}</MenuItem>                                          
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                      <FormControl fullWidth={true} className={classes.formControl}>
                                        <InputLabel htmlFor="studentsRangeId" className={classes.configurationLabel}>{t('configuration.cntUniversityInformation.fields.institutionSize')}</InputLabel>
                                        <Select
                                          value={formData.studentsRangeId}
                                          inputProps={{
                                            name: 'studentsRangeId',
                                            id: 'studentsRangeId',
                                            onChange:(updateField)
                                          }}
                                        >
                                          {studentsRange.map(v => (
                                              <MenuItem key={v.id} value={v.id}>{v.range}</MenuItem>                                          
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                      <FormControl fullWidth={true} className={classes.formControl}>
                                        <InputLabel htmlFor="countryId" className={classes.configurationLabel}>{t('configuration.cntUniversityInformation.fields.country')}</InputLabel>
                                        <Select
                                          value={formData.countryId}
                                          inputProps={{
                                            name: 'countryId',
                                            id: 'countryId',
                                            onChange:(updateField)
                                          }}
                                        >
                                          {countries.map(i => (
                                              <MenuItem key={i.id} value={i.id}>{i.translation}</MenuItem>                                          
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                </Grid> 
                                <Grid container>
                                    <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                      <div className={classes.wrapInput}>
                                        <CustomInput
                                          id="state"
                                          formControlProps={{
                                            fullWidth: true
                                          }}
                                          inputProps={{
                                            name:"state",
                                            value:(formData.state),
                                            onChange:(updateField),
                                            error:errors.state
                                          }}
                                          customInputProps={{maxLength: "25"}}
                                        />
                                        <div className={classes.wrapLabel}>
                                          <label className={classes.configurationLabel}>{t('configuration.cntUniversityInformation.fields.state')}</label>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                      <FormControl fullWidth={true} className={classes.formControl} style={{marginTop: 42}}>
                                        <Select
                                          value={formData.languageId}
                                          inputProps={{
                                            name: 'languageId',
                                            id: 'languageId',
                                            onChange:(updateField)
                                          }}
                                        >
                                          {languages.map(i => (
                                              <MenuItem key={i.id} value={i.id}>{i.translation}</MenuItem>                                          
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <div className={classes.wrapLabel}>
                                        <label className={classes.configurationLabel}>{t('configuration.cntUniversityInformation.fields.mainLanguage')}</label>
                                      </div>
                                    </Grid>
                                </Grid>  
                                <Grid container>
                                    <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                      <div className={classes.wrapInput} style={{marginTop: 39}}>
                                        <NoSsr>
                                          <Selectr
                                            classes={classes}
                                            styles={selectStyles}
                                            options={timezonesByLan[i18nH.getLanguage()].file.timezones}
                                            components={components}
                                            value={formData.timezone}
                                            placeholder={t('introductoryModal.view1.fields.timeZone.placeholder')}
                                            onChange={chooseTZ('timezone')}
                                            isClearable
                                          />
                                        </NoSsr>
                                        <div className={classes.wrapLabel} style={{bottom: 33}}>
                                          <label className={classes.configurationLabel}>{t('configuration.cntUniversityInformation.fields.timeZone')}</label>
                                          <Tooltip title={t('configuration.cntUniversityInformation.tooltips.timeZone')} classes={{ tooltip: classes.lightTooltip }}>  
                                            <span className="fa fa-info-circle" style={{fontSize: 15, color: '#2196f3'}}></span>
                                          </Tooltip>
                                        </div>
                                        { errors.timezone &&
                                          <hr style={{height: 2, backgroundColor: '#f44336', border: 'none', marginTop: -1}} />
                                        } 
                                      </div>
                                    </Grid>
                                </Grid> 
                                <Grid container>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                                    <Button variant="contained" color="primary" className={classes.buttonBottom} style={{marginTop: 20, marginLeft: 0}} disabled={disableButtons.general} type="submit">
                                      {t('configuration.cntUniversityInformation.btnSave')}
                                    </Button>
                                  </Grid>
                                </Grid> 
                              </form>
                            </Grid>}   

                           {typeof userProfile.getPermissions()['settings_management_data'] !== 'undefined'? 
                            <Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>

                              <span className={classes.cntTitle}>{t('configuration.cntFuncionalityManagment.title')}</span>

                              <Grid container>
                                <Grid item style={{marginTop: 40, paddingBottom: 20}}>
                                  <div style={{paddingLeft: 15}}>
                                    
                                    <div>
                                      <label className={classes.gestionSubtitle}>{t('configuration.cntFuncionalityManagment.subtitle')}</label>
                                      <hr style={{height: 2, backgroundColor: '#666', border: 'none'}}/>
                                    </div>

                                    <div style={{marginTop: 15}}>
                                      <label className={classes.controlSubtitle}>
                                          {t('configuration.cntFuncionalityManagment.description')}
                                      </label>
                                    </div>

                                    <div style={{marginTop: 15}}>
                                      <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={managementData.groupLessons}
                                              value="groupLessons"
                                              onChange={e => updateCheck('groupLessons', e)}
                                              classes={{
                                                root: classes.customCheck,
                                                checked: classes.checked,
                                              }}
                                            />
                                          }
                                          label={t('configuration.cntFuncionalityManagment.fields.groupLessons')}
                                          style={{marginLeft: -6}}
                                      />
                                    </div>

                                    <div style={{marginBottom: 15}}>
                                      <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={managementData.individualLessons}
                                              value="individualLessons"
                                              onChange={e => updateCheck('individualLessons', e)}
                                              classes={{
                                                root: classes.customCheck,
                                                checked: classes.checked,
                                              }}
                                            />
                                          }
                                          label={t('configuration.cntFuncionalityManagment.fields.individualLessons')}
                                          style={{marginLeft: -6}}
                                      />
                                    </div>

                                    <div>
                                      <label className={classes.gestionSubtitle}>{t('configuration.cntFuncionalityManagment.subtitle2')}</label>
                                      <hr style={{height: 2, backgroundColor: '#666', border: 'none'}}/>
                                    </div>

                                    <div style={{marginTop: 15}}>
                                      <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={managementData.requiredComments}
                                              value="requiredComments"
                                              onChange={e => updateCheck('requiredComments', e)}
                                              classes={{
                                                root: classes.customCheck,
                                                checked: classes.checked,
                                              }}
                                            />
                                          }
                                          label={t('configuration.cntFuncionalityManagment.fields.feedbackComments')}
                                          style={{marginLeft: -6}}
                                      />
                                    </div>

                                    <div style={{marginBottom: 15}}>
                                      <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={managementData.hideRating}
                                              value="hideRating"
                                              onChange={e => updateCheck('hideRating', e)}
                                              classes={{
                                                root: classes.customCheck,
                                                checked: classes.checked,
                                              }}
                                            />
                                          }
                                          label={t('configuration.cntFuncionalityManagment.fields.activeFeedback')}
                                          style={{marginLeft: -6}}
                                      />
                                    </div>

                                  </div>
                                </Grid>
                              </Grid>

                              <Grid container>
                                <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                                  <Button variant="contained" color="primary" onClick={sendManagementData} className={classes.buttonBottom} style={{marginTop: 20, marginLeft: 0}} disabled={disableButtons.management}>
                                    {t('configuration.cntFuncionalityManagment.btnSave')}
                                  </Button>
                                </Grid>
                              </Grid>

                            </Grid>:''}

                            {typeof userProfile.getPermissions()['settings_access_control'] !== 'undefined'?'':''}
                            
                          </Grid>
                        </CardContent>}  

                        {currentTab === 'two' &&
                        <CardContent>
                          <Grid container>    

                            <Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>

                              <span className={classes.cntTitle}>{t('configuration.cntAdminSystem.title')}</span>

                              <Grid container>
                                <Grid item style={{marginTop: 40, paddingBottom: 20}} xs={12} sm={12} md={12}>
                                  <div className={classes.paddingConfig}>
                                    
                                    <div style={{paddingBottom: 10}}>
                                      <label className={classNames(classes.controlSubtitle, classes.paRight)}>{t('configuration.cntAdminSystem.logo.title')}</label>
                                      <Tooltip title={t('configuration.cntAdminSystem.tooltips.logo')} classes={{ tooltip: classes.lightTooltip }}>  
                                        <span className="fa fa-info-circle" style={{fontSize: 15, color: '#2196f3'}}></span>
                                      </Tooltip>
                                    </div>

                                    <img src={adminCustom.logoImg} alt="empty" style={{height:64, backgroundColor: adminCustom.color, padding: '10px'}} />
                                    <br/>
                                    <Typography variant="caption" gutterBottom>{t('configuration.cntAdminSystem.logo.tagDimentions')}</Typography>
                                    <Grid container className={classes.marginTopCnt}>
                                      <input
                                        accept="image/x-png,image/jpeg"
                                        className={classes.hidden}
                                        type="file"
                                        id="contained-button-file"
                                        onChange={e => selectPicture(t, 'adminLogo', adminCustom, setAdminCustom, e)}
                                      />
                                      <label htmlFor="contained-button-file">
                                        <Button variant="contained" size="small" component="span">
                                          {t('configuration.cntAdminSystem.logo.btnSelectTitle')}
                                        </Button>
                                        <TextField value={adminCustom.logoFile.name} style={{marginLeft: 10}} placeholder={t('configuration.cntAdminSystem.logo.textInput')} disabled />
                                      </label>
                                    </Grid>
                                    <Grid container className={classes.marginTopCnt}>
                                      <Button variant="contained" color="primary" onClick={sendCusmotization} disabled={disableButtons.adminLogo}> 
                                         {t('configuration.cntAdminSystem.logo.btnUpload')}
                                      </Button>
                                      <Tooltip title={t('configuration.cntAdminSystem.logo.btnDeleteTitle')} classes={{ tooltip: classes.lightTooltip }}>  
                                        <Button variant="contained" style={{marginLeft: 10, marginRight:10}} 
                                         onClick={e => showAlert(delAdminLogo, e)}><span className="fa fa-trash"></span></Button>
                                      </Tooltip>
                                      { loadingPicture.adminLogo ? <CircularProgress className={classes.progress} style={{width: 29, height: 29}} /> : ''}
                                    </Grid>
                                    <div style={{paddingBottom: 10, marginTop: 25}}>
                                      <label className={classNames(classes.controlSubtitle, classes.paRight)}>{t('configuration.cntAdminSystem.color.title')}</label>
                                      <Tooltip title={t('configuration.cntAdminSystem.tooltips.color')} classes={{ tooltip: classes.lightTooltip }}>  
                                        <span className="fa fa-info-circle" style={{fontSize: 15, color: '#2196f3'}}></span>
                                      </Tooltip>
                                    </div>

                                    <Grid item >  
                                      <Paper style={{width: 276, height: 100, backgroundColor: adminCustom.color }}>
                                         
                                      </Paper>                                    
                                    </Grid>
                      
                                    <Grid container className={classes.marginTopCnt}>
                                       <TwitterPicker 
                                          triangle={'hide'} 
                                          color={ adminCustom.color }
                                          onChangeComplete={ changeColor } 
                                        />
                                    </Grid>
                      
                                    <Grid container style={{marginTop: 10}}>
                                      <Button variant="contained" color="primary" disabled={disableButtons.color} onClick={updateColor}>
                                        {t('configuration.cntAdminSystem.color.btnUpdate')}
                                      </Button>
                            
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>

                            </Grid>
                          </Grid>
                        </CardContent>}

                        {currentTab === 'three' &&
                        <CardContent>

                          <Grid container>    

                            <Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>

                              <span className={classes.cntTitle}>{t('configuration.cntCustomization.title')}</span>

                              <Grid container>
                                <Grid item style={{marginTop: 40, paddingBottom: 20}} xs={12} sm={12} md={12}>

                                  <div className={classes.paddingConfig} style={{paddingBottom:20}}>
                                    <div style={{paddingBottom: 10}}>
                                      <label className={classNames(classes.controlSubtitle, classes.paRight)}>{t('configuration.cntCustomization.dashboardLogo.title')}</label>
                                      <Tooltip
                                       title={
                                            <React.Fragment>
                                              <img src={pathAssets+"adm-dashboard-logo.png"} alt=""/>
                                            </React.Fragment>
                                          }
                                       classes={{ tooltip: classes.lightTooltip }}>  
                                        <span className="fa fa-info-circle" style={{fontSize: 15, color: '#2196f3'}}></span>
                                      </Tooltip>
                                    </div>
                                    <img src={dashboardLogo.logoImg} alt="empty" style={{height:60, backgroundColor: '#2188FC', padding:10}} /> <br/>
                                    <Typography variant="caption" gutterBottom>{t('configuration.cntCustomization.dashboardLogo.recommendation')}</Typography> <br/>
                                    <Typography variant="caption" gutterBottom>{t('configuration.cntCustomization.dashboardLogo.tagDimentions')}</Typography>
                                    <Grid container className={classes.marginTopCnt}>
                                      <input
                                        accept="image/x-png,image/jpeg"
                                        className={classes.hidden}
                                        type="file"
                                        id="contained-button-file4"
                                        onChange={e => selectPicture(t, 'dashboardLogo', dashboardLogo, setDashboardLogo, e)}
                                      />
                                      <label htmlFor="contained-button-file4">
                                        <Button variant="contained" size="small" component="span">
                                          {t('configuration.cntAdminSystem.logo.btnSelectTitle')}
                                        </Button>
                                        <TextField value={dashboardLogo.logoFile.name} style={{marginLeft: 10}} placeholder={t('configuration.cntAdminSystem.logo.textInput')} disabled />
                                      </label>
                                    </Grid>
                                    <Grid container className={classes.marginTopCnt}>
                                      <Button variant="contained" color="primary" onClick={sendDashboardLogo} disabled={disableButtons.dashboardLogo}>
                                         {t('configuration.cntAdminSystem.logo.btnUpload')}
                                      </Button>
                                      <Tooltip title={t('configuration.cntAdminSystem.logo.btnDeleteTitle')} classes={{ tooltip: classes.lightTooltip }}>
                                        <Button variant="contained" style={{marginLeft: 10, marginRight:10}}
                                        onClick={e => showAlert(delDashboardLogo, e)}><span className="fa fa-trash"></span></Button>
                                      </Tooltip>
                                      { loadingPicture.dashboardLogo ? <CircularProgress className={classes.progress} style={{width: 29, height: 29}} /> : ''}
                                    </Grid>
                                  </div>
                                  
                                  <div className={classes.paddingConfig} style={{paddingBottom:20}}>
                                    <div style={{paddingBottom: 10}}>
                                      <label className={classNames(classes.controlSubtitle, classes.paRight)}>{t('configuration.cntCustomization.loginLogo.title')}</label>
                                      <Tooltip
                                       title={
                                            <React.Fragment>
                                              <img src={pathAssets+"adm-login-logo.png"} alt="" />
                                            </React.Fragment>
                                          }
                                       classes={{ tooltip: classes.lightTooltip }}>  
                                        <span className="fa fa-info-circle" style={{fontSize: 15, color: '#2196f3'}}></span>
                                      </Tooltip>
                                    </div>
                                    <img src={loginLogo.logoImg} alt="empty" style={{height:75}} /> <br/>
                                    <Typography variant="caption" gutterBottom>{t('configuration.cntCustomization.loginLogo.tagDimentions')}</Typography>
                                    <Grid container className={classes.marginTopCnt}>
                                      <input
                                        accept="image/x-png,image/jpeg"
                                        className={classes.hidden}
                                        type="file"
                                        id="contained-button-file"
                                        onChange={e => selectPicture(t, 'loginLogo', loginLogo, setLoginLogo, e)}
                                      />
                                      <label htmlFor="contained-button-file">
                                        <Button variant="contained" size="small" component="span">
                                          {t('configuration.cntAdminSystem.logo.btnSelectTitle')}
                                        </Button>
                                        <TextField value={loginLogo.logoFile.name} style={{marginLeft: 10}} placeholder={t('configuration.cntAdminSystem.logo.textInput')} disabled />
                                      </label>
                                    </Grid>
                                    <Grid container className={classes.marginTopCnt}>
                                      <Button variant="contained" color="primary" onClick={sendLoginLogo} disabled={disableButtons.loginLogo}>
                                         {t('configuration.cntAdminSystem.logo.btnUpload')}
                                      </Button>
                                      <Tooltip title={t('configuration.cntAdminSystem.logo.btnDeleteTitle')} classes={{ tooltip: classes.lightTooltip }}>
                                        <Button variant="contained" style={{marginLeft: 10, marginRight:10}}
                                        onClick={e => showAlert(delLoginLogo, e)}><span className="fa fa-trash"></span></Button>
                                      </Tooltip>
                                      { loadingPicture.loginLogo ? <CircularProgress className={classes.progress} style={{width: 29, height: 29}} /> : ''}
                                    </Grid>
                                  </div>


                                  <div className={classes.paddingConfig} style={{paddingBottom:20}}>
                                    <div style={{paddingBottom: 10}}>
                                      <label className={classNames(classes.controlSubtitle, classes.paRight)}>{t('configuration.cntCustomization.homeLogo.title')}</label>
                                      <Tooltip
                                       title={
                                          <React.Fragment>
                                            <img src={pathAssets+"adm-tutoring-home-logo.png"} alt="" />
                                          </React.Fragment>
                                        }
                                       classes={{ tooltip: classes.lightTooltip }}>  
                                        <span className="fa fa-info-circle" style={{fontSize: 15, color: '#2196f3'}}></span>
                                      </Tooltip>
                                    </div>
                                    <img src={homeLogo.logoImg} alt="empty" style={{width: 200}} /> <br/>
                                    <Typography variant="caption" gutterBottom>{t('configuration.cntCustomization.homeLogo.tagDimentions')}</Typography>
                                    <Grid container className={classes.marginTopCnt}>
                                      <input
                                        accept="image/x-png,image/jpeg"
                                        className={classes.hidden}
                                        type="file"
                                        id="contained-button-file3"
                                        onChange={e => selectPicture(t, 'homeLogo', homeLogo, setHomeLogo, e)}
                                      />
                                      <label htmlFor="contained-button-file3">
                                        <Button variant="contained" size="small" component="span">
                                          {t('configuration.cntAdminSystem.logo.btnSelectTitle')}
                                        </Button>
                                        <TextField value={homeLogo.logoFile.name} style={{marginLeft: 10}} placeholder={t('configuration.cntAdminSystem.logo.textInput')} disabled />
                                      </label>
                                    </Grid>
                                    <Grid container className={classes.marginTopCnt}>
                                      <Button variant="contained" color="primary" onClick={sendHomeLogo} disabled={disableButtons.homeLogo}>
                                         {t('configuration.cntAdminSystem.logo.btnUpload')}
                                      </Button>
                                      <Tooltip title={t('configuration.cntAdminSystem.logo.btnDeleteTitle')} classes={{ tooltip: classes.lightTooltip }}>
                                         <Button variant="contained" style={{marginLeft: 10, marginRight:10}}
                                      onClick={e => showAlert(delHomeLogo, e)}><span className="fa fa-trash"></span></Button>
                                      </Tooltip>
                                      { loadingPicture.homeLogo ? <CircularProgress className={classes.progress} style={{width: 29, height: 29}} /> : ''}
                                    </Grid>
                                  </div>

                                  <div className={classes.paddingConfig}>
                                    <div style={{paddingBottom: 10}}>
                                      <label className={classNames(classes.controlSubtitle, classes.paRight)}>{t('configuration.cntCustomization.emailLogo.title')}</label>
                                      <Tooltip title={t('configuration.cntCustomization.tooltips.emailLogo')} classes={{ tooltip: classes.lightTooltip }}>  
                                        <span className="fa fa-info-circle" style={{fontSize: 15, color: '#2196f3'}}></span>
                                      </Tooltip>
                                    </div>
                                    <img src={emailLogo.logoImg} alt="empty" style={{width: 200}} /> <br/>
                                    <Typography variant="caption" gutterBottom>{t('configuration.cntCustomization.emailLogo.tagDimentions')}</Typography>
                                    <Grid container className={classes.marginTopCnt}>
                                      <input
                                        accept="image/x-png,image/jpeg"
                                        className={classes.hidden}
                                        type="file"
                                        id="contained-button-file2"
                                        onChange={e => selectPicture(t, 'emailLogo', emailLogo, setEmailLogo, e)}
                                      />
                                      <label htmlFor="contained-button-file2">
                                        <Button variant="contained" size="small" component="span">
                                          {t('configuration.cntAdminSystem.logo.btnSelectTitle')}
                                        </Button>
                                        <TextField value={emailLogo.logoFile.name} style={{marginLeft: 10}} placeholder={t('configuration.cntAdminSystem.logo.textInput')} disabled />
                                      </label>
                                    </Grid>
                                    <Grid container className={classes.marginTopCnt}>
                                      <Button variant="contained" color="primary" onClick={sendEmailLogo} disabled={disableButtons.emailLogo}>
                                         {t('configuration.cntAdminSystem.logo.btnUpload')}
                                      </Button>
                                      <Tooltip title={t('configuration.cntAdminSystem.logo.btnDeleteTitle')} classes={{ tooltip: classes.lightTooltip }}>
                                          <Button variant="contained" style={{marginLeft: 10, marginRight:10}}
                                           onClick={e => showAlert(delEmailLogo, e)}><span className="fa fa-trash"></span>
                                          </Button>
                                      </Tooltip>
                                      { loadingPicture.emailLogo ? <CircularProgress className={classes.progress} style={{width: 29, height: 29}} /> : ''}
                                    </Grid>
                                  </div>

                                </Grid>
                              </Grid>

                            </Grid>
                          </Grid>
                        </CardContent>} 

                        {currentTab === 'four' &&
                        <CardContent>
                          <Grid container>    

                            <Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>

                              <span className={classes.cntTitle}>
                                {t('configuration.cntBulkDelete.title')}&nbsp;&nbsp;
                                <Tooltip title={t('configuration.cntBulkDelete.tooltip')} classes={{ tooltip: classes.lightTooltip }}>  
                                  <span className="fa fa-info-circle" style={{fontSize: 15, color: '#2196f3'}}></span>
                                </Tooltip>
                              </span>

                              <Grid container>
                               {typeof userProfile.getPermissions()['settings_utilities_bulk_delete'] !== 'undefined' &&
                                <Grid item style={{marginTop: 40, paddingBottom: 20, width: '100%'}}>
                                  <div style={{paddingLeft: 15}}>
                                    
                                    <div>
                                      <label className={classes.gestionSubtitle}>{t('configuration.cntBulkDelete.tutorsSection.title')}</label>
                                      <hr style={{height: 1, backgroundColor: 'rgba(0, 0, 0, 0.12)', border: 'none'}}/>
                                    </div>

                                    <div style={{marginTop: 15}}>
                                      <Button variant="contained" color="default" onClick={showModal}>
                                      {t('configuration.cntBulkDelete.tutorsSection.btnSubjectsAssigned')}
                                      </Button>
                                    </div>

                                  </div>  
                                </Grid>}

                              </Grid> 
                            
                            </Grid>  

                          </Grid>

                        </CardContent>}   

                        {currentTab === 'five' &&
                        <CardContent>

                          <Grid container className="ctnAuthTitle">
                            <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                              <Typography variant="h5">{t('configuration.cntAuthentication.title')}</Typography>
                            </Grid>
                          </Grid>
                          
                          <Grid container className="cntAuthentication">    
                          
                            <Grid item className="authCntSeparate" xs={12} sm={12} md={12}>
                                
                                <div className={classNames("selector",markedAuthType.email)}></div>
                                <div className="subContainer">
                                  <h4>{t('configuration.cntAuthentication.authType.linc.title')}</h4>

                                  <div className="cntRadioButton">
                                    <p>
                                      {t('configuration.cntAuthentication.authType.linc.radioBtnDescription')}
                                    </p>
                                    <Radio
                                      checked={authType === 'email'}
                                      onChange={handleChangeAuthType}
                                      value="email"
                                      name="radio-email"
                                      className="radio"
                                    />
                                  </div>

                                  <Typography variant="subtitle1">{t('configuration.cntAuthentication.authType.linc.allowedDomains.title')}</Typography>
                                  
                                  <div className="cntDomains">
                                    <div className="subCntDomains">
                                      <TextField
                                          fullWidth={false}
                                          id="domains"
                                          name="domains"
                                          label={''}
                                          multiline
                                          minRows="2"
                                          value={domains}
                                          className={classNames(classes.textArea, 'subCntTexfield')}
                                          onChange={updateDomains}
                                          disabled={disableAuthType.email}
                                      />
                                      <div>
                                        <label>
                                        {t('configuration.cntAuthentication.authType.linc.allowedDomains.info')}
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="cntButton">
                                    <Button 
                                          variant="contained" 
                                          color="primary" 
                                          className="btn" 
                                          disabled={disableButtons.access} 
                                          onClick={e => validateControlData(t, e)}
                                    >
                                      {t('configuration.cntAuthentication.authType.linc.btnSave')}
                                    </Button>
                                  </div>

                                </div>  

                            </Grid>

                          </Grid>

                          {/*<Grid container className="cntAuthentication">    

                            <Grid item className="authCntSeparate" xs={12} sm={12} md={12}>

                                <div className={classNames("selector",markedAuthType.sso)}></div>
                                <div className="subContainer">
                                  <h4>{t('configuration.cntAuthentication.authType.sso.title')}</h4>

                                  <div className="cntRadioButton">
                                    <p>
                                    {t('configuration.cntAuthentication.authType.sso.radioBtnDescription')}
                                    </p>
                                    <Radio
                                      checked={authType === 'sso'}
                                      onChange={handleChangeAuthType}
                                      value="sso"
                                      name="radio-sso"
                                      className="radio"
                                    />
                                  </div>

                                  <h4 className="bh4">{t('configuration.cntAuthentication.authType.sso.subtitle')}</h4>
                                  <Typography variant="subtitle1" className="guiaCaption">
                                    <i className='fa fa-question-circle'></i> <a href="#">{t('configuration.cntAuthentication.authType.sso.guiaLink')}</a>
                                  </Typography>

                                  <Typography variant="subtitle1" className="inputTitle">{t('configuration.cntAuthentication.authType.sso.fields.emailId.label')}</Typography>
                                  
                                  <div className="cntDomains">
                                    <div className="subCntDomains">
                                      <TextField
                                          fullWidth={false}
                                          id="emailIdAttr"
                                          name="emailIdAttr"
                                          label={''}
                                          value={ssoData.emailIdAttr}
                                          className={classNames(classes.textArea, 'subCntTexfield')}
                                          onChange={updateSSOField}
                                          error={ssoErrors.emailIdAttr}
                                          disabled={disableAuthType.sso}
                                      />
                                      <div>
                                        <label>
                                        {t('configuration.cntAuthentication.authType.sso.fields.emailId.info')} 
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <Typography variant="subtitle1" className="inputTitle">{t('configuration.cntAuthentication.authType.sso.fields.issuer')}</Typography>
                                  
                                  <div className="cntDomains">
                                    <div className="subCntDomains">
                                      <TextField
                                          fullWidth={false}
                                          id="issuer"
                                          name="issuer"
                                          label={''}
                                          value={ssoData.issuer}
                                          className={classNames(classes.textArea, 'subCntTexfield')}
                                          onChange={updateSSOField}
                                          error={ssoErrors.issuer}
                                          disabled={disableAuthType.sso}
                                      />
                                    </div>
                                  </div>

                                  <Typography variant="subtitle1" className="inputTitle">{t('configuration.cntAuthentication.authType.sso.fields.loginURL')}</Typography>
                                  
                                  <div className="cntDomains">
                                    <div className="subCntDomains">
                                      <TextField
                                          fullWidth={false}
                                          id="entryPoint"
                                          name="entryPoint"
                                          label={''}
                                          multiline
                                          minRows="2"
                                          value={ssoData.entryPoint}
                                          className={classNames(classes.textArea, 'subCntTexfield')}
                                          onChange={updateSSOField}
                                          error={ssoErrors.entryPoint}
                                          disabled={disableAuthType.sso}
                                      />
                                    </div>
                                  </div>

                                  <Typography variant="subtitle1" className="inputTitle">{t('configuration.cntAuthentication.authType.sso.fields.logoutURL')}</Typography>
                                  
                                  <div className="cntDomains">
                                    <div className="subCntDomains">
                                      <TextField
                                          fullWidth={false}
                                          id="logoutUrl"
                                          name="logoutUrl"
                                          label={''}
                                          multiline
                                          minRows="2"
                                          value={ssoData.logoutUrl}
                                          className={classNames(classes.textArea, 'subCntTexfield')}
                                          onChange={updateSSOField}
                                          error={ssoErrors.logoutUrl}
                                          disabled={disableAuthType.sso}
                                      />
                                    </div>
                                  </div>

                                  <Typography variant="subtitle1" className="inputTitle">{t('configuration.cntAuthentication.authType.sso.fields.certificate')}</Typography>
                                  
                                  <div className="cntDomains">
                                    <div className="subCntDomains">
                                      <TextField
                                          fullWidth={false}
                                          id="cert"
                                          name="cert"
                                          label={''}
                                          multiline
                                          minRows="10"
                                          value={ssoData.cert}
                                          className={classNames(classes.textArea, 'subCntTexfield')}
                                          onChange={updateSSOField}
                                          error={ssoErrors.cert}
                                          disabled={disableAuthType.sso}
                                      />
                                    </div>
                                  </div>

                                  <div className="cntButton">
                                    <Button variant="contained" color="primary" 
                                            disabled={disableButtons.sso} 
                                            className="btn" 
                                            onClick={validateSSOData} 
                                    >
                                      {t('configuration.cntAuthentication.authType.sso.btnSave')}
                                    </Button>
                                  </div>

                                </div>  

                            </Grid>

                                      </Grid>*/}

                        </CardContent>}   

                      </Card>
                    </Grid>

                  </Grid>
                  <AlertMessage open={alertMsg.open} variant={alertMsg.className} message={alertMsg.message} eventClose={closeMessage} />
                  <AlertDelete open={openAlert} eventClose={closeAlert} eventOk={alertRemove.funct} content={''} title={t('configuration.cntAdminSystem.alerts.onDelete.msg')} />
                  <GenericModal 
                    open={openModal} 
                    title={t('configuration.cntBulkDelete.modalSubjectsAssigned.title')} 
                    content={<RemoveAssignment classes={classes} eventClose={closeModal} />} 
                    size="sm" 
                    eventClose={closeModal}
                    contentCustomPadding={'8px 24px 0 24px'} 
                  />
                </React.Fragment>
               )
            }
          }
      </Translation>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Configuration);