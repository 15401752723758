import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';

// Libs
import ReeValidate from 'ree-validate';

// handle of languages
import { Translation } from 'react-i18next';

import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";

// Components
import CardHeader from "../../components/Card/CardHeader";
import CustomInput from "../../components/Inputs/CustomInput";
import AlertMessage from "../../components/Modal/AlertMessage";
import AlertDelete from "../../components/Modal/AlertDelete";
import IOSSwitch from '../../components/Checked/IOSSwitch';

// Material ui v5
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

// Api
import StudentsApi from "../../api/Users/StudentsApi";

// Models
import UserProfile from "../../models/UserProfile";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

const Api = new StudentsApi();
const userProfile = new UserProfile();

var moment = require('moment-timezone');

let titleKey = 'titleAdd';
let titleIcon;
let alertMessage = '';
let alertClassName = 'success';
let allFormData = {};
let cStatus = ''; 

class Student extends Component {

  constructor(){
	  
	super();

	this.validator = new ReeValidate.Validator({
      name: 'required',
      lastName: 'required',
      email: 'required',
      password: 'required|min:6'
    });

    // this.validator.localize('es', es);

	this.state = {
	 	formData: {
	      id: 0,
	  	  name: '',
	      lastName: '',
	      email: '',
	      phone: '',
	      status: true,
	      gender: 'F',
	      birthday: null, 
	      careerId: '',
	      periodId: '',
	      confirmed: true,
	      isTutor: false,
	      password: ''
	    },
	    careers: [],
		periods: [],
	    domains: [{id:1, name: ''}],
	    disabledButton: false,
	    errors: this.validator.errors,
		currentTab: 'one',
		openAlert: false,
	    openAlertMessage: false,
	    domain: ''
    };

  }

  formatDate = (date) => { // Format to api
	date = new Date(date);
	date = date.getFullYear()+"-"+((date.getMonth() + 1) < 10?"0"+(date.getMonth() + 1):(date.getMonth() + 1))+"-"+(date.getDate() < 10?"0"+date.getDate():date.getDate());
	return date;
  }

  getRealDate = (date) =>{ // Get real date
	date = new Date(date);
	date = date.setDate(date.getDate() + 1);
	return date;
  }

  getOneRow = (id) => {
      
	  Api.getById(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   id).then(response => {
            
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
           
	       if (responseData.modal) {
	           this.props.history.push("/users/students");
	       } else {
			   cStatus = responseData.data.row.status;
	       	   this.setState({
				formData:{...responseData.data.row, birthday: responseData.data.row.birthday!==''?this.getRealDate(responseData.data.row.birthday):null}, 
	       	   	careers:responseData.data.careers,
				periods:responseData.data.periods,
	       	   	domains:responseData.data.domains,
	       	   	domain:responseData.data.row.email.split("@")[1]
	       	   });

	       }

      }).catch((err) => {Api.closeSession(err)});
    
  };

  getCareers = () => {
      
	  Api.getCareers(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId).then(response => {

	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
	       	
	       this.setState({
	       	   careers:responseData.data.rows, 
			   periods:responseData.data.periods,
	       	   domains:responseData.data.domains,
	       	   domain:responseData.data.domains[0].name
	       });

      }).catch((err) => {Api.closeSession(err)});
    
  };

  save = (data) => {
      
	  Api.save(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   data).then(response => {
           
           this.handleLoading();
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

	       if (responseData.modal) {
	       	   userProfile.addAlert({msg: responseData.message, class: responseData.data.className});
	           this.props.history.push("/users/students");
	       } else {
			    if(data.birthday===''){
					this.setState({formData:{...data, birthday: null}});
				}
	       	   this.showAlert(responseData);
	       }

      }).catch((err) => {this.handleLoading();Api.closeSession(err)});
    
  };

  update = (data) => {
      
	  Api.update(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   data).then(response => {
           
           this.handleLoading();
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

	       if (responseData.modal) {

			if(data.birthday===''){
				this.setState({formData:{...data, birthday: null}});
			}

			if (responseData.data.className === 'warning') {
				this.showAlert(responseData);
			} else {
	       	   
	       	   userProfile.addAlert({msg: responseData.message, class: responseData.data.className});
	       	   
	       	   if (!data.isTutor) {
                  this.props.history.push("/users/students");
				} else { this.props.history.push("/users/tutors"); }
			
			}
	           
	       } else {
				if(data.birthday===''){
					this.setState({formData:{...data, birthday: null}});
				}
	       	   this.showAlert(responseData);
	       }

      }).catch((err) => {this.handleLoading();Api.closeSession(err)});
    
  };

  submit = (formData, tr) => {

	if (formData.birthday !== null && formData.birthday !== '' && !moment(formData.birthday).isValid()) {

		this.showAlert({message: tr('users.form.tutor.alerts.birthdayIncorrect'), data: {className: 'warning'}});
	
	} else {
    
		this.handleLoading();
		
		// change here
		formData.email = formData.email+'@'+this.state.domain;

		if (formData.careerId === "") formData.careerId = 0;
		if (formData.periodId === "") formData.periodId = 0;

		if (this.state.formData.id > 0) {
			formData.id = this.state.formData.id;
			if (!formData.status && cStatus !== formData.status) {
				allFormData = formData;
				this.setState({ openAlert: true });
			} else {
				formData.birthday = formData.birthday !== null?this.formatDate(formData.birthday):'';
				this.update(formData);
			}
		} else {
			formData.birthday = formData.birthday !== null?this.formatDate(formData.birthday):'';
			this.save(formData);
		}

    }
   
  };

  validateAndSubmit = (e, tr) => {

		e.preventDefault()

		const { formData } = this.state;
		const { errors } = this.validator;

		this.validator.validateAll(formData)
			.then(success => {
			if (success) {
				this.submit(formData, tr)
			} else {
				this.setState({ errors })
			}
		})
    
  }

  handleChange = e => {

		const name = e.target.name;
	    let value = e.target.value;
	    const { errors } = this.validator;
	    
	    errors.remove(name);

	    if (name === 'email') {
	       value = value.split(' ').join('');
	    }

	    this.setState({ formData: { ...this.state.formData, [name]: value } });
   
  };

  birthdayChangeHandle = (newValue) => {
	this.setState({ formData: { ...this.state.formData, birthday: newValue } });
  };

  changeDomain = e => {

	const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value });
   
  };

  showAlert = (data) => {

  	alertMessage = data.message;
    alertClassName = data.data.className; 
    this.setState({ openAlertMessage: true });

  };

  closeAlert = () => {
	this.setState({ openAlert: false });
	this.handleLoading();
  };

  handleLoading = () => {
    this.setState({ disabledButton: !this.state.disabledButton });
  };

  activeAsTutor = name => event => {
    this.setState({ formData: { ...this.state.formData, [name]: event.target.checked } });
  };

  activeAccount = name => event => {
    this.setState({ formData: { ...this.state.formData, [name]: event.target.checked } });
  };

  closeMessage = (event, reason) => {
	this.setState({ openAlertMessage: false });
  };

  changeTab = (event, currentTab) => {
    this.setState({ currentTab });
  };

  deleteRow = () => {
	this.setState({ openAlert: false });
	allFormData.email = allFormData.email+'@'+this.state.domain;
	if (allFormData.careerId === "") allFormData.careerId = 0;
	if (allFormData.periodId === "") allFormData.periodId = 0;
	allFormData.birthday = allFormData.birthday !== null?this.formatDate(allFormData.birthday):'';
	this.update(allFormData);
  };

  componentWillMount(){
  	
  	const id = this.props.match.params.id;

  	if (id !== 'add') {
  		
  		titleKey = 'titleModify';
  		titleIcon = 'fa fa-pen';
	  	
	  	if (isNaN(id)) {
			this.props.history.push("/users/students");
		} else {
			this.validator = new ReeValidate.Validator({
				name: 'required',
				lastName: 'required',
				email: 'required'
			});
			this.getOneRow(id);
		}

    } else {
    	titleKey = 'titleAdd';
    	titleIcon = 'fa fa-plus';
    	this.getCareers();
    }

  }

  render() {

  	const { classes, match } = this.props;
  	let {formData, careers, periods, errors, currentTab, openAlertMessage, domains, domain, openAlert} = this.state;
	
  	if (formData.careerId === 0) formData.careerId = "";
	if (formData.periodId === 0) formData.periodId = ""; 
  	if (formData.email !== "") {
  		let tempData = formData.email.split("@");
  		formData.email = tempData[0];
  		domain = (typeof domain !== 'undefined' && domain !== '')?domain:tempData[1];
    }
    
    return (
      
      <div>
       <Translation>
	     {
	      (t, { i18n }) => {
	      	 return (
	      	   <React.Fragment>	
			       <form onSubmit={ e => this.validateAndSubmit(e, t)}>
			        <Grid container className={classes.container}>
			            <Grid item className={classes.grid} xs={12} sm={12} md={3}>
				       
					        <Grid item className={classNames(classes.gridHeader, classes.gridHeaderPadding)} xs={12} sm={12} md={12}>

				              <Button color="default" component={Link} to="/users/students" className={classes.btnBack}>
				                <span className={classNames(classes.iconBack, "fa fa-arrow-left")}></span> 
				                {t('categories.form.back')}
				              </Button>
					       
					        </Grid>
					  
					    </Grid>  
				        <Grid item className={classes.grid} xs={12} sm={12} md={12}>
					      <Card className={classes.cardContet}>
				            <CardHeader className={classes.cardHeader}>
				              <h4 className={classes.cardTitleWhite}>
				                 <span className={classNames(classes.iconBack, titleIcon)}></span> 
				                 {t('users.form.student.title.'+titleKey)}
				              </h4>
				              <p className={classes.cardCategoryWhite}>{t('categories.form.subtitle')}</p>
				            </CardHeader>
				            <AppBar position="static" style={{backgroundColor: "#f5f5f5", color: "#333"}}>
					          <Tabs value={currentTab} onChange={this.changeTab} indicatorColor="primary" textColor="primary">
					            <Tab value="one" label={t('users.form.tabs.general.title')} />
					            {/*<Tab value="two" label="Cambiar contraseña" />*/}
					          </Tabs>
						    </AppBar>
						    {currentTab === 'one' &&
							
				            <CardContent>

                             <Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>
                                <span className={classes.cntTitle}>{t('users.form.tabs.general.divisions.basicInformation.title')}</span>	
						      
								<Grid container>
									<Grid item className={classes.grid} xs={12} sm={12} md={6}>
									<CustomInput
										labelText={t('users.form.fields.name')}
										id="name"
										formControlProps={{
										fullWidth: true
										}}
										inputProps={{
										name:"name",
										value:formData.name,
										onChange:(this.handleChange),
										error:errors.has('name')
										}}
										customInputProps={{maxLength: "50"}}
										
									/>
									{ errors.has('name') &&
										<label className={classes.error} htmlFor="name"></label>
									}
									</Grid>
									<Grid item className={classes.grid} xs={12} sm={12} md={6}>
									<CustomInput
										labelText={t('users.form.fields.lastName')}
										id="lastName"
										formControlProps={{
										fullWidth: true
										}}
										inputProps={{
										name:"lastName",
										value:formData.lastName,
										onChange:(this.handleChange),
										error:errors.has('lastName')
										}}
										customInputProps={{maxLength: "50"}}
										
									/>
									{ errors.has('lastName') &&
										<label className={classes.error} htmlFor="lastName"></label>
									}
									</Grid>
								</Grid>

								<Grid container>

									<Grid item className={classes.grid} xs={12} sm={12} md={6}>
										<Grid container>
											<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{paddingLeft: '0px !important'}}> 
												<FormControl fullWidth={true} className={classes.formControl}>
													<InputLabel htmlFor="email">{t('users.form.fields.email')}</InputLabel>
													<Input
														disabled={match.params.id === "add"?false:true}
														id="email"
														name="email"
														value={formData.email}
														endAdornment={
														<InputAdornment position="end">@</InputAdornment>
														}
														onChange= {this.handleChange}
														error={errors.has('email')}
													/>
												</FormControl>
											</Grid>  
											<Grid item className={classes.grid} xs={12} sm={12} md={6}>
											<FormControl fullWidth={true} className={classes.formControl} style={{marginTop: 42}}>
												<Select
													disabled={match.params.id === "add"?false:true}
													value={domain}
													onChange={this.changeDomain}
													inputProps={{
													name: 'domain',
													id: 'domain'
													}}
												>
													{domains.map((i, k) => {
														return (
															<MenuItem value={i.name} key={k}>{i.name}</MenuItem>
															)
													})}
												</Select>
											</FormControl>
											</Grid>
											{errors.has('email') &&
												<label className={classes.error} htmlFor="email"></label>
											}
										</Grid>
									</Grid>

									<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{position:'relative'}}>
		
										<FormControl fullWidth={true} className={classes.formControl}>
											<InputLabel htmlFor="password">{t('users.form.fields.password')}</InputLabel>
											<Input
												id="password"
												name="password"
												value={formData.password}
												type="password"
												onChange= {this.handleChange}
												error={errors.has('password')}
												inputProps={{
													minLength:"6",
													maxLength:"50"
												}}
											/>
										</FormControl>
										<div style={{display:'flex', position:'absolute'}}>
											<div>
												<span className={classNames(classes.spanIconInfo, "fa fa-info-circle")}></span>
											</div>
											<div style={{paddingLeft: 6}}>
												<Typography variant="caption" style={{color: '#9B9B9B'}} >{t('users.form.tutor.alerts.infoPassword')}</Typography>
											</div>	
										</div>
										{ errors.has('password') &&
											<label className={classes.error} htmlFor="password"></label>
										}
										
									</Grid>

								</Grid>

								<Grid container>
									<Grid item className={classes.grid} xs={12} sm={12} md={6}>
										<FormControl fullWidth={true} className={classes.formControl}>
										<InputLabel htmlFor="gender">{t('users.form.fields.gender')}</InputLabel>
										<Select
											value={formData.gender}
											onChange={this.handleChange}
											inputProps={{
												name: 'gender',
												id: 'gender'
											}}
										>
											<MenuItem value="F">{t('selectGender.female')}</MenuItem>
											<MenuItem value="M">{t('selectGender.male')}</MenuItem>
											<MenuItem value="O">{t('selectGender.other')}</MenuItem>
										</Select>
										</FormControl>
									</Grid>
								</Grid>
								
							  </Grid>

							  <Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>
                                <span className={classes.cntTitle}>{t('users.form.tabs.general.divisions.advancedInformation.title')}</span>	
							    <Grid container>

									<Grid item className={classes.grid} xs={12} sm={12} md={6}>
										<FormControl fullWidth={true} className={classes.formControl}>
										<InputLabel htmlFor="careerId">{t('users.form.fields.career.name')}</InputLabel>
										<Select
											value={formData.careerId}
											onChange={this.handleChange}
											inputProps={{
											name: 'careerId',
											id: 'careerId'
											}}
										>
											<MenuItem value="">
											<em>{t('users.form.fields.career.defaultValue')}</em>
											</MenuItem>
											{careers.map((i, k) => {
												return (
													<MenuItem value={i.id} key={k}>{i.name}</MenuItem>
													)
											})}
										</Select>
										</FormControl>
									</Grid>

									<Grid item className={classes.grid} xs={12} sm={12} md={6}>
										<FormControl fullWidth={true} className={classes.formControl}>
										<InputLabel htmlFor="periodId">{t('users.form.fields.period.name')}</InputLabel>
										<Select
											value={formData.periodId}
											onChange={this.handleChange}
												inputProps={{
												name: 'periodId',
												id: 'periodId'
											}}
										>
											<MenuItem value="">
											  <em>{t('users.form.fields.period.defaultValue')}</em>
											</MenuItem>
											{periods.map((i, k) => {
												return (
													<MenuItem value={i.id} key={k}>{i.period}</MenuItem>
													)
											})}
										</Select>
										</FormControl>
									</Grid>

								</Grid>
								<Grid container>
									<Grid item className={classes.grid} xs={12} sm={12} md={6}>
										<CustomInput
											labelText={t('users.form.fields.phone')}
											id="phone"
											formControlProps={{
											fullWidth: true
											}}
											inputProps={{
												name:"phone",
												value:formData.phone,
												onChange:(this.handleChange)
											}}
											customInputProps={{maxLength: "15"}}
										/>
									</Grid>
									<Grid item className={classNames(classes.grid, 'birthdayDatePicker')} xs={12} sm={12} md={6}>
										{/*<TextField
											fullWidth={true}
											id="birthday"
											name="birthday"
											label={t('users.form.fields.birthday')}
											type="date"
											value={formData.birthday}
											className={classes.textField}
											onChange={this.handleChange}
											style={{marginTop: "27px"}}
												InputLabelProps={{
												shrink: true,
											}}
										/>*/}
										<LocalizationProvider dateAdapter={AdapterDateFns} locale={ i18n.language==='en'?enLocale:esLocale }>
											<DatePicker
												label={t('users.form.fields.birthday')}
												id="birthday"
												name="birthday"
												inputFormat={t('dateFormat')}
												value={formData.birthday}
												onChange={this.birthdayChangeHandle}
												renderInput={(params) => <TextField {...params} />}
											/>
										</LocalizationProvider>
									</Grid>
								</Grid>

							  </Grid>

							  <Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>
                                <span className={classes.cntTitle}>{t('users.form.tabs.general.divisions.settings.title')}</span>

								<Grid container className={classes.studentCheckboxContainerFirts}>

								   <Grid item className={classes.grid} xs={12} sm={12} md={5}>
										<div>
											<label className={classes.studentCheckboxContainerItemLabel} >{t('users.form.fields.status')}</label>
											<Tooltip title={t('users.form.tabs.general.divisions.settings.tooltips.accountStatus')} classes={{ tooltip: classes.lightTooltip }}>  
												<span className={classNames(classes.studentCheckboxContainerItemTooltipSpan, "fa fa-info-circle")}></span>
											</Tooltip>
										</div>
									</Grid>	

									<Grid item className={classes.grid} xs={12} sm={12} md={7}>
										<IOSSwitch 
											checked={formData.status}
											onChange={this.activeAccount("status")}
											value="status"
											name="status" 
										/>
									</Grid>

								</Grid>

								<Grid container className={classes.studentCheckboxContainer}>

								   <Grid item className={classes.grid} xs={12} sm={12} md={5}>
										<div>
											<label className={classes.studentCheckboxContainerItemLabel}>{t('users.form.fields.confirmedEmail')}</label>
											<Tooltip title={t('users.form.tabs.general.divisions.settings.tooltips.confirmedEmail')} classes={{ tooltip: classes.lightTooltip }}>  
												<span className={classNames(classes.studentCheckboxContainerItemTooltipSpan, "fa fa-info-circle")}></span>
											</Tooltip>
										</div>
									</Grid>	

									<Grid item className={classes.grid} xs={12} sm={12} md={7}>
										<IOSSwitch 
											checked={formData.confirmed}
											onChange={this.activeAccount("confirmed")}
											value="confirmed"
											name="confirmed" 
										/>
									</Grid>

								</Grid>

								{match.params.id !== "add" && <Grid container className={classes.studentCheckboxContainer}>

								   <Grid item className={classes.grid} xs={12} sm={12} md={5}>
										<div>
											<label className={classes.studentCheckboxContainerItemLabel} >{t('users.form.fields.asTutor')}</label>
											<Tooltip title={t('users.form.tabs.general.divisions.settings.tooltips.tutorPermissions')} classes={{ tooltip: classes.lightTooltip }}>  
											  <span className={classNames(classes.studentCheckboxContainerItemTooltipSpan, "fa fa-info-circle")}></span>
											</Tooltip>
										</div>
									</Grid>	

									<Grid item className={classes.grid} xs={12} sm={12} md={7}>
										<IOSSwitch 
											checked={formData.isTutor}
											onChange={this.activeAsTutor("isTutor")}
											value="isTutor" 
											name="isTutor"
										/>
									</Grid>
								
								</Grid>}	

							  </Grid>

							</CardContent>}
				            {currentTab === 'two' &&
				            <CardContent>
				            En desarrollo..
				            </CardContent>}
				            <CardActions>
				              <Button variant="contained" color="primary" className={classes.buttonBottom} disabled={this.state.disabledButton} type="submit">
				                 {t('categories.form.btnSave')}
				              </Button>
				            </CardActions>
				          </Card>
				        </Grid>
			       </Grid>  
			       </form>  
				   <AlertDelete open={openAlert} eventClose={this.closeAlert} eventOk={this.deleteRow} title={t('users.form.student.modalInactive.title')} content={t('users.form.student.modalInactive.content')} />
			       <AlertMessage open={openAlertMessage} variant={alertClassName} message={alertMessage} eventClose={this.closeMessage} />
		       </React.Fragment>
             )
          }
        }
        </Translation>
      </div>

    );

  }

}

export default withStyles(styles, { withTheme: true })(Student);
