import { createTheme } from '@mui/material/styles';
import UserProfile from "./models/UserProfile";

const defaultTheme = createTheme();

const userProfile = new UserProfile();

const cUser = userProfile.getUser();

const stylesBack = { color: '#2188FC' };

if ((cUser !== null) && (typeof cUser.universities !== 'undefined') && (typeof cUser.universities.backendstyles !== 'undefined')
        && (typeof cUser.universities.backendstyles[0] !== 'undefined')) {
        stylesBack.color = cUser.universities.backendstyles[0].headerColor;
}

export default  createTheme({
    spacing: 5,
    palette: {
        text: {
            primary: '#121212',
            secondary: '#B0B0B0',
            white: '#fff'
        },
        primary: {
            light: '#00c0ff',
            main: '#2188FC',
            dark: '#036ee7',
        },
        secondary:{
            light: '#00c0ff',
            main: stylesBack.color,
            dark: '#036ee7'
        } 
    },
    typography: {
        fontFamily: 'Helvetica Neue'
    },
    overrides: {
        MuiFormControlLabel: {
            root:{
                color: '#121212',
                fontWeight: 300
            }
        },
        MuiToolbar: {
            regular: {
                [defaultTheme.breakpoints.up('xs')]: {
                    height: '60px',
                    minHeight: '60px'
                }
            }
        },
        MuiDrawer: {
            paper: {
                overflowY: 'initial'
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: "initial",
                border: "1px solid rgb(232,232,232)",
            }
        },
        MuiButton: {
            root: {
                boxShadow: "initial !important",
                textTransform: 'initial',
                paddingLeft: '20px',
                paddingRight: '20px',
            }
        },
        MuiTypography: {
            root: {
                position: 'relative'
            },
            body1: {
                fontWeight: 300
            }
        },
        MuiTab:{
            root: {
                textTransform: 'capitalize',
                fontWeight: 400,
                fontSize: 16,
                [defaultTheme.breakpoints.up('md')] : {
                    fontSize: 16,
                    minWidth: 100
                }
            },
            textColorPrimary: { 
                "&$selected": { color: "#121212", }
            },
            wrapper: {
                fontWeight: 300
            }
        },
        MuiAppBar: {
            colorDefault: {
                position: 'relative',
                backgroundColor: 'transparent',
                boxShadow: 'initial',
                '&::before' : {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    height: '2px',
                    background: '#F1F1F1'
                }
            }
        },
        MuiListItem: {
            gutters: {
                paddingRight: '30px',
                paddingLeft: '15px'
            },
            button: {
                '&.btn-menu': {
                    '&::after': {
                        backgroundColor: stylesBack.color
                    },
                    '&:hover,&.btn-active': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)'
                    }
                }
            }
        },
        MuiInput: {
            underline: {
                '&::before' : {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
                }
            },
            root: {
                maxHeight: '32px',
                fontWeight: 300,
                '&.MuiInputBase-multiline' : {
                    maxHeight: 'initial'
                }
            }
        },
        MuiSelect: {
            select: {
                "&:focus": {
                    background: "red"
                }
            }
        },
        MuiInputBase: {
            input: {
                height: 'initial'
            }
        },
        MuiLink: {
            underlineHover: {
                color: '#121212',
                '&:hover': {
                    color: '#2188FC'
                },
                '&:focus': {
                    color: '#121212'
                }
            }
        },
        MuiMenuItem: {
            root:{
                '&.disabled': {
                    pointerEvents: 'none',
                    opacity: 0.3
                }
            }
        },
        MuiTableCell:{
            root:{
                padding: '12px 40px 12px 16px',
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
            },
            head: {
                fontSize: '0.75rem'
            }
        },
        MuiAccordionActions:{
            root:{
                justifyContent: 'flex-start'
            }
        }
    }
    /*breakpoints: {
        values: {
            xs: 0,
            sm: 400,
            md: 768,
            lg: 996,
            xl: 1300
        }
    }*/
});