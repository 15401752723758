import React, { useState } from "react";
// Router
import { withRouter } from 'react-router-dom';
// Material UI
import { Grid, Box, Button } from '@material-ui/core';
import Modal from '../components/Modal-Status';
import { connect } from 'react-redux';
import { getSectionTitle } from '../../../redux/actions/global';
// handle of languages
import { Translation } from 'react-i18next';


const Formats = ({ history, getSectionTitle }) => {

    const [items] = useState([
        {
            title : 'promotion.sections.linc.items.item1.title',
            descrip : 'promotion.sections.linc.items.item1.descrip',
            img : require('../../../assets/img/promotion/grid.png'),
            file: 'promotion.sections.linc.items.item1.file'
        },
        {
            title : 'promotion.sections.linc.items.item2.title',
            descrip : 'promotion.sections.linc.items.item2.descrip',
            img : require('../../../assets/img/promotion/logo.png'),
            file: 'promotion.sections.linc.items.item2.file'
        },
        {
            title : 'promotion.sections.linc.items.item3.title',
            descrip : 'promotion.sections.linc.items.item3.descrip',
            img : require('../../../assets/img/promotion/decorativo.png'),
            file: 'promotion.sections.linc.items.item3.file'
        }
    ]);

    

    const [currentItem, setCurrentItem] = useState('');

    const [statusModal, setStatusModal] = useState(false);

    const handleModal = (status,title='') => {
        setCurrentItem(title);
        setStatusModal(status);
    }

    const handleTitle = (title) => {
        getSectionTitle({ title : title, icon : 'icon-mic' });
        return title;
    }

	const maxW = 370;

    return <Translation>
        {
    	    (t, { i18n }) => <Grid id="promotion" container>

                <Box p={6} width={1}>
                    <Box mb={8}  ml={-3} mr={-3} display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
                        <Box flexGrow={1} width={1} maxWidth={maxW*3} pl={3} pr={3}>
                            <Box className="btn-back" fontSize={{ xs : 17, sm : 20 }} color="text.main" mb={6} component="button" onClick={() => history.push('/promotion')}>
                                <Box className="icon-arrow-left" mr={4} fontSize={{ xs : 18, sm : 20 }} color="text.main" component="i"></Box>{handleTitle(t('promotion.sections.linc.items.title'))}
                            </Box> 
                        </Box>
                        <Box flexGrow={1} pl={3} pr={3} width={{ xs : 1, md : 2/3 }} maxWidth={maxW*2}>
                            <Box fontSize={{ xs: 14, sm : 15 }} pl={2} pr={2} pt={1} pb={1} borderLeft="4px solid #2188FC" component="p">{t('promotion.sections.linc.items.descrip')}</Box>
                        </Box>
                        <Box flexGrow={1} pl={3} pr={3} width={{ xs : 1, md : 1/3 }} maxWidth={{ xs : maxW*2, md :maxW }} textAlign="right">
                            <a href={t('promotion.sections.linc.items.allFiles')} download="" target="blank_">
                                <Button className="btn-downloadAll" variant="contained" color="primary">{t('promotion.sections.linc.items.btnDownload')}</Button>
                            </a>
                        </Box>
                    </Box> 
                    <Box ml={-3} mr={-3} display="flex" flexWrap="wrap" justifyContent="center">
                    {   items.map( (item,index) =>  <Box className="downloads" flexGrow={1} width={{ md : 1/2, lg : 1/3 }} maxWidth={maxW} pl={3} pr={3} mb={6} key={index}>
                                <Box component="figure" m={0}>
                                    <a href={t(item.file)} download={t(item.title)} target="blank_">
                                        <Box className="btn-promotion no-hover" width={1} onClick={ () => setCurrentItem() }>
                                            <Box height={150} display="flex" justifyContent="center" alignItems="center">
                                                <Box maxWidth="100%" maxHeight="90%" component="img" src={item.img}></Box>
                                            </Box>
                                            <Box bgcolor="rgba(37,37,37,0.8)" className="hover">
                                                <Box className="hover-content" color="#fff">
                                                    <Box className="icon-cloud-download" fontSize={60} component="i"></Box>
                                                    <Box fontWeight={500} mt={-1.5} fontSize={16} display="block" component="span">{t('promotion.sections.linc.items.hover')}</Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </a>
                                    <Box component="figcaption">
                                        <Box fontSize={{ xs : 16, sm : 18 }} fontWeight={400} mt={3} color="text.primary" component="h3">{t(item.title)}</Box>
                                        <Box fontSize={15} color="text.primary" component="p">{t(item.descrip)}</Box>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    }
                    </Box>
                </Box>
                <Modal title={currentItem} status={statusModal} handleStatus={handleModal}></Modal>
      
    	    </Grid>
        }
    </Translation>

}

const mapDispatchToProps = {
    getSectionTitle
};

export default withRouter(connect('', mapDispatchToProps)(Formats))