import React, { Component } from 'react';
import classNames from 'classnames';

// Libs
import ReeValidate from 'ree-validate';

import axios from 'axios';
import { Link } from 'react-router-dom';

import {urlApi} from "../../variables/globals";

// Material ui Components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// handle of languages
import { Translation } from 'react-i18next';
import i18nHelper from '../../helpers/i18n';

// Components
import CustomInput from "../../components/Inputs/CustomInput";
import Translate from '../../layouts/Login/Translate';
import Footer from '../../layouts/Login/Footer';
import Msg from './components/Msg';

// Styles
import styles from "../../assets/jss/modules/loginStyles";

import logo from "../../assets/img/logo-horizontal.svg";
import backArrow from "../../assets/img/backArrow.svg";

import Banners from "./components/LoginBanners";

const i18nH = new i18nHelper();

class RecoverPassword extends Component {

  constructor(props) {
    
    super(props);
    
    this.validator = new ReeValidate.Validator({
      email: 'required|email',
    })
    
    this.state = {
      formData: {
        email: '',
      },
      loading: false,
      disabledButton: true,
      message: '',
      errors: this.validator.errors,
      showPassword: false,
      showRecoverPassword: 'block',
      hideResponse: 'none',
      response: {
        msg: '',
        title: ''
      }
    }
    
    this.onChange = this.onChange.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);

  }

  restorePassword(formData, t){
    axios.request({
           method:'patch',
           url:urlApi+'administrators/recoverpassword',
           data:formData,
           headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18nH.getLanguage()
         }
    }).then(response => {
           
         this.handleLoading();
         let responseData = response.data.data;

         if (responseData.modal) {

            let responseMsg = responseData.message;

            this.setState({ message: responseMsg });

         } else {

           this.setState({ showRecoverPassword: 'none', hideResponse: 'block', response: {msg: responseData.message, title: responseData.data.title } });
                      
         }

    }).catch((err) => {
      this.handleLoading(); 
      if (err.response.status === 429) {
         this.setState({ message: 'error.429' });
      }
      console.log(err.message);
    });
  }
  
  onChange(e) {

    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    
    // reset errors for url field
    errors.remove(name);
    
    // update form data
    this.setState({ formData: { ...this.state.formData, [name]: value } });
    
    this.validator.validate(name, value).then(() => {
        
        let dis = false;
        if (errors.items.length > 0 || 
          this.state.formData.email.trim() === "") dis = true;

        this.setState({ errors });
        this.setState({ disabledButton: dis});
    
    });

  }

  handleLoading = () => {
    this.setState({ loading: !this.state.loading });
    this.setState({ disabledButton: !this.state.disabledButton });
  };
  
  submit(formData, t) {
    this.handleLoading();
    this.restorePassword(formData, t);
  }
  
  validateAndSubmit(t, e) {

    e.preventDefault()
    
    const { formData } = this.state;
    const { errors } = this.validator;

    this.validator.validateAll(formData)
      .then(success => {
        if (success) {
          this.submit(formData, t)
        } else {
          this.setState({ errors })
        }
      })
    
  }

  clearMsg = () => {
    this.setState({ message: '' });
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {

  	const { classes } = this.props;
    const { errors } = this.state;
  
    return (
      
      <Translation>
        {
          (t, { i18n }) => {
             return (
              <form onSubmit={this.validateAndSubmit.bind(this, t)}>
                <Grid container className={classes.containerCenter}>
                    
                    <Banners />
                    
                    <Grid item className={classes.gridLogin} xs={12} sm={12} md={4}>
                      
                      
                      <Grid item className={classNames(classes.grid, classes.cntLogoLogin)} xs={12} sm={12} md={12}>
                        <img src={logo} alt="logo" className={classes.logoLogin} />
                        <Grid container style={{marginTop: 30}}>

                          <Grid container>
                            <Grid item className="" xs={12} sm={12} md={12}>
                              <Link to="/login" className=""><img src={backArrow} alt="back arrow" className="" /></Link>
                            </Grid>
                          </Grid>

                          <Grid item className={classNames(classes.center)} xs={12} sm={12} md={12} style={{display: this.state.showRecoverPassword}}>
                            <h4 className={classes.welcomeTitleLogin}>{t('recoverPassword.title')}</h4>
                            <p className={classes.subTitleLogin}>{t('recoverPassword.subtitle1')}</p> 
                            <p className={classes.subTitleLogin} style={{marginTop: 0}}>{t('recoverPassword.subtitle2')}</p>  
                          </Grid>

                          <Grid item className={classNames(classes.center)} xs={12} sm={12} md={12} style={{display: this.state.hideResponse}}>
                            <h4 className={classes.welcomeTitleLogin}>{this.state.response.title}</h4>
                            <p className={classes.subTitleLogin}>{this.state.response.msg}</p> 
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} style={{display: this.state.showRecoverPassword}}>
                            
                            <Grid container>
                              <Msg message={this.state.message} loading={this.state.loading} clear={this.clearMsg}/>
                            </Grid>  

                            <Grid container>  
                              <Grid item className="" xs={12} sm={12} md={12} style={{paddingTop:0}}>
                                <CustomInput
                                    formControlProps={{
                                      fullWidth: true,
                                      style: ({marginTop: 10})
                                    }}
                                    inputProps={{
                                      name:"email",
                                      placeholder:(t('recoverPassword.inputEmailPlaceholder')),
                                      onChange:(this.onChange)
                                    }}
                                    error={errors.has('email')}
                                  />
                                { errors.has('email') &&
                                  <label className={classes.error} htmlFor="email"></label>
                                }
                              </Grid>
                            </Grid>  

                            <Grid container style={{marginTop: 20}}>  
                              <Grid item xs={12} sm={12} md={12}>
                                <Button variant="contained" disabled={this.state.disabledButton} className={classes.button} type="submit">{t('recoverPassword.btnSend')}</Button>
                              </Grid>
                            </Grid>  

                          </Grid>
                          
                        </Grid>
                      </Grid>

                      <Grid container className={classes.cntFooter}><Translate t={t} /><Footer /></Grid>

                    </Grid>

                </Grid>
              </form> 

            )
           }
         }
        </Translation>

    );

  }

}

export default withStyles(styles, { withTheme: true })(RecoverPassword);