import React, { Component, Suspense } from 'react';

import { AppBar, Toolbar, IconButton, Hidden, Grid, Box, Typography, Fab, withStyles } from '@material-ui/core';

import ScrollToTop from "react-scroll-up";
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import UserProfile from "../../models/UserProfile";

// Api
import MainApi from "../../api/MainApi";

// core components
import DrawerMenu from './Menu2';
import OptionsMenu from './OptionsMenu';
import Footer from './Footer';
import IntroductoryModal from "./IntroductoryModal";
import mainStyles from "../../assets/jss/layouts/dashboard/main";
import i18nHelper from '../../helpers/i18n';

// Menu
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addMenu } from '../../redux/actions/menu';
import { getMainColor } from '../../redux/actions/global';

const userProfile = new UserProfile();
const Api = new MainApi();
const i18nH = new i18nHelper();

let backgrounds = {
  'mobile':{
    'es': {img: 'https://d10epvfnplv6o9.cloudfront.net/images/web/control-panel/dashboard/artboard-iphone-es.png'},
    'en': {img: 'https://d10epvfnplv6o9.cloudfront.net/images/web/control-panel/dashboard/artboard-iphone-en.png'}
  },
  'ipad':{
    'es': {img: 'https://d10epvfnplv6o9.cloudfront.net/images/web/control-panel/dashboard/artboard-ipad-es.png'},
    'en': {img: 'https://d10epvfnplv6o9.cloudfront.net/images/web/control-panel/dashboard/artboard-ipad-en.png'}
  }, 
  'desktop': {
    'es': {img: 'https://d10epvfnplv6o9.cloudfront.net/images/web/control-panel/dashboard/artboard-desktop-es.png'},
    'en': {img: 'https://d10epvfnplv6o9.cloudfront.net/images/web/control-panel/dashboard/artboard-desktop-en.png'}
  }
};

let showBack = false;

function getItems() {
   
	const cachedMenu = localStorage.getItem('menu');
	var json = {};

	if (cachedMenu) {
             
       json['menu'] = JSON.parse(cachedMenu);
      
    }
  
    return json;

}

class Main extends Component {	

  state = {
    mobileOpen: false,
    open: true, 
    close: false,
    lanChanged: ''
  };

  constructor(props){
    super(props);
    const { addMenu } = this.props;
    addMenu(getItems().menu);
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };	

  handleDrawerOpen = () => {
    this.setState({ open: true, close: false });
  };

  handleDrawerClose = () => {
    this.setState({ open: false, close: true });
  };

  getPermissions = (token, lan) => {

	 Api.getPermissions(token, lan).then(response => {

	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
         
	       if (responseData.modal) {
	           console.log(responseData.message);
	       } else {
	          userProfile.saveOnlyPermissions(responseData.data);
	          userProfile.saveUser(responseData.data.user);
	          // userProfile.saveLanguage(responseData.data.language);
            const { addMenu } = this.props;
            addMenu(getItems().menu);
	       }

	  }).catch((err) => {Api.closeSession(err)});
  
  };

  updateMenu = (lan) => {
   	 const storagedToken = userProfile.getToken();
   	 userProfile.saveLanguage(lan);
   	 this.setState({lanChanged: lan}); 
     this.getPermissions(storagedToken, lan);
  }

  render() {

    const { titleSection, compact } = this.props;
  	const { classes } = this.props;
  	const { mobileOpen, open, /*items,*/ lanChanged, design } = this.state;
    const children = React.cloneElement(this.props.children, {
        reloadData : lanChanged
    });

  	let cPatch = children.props.location.pathname, stylesBack = {};
  	const cUser = userProfile.getUser();

  	if ((cUser !== null) && (typeof cUser.universities !== 'undefined') && (typeof cUser.universities.backendstyles !== 'undefined')
  		&& (typeof cUser.universities.backendstyles[0] !== 'undefined')) {
  		stylesBack.backgroundColor = cUser.universities.backendstyles[0].headerColor;
      // const { getMainColor } = this.props;
      // getMainColor(stylesBack.backgroundColor);
  	}

  	showBack = (cPatch === '/')?true:false;

    return (
	    <div className={classes.root}>
            <Suspense fallback={<div>Loading...</div>}>
            <Grid container style={{ flexWrap: 'initial' }}>
                <Hidden mdUp>
	                <div className="blurcolor" onClick={this.handleDrawerToggle} open={mobileOpen}></div>
	            </Hidden>
                <Box width={ compact?50:240 } position={{ xs: 'fixed', md: 'relative' }} className="box-menu" borderRight={{ xs: '0px transparent', md: '1px solid #ECECEC' }} open={mobileOpen}>
                    {/*  SIDEBAR  */} 
	                <DrawerMenu logo={ cUser && cUser.universities && cUser.universities.backendstyles && cUser.universities.backendstyles[0]?cUser.universities.backendstyles[0].logo:'' } 
	                        bgColor={stylesBack} />
                    {/* / SIDEBAR  */}
                </Box>
                <Grid item xs={true} className="app">
                    {/*  HEADER  */}  
	                <AppBar position="relative" color="default" style={stylesBack}>
	                    <Toolbar disableGutters={!open}>
	                        <Hidden mdUp>
	                            <IconButton color="inherit" aria-label="open drawer" onClick={this.handleDrawerToggle}>
		                            <i className="icon-menu"></i>
		                        </IconButton>
	                        </Hidden>
                          <Hidden only={['xs','sm']} > 
                                <span style={{marginLeft: 50, fontSize: 18, color: 'white', fontWeight: 500}}>{
                                  cUser && cUser.universities?cUser.universities.name:''
                                }</span>
                          </Hidden>
                            <OptionsMenu history={children.props.history} changeMenu={i => this.updateMenu(i)}/>
	                    </Toolbar>
	                </AppBar>
                    {/* / HEADER  */}
                    <div style={{position: 'relative', minHeight: '100vh', padding: showBack?0:24}} >
                       {showBack && <Hidden smUp>
                         <div style={{position: 'absolute', background: showBack?'url('+backgrounds.mobile[i18nH.getLanguage()].img+')center bottom / cover no-repeat':'', left: 0, right:0, top:0, bottom:0}}></div>
                       </Hidden>}
                       {showBack && <Hidden lgUp xsDown>
                         <div style={{position: 'absolute', background: showBack?'url('+backgrounds.ipad[i18nH.getLanguage()].img+')center bottom / cover no-repeat':'', left: 0, right:0, top:0, bottom:0}}></div>
                       </Hidden>}
                       {showBack && <Hidden only={['xs', 'sm', 'md']} >   
                         <div style={{position: 'absolute', background: showBack?'url('+backgrounds.desktop[i18nH.getLanguage()].img+')center bottom / cover no-repeat':'', left: 0, right:0, top:0, bottom:0}}></div>
                       </Hidden>}
                        <Typography className="main-title" component="h2">
                          <Box fontSize="18px" color="text.primary" pr={1.5} component="i" className={ titleSection.icon+' icon' }></Box>
                          <Box fontSize="18px" color="text.primary" component="span">{ titleSection.title }</Box>
                        </Typography>
			                  <div className={classes.container} ref="wrapper" style={{position: 'relative'}}>{children}</div>      
                        <Footer patch={cPatch} />
                        <ScrollToTop showUnder={160}>
	                        <Fab color="primary" aria-label="Subir">
	                            <ArrowUpward />
	                        </Fab>
	                    </ScrollToTop>
	                </div>
                </Grid>
            </Grid>
            
	        { (typeof userProfile.getUser() !== 'undefined') && (typeof userProfile.getUser().universities.completedGuide === 'undefined' ||
			(typeof userProfile.getUser().universities.subscriptions !== 'undefined' && 
			userProfile.getUser().universities.completedGuide === 0))?<IntroductoryModal />:''}
	        </Suspense>

	    </div>
    );

  }

}

const mapStateToProps = state => ({
    compact: state.menu.compact,
    menu: state.menu.menu,
    titleSection: state.global.section
});

const mapDispatchToProps = {
    addMenu,
    getMainColor
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(withStyles(mainStyles, { withTheme: true })(Main)));
