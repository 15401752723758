/* ================= Actions types ================ */
export const GET_SECTION_TITLE = 'GET_SECTION_TITLE';
export const MAIN_COLOR = 'MAIN_COLOR';
export const GET_MESSAGE = 'GET_MESSAGE';
export const STATUS_MESSAGE = 'STATUS_MESSAGE';

/* ================= Actions creators ================ */
const getSectionTitle = (title) => {
	return { type: GET_SECTION_TITLE, payload: title }
}

const getMainColor = (color) => {
	return { type: MAIN_COLOR, payload: color }
}

const getMessage = (msg, type) => {
	return { type: GET_MESSAGE, payload: { message : msg, type : type } }
}

const statusMessage = (band) => {
	return { type: STATUS_MESSAGE, payload: band }
}

export {
	getSectionTitle,
	getMainColor,
	getMessage,
	statusMessage
}