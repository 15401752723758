import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Libs
import ReeValidate from 'ree-validate';

// handle of languages
import { Translation } from 'react-i18next';

// Components
import CardHeader from "../../components/Card/CardHeader";
import CustomInput from "../../components/Inputs/CustomInput";

// Api
import CareersApi from "../../api/Careers/CareersApi";

// Models
import UserProfile from "../../models/UserProfile";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

const Api = new CareersApi();
const userProfile = new UserProfile();
let titleKey = 'titleAdd';
let titleIcon = 'fa fa-plus';

class Career extends Component {

   constructor(){
	  
	super();

	this.validator = new ReeValidate.Validator({
      name: 'required',
      status: 'required'
    });

	this.state = {
	 	formData: {
	      id: 0,
	  	  name: '',
	      status: 'Activo'
	    },
	    disabledButton: false,
	    errors: this.validator.errors
    };

  }

  getOneRow = (id) => {
      
	  Api.getById(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   id).then(response => {
            
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
           
	       if (responseData.modal) {
	           this.props.history.push("/careers");
	       } else {
	       	
	       	this.setState({formData:{...responseData.data}});
	       
	       }

      }).catch((err) => {Api.closeSession(err)});
    
  };

  save = (data) => {
      
	  Api.save(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   data).then(response => {
           
           this.handleLoading();
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

	       if (responseData.modal) {
	       	   userProfile.addAlert({msg: responseData.message, class: responseData.data.className});
	           this.props.history.push("/careers");
	       } else {
	       	   console.log(responseData.message);
	       }

      }).catch((err) => {this.handleLoading();Api.closeSession(err)});
    
  };

  update = (data) => {
      
	  Api.update(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   data).then(response => {
           
           this.handleLoading();
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

	       if (responseData.modal) {
	       	   userProfile.addAlert({msg: responseData.message, class: responseData.data.className});
	           this.props.history.push("/careers");
	       } else {
	       	   console.log(responseData.message);
	       }

      }).catch((err) => {this.handleLoading();Api.closeSession(err)});
    
  };

  handleChange = e => {

	const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    
    errors.remove(name);

    this.setState({ formData: { ...this.state.formData, [name]: value } });
   
  };

  handleLoading = () => {
    this.setState({ disabledButton: !this.state.disabledButton });
  };

  submit = formData => {
    
    this.handleLoading();
    
    if (this.state.formData.id > 0) {
    	formData.id = this.state.formData.id;
    	this.update(formData);
    } else {
    	this.save(formData);
    }
   
  };

  validateAndSubmit = e => {

    e.preventDefault()
    
    const { formData } = this.state;
    const { errors } = this.validator;

    this.validator.validateAll(formData)
      .then(success => {
        if (success) {
          this.submit(formData)
        } else {
          this.setState({ errors })
        }
      })
    
  }

  componentWillMount(){
  	
  	const id = this.props.match.params.id;

  	if (id !== 'add') {
  		
  		titleKey = 'titleModify';
  		titleIcon = 'fa fa-pen'
	  	
	  	if (isNaN(id)) {
			this.props.history.push("/careers");
		} else {
			this.getOneRow(this.props.match.params.id);
		}

    } else {

    	titleIcon = 'fa fa-plus';
	  	titleKey = 'titleAdd';
    	
    }

  }

  render() {

  	const { classes } = this.props;
  	let {formData, errors} = this.state;

    return (
      
      <div>
       <Translation>
	    {
	      (t, { i18n }) => {
	      	   return (
		       <form onSubmit={this.validateAndSubmit}>
		        <Grid container className={classes.container}>
		            <Grid item className={classes.grid} xs={12} sm={12} md={3}>
			       
				        <Grid item className={classNames(classes.gridHeader, classes.gridHeaderPadding)} xs={12} sm={12} md={12}>

			              <Button color="default" component={Link} to="/careers" className={classes.btnBack}>
			                <span className={classNames(classes.iconBack, "fa fa-arrow-left")}></span> 
			                {t('categories.form.back')}
			              </Button>
				       
				        </Grid>
				  
				    </Grid>  
			        <Grid item className={classes.grid} xs={12} sm={12} md={12}>
				      <Card className={classes.cardContet}>
			            <CardHeader className={classes.cardHeader}>
			              <h4 className={classes.cardTitleWhite}>
			                 <span className={classNames(classes.iconBack, titleIcon)}></span> 
			                 {t('careers.form.'+titleKey)}
			              </h4>
			              <p className={classes.cardCategoryWhite}>{t('categories.form.subtitle')}</p>
			            </CardHeader>
			            <CardContent>
			              <Grid container>
			                <Grid item className={classes.grid} xs={12} sm={12} md={12}>
			                  <CustomInput
			                    labelText={t('careers.form.fields.name')}
			                    id="name"
			                    formControlProps={{
			                      fullWidth: true
			                    }}
			                    inputProps={{
			                      name:"name",
			                      value:formData.name,
			                      onChange:(this.handleChange),
			                      error:errors.has('name')
			                    }}
			                    customInputProps={{maxLength: "95"}}
			                    
			                  />
			                  { errors.has('name') &&
		                        <label className={classes.error} htmlFor="name"></label>
		                      }
			                </Grid>
			              </Grid>
			              <Grid container>
			                <Grid item className={classes.grid} xs={12} sm={12} md={12}>
			                    <FormControl fullWidth={true} className={classes.formControl}>
						          <InputLabel htmlFor="status">{t('careers.form.fields.status')}</InputLabel>
						          <Select
						            value={formData.status}
						            onChange={this.handleChange}
						            inputProps={{
						              name: 'status',
						              id: 'status'
						            }}
						          >
						            <MenuItem value="Activo">{t('selectStatus.active')}</MenuItem>
						            <MenuItem value="Inactivo">{t('selectStatus.inactive')}</MenuItem>
						          </Select>
						        </FormControl>
			                </Grid>
			              </Grid>
			            </CardContent>
			            <CardActions>
			              <Button variant="contained" color="primary" className={classes.buttonBottom} disabled={this.state.disabledButton} type="submit">
			                 {t('categories.form.btnSave')}
			              </Button>
			            </CardActions>
			          </Card>
			        </Grid>
		       </Grid>  
		       </form>  
            )
          }
        }
        </Translation>
      </div>

    );

  }

}

export default withStyles(styles, { withTheme: true })(Career);
