import React, { useState, useEffect  } from 'react';
import classNames from 'classnames';
import { Link, useParams, useHistory } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Tabs, Tab, Button } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';

// Components
import AlertMessage from "../../components/Modal/AlertMessage";
import CardHeader from "../../components/Card/CardHeader";
import CustomInput from "../../components/Inputs/CustomInput";
import { Alert, AlertTitle } from '@material-ui/lab';
import InputLabel from '@material-ui/core/InputLabel';

// Api
import AdministratorApi from "../../api/Administrators/AdministratorApi";

// Models
import UserProfile from "../../models/UserProfile";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

// handle of languages
import { Translation } from 'react-i18next';

const Api = new AdministratorApi();
const userProfile = new UserProfile();
let titleKey = 'titleAdd';
let titleIcon = 'fa fa-plus';

const Administrator = ({classes}) => {
    return <App classes={classes} />;
};
  
const App = ({classes}) => {

    let history = useHistory();
    let { id } = useParams();

    const [errors, setErrors] = useState({
        name: false,
        description: false
    });
    const [currentTab, setTab] = useState('general');
    const [alertMsg, setAlertMsg] = useState({
        open: false,
        className: 'success',
        message: ''
    });
    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        lastName: '',
        status: 'Activo',
        email: '',
        password: '',
        phone: '',
        gender: 'F',
        profileId: ''
    });
    const [saveButton, enableButton] = useState(false);
    const [profiles, setProfiles] = useState([]);

    const closeMessage = (event) => {
        setAlertMsg({...alertMsg, open: false});
    };

    const changeTab = (event, tab) => {  
        setTab(tab);
    }

    const handleChange = e => {

		  const name = e.target.name;
	    const value = e.target.value;

        setFormData({ 
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: false
        });
   
    };

    function sendData(data) {
      
        Api.save(
            userProfile.getToken(),
            data
        ).then(response => {
  
          let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
          let data = responseData.data;
  
          enableButton(false);
  
          if (responseData.modal) {
              
              if (data.className === 'success') {
                  
                  userProfile.addAlert({msg: responseData.message, class: data.className});
                  history.push("/administrators"); 
              
                } else {

                setAlertMsg({
                  open: true,
                  className: data.className,
                  message: responseData.message
                });

              }
                 
          } else {
            console.log("Error");
          }
  
        }).catch((err) => {enableButton(false);Api.closeSession(err)});
  
    }

    function update(data) {
      
      Api.update(
           userProfile.getToken(), 
           data
      ).then(response => {
             
        enableButton(false);
        let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
        let data = responseData.data;
  
        if (responseData.modal) {
              
          if (data.className === 'success') {

            if (data.token !== '')
              userProfile.saveToken(data.token);

            userProfile.addAlert({msg: responseData.message, class: data.className});
            history.push("/administrators"); 
          
          } else {

              setAlertMsg({
                open: true,
                className: data.className,
                message: responseData.message
              });

          }
             
        } else {
          console.log(responseData.message);
        }
  
      }).catch((err) => {enableButton(false);Api.closeSession(err)});
      
    };

    const validateForm = (t, e) => {
    
        e.preventDefault();
    
        let labels = {};

        if (formData.name.trim() === '') labels['name'] = true;
        if (formData.lastName.trim() === '') labels['lastName'] = true;
        if (formData.email.trim() === '') labels['email'] = true;

        if (formData.profileId === '') labels['profileId'] = true;
        if (formData.password.trim() === '' && isNaN(id)) labels['password'] = true;
    
        if (Object.keys(labels).length > 0) {

            setErrors({...errors, ...labels});

        } else if (!Api.validateEmail(formData.email.trim())) {
            
          setAlertMsg({
              open: true,
              className: 'warning',
              message: t('administrators.alerts.incorrectEmailFormat')
            });

        } else if ((isNaN(id) && formData.password.length < 8) || 
                   (formData.password.length > 0 && formData.password.length < 8)) {
          
          setAlertMsg({
            open: true,
            className: 'warning',
            message: t('administrators.alerts.incorrectPassword')
          });

        }else {

            enableButton(true);

            if (id > 0) {
              update(formData);
            } else {
              sendData(formData);
            }

        }
        
    }

    /*const convertPermsToObject = (permis, items) => {
    
      for (var k in items) {

        permis[items[k].permissionId] = {permissionId:items[k].permissionId, allowed: (items[k].allowed === "SI"?true:false)}; 
           
        if (items[k].subSections.length > 0)
           permis = convertPermsToObject(permis, items[k].subSections);

      }

      return permis;

    };*/

    const getOneRow = (id) => {

      Api.byId(
        userProfile.getToken(), id
      ).then(response => {

        let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
        let data = responseData.data;

        if (responseData.modal) {
	        history.push("/administrators");
	      } else {

          setProfiles(data.profiles);
          setFormData(data.info);

        }

      }).catch((err) => {Api.closeSession(err)});

    }

    function showProfiles() {
      
      Api.getProfiles(userProfile.getToken()).then(response => {

        let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

        setProfiles(responseData.data);

      }).catch((err) => {Api.closeSession(err)});
      
    };

    useEffect(() => {

      if (id !== 'add') {
    
        titleKey = 'titleModify';
        titleIcon = 'fa fa-pen'
        
        if (isNaN(id)) {
          history.push("/profiles");
        } else {
          getOneRow(id);
        }
  
      } else {
        titleIcon = 'fa fa-plus';
        titleKey = 'titleAdd';
        showProfiles();
      }

    }, [])

    return (
        <div>
            <Translation>
             {
                (t, { i18n }) => {
                    
                    return (  
                      <Grid container> 

                         <Grid item className={classes.grid} xs={12} sm={12} md={3}>
                            <Grid item className={classNames(classes.gridHeader, classes.gridHeaderPadding)} xs={12} sm={12} md={12}>
                                <Button color="default" component={Link} to="/administrators" className={classes.btnBack}>
                                <span className={classNames(classes.iconBack, "fa fa-arrow-left")}></span> 
                                {t('categories.form.back')}
                                </Button>                            
                            </Grid>
                         </Grid>   
                        
                         <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                          <Card className={classes.cardContet}>    
                             
                             <CardHeader className={classes.cardHeader}>
                                <h4 className={classes.cardTitleWhite}>
                                    <span className={classNames(classes.iconBack, titleIcon)}></span> 
                                    {t('administrators.form.'+titleKey)}
                                </h4>
                                <p className={classes.cardCategoryWhite}>{t('categories.form.subtitle')}</p>
                             </CardHeader>
                             <AppBar position="static" style={{backgroundColor: "#f5f5f5", color: "#333"}}>
                                <Tabs value={currentTab} onChange={changeTab} indicatorColor="primary" textColor="primary">
                                  <Tab value="general" label={t('administrators.form.tabs.general')} />
                                  {/*<Tab value="permissions" label={t('configuration.tabs.webPortal')} />*/}
                                </Tabs>
                             </AppBar>
                             
                             {currentTab === 'general' && <CardContent>
                               <form onSubmit={e => validateForm(t, e)}>
                                {id !== 'add' && <Grid container>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                                  <Alert severity="info">
                                    <AlertTitle style={{fontWeight:400}}>{t('administrators.alerts.alertTitle')}</AlertTitle>
                                  </Alert>
                                  </Grid>
                                </Grid>}     
                                <Grid container>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                    <CustomInput
                                      labelText={t('administrators.form.fields.name')}
                                      id="name"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name:"name",
                                        value:formData.name,
                                        onChange:(handleChange),
                                        error:errors.name
                                      }}
                                      customInputProps={{maxLength: "50"}}
                                    />
                                  </Grid>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                    <CustomInput
                                      labelText={t('administrators.form.fields.lastName')}
                                      id="lastName"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name:"lastName",
                                        value:formData.lastName,
                                        onChange:(handleChange),
                                        error:errors.lastName
                                      }}
                                      customInputProps={{maxLength: "50"}}
                                    />
                                  </Grid>
                                </Grid>
                                
                                <Grid container>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                    <CustomInput
                                      labelText={t('administrators.form.fields.email')}
                                      id="email"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name:"email",
                                        value:formData.email,
                                        onChange:(handleChange),
                                        error:errors.email
                                      }}
                                      customInputProps={{maxLength: "50"}}
                                    />
                                  </Grid>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                    <FormControl fullWidth={true} className={classes.formControl}>
                                      <InputLabel htmlFor="gender">{t('administrators.form.fields.gender')}</InputLabel>
                                      <Select
                                        value={formData.gender}
                                        onChange={handleChange}
                                        inputProps={{
                                          name: 'gender',
                                          id: 'gender'
                                        }}
                                      >
                                        <MenuItem value="F">{t('selectGender.female')}</MenuItem>
                                        <MenuItem value="M">{t('selectGender.male')}</MenuItem>
                                        <MenuItem value="O">{t('selectGender.other')}</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>

                                <Grid container>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                    <FormControl fullWidth={true} className={classes.formControl}>
                                      <InputLabel htmlFor="profileId">{t('administrators.form.fields.profile.label')}</InputLabel>
                                      <Select
                                        value={formData.profileId}
                                        onChange={handleChange}
                                        inputProps={{
                                          name: 'profileId',
                                          id: 'profileId'
                                        }}
                                        error={errors.profileId}
                                      >
                                        <MenuItem value="">
                                          <em>{t('administrators.form.fields.profile.defaultValue')}</em>
                                        </MenuItem>
                                        {profiles.map((i, k) => {
                                          return (
                                              <MenuItem value={i.id} key={k}>{i.name}</MenuItem>
                                            )
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                    <FormControl fullWidth={true} className={classes.formControl}>
                                      <InputLabel htmlFor="status">{t('administrators.form.fields.status')}</InputLabel>
                                      <Select
                                        value={formData.status}
                                        onChange={handleChange}
                                        inputProps={{
                                          name: 'status',
                                          id: 'status'
                                        }}
                                      >
                                          <MenuItem value="Activo">{t('selectStatus.active')}</MenuItem>
                                          <MenuItem value="Inactivo">{t('selectStatus.inactive')}</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>

                                <Grid container>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                    <CustomInput
                                      labelText={t('administrators.form.fields.phone')}
                                      id="phone"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name:"phone",
                                        value:formData.phone,
                                        onChange:(handleChange),
                                        error:errors.phone
                                      }}
                                      customInputProps={{maxLength: "15"}}
                                    />
                                  </Grid>
                                  <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                                    <CustomInput
                                      labelText={t('administrators.form.fields.password')}
                                      id="password"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name:"password",
                                        value:formData.password,
                                        onChange:(handleChange),
                                        error:errors.password,
                                        type:"password"
                                      }}
                                      customInputProps={{maxLength: "50"}}
                                    />
                                  </Grid>
                                </Grid>
                                
                                <Grid container>
                                    <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                                        <Button variant="contained" color="primary" className={classes.buttonBottom} 
                                                type="submit" style={{marginTop: 20, marginLeft: 0}} disabled={saveButton}>
                                            {t('administrators.form.btnSave')}
                                        </Button>
                                    </Grid>
                                </Grid>
                               </form>
                             </CardContent>}

                          </Card>
                         </Grid>
                        <AlertMessage open={alertMsg.open} variant={alertMsg.className} message={alertMsg.message} eventClose={closeMessage} />
                      
                      </Grid>
                )}
            }
           </Translation>    
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(Administrator);