const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  appBar: {
    position: 'absolute',
    backgroundColor: '#2188FC',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    height: "100%",
    // background: "#edecec",
    [theme.breakpoints.up('md')]: {
      position: 'relative'
    }
  },
  content: {
    flexGrow: 1,
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  container:{
    marginTop: "0px",
    minHeight: "calc(100vh - 123px)",
    paddingTop: "0px",
    paddingBottom: "30px",
    paddingRight: "0px",
    paddingLeft: "0px",
    marginRight: "auto",
    marginLeft: "auto"
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
      width:'100%'
    }
  },
  'appBarShift-left': {
    marginLeft: drawerWidth,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    }
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    position: 'relative',
    ...theme.mixins.toolbar,
  },
  'content-left': {
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: drawerWidth,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  },
  'contentShift-left-default': {
    marginLeft: 0,
  },
  'menuStyle':{
    position: 'fixed',
  	top: 0,
  	overflowY: 'hidden',
  	width: drawerWidth,
    height: "100%"
  },
  'logo':{
    position: "absolute",
    maxWidth: "65%",
    maxHeight: "85%",
    left: "22px",
    height: "100%"
  },
  'iconHeader': {
    width: "1.5em",
    overflow: "auto",
    fontSize: "30px",
    marginTop: "6px",
    height: "1.2em",
    userSelect: "none",
    flexShrink: 0
  },
  backImage: {

  }
});

export default styles;