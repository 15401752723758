import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// Components
import CustomInput from "../../components/Inputs/CustomInput";

import { Translation } from 'react-i18next';

const styles = theme => ({
  gridHeader:{
    display: "flex",
    alignItems: "center",
    color: "#666",
    paddingLeft: "0px",
    marginBottom: "5px"
  },
  button: {
    margin: theme.spacing(1),
    marginTop: "22px"
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  bootstrapRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing(3)
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    width: 'calc(100% - 24px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  widthFormControl:{
    width: "70%"
  }
});

class CustomSearcher extends Component {

  handleClick = (e) => {
    e.preventDefault();
    this.props.eventChange(e, this.search);
  }

  handleEnter = (e) => {

    var keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
        this.handleClick(e);
    };

  }

  handleKeyPress = (e) => {
    var keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      e.preventDefault();
    }
  }

  componentDidMount() {

    const node = ReactDOM.findDOMNode(this.search);

    const addEvent = node.addEventListener || node.attachEvent;
    addEvent("keypress", this.handleKeyPress, false);

  }	

  render() {
    
  	const { classes, placeholder } = this.props;

    return (
      
       <Translation>
         {
          (t, { i18n }) => {
            
            return (
               <Grid item className={classNames(classes.gridHeader)} xs={12} sm={12} md={12}>
        	       <CustomInput
                        formControlProps={{
                          fullWidth: false,
                          classes: {
        		                 root: classes.widthFormControl
        		             }
                        }}
                        inputProps={{
                        	placeholder: placeholder,
                        	disableUnderline: true,
                        	name: "search",
                          onKeyUp: this.handleEnter,
                        	classes: {
            			            root: classes.bootstrapRoot,
            			            input: classes.bootstrapInput
            			        },
            			        inputRef: input => this.search = input
                        }}
                      />
        	        <Button variant="contained" color="default" className={classes.button} onClick={this.handleClick}>
        		       {t('customSearcherText.btnSearch')}
        		       <span className={classNames(classes.rightIcon, 'fa fa-search')} ></span>
        		    </Button> 
               </Grid>
             )
            }
          }
        </Translation>
    );

  }

}

export default withStyles(styles, { withTheme: true })(CustomSearcher);