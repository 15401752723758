const gquality = 1;
const gtype = "image/jpeg";

function createThumb(img, maxWidth, maxHeight, type, quality) {

      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          
      var MAX_WIDTH = maxWidth;
      var MAX_HEIGHT = maxHeight;
      var width = img.width;
      var height = img.height;

      /*************************/
      if (MAX_WIDTH >= MAX_HEIGHT) { // BY WIDTH

          height = (MAX_WIDTH / (width/100)) * (height/100);
          width = MAX_WIDTH;
      
      } else { // BY HEIGHT

          width = (MAX_HEIGHT / (height/100)) * (width/100);
          height = MAX_HEIGHT;
         
      }
      /*************************/  

      /*if (width > height) {

        if (width > MAX_WIDTH) {

            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;

        }
      } else {
        if (height > MAX_HEIGHT) {
            
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;

        }
      }*/

      canvas.width = width;
      canvas.height = height;

      var ctx2 = canvas.getContext("2d"), 
      q = (typeof quality !== 'undefined')?quality:gquality,
      t = (typeof type !== 'undefined' && type !== '')?type:gtype;

      ctx2.drawImage(img, 0, 0, width, height);

      var dataurl = canvas.toDataURL(t, q);

      return {dataurl: dataurl, img: img};

}

class handleFiles {

  constructor(){
    this.quality = gquality;
    this.type = gtype;
  }

  dataURItoBlob(dataURI) {
      
      var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
          atob(dataURI.split(',')[1]) :
          unescape(dataURI.split(',')[1]);
      var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      var max = bytes.length;
      var ia = new Uint8Array(max);
      for (var i = 0; i < max; i++)
          ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
  
  }

  resize(picture, maxWidth, maxHeight, fn, type, quality) {

    let fr = new FileReader();
    fr.readAsDataURL(picture);

    fr.onload = function(e) {

      let dataUrl = e.target.result, img = new Image();
          img.src = dataUrl;

        img.onload = function() {
          
            var resizedDataUrl = createThumb(img, maxWidth, maxHeight, type, quality);
            fn(resizedDataUrl);

        };

    };

  }

}

export default handleFiles;