import i18n from 'i18next';
import { initReactI18next }  from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en/translations.json';
import es from './locales/es/translations.json';
import fr from './locales/fr/translations.json';

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: en },
      es: { translations: es },
      fr: { translations: fr }
    },
    // lng: "en",
    fallbackLng: 'en',

    saveMissing: true,

    nsSeparator: '.',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    debug: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true
    }

  }, (err, t) => {
    /*if (err) return console.log('something went wrong loading', err);
        console.log(t('home.welcomeMan'));*/
  });


export default i18n;