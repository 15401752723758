import axios from 'axios';
import BaseApi from "../BaseApi";
import UserProfile from "../../models/UserProfile";

const userProfile = new UserProfile();

class NewsApi extends BaseApi {

    getNews(token, limit, page, textToSearch, status) {

    	this.headers.Authorization = token;
    	this.headers['Accept-Language'] = userProfile.getLanguage();

    	let url =  this.url+'homenews/list?limit='+limit+'&skip='+(page+1)+'&search='+textToSearch+'&status='+status;
    
        return axios.get(url,{headers: this.headers});

    }

    deleteNew(token, id, limit, page, textToSearch, status) {

    	this.headers.Authorization = token;
    	this.headers['Accept-Language'] = userProfile.getLanguage();

    	let url =  this.url+'homenews/delete',
    	    data = {
    	    	limit: limit,
    	    	skip: page+1,
    	    	search: textToSearch,
    	    	status: status,
    	    	id: id
    	    }

        return axios.request({ method:'patch', url:url, headers: this.headers, data:data });
    }

    updateNew(token, info) {

        this.headers.Authorization = token;
        this.headers['Accept-Language'] = userProfile.getLanguage();

        let url =  this.url+'homenews/update';

        return axios.request({ method:'patch', url:url, headers: this.headers, data:info });
    }

    saveNew(token, info) {

        this.headers.Authorization = token;
        this.headers['Accept-Language'] = userProfile.getLanguage();

        let url =  this.url+'homenews/save';

        return axios.request({ method:'post', url:url, headers: this.headers, data:info });
    }

    saveIMG(token,file,id) {
        
        this.headers.Authorization = token;
        this.headers['id'] = id;
        this.headers['Accept-Language'] = userProfile.getLanguage();
        let url = this.url+'homenews/uploadnoticephoto';

        return axios.request({method:'post', url:url, headers: this.headers, data:file});

    }

}

export default NewsApi