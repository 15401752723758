import axios from 'axios';
import BaseApi from "../BaseApi";
import UserProfile from "../../models/UserProfile";

const userProfile = new UserProfile();

class LessonsApi extends BaseApi {

  constructor(){
    super();
    this.api = this.url + 'lessons/';
  }

  getStatisticOfLessons(token, universityId, initialDate, endtDate, categoryId, type, modality) {

    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.api+'statistics?universityId='+universityId+'&date1='+initialDate+'&date2='+endtDate+'&categoryId='+categoryId+'&type='+type+'&modality='+modality;
    
    return axios.get(url,{headers: this.headers});

  }

  getSubjects(token, by , id, initialDate, endtDate, categoryId, type, modality) {

    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.api+(by==='semester'?'subjectsbysemester':'subjectsbycareer')+'?id='+id+'&date1='+initialDate+'&date2='+endtDate+'&categoryId='+categoryId+'&type='+type+'&modality='+modality;
    
    return axios.get(url,{headers: this.headers});

  }

  getDownloadStatistic(token, universityId, initialDate, endtDate, categoryId, type, modality) {
     let headers =  {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Authorization': '',
        'Accept-Language': ''
    };

    headers.Authorization = token;
    headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.api+'download?universityId='+universityId+'&date1='+initialDate+'&date2='+endtDate+'&categoryId='+categoryId+'&type='+type+'&modality='+modality;
    
    return axios.get(url,{headers: headers, responseType: 'arraybuffer'});

  }

  downloadAvailabilityOfTutors(token) {
    let headers =  {
       'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
       'Authorization': '',
       'Accept-Language': ''
   };

   headers.Authorization = token;
   headers['Accept-Language'] = userProfile.getLanguage();
   let url =  this.api+'tutors/availability/download';
   
   return axios.get(url,{headers: headers, responseType: 'arraybuffer'});

 }

}

export default LessonsApi;