import React, { useState, useEffect  } from 'react';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Box, Tabs, Tab, Button, Paper } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';

// Components
import Datagrid from "../../components/Datagrid/Grid";
import SearcherInput from "../../components/Inputs/SearcherInput";
import AlertDelete from "../../components/Modal/AlertDelete";
import AlertMessage from "../../components/Modal/AlertMessage";

// Api
import AdministratorApi from "../../api/Administrators/AdministratorApi";

// Models
import UserProfile from "../../models/UserProfile";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

// handle of languages
import { Translation } from 'react-i18next';

const Api = new AdministratorApi();
const userProfile = new UserProfile();

let columns = {
    'fullName': {}, 
    'email': {},
    'profile': {}
};

const Administrators = ({classes}) => {
    return <App classes={classes} />;
};
  
const App = ({classes}) => {

    const [alertMsg, setAlertMsg] = useState({
        open: false,
        className: 'success',
        message: ''
    });
    const [openAlert, setOpenAlert] = useState(false);
    const [status, setStatus] = useState('Activo');
    const [textToSearch, setTextToSearch] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [data, setData] = useState([]);
    const [alertRemove, setAlertRemove] = useState({
        funct: '',
        message: ''
    });

    const filterByStatus = (event, tab) => {
        setStatus(tab);
    };

    const searchRows = (event, search) => {
        setTextToSearch(search.value);
        setCurrentPage(0);
    };

    const handleChangePage = (event, page) => {
        setCurrentPage(page);
    };

    const showAlert = (funct, e) => {
        setAlertRemove({
            funct: funct
        });
        setOpenAlert(true);
    };

    const translateColumns = (tr) => {

        columns.fullName.label = tr('administrators.table.columns.name');
        columns.profile.label = tr('administrators.table.columns.profile');
        columns.email.label = tr('administrators.table.columns.email');
        
        if (typeof userProfile.getPermissions()['settings_administrators_users_write'] !== 'undefined')
          columns.opciones = {label: tr('administrators.table.columns.options')};

        return columns;
    };

    const closeMessage = (event) => {
        setAlertMsg({...alertMsg, open: false});
    };

    const closeAlert = (event) => {
        setOpenAlert(false);
    };

    const deleteItem = (id) => {
       
        setOpenAlert(false);
        
        Api.delete(
            userProfile.getToken(), 
            id,
            currentPage, 
            textToSearch,
            status
        ).then(response => {
    
          let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
          let data = responseData.data;  
        
          if (data.className === 'success') {
             setData(customColumns(data.rows));
             setTotalRows(data.total);
          }
    
          setAlertMsg({
            open: true,
            className: data.className,
            message: data.message
          });
    
        }).catch((err) => {Api.closeSession(err)});
    
    }

    function customColumns(rows) {

        rows = rows.map((val, key) => {

          if (val.master)
            val.profile = (<div id="cntLabel"><span>{val.profile}</span> <span className="span">Master</span></div>);

          if (typeof userProfile.getPermissions()['settings_administrators_users_write'] !== 'undefined') 
            val.opciones = (<div>
                            <IconButton key={val.id+'e'} className={classes.button} 
                             classes={{
                                root: classes.zeroPadTableBtn
                              }}
                             style={{marginTop:0, color: '#3498db', display: 'inline-block'}} component={Link} to={"/administrators/"+val.id}>
                            <EditIcon />
                            </IconButton>
                            <IconButton key={val.id+'d'} className={classes.button} 
                             classes={{
                                root: classes.zeroPadTableBtn
                             }}
                             style={{marginTop:0, color: '#d9534f', display: 'inline-block'}} onClick={e => showAlert(() => deleteItem(val.id), e)}>
                            <ClearIcon />
                            </IconButton>
                        </div>);
                                          
            return val;
        });

        return rows;
    }

    function getRowsPerPage(page) {

        Api.list(
            userProfile.getToken(), 
            page, 
            textToSearch,
            status
        ).then(response => {
  
          let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
          let data = responseData.data;

          setData(customColumns(data.rows));
          setTotalRows(data.total);

        }).catch((err) => {Api.closeSession(err)});  
    }

    useEffect(() => {

        let alert = userProfile.getAlert();
        if (alert && alert !== null)
            setAlertMsg({
                open: true,
                className: alert.class,
                message: alert.msg
            });

        Api.limit = rowsPerPage;
        getRowsPerPage(currentPage);
    
    }, [currentPage, status, textToSearch])

    return (
        <div>
            <Translation>
             {
                (t, { i18n }) => {
                    
                    return (
                      <Grid id="settings" container>
                        <Box ml={-2} mr={-2} className="filters" display="flex" flexWrap="wrap" mb={2} width={1}>
                            <Box pl={2} pr={2} width={{ xs: 1, md: 1/2 }} mb={4} flexGrow={1} order={0}>
                                <Box className="actions" p={2} height={1} borderRadius={2} display="flex" flexWrap="wrap" alignItems="center">
                                    <Grid item xs={true}>
                                        <Tabs value={status} onChange={filterByStatus} indicatorColor="primary" textColor="primary" style={{ flex : 1 }}>
                                            <Tab value="Activo" label={t('administrators.tabs.active')} />
                                            <Tab value="Inactivo" label={t('administrators.tabs.inactive')}/>
                                        </Tabs>
                                    </Grid>
                                    {typeof userProfile.getPermissions()['settings_administrators_users_write'] !== 'undefined' &&
                                    <Box flexGrow={1} width={1} maxWidth={{ xs: 110, sm: 140, md : 110, lg : 140 }}>
                                        <Button className="btn-filter" variant="contained" color="primary" component={Link} to="/administrators/add">{t('news.tabs.btn')}</Button>
                                    </Box>}
                                </Box>
                            </Box>
                            <Box pl={2} pr={2} width={{ xs: 1, md: 1/2 }} mb={4} flexGrow={1} order={{ xs: 2, md: 1 }}>
                                <Box className="search" p={2} height={1} borderRadius={2} display="flex" flexWrap="wrap" alignItems="center">
                                    <Grid item xs={true}>
                                        <SearcherInput className="input-search" placeholder={t('administrators.search.placeholder')} eventChange={searchRows} band={true}></SearcherInput>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>  
                        
                        <Datagrid data={data} page={currentPage} totalRows={totalRows} rowsPerPage={rowsPerPage} columns={translateColumns(t)} eventOnChange={handleChangePage} />

                        <AlertMessage open={alertMsg.open} variant={alertMsg.className} message={alertMsg.message} eventClose={closeMessage} />
                        <AlertDelete open={openAlert} eventClose={closeAlert} eventOk={alertRemove.funct} content={''} title={t('administrators.modalDelete.title')} />
                      </Grid>
                )}
            }
           </Translation>    
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(Administrators);