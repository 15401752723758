
import axios from 'axios';
import BaseApi from "../BaseApi";
import UserProfile from "../../models/UserProfile";

const userProfile = new UserProfile();

class ProfilesApi extends BaseApi {

  constructor(){
    super();
    this.limit = 10;
    this.api = this.url + 'profiles/';
  }

  list(token, page, textToSearch, status) {

    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.api+'list?limit='+this.limit+'&skip='+(page * this.limit);

    if (textToSearch !== '') url = url+'&search='+textToSearch.trim();
    if (status !== '') url = url+'&status='+status;
    
    return axios.get(url,{headers: this.headers});

  }

  delete(token, id, page, textToSearch, status) {
    
  	this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
  	let skip = (page*this.limit);
  	let url = this.api+'delete', 
  	    data = {
	       	limit:this.limit,
	       	skip: skip,
	       	id: parseInt(id, 10)
	    }

  	if (textToSearch !== '') data.search = textToSearch.trim();
    if (status !== '') data.status = status;

  	return axios.request({method:'patch', url:url, headers: this.headers, data:data});

  }

  save(token, universityId, info, permissions) {
    
    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'add', 
        data = {
          universityId:universityId,
          permissions: permissions,
          ...info
      }

    return axios.request({method:'post', url:url, headers: this.headers, data:data});

  }

  getPermissions(token) {

    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.api+'permissions/show';
    
    return axios.get(url,{headers: this.headers});

  }

  byId(token, id) {

    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.api+'detail?id='+id;
    
    return axios.get(url,{headers: this.headers});

  }

  update(token, info, permissions) {
    
    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'modify', 
        data = {
          ...info,
          permissions: permissions
      }

    return axios.request({method:'patch', url:url, headers: this.headers, data:data});

  }

}

export default ProfilesApi;