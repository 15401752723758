import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {appVersion} from "../../variables/globals";

// handle of languages
import { Translation } from 'react-i18next';

const footerStyle = {
  footer: {
    bottom: "0",
    padding: "0px 0",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
    lineHeight: "1.5em"
  },
  a: {
    color: "#3f51b5",
    textDecoration: "none",
    backgroundColor: "transparent",
    '&:hover,&:focus': {
      color: "#3f51b5"
    }
  },
  container: {
  	paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    paddingBottom: "0px"
  }
};

class Footer extends Component {

	render(){

		const { classes, patch } = this.props;

		return (

      <Translation>
       {
        (t, { i18n }) => {
          return (
    			 <footer className={classes.footer}>
    		      <div className={classes.container}>
    		        {(patch !== '/')?<p>
    			        <span>
    			            &copy; {1900 + new Date().getYear()}{" "}
    			            <a href="https://linc.io" target="_blank" rel="noopener noreferrer" className={classes.a}>
    			              {t('footer.copyright')}
    			            </a> {t('footer.version')} {appVersion}
    			        </span>
    			       </p>:''}
    		      </div>
    		    </footer>
           )
          }
         }
       </Translation>
		);

	}

}

export default withStyles(footerStyle)(Footer);