// Models
import { getMessage, statusMessage } from './global.js';
import store from '../store';
// Api
import NewsApi from "../../api/news/NewsApi";;
const Api = new NewsApi();
/* ================= Actions types ================ */
export const GET_NEWS = 'GET_NEWS';
export const GET_NEW = 'GET_NEW';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const ADD_NEW = 'ADD_NEW';
export const EDIT_NEW = 'EDIT_NEW';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_SEARCH = 'CHANGE_SEARCH';
export const SAVE_NEW = 'SAVE_NEW';
export const ACTION = 'ACTION';
/* ================= Actions creators ================ */
const changePage = (page) => {
    return { type: CHANGE_PAGE, payload: page }
}
const changeSearch = (page) => {
    return { type: CHANGE_SEARCH, payload: page }
}
const getNews = (token, limit, page, search, status) => {
    return dispatch => {
        return Api.getNews(token, limit, page, search, status).then(response => {
            dispatch({ type: GET_NEWS, 'payload': response.data.data.data });
        }).catch((err) => {
            Api.closeSession(err)
        });
    }   
}
const getNew = (id) => {
    return { type: GET_NEW, payload: id }
}
const changeStatusNew = (status) => {
    return { type: CHANGE_STATUS, payload: status }
}
const addNew = (datasNew) => {
    return { type: ADD_NEW, payload: datasNew }
}
const saveNew = (token, datas, img='') => {
    return dispatch => Api.saveNew(token, datas)
        .then( response => response.data.data.data)
        .then( json => {
            dispatch(getMessage(json.message,json.className));
            dispatch(statusMessage(true));
            Api.saveIMG(token,img.file,json.id)
               .then( response => response.data.data.data )
               .then( response => {
                   dispatch(getMessage(json.message,json.className));
                   dispatch(statusMessage(true));
                   const news = getNews(token,store.getState().news.limit,store.getState().news.page,store.getState().news.search,store.getState().news.status);
                   dispatch(news);
               })
               .catch(err => {
                    Api.closeSession(err)
                })  
        })
        .catch(err => {
            Api.closeSession(err)
        }) 
}
const deleteNew = (token, id, limit, page, search, status) => {
    return dispatch => {
        return Api.deleteNew(token, id, limit, page, search, status).then(response => {
            const { message,className } = response.data.data.data;
            dispatch(getMessage(message,className));
            dispatch(statusMessage(true));
            dispatch({ type: GET_NEWS, 'payload': response.data.data.data });
        }).catch(err => {
            Api.closeSession(err)
        })
    }
}
const updateNew = (token, datas, img) => {
    return dispatch => {
        return Api.updateNew(token, datas)
            .then(response => response.data.data.data
            )
            .then(json => {
                dispatch(getMessage(json.message,json.className));
                dispatch(statusMessage(true))
                if (json.className === 'success' && img.file !== ''){
                    Api.saveIMG(token,img.file,datas.id)
                        .then( response => response.data.data.data
                        )
                        .then( json => {
                            if (json.className === 'success'){
                                dispatch(getMessage(json.message,json.className));
                                dispatch(statusMessage(true));
                                const news = getNews(token,store.getState().news.limit,store.getState().news.page,store.getState().news.search,store.getState().news.status);
                                dispatch(news); 
                            }
                        })
                        .catch( err => {
                            Api.closeSession(err)
                        })               
                }else{
                    const news = getNews(token,store.getState().news.limit,store.getState().news.page,store.getState().news.search,store.getState().news.status);
                    dispatch(news); 
                }   
            }).catch( err  => {
                Api.closeSession(err)
            })
    }
}

export {
    getNews,
    getNew,
    changeStatusNew,
    addNew,
    saveNew,
    updateNew,
    deleteNew,
    changePage,
    changeSearch
}