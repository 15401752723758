import axios from 'axios';
import BaseApi from "../BaseApi";
import UserProfile from "../../models/UserProfile";

const userProfile = new UserProfile();

class LessonsApi extends BaseApi {

  constructor(){
    super();
    this.limit = 10;
    this.api = this.url + 'lessons/';
  }
  
  search(token, universityId, page, textToSearch, status, type) {

    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.api+'search?universityId='+universityId+'&limit='+this.limit+'&skip='+(page * this.limit);

    if (textToSearch !== '') url = url+'&search='+textToSearch.trim();
    if (status !== '') url = url+'&status='+status;
    if (type !== '' && typeof type !== 'undefined') url = url+'&type='+type;
    
    return axios.get(url,{headers: this.headers});

  }

  getDetail(token, universityId, id) {

    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.api+'getdetail?universityId='+universityId+'&id='+id;
    
    return axios.get(url,{headers: this.headers});

  }

  end(token, universityId, id) {

    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.api+'end',
        data = {
          universityId:universityId,
          lessonId: parseInt(id, 10)
        };

    return axios.request({method:'patch', url:url, headers: this.headers, data:data});

  }

}

export default LessonsApi;