import React, { useState, useEffect  } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import AlertMessage from "../../../components/Modal/AlertMessage";
// handle of languages
import { Translation } from 'react-i18next';

// Api
import CategoriesApi from "../../../api/Subjects/CategoriesApi";
import TutorsApi from "../../../api/Users/TutorsApi";

// Models
import UserProfile from "../../../models/UserProfile";
// import {styles} from "../../assets/jss/modules/mainStyles";

const Api = new CategoriesApi();
const Api2 = new TutorsApi();
const userProfile = new UserProfile();
let alertMessage = '';
let alertClassName = 'success';

export default function RemoveAssignment({classes, eventClose}) {

  const [categories, setCategories] = useState([]);

  const [categoryId, setCategoryId] = useState("n");
  const [understood, setUnderstood] = useState(false);
  const [enableBtnDelete, setEnableBtnDelete] = useState(true);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);

  const updateCheck = (e) => {
  
    if (categoryId !== 'n' && !understood) {
      setEnableBtnDelete(false);
    } else {
      setEnableBtnDelete(true);
    }

    setUnderstood(!understood);

  }

  const updateCategory = e => {
    
    if (e.target.value !== 'n' && understood) {
      setEnableBtnDelete(false);
    } else {
      setEnableBtnDelete(true);
    }

    setCategoryId(e.target.value);
  
  };

  const getCategories = () => {

    Api.show(userProfile.getToken()).then(response => {
         
      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
        
      if (responseData.modal) {
         console.log(responseData.msg);
      } else {
         setCategories(responseData.data);
      }

    }).catch((err) => {Api.closeSession(err)});
    
  };

  const removeAssignedSubjects = (catId) => {

    setUnderstood(true);

    Api2.removeAssignedSubjects(userProfile.getToken(), catId).then(response => {
         
      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
        
      if (responseData.modal) {

        alertMessage = responseData.message;
        alertClassName = responseData.data.className; 
        setOpenAlertMessage(true);
        eventClose();
   
      } else {
        console.log(responseData.message);
      }

      setUnderstood(false);

    }).catch((err) => {setUnderstood(false);Api.closeSession(err)});
    
  };

  const clear = (t, e) => {
    
    if (categoryId === 'n') {

      alertMessage = t('configuration.cntBulkDelete.modalSubjectsAssigned.warningAlert');
      alertClassName = 'warning'; 
      setOpenAlertMessage(true);
    
    } else {

      removeAssignedSubjects(categoryId);

    }
  
  };

  const closeMessage = (event) => {
    setOpenAlertMessage(false);
  };
  
  useEffect(() => {

    getCategories();

 }, [])

  return (
    <Translation>
      {
        (t, { i18n }) => {

          return (
            <React.Fragment> 
              <Grid container className={classes.container}>
                <Grid item className={classNames(classes.grid)} xs={12} sm={12} md={12}>
                  <Typography variant="body1" gutterBottom>
                    {t('configuration.cntBulkDelete.modalSubjectsAssigned.firstParagraph')}
                  </Typography>
                  <div>
                    <FormControl fullWidth={true} className={classes.formControl} style={{marginTop: 10}}>
                      <Select
                        value={categoryId}
                        inputProps={{
                          name: 'categoryId',
                          id: 'categoryId',
                          onChange:(updateCategory)
                        }}
                      >
                        <MenuItem value="n">
                          <em>{t('configuration.cntBulkDelete.modalSubjectsAssigned.categorySelect.defaultValue')}</em>
                        </MenuItem>
                        {categories.map((i, k) => {
                          return (
                              <MenuItem value={i.id} key={k}>{i.name}</MenuItem>
                            )
                        })}
                        <MenuItem value="all" key={-1}>{t('configuration.cntBulkDelete.modalSubjectsAssigned.categorySelect.allOptions')}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{marginTop: 15}}>
                    <FormControlLabel
                        style={{display:'flex',alignItems: 'flex-start'}}
                        control={
                          <Checkbox
                            checked={understood}
                            onChange={e => updateCheck(e)}
                            classes={{
                              root: classes.customCheck,
                              checked: classes.checked,
                            }}
                            style={{paddingTop: 0}}
                          />
                        }
                        label={t('configuration.cntBulkDelete.modalSubjectsAssigned.noticeCheck')}
                        
                    />
                  </div>
                </Grid>
                <Grid item className={classNames(classes.grid)} style={{marginTop: 25, textAlign: 'right'}} xs={12} sm={12} md={12}>
                  <Button variant="contained" onClick={eventClose} style={{marginRight: 12}}>{t('configuration.cntBulkDelete.modalSubjectsAssigned.buttons.cancel')}</Button>
                  <Button 
                    variant="contained" 
                    style={{backgroundColor:(!enableBtnDelete?'rgb(220, 0, 78)':'rgba(0, 0, 0, 0.12)'), color: (!enableBtnDelete?'#fff':'rgba(0, 0, 0, 0.26)')}} 
                    disabled={enableBtnDelete}
                    onClick={e => clear(t, e)}
                  >
                  {t('configuration.cntBulkDelete.modalSubjectsAssigned.buttons.confirm')}
                  </Button>
                </Grid>
              </Grid>  
              <AlertMessage open={openAlertMessage} variant={alertClassName} message={alertMessage} eventClose={closeMessage} />
            </React.Fragment>              
          );

        }
     }
    </Translation>
  );
}