import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
// import firebaseInit from '../../firebaseInit';
import axios from 'axios';

let currentToken = '';
let lessonId = 298;
let universityId = 10001;

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  input: {
    margin: theme.spacing(1),
  }
});

class Chat extends Component {

  constructor(){
    super();

    this.updateMessage = this.updateMessage.bind(this);
    this.submitMsg = this.submitMsg.bind(this);

    this.state = {
      message: '',
      messages: []
    };

  }

  componentWillMount(){ // before render (action)
   
    /*firebaseInit.auth().onAuthStateChanged(async (user) => {
      
      if (user) {
        
        console.log("USER IS LOG IN");*/
        /*firebaseInit.auth().currentUser.getIdToken(true).then(function(idToken) {
          console.log(idToken);
        }).catch(function(error) {
           console.log("Error to get token");
        });*/

      /*firebaseInit.auth().signOut().then(function() {
          console.log("Sign-out successful.");
        }).catch(function(error) {
          console.log(error);
      });*/

        /*this.getMessages();
 
      } else {
        
         console.log("USER IS NOT LOGIN");
         
         await this.getFirebiseToken();

        firebaseInit.auth().setPersistence(firebaseInit.auth.Auth.Persistence.LOCAL).then(() => {

              firebaseInit.auth().signInWithCustomToken(currentToken)
              .then((customToken) => {

                  this.getMessages();
              
              }).catch(function(error) {
                
                  console.log(error);
              
              });

          }).catch(function(error) {
            console.log(error);
          });

      }

    });*/

    /*this.authListener = this.authListener.bind(this);
    this.authListener();*/

  }

  componentDidMount(){ // after render (action)

    /*firebaseInit.database().ref("chat/").orderByChild("lessonId").equalTo(1).on("value", function(snapshot) {
    console.log(snapshot.val());
    });*/

  }

  getMessages(){

      /*firebaseInit.database().ref("chats/").child(universityId).child(lessonId).on('value', snapshot => {
     
        var currentChat = snapshot.val();

        if (currentChat != null) {

            let messagesVal = currentChat;
            let messages = Object.keys(messagesVal).map(messageKey => {
                              let cItem = messagesVal[messageKey];
                              cItem.key = messageKey;
                              return cItem;
                            });
   
            this.setState({messages: messages});
        }

      });*/
  }

  async getFirebiseToken(){

    let cToken = 'I2002JUnn1RMaseL45ExIylk0uGjXrIp8DbvGmPvHXp6TpXEg3893W5i0C34V3z0';
    
    return axios.get('http://localhost:3000/api/users/chat', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': cToken
        }
    }).then(response => {
       
       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
       currentToken = responseData.data.tokenFirebase;
       
    }).catch(err => console.log(err));

  }

  updateMessage(e){
    this.setState({message: e.target.value.trim()});
  }

  submitMsg(){

    let cd = (new Date().getTime()) * -1;
    
    let msg = {
      _id: cd,
      avatar: "https://media.linc.mx/images/universities/app/profile/student.jpg",
      createdAt: cd,
      name: "Jonathan Sosa",
      order: cd,
      profile: "student",
      text: this.state.message,
      uid: "124"
    };

    // firebaseInit.database().ref('chats/').child(universityId).child(lessonId).push(msg);
    
    let allMessages = this.state.messages;
    allMessages.push(msg);
    this.setState({messages: allMessages, message: ''});

  }

  render() {

    const { classes } = this.props;

    const allMessages = this.state.messages.map((msg, i) => {
          return (
              <li key={i} className={classes.listSection}>
                {msg.text}
              </li>
          )
    });

    return (
      
      <div>
        <h1>Chat</h1>
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary">
              Tutoria #7676767
            </Typography>
            <List className={classes.root}>
            {allMessages}
          </List>
          </CardContent>
          <CardActions>
              <Input
                value={this.state.message}
            placeholder="Placeholder"
            className={classes.input}
            inputProps={{
              'aria-label': 'Message',
            }}
            onChange={this.updateMessage}
          /> 
            <Button variant="contained" color="secondary"  onClick={this.submitMsg} className={classes.button}>
            Enviar
          </Button>
          </CardActions>
        </Card>
      </div>

    );

  }

}

export default withStyles(styles)(Chat);
