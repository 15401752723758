class common {

    // constructor(){}
  
    getStartUrl(items){

        let url = "";
      
        for (var k in items) {
    
          if (items[k].url !== "") {
             url = items[k].url; 
             break;
          } else if (items[k].subSections.length > 0) {
            url = this.getStartUrl(items[k].subSections);
            if (url !== "") break;
          }
    
        }
    
        return url;
    
    }
  
  }
  
  export default common;