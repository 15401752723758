import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
//Material ui
import { withStyles } from '@material-ui/core/styles';
import { Grid, Icon, Typography, CircularProgress, SnackbarContent } from '@material-ui/core';
// Styles
import styles from "../../../assets/jss/modules/loginStyles";
// handle of languages
import { Translation } from 'react-i18next';

const Msg = ({ status, classes, loading, message, clear }) => {

    const [timer, setTimer] = useState('');

    useEffect( () => {
        if (message !== '' || loading){
            clearTimeout(timer);
        	setTimer(setTimeout(() => {
                clear();
        	},4000));
        }
    },[message]);
    
    return <Translation>
        { 
            (t, { i18n }) => {
            	return <Grid item className={classNames(classes.grid, classes.center)} 
                 style={{marginTop:25}} xs={12} sm={12} md={12}>
                    { loading ? <CircularProgress className={classes.progress} /> : ''}
                    { message !== '' ? <SnackbarContent 
                    className={classNames(classes.snackbar, classes.warning, 'msg-alert')}
                    message={
                        <span className={classes.message}>
                            <Icon className={classNames(classes.icon, 'fa fa-exclamation-triangle')} />
                            <Typography variant="body2" className={classes.marginLeftTitle} color="inherit">{t(message)}</Typography>
                        </span>
                    } /> : ''}
               </Grid>
            }
        }
    </Translation>

}

export default withStyles(styles, { withTheme: true })(Msg);