import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Translation } from 'react-i18next';

const styles = {
  homePictureLan: {
     position: 'absolute',
     transform: 'translate(-50%, -50%)',
     left: '50%',
     top: '50%',
     width: '80%',
     maxWidth: '900px'
  },
  homePicturePOrt: {
     position: 'absolute',
     transform: 'translate(-50%, -50%)',
     left: '50%',
     top: '50%',
     maxWidth: '80%',
     maxHeight: '90%'
  }
};

class Home extends Component {

  componentDidMount(){
  }

  render() {

    return (
      
      <div>
        <Translation>
		    {
		        (t, { i18n }) => {
		        	
		        	return (
                  <div>
                     {}
                  </div>
                )
	        }
	      }
	    </Translation>
        
      </div>

    );

  }

}

export default withStyles(styles, { withTheme: true })(Home);
