import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { Alert, AlertTitle } from '@material-ui/lab';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@mui/material/Autocomplete';

import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Avatar from '@material-ui/core/Avatar';

// Libs
import ReeValidate from 'ree-validate';

// handle of languages
import { Translation } from 'react-i18next';

import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";

// Components
import CardHeader from "../../components/Card/CardHeader";
import CustomInput from "../../components/Inputs/CustomInput";
import AlertMessage from "../../components/Modal/AlertMessage";
import CustomSearcher from "../../components/Inputs/CustomSearcher";
import ActiveCheck from "../../components/Checked/ActiveCheck";
import SelectsSchedule from "./components/SelectsSchedule";
import AlertDelete from "../../components/Modal/AlertDelete";
import IOSSwitch from '../../components/Checked/IOSSwitch';

// Material ui v5
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

// Api
import TutorsApi from "../../api/Users/TutorsApi";

// Models
import UserProfile from "../../models/UserProfile";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

const Api = new TutorsApi();
const userProfile = new UserProfile();

var moment = require('moment-timezone');

let titleKey = 'titleAdd';
let titleIcon;
let alertMessage = '';
let alertClassName = 'success';
let showAlert = false;
let searchText = '';
let filterType = 'all';
let filterModality = 'all';
let filterCategory = 0;
let allFormData = {};
let cStatus = '';

const top100Films = [
	{ title: 'The Shawshank Redemption', year: 1994 },
	{ title: 'The Godfather', year: 1972 },
	{ title: 'The Godfather: Part II', year: 1974 }
  ];

class Tutor extends Component {

   constructor(){
	  
	 super();

	this.validator = new ReeValidate.Validator({
      name: 'required',
      lastName: 'required',
      email: 'required',
      password: 'required|min:6'
    });

  // this.validator.localize('es', es);

	this.state = {
	 	formData: {
	      id: 0,
	  	  name: '',
	      lastName: '',
	      email: '',
	      phone: '',
	      status: true,
	      gender: 'F',
	      birthday: null, 
	      careerId: 0,
	      // periodId: '',
	      confirmed: true,
	      isTutor: true,
	      password: '',
	      description: '',
		  vacationMode: false,
		  sameCampus: false
	    },
	    disabledButton: false,
	    errors: this.validator.errors,
		currentTab: 'one',
		openAlert: false,
	    openAlertMessage: false,
		categories: [],
	    subjects: [],
	    selectedSubjects: [],
	    domains: [{id:1, name: ''}],
	    activedSchedules: false,
	    schedules: {
			Lunes: [
				{
					id: "",
					from: "09:00",
					to: "18:00",
					status: 1
				}
			],
			Martes: [
				{
					id: "",
					from: "09:00",
					to: "18:00",
					status: 1
				}
			],
			Miercoles: [
				{
					id: "",
					from: "09:00",
					to: "18:00",
					status: 1
				}
			],
			Jueves: [
				{
					id: "",
					from: "09:00",
					to: "18:00",
					status: 1
				}
			],
			Viernes: [
				{
					id: "",
					from: "09:00",
					to: "18:00",
					status: 1
				}
			],
	    	Sabado: [],
	    	Domingo: []
	    },
      schedulesTo:{
        Lunes: ["09:00"],
		Martes: ["09:00"],
		Miercoles: ["09:00"],
		Jueves: ["09:00"],
		Viernes: ["09:00"],
        Sabado: [],
        Domingo: []
      },
	    weekDay: 'Lunes',
	    Lunes: true,
	    Martes: true,
	    Miercoles: true,
	    Jueves: true,
	    Viernes: true,
	    Sabado: false,
	    Domingo: false,
	    domain: '',
      disabledDisponibility: true,
	  customSchedules: [],
	  schedulesBySubject: []
    };

  }

  formatDate = (date) => { // Format to api
	date = new Date(date);
	date = date.getFullYear()+"-"+((date.getMonth() + 1) < 10?"0"+(date.getMonth() + 1):(date.getMonth() + 1))+"-"+(date.getDate() < 10?"0"+date.getDate():date.getDate());
	return date;
  }

  getRealDate = (date) =>{ // Get real date
	date = new Date(date);
	date = date.setDate(date.getDate() + 1);
	return date;
  }

  getOneRow = (id) => {
      
	  Api.getById(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   id).then(response => {
            
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
           
	       if (responseData.modal) {
	           this.props.history.push("/users/tutors");
	       } else {

	       	   if (responseData.data.row.vacationMode || !responseData.data.activedSchedules || responseData.data.selectedSubjects.length === 0) {
                  showAlert = true;
	       	   } else{
	       	   	  showAlert = false; 
	       	   }

             let cSchedulesTo = {};

             if (Object.keys(responseData.data.schedules).length > 0) {

                  for (var day in responseData.data.schedules) {
                      cSchedulesTo[day] = (typeof cSchedulesTo[day] !== 'undefined')?cSchedulesTo[day]:[]; 
                      for (var h in responseData.data.schedules[day]) {
                           cSchedulesTo[day][h] = responseData.data.schedules[day][h]['from'];  
                      }
                 
                  }

			 }
			 
			   cStatus = responseData.data.row.status;
	       	   this.setState({
	       	   	   formData:{...responseData.data.row, birthday: responseData.data.row.birthday!==''?this.getRealDate(responseData.data.row.birthday):null}, 
				   categories:responseData.data.categories,
	       	   	   subjects:responseData.data.subjects,
	       	   	   selectedSubjects:responseData.data.selectedSubjects,
                   schedulesTo: cSchedulesTo,
	       	   	   schedules: responseData.data.schedules,
	       	   	   activedSchedules:responseData.data.activedSchedules,
	       	   	   Lunes: (typeof responseData.data.schedules.Lunes !== 'undefined' && responseData.data.schedules.Lunes[0].status === 1)?true:false,
	       	   	   Martes: (typeof responseData.data.schedules.Martes !== 'undefined' && responseData.data.schedules.Martes[0].status === 1)?true:false,
	       	   	   Miercoles: (typeof responseData.data.schedules.Miercoles !== 'undefined' && responseData.data.schedules.Miercoles[0].status === 1)?true:false,
	       	   	   Jueves: (typeof responseData.data.schedules.Jueves !== 'undefined' && responseData.data.schedules.Jueves[0].status === 1)?true:false,
	       	   	   Viernes: (typeof responseData.data.schedules.Viernes !== 'undefined' && responseData.data.schedules.Viernes[0].status === 1)?true:false,
	       	   	   Sabado: (typeof responseData.data.schedules.Sabado !== 'undefined' && responseData.data.schedules.Sabado[0].status === 1)?true:false,
	       	   	   Domingo: (typeof responseData.data.schedules.Domingo !== 'undefined' && responseData.data.schedules.Domingo[0].status === 1)?true:false,
	       	   	   domains:responseData.data.domains,
	       	   	   domain:responseData.data.row.email.split("@")[1],
				   disabledDisponibility:!responseData.data.row.disabledDisponibility,
				   sameCampus: responseData.data.sameCampus,
				   customSchedules:responseData.data.customSchedules,
				   schedulesBySubject:responseData.data.schedulesBySubject
	       	   	});

	       }

      }).catch((err) => {Api.closeSession(err)});
    
  };

  filterSearch = (text) => {
      
	  Api.filterSearch(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   text,
           filterType,
		   filterModality,
		   filterCategory).then(response => {
            
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

       	   this.setState({subjects:responseData.data.subjects});

      }).catch((err) => {Api.closeSession(err)});
    
  };

  getCareers = () => {
      
	  Api.getCareers(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId).then(response => {

	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
	       	
	       this.setState({
				categories:responseData.data.categories,   
	       	    subjects:responseData.data.subjects,
	       	    domains:responseData.data.domains,
	       	    domain:responseData.data.domains[0].name
	       	});

      }).catch((err) => {Api.closeSession(err)});
    
  };

  save = (data) => {
      
	  Api.save(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   data).then(response => {
           
           this.handleLoading();
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

	       if (responseData.modal) {
	       	   userProfile.addAlert({msg: responseData.message, class: responseData.data.className});
	           this.props.history.push("/users/tutors");
	       } else {
				if(data.birthday===''){
					this.setState({formData:{...data, birthday: null}});
				}
	       	    this.showAlert(responseData);
	       }

      }).catch((err) => {this.handleLoading();Api.closeSession(err)});
    
  };

  update = (data) => {
      
	  Api.update(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   data).then(response => {
           
           this.handleLoading();
	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

	       if (responseData.modal) {

				if(data.birthday===''){
					this.setState({formData:{...data, birthday: null}});
				}
               
			    if (responseData.data.className === 'warning') {
				  this.showAlert(responseData);
			    } else {

				userProfile.addAlert({msg: responseData.message, class: responseData.data.className});
	           
				if (!data.isTutor) {
				   this.props.history.push("/users/students");
				} else { this.props.history.push("/users/tutors"); }

			   }

	       } else {
				if(data.birthday===''){
					this.setState({formData:{...data, birthday: null}});
				}
	       	    this.showAlert(responseData);
	       }

      }).catch((err) => {this.handleLoading();Api.closeSession(err)});
    
  };

  submit = (formData, tr) => {

	if (formData.birthday !== null && !moment(formData.birthday).isValid()) {

		this.showAlert({message: tr('users.form.tutor.alerts.birthdayIncorrect'), data: {className: 'warning'}});
	
	} else {
    
		this.handleLoading();

		formData.email = formData.email+'@'+this.state.domain;

		formData.subjects = this.state.selectedSubjects;
		formData.schedules = this.state.schedules;
		formData.disabledDisponibility = !this.state.disabledDisponibility;
		// formData.tags = [3, 2, 'Tag5', 'Tag6'];
		
		if (this.state.formData.id > 0) {
			formData.id = this.state.formData.id;
			if (!formData.status && cStatus !== formData.status) {
				allFormData = formData;
				this.setState({ openAlert: true });
			} else {
				formData.birthday = formData.birthday !== null?this.formatDate(formData.birthday):'';
				this.update(formData);
			}
		} else {
			formData.birthday = formData.birthday !== null?this.formatDate(formData.birthday):'';
			this.save(formData);
		}

    }
   
  };

  validateAndSubmit = (e, tr) => {

	    e.preventDefault()
	    
	    const { formData } = this.state;
	    const { errors } = this.validator;

	    this.validator.validateAll(formData)
	      .then(success => {
	        if (success) {
	          this.submit(formData, tr)
	        } else {
	          this.setState({ errors })
	        }
	      })
    
  }

  handleChange = e => {

		const name = e.target.name;
	    let value = e.target.value;
	    const { errors } = this.validator;
	    
	    errors.remove(name);

	    if (name === 'email') {
	       value = value.split(' ').join('');
	    }

	    this.setState({ formData: { ...this.state.formData, [name]: value } });
   
  };

  birthdayChangeHandle = (newValue) => {
	this.setState({ formData: { ...this.state.formData, birthday: newValue } });
  };

  showAlert = (data) => {

	  	alertMessage = data.message;
	    alertClassName = data.data.className; 
	    this.setState({ openAlertMessage: true });

  };

  handleLoading = () => {
    this.setState({ disabledButton: !this.state.disabledButton });
  };

  activeAsTutor = name => event => {
    this.setState({ formData: { ...this.state.formData, [name]: event.target.checked } });
  };

  activeAccount = name => event => {
    this.setState({ formData: { ...this.state.formData, [name]: event.target.checked } });
  };

  closeAlert = () => {
	this.setState({ openAlert: false });
	this.handleLoading();
  };

  closeMessage = (event, reason) => {
    this.setState({ openAlertMessage: false });
  };

  changeTab = (event, currentTab) => {
    this.setState({ currentTab });
  };

  selectSubject = (cSubject, t) => () => {

	    let cSubjects = [...this.state.subjects];
	    const stedSubs = [...this.state.selectedSubjects];
	    let exitsSubject = false;

	    for (var k in stedSubs) {
	    	if (stedSubs[k].id === cSubject.id) 
	    		exitsSubject = true;
	    }

	    if (exitsSubject) {

	    	this.showAlert({message: t('users.form.tutor.alerts.subjectAlreadyAssigned'), data: {className: 'warning'}});

	    } else {

	    	stedSubs.push(cSubject);
	    	cSubjects = cSubjects.filter((e, i) => {
	    		return e.id !== cSubject.id
	    	});
	    	this.setState({selectedSubjects: stedSubs, subjects: cSubjects});

	    }

  };

  searchRows = (e, search) => {
    searchText = search.value;
    this.filterSearch(search.value);
  };

  filterByType = (e) => {
    filterType = e.target.value;
    this.filterSearch(searchText);
  };

  filterByModality = (e) => {
    filterModality = e.target.value;
    this.filterSearch(searchText);
  };

  filterByCategory = (e) => {
    filterCategory = e.target.value;
    this.filterSearch(searchText);
  };

  deleteSubject = data => () => {

	  	let cSubjects = [...this.state.subjects];
	  	let exitsSubject = false;

	    for (var k in cSubjects) {
	    	if (cSubjects[k].id === data.id) 
	    		exitsSubject = true;
	    }

	    if (!exitsSubject) {
	       cSubjects.push(data);
	       this.setState({subjects: cSubjects});
	    }

	    this.setState(state => {
	      const selectedSubjects = [...state.selectedSubjects];
	      const SubjectToDelete = selectedSubjects.indexOf(data);
	      selectedSubjects.splice(SubjectToDelete, 1);
	      return { selectedSubjects };
	    });

  };

  filterByStatus = (event, tab) => {
    this.setState({weekDay: tab});
  };

  activeDay = (name, event) => {

    let allSchedules = this.state.schedules;

    if (typeof allSchedules[name] !== 'undefined') {
        
        allSchedules[name] = allSchedules[name].map((v, i) => {
          
            v.status = (event.target.checked)?1:0;
            return v;

        });

    }

  	this.setState({ [name]: event.target.checked, schedules: allSchedules});
  
  };

  changeDomain = e => {

	  const name = e.target.name;
      const value = e.target.value;

      this.setState({ [name]: value });
   
  };

  changeDisponibility = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  addSchedule = e => {
      
  	  let selectedSchedules = this.state.schedules, 
          currentDay = this.state[this.state.weekDay],
          schedulesTo = this.state.schedulesTo;
          currentDay = (currentDay)?1:0; 
      const schedules = selectedSchedules[this.state.weekDay] || [];
      
      if (typeof selectedSchedules[this.state.weekDay] !== 'undefined') {

      	  if (schedules.length < 10) {
      	  let hour = 6,
      	      minutes = '00',
      	      timeFrom = '',
			  timeTo = '',
			  increment = 5;

      	      // while(`${hour<10?'0'+hour:hour}:${minutes}` !== '23:30'){
				while(`${hour<10?'0'+hour:hour}:${minutes}` !== '23:05'){  		
      	      	  
                  const timeExist = selectedSchedules[this.state.weekDay].filter(time => (parseInt(`${hour<10?'0'+hour:hour}:${minutes}`.replace(/:/g,'')) >= parseInt(time.from.replace(/:/g,''))) &&
                  	                                                                   (parseInt(`${hour<10?'0'+hour:hour}:${minutes}`.replace(/:/g,'')) <= parseInt(time.to.replace(/:/g,''))) );
	              
                  if (timeExist.length === 0) {
                  	  break;
                  }else{

					  if (minutes==='55' || minutes===55) {
						minutes='00';
						hour += 1;
					  } else {
                        minutes = (parseInt(minutes)+increment)<10?'0'+(parseInt(minutes)+increment):parseInt(minutes)+increment;
					  }
					  
                      /*if (minutes==='30') {
                  	     minutes='00';
                  	     hour += 1;
                      }else{
                  	     minutes = '30';
                      }*/
                  }
			   }
				
      	      timeFrom = `${hour<10?'0'+hour:hour}:${minutes}`;
			  /*if (minutes==='30'){
      	      	  timeTo = `${(hour+1)<10?'0'+(hour+1):(hour+1)}:00`;
              } else{
      	      	  timeTo = `${hour<10?'0'+hour:hour}:30`;
			  }*/

			  if (minutes==='55' || minutes===55){
				timeTo = `${(hour+1)<10?'0'+(hour+1):(hour+1)}:00`;
			  } else{
			    timeTo = `${hour<10?'0'+hour:hour}:${(parseInt(minutes)+increment)<10?'0'+(parseInt(minutes)+increment):parseInt(minutes)+increment}`;
			  }
			  
              if (timeFrom === '23:00'){
                  selectedSchedules[this.state.weekDay].push({id: "", from: timeFrom, to: timeFrom, status: currentDay});
                  schedulesTo[this.state.weekDay].push(timeFrom);
              // } else if(timeFrom !=='23:30'){
			  } else if(timeFrom !=='23:05'){
                  selectedSchedules[this.state.weekDay].push({id: "", from: timeFrom, to: timeTo, status: currentDay});
                  schedulesTo[this.state.weekDay].push(timeFrom);
			  }
			  
          }
      } else {
		  // selectedSchedules[this.state.weekDay] = [{id: "", from: "06:00", to: "06:30", status: currentDay}];
		  selectedSchedules[this.state.weekDay] = [{id: "", from: "06:00", to: "06:05", status: currentDay}];
          schedulesTo[this.state.weekDay] = ["06:00"];
	  }
    
      this.setState({schedules: selectedSchedules, schedulesTo: schedulesTo});
   
  };

  changeSchedule = (e,last) => {
	  const name = e.target.name;
	  let value = e.target.value;
	  let selectedSchedules = this.state.schedules;
	  let elemSplit = name.split('-'), increment = 5;

	  selectedSchedules[elemSplit[0]][elemSplit[1]][elemSplit[2]] = value;
    
	  this.setState({schedules: selectedSchedules});

	  if (elemSplit[2] === 'from'){
	  	    let hour = parseInt(value.slice(0,2)),
      	    minutes = value.slice(3,5);
	  	    value = parseInt(value.replace(/:/g,''));
	  	    if (!(value > last && value < parseInt(selectedSchedules[elemSplit[0]][elemSplit[1]]['to'].replace(/:/g,'')))) {
	  	    	/*if (minutes==='30'){
      	      	    minutes = '00';
      	      	    hour += 1;
                }
                else{
                    minutes = '30';
				}*/
				if (minutes==='55' || minutes===55) {
				  minutes='00';
				  hour += 1;
				} else {
				  minutes = (parseInt(minutes)+increment)<10?'0'+(parseInt(minutes)+increment):parseInt(minutes)+increment;
				}
                selectedSchedules[elemSplit[0]][elemSplit[1]]['to'] = `${hour<10?'0'+hour:hour}:${minutes}`;
	  	    }
	  	    
      	    
	  }

  } 

  deleteSchedule = index => {
 
     let schedules = this.state.schedules;
    if (typeof schedules[this.state.weekDay] !== 'undefined') {
        schedules[this.state.weekDay] = schedules[this.state.weekDay].filter((v, k) => {
            return k !== index;
        });

    }  

     this.setState({schedules:schedules})
  }

  deleteRow = () => {
	this.setState({ openAlert: false });
	allFormData.email = allFormData.email+'@'+this.state.domain;
	allFormData.birthday = allFormData.birthday !== null?this.formatDate(allFormData.birthday):'';
	this.update(allFormData);
  };

  returnBgColor(type) {
    let color = '#2288fc';
    if (type === 'Grupal')
       color = '#F5A623';  
    return color;
  }

  returnLimit(type, limit) {
    let limitString = '';
    if (type === 'Grupal')
       limitString = ' ('+limit+')';  
    return limitString;
  }

  returnIcon(modality) {
    let icon = 'icon-in-person';
    if (modality === 'Online')
       icon = 'icon-online';  
    return icon;
  }

  onChangeTag = (event , values, reason) => {
	if (reason === 'createOption') {
		console.log(values[values.length-1]);
	}
	
    // this.setState({selectedPlaces:value});
  };

  componentWillMount(){
  	    
  	  showAlert = false; 
	  	const id = this.props.match.params.id;

	  	if (id !== 'add') {
	  		
	  		titleKey = 'titleModify';
	  		titleIcon = 'fa fa-pen'
		  	
		  	if (isNaN(id)) {
  				 this.props.history.push("/users/tutors");
  			} else {
				this.validator = new ReeValidate.Validator({
					name: 'required',
					lastName: 'required',
					email: 'required'
				});
  				 this.getOneRow(id);
  			}

  	    } else {
  	    	titleKey = 'titleAdd';
  	    	titleIcon = 'fa fa-plus';
  	    	this.getCareers();
  	    }

  }

  render() {

  	const { classes, match } = this.props;
  	let {formData, categories, errors, currentTab, openAlertMessage, subjects, selectedSubjects, sameCampus, openAlert,
  		 weekDay, Lunes, Martes, Miercoles, Jueves, Viernes, Sabado, Domingo, schedules, domains, domain, activedSchedules, 
		 disabledDisponibility, customSchedules, schedulesBySubject} = this.state;

  	// if (formData.careerId === 0) formData.careerId = ""; 
  	if (formData.email !== "") {
  		let tempData = formData.email.split("@");
  		formData.email = tempData[0];
  		domain = (typeof domain !== 'undefined' && domain !== '')?domain:tempData[1];
    }
	

	let subjectSched = [];
	
	if (Object.keys(schedulesBySubject).length > 0) {

		for (const [key, row] of Object.entries(schedulesBySubject)) {
			subjectSched.push(<React.Fragment key={key}>
			<List className={classes.rootList} key={key} style={{maxWidth: 635}}>
			<ListItem>
			  <ListItemText primary={<React.Fragment>
				<span>{row.name}</span> 
				<span className={"sChipSpan "+(row.type=='Grupal'?'group':'individual')} style={{marginLeft:14}}>
					<i className={(row.modality==='Online'?'icon-online custom-icon-online':'icon-in-person custom-icon-in-person')}></i> {row.translatedModality}
				</span>
				<Link to={"/subjects/"+key+"/"+row.type} style={{marginLeft:3}} key={key} target="_blank" rel="noopener" rel="noreferrer">
                    <IconButton color="primary" style={{marginTop:0, color: '#3498db', display: 'inline-block'}}>
                          <VisibilityIcon />
                    </IconButton>
                </Link>
			</React.Fragment>} />
			</ListItem>
			<Divider component="li" variant="inset" style={{marginLeft: 14}} />
			<li>
				<Typography
				className={classes.dividerInset}
				color="textSecondary"
				display="block"
				variant="caption"
				>
				</Typography>
			</li>
			{row.days.map((day) => (
			<ListItem key={key+day.weekDay}>
				<ListItemAvatar>
				<Avatar>{day.weekDay.charAt(0)}</Avatar>	
				</ListItemAvatar>
				<ListItemText primary={day.weekDay} secondary={day.schedules} />
			</ListItem>))}
			</List>
			</React.Fragment>);

		}
	
    }
    
    return (
      
      <div>
			 <Translation>
	     {
	      (t, { i18n }) => {
	      	 return (
	      	   <React.Fragment>
								<form onSubmit={ e => this.validateAndSubmit(e, t)}>
									<Grid container className={classes.container}>
											<Grid item className={classes.grid} xs={12} sm={12} md={3}>
									
											<Grid item className={classNames(classes.gridHeader, classes.gridHeaderPadding)} xs={12} sm={12} md={12}>

													<Button color="default" component={Link} to="/users/tutors" className={classes.btnBack}>
														<span className={classNames(classes.iconBack, "fa fa-arrow-left")}></span> 
														{t('categories.form.back')}
													</Button>
										
											</Grid>
								
									</Grid>  
										<Grid item className={classes.grid} xs={12} sm={12} md={12}>
										<Card className={classes.cardContet}>
												<CardHeader className={classes.cardHeader}>
													<h4 className={classes.cardTitleWhite}>
														<span className={classNames(classes.iconBack, titleIcon)}></span> 
														{t('users.form.tutor.title.'+titleKey)}
													</h4>
													<p className={classes.cardCategoryWhite}>{t('categories.form.subtitle')}</p>
												</CardHeader>
													{showAlert ?
												<Grid container>
													<Grid item className="" xs={12} sm={12} md={12}>
														<Paper className={classNames(classes.cntAlertTutor)} elevation={1}>
																<Grid container className="">
																		<Grid item className={classNames(classes.centerContent)} xs={12} sm={12} md={2}>
																			<span className={classNames("fa fa-exclamation-triangle", classes.cntAlertTutorColor)} style={{fontSize: 35, padding: "15px 0"}}></span>
																		</Grid>
																		<Grid item className="" xs={12} sm={12} md={10}>
																		<Typography component="p" className={classes.messageTutorp}>
																		{t('users.form.tutor.alerts.dataMissing.title')}
																		</Typography>
																		<List style={{padding: 0, display: "inline-block", marginTop: 5}}>
																				{!activedSchedules &&
																<ListItem className={classes.listItemTutor}>
																	<ListItemIcon>
																	<span className="fa fa-times"></span>
																</ListItemIcon>
																<span className={classes.listItemTextTutor}>{t('users.form.tutor.alerts.dataMissing.reasons.withoutSchedules')}</span>
																</ListItem>}
																{formData.vacationMode &&
																<ListItem className={classes.listItemTutor}>
																	<ListItemIcon>
																	<span className="fa fa-times"></span>
																</ListItemIcon>
																	<span className={classes.listItemTextTutor}>{t('users.form.tutor.alerts.dataMissing.reasons.vacationMode')}</span>
																</ListItem>}
																{selectedSubjects.length === 0 &&
																<ListItem className={classes.listItemTutor}>
																	<ListItemIcon>
																	<span className="fa fa-times"></span>
																</ListItemIcon>
																	<span className={classes.listItemTextTutor}>{t('users.form.tutor.alerts.dataMissing.reasons.withoutSubjects')}</span>
																</ListItem>}
															</List>
														</Grid>
													</Grid>
											</Paper>
													</Grid>
													</Grid>
													: ''}
												<AppBar position="static" style={{backgroundColor: "#f5f5f5", color: "#333"}}>
													<Tabs value={currentTab} onChange={this.changeTab} indicatorColor="primary" textColor="primary">
														<Tab value="one" label={t('users.form.tabs.general.title')} />
														<Tab value="two" label={t('users.form.tabs.disponibility.title')} />
														<Tab value="three" label={t('users.form.tabs.subjects')} />
													</Tabs>
												</AppBar>
										{currentTab === 'three' &&
												<CardContent>

													<Grid container>
														<Grid item className={classes.grid} xs={12} sm={12} md={6}>
															<Typography variant="h6" style={{textTransform: 'capitalize', marginLeft: 5}}>{t('users.form.tutor.tabSubjects.titleSubjectsList')}</Typography>
															<Typography variant="caption" style={{color: '#9B9B9B', marginLeft: 5}} >{t('users.form.tutor.tabSubjects.subtitleSubjectsList')}</Typography>
														</Grid>
														<Grid item className={classes.grid} xs={12} sm={12} md={6}>
															<Typography variant="h6" style={{textTransform: 'capitalize'}}>{t('users.form.tutor.tabSubjects.titleAssignedSubjects')}</Typography>
															<Typography variant="caption" style={{color: '#9B9B9B'}}>{t('users.form.tutor.tabSubjects.subtitleAssignedSubjects')}</Typography>
														</Grid>
													</Grid>  

													<Grid container>

														<Grid item className={classes.grid} xs={12} sm={12} md={6}> 
															<Grid container>
																<Grid item className={classes.grid} xs={12} sm={12} md={12}>
																	<Grid container style={{marginTop:15}}>
																		<Grid item className={classes.grid} xs={12} sm={12} md={12}>
																		<Typography variant="subtitle2" style={{textTransform: 'none'}} gutterBottom>{t('users.form.tutor.tabSubjects.filterTitle')}</Typography>
																		</Grid>
																	</Grid>
																	<Grid container>
																		<Grid item className={classes.grid} xs={12} sm={12} md={4}>
																			<FormControl fullWidth={true} className={classes.formControl} style={{margin: '0 0 0 0'}}>
																				<InputLabel htmlFor="type">{t('users.form.tutor.tabSubjects.selectFilterByType.label')}</InputLabel>
																				<Select
																					value={filterType}
																					onChange={this.filterByType}
																					inputProps={{
																						name: 'filterType',
																						id: 'filterType'
																					}}
																				>
																					<MenuItem value="all">{t('users.form.tutor.tabSubjects.selectFilterByType.options.all')}</MenuItem>
																					<MenuItem value="Individual">{t('users.form.tutor.tabSubjects.selectFilterByType.options.individual')}</MenuItem>
																					<MenuItem value="Grupal">{t('users.form.tutor.tabSubjects.selectFilterByType.options.group')}</MenuItem>
																				</Select>
																			</FormControl>
																		</Grid>	
																		<Grid item className={classes.grid} xs={12} sm={12} md={4}>
																			<FormControl fullWidth={true} className={classes.formControl} style={{margin: '0 0 0 0'}}>
																				<InputLabel htmlFor="modality">{t('users.form.tutor.tabSubjects.selectFilterByModality.label')}</InputLabel>
																				<Select
																					value={filterModality}
																					onChange={this.filterByModality}
																					inputProps={{
																						name: 'filterModality',
																						id: 'filterModality'
																					}}
																				>
																					<MenuItem value="all">{t('users.form.tutor.tabSubjects.selectFilterByModality.options.all')}</MenuItem>
																					<MenuItem value="Presencial">{t('users.form.tutor.tabSubjects.selectFilterByModality.options.inperson')}</MenuItem>
																					<MenuItem value="Online">{t('users.form.tutor.tabSubjects.selectFilterByModality.options.online')}</MenuItem>
																				</Select>
																			</FormControl>
																		</Grid>
																		<Grid item className={classes.grid} xs={12} sm={12} md={4}>
																			<FormControl fullWidth={true} className={classes.formControl} style={{margin: '0 0 0 0'}}>
																				<InputLabel htmlFor="category">{t('users.form.tutor.tabSubjects.selectFilterByCategory.label')}</InputLabel>
																				<Select
																					value={filterCategory}
																					onChange={this.filterByCategory}
																					inputProps={{
																						name: 'filterCategory',
																						id: 'filterCategory'
																					}}
																				>
																					<MenuItem value="0" key="0">{t('users.form.tutor.tabSubjects.selectFilterByCategory.options.all')}</MenuItem> 
																					{categories.map((i, k) => {
																						return (
																								<MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>
																							)
																					})}
																				</Select>
																			</FormControl>
																		</Grid>
																	</Grid>
																</Grid>
															</Grid>

															<Grid container>
																<Grid item className={classes.grid} xs={12} sm={12} md={12}>
																	<CustomSearcher eventChange={this.searchRows} placeholder={t('users.form.tutor.tabSubjects.customSearcherPlaceholder')} />
																</Grid>
															</Grid>
															<List className={classes.subjectsList}>
																{subjects.map(s => (
																	
																	<ListItem
																		key={s.id}
																		dense
																		button
																		onClick={this.selectSubject(s, t)}
																		className={classes.listItem}
																		style={{backgroundColor: this.returnBgColor(s.type)}}
																	>
																	    <ListItemIcon classes={{root: classes.iconsIntoListSubjects}}>
																			<span className={(s.modality==='Online')?"icon-online":"icon-in-person"} style={{color: "#fff"}}></span>
																		</ListItemIcon>
																		<ListItemText primary={s.name+' ('+s.category+')'+this.returnLimit(s.type, s.limit)} classes={
																			{primary: classes.listItemChip}
																		} />
																		<ListItemIcon classes={{root: classes.iconsIntoListSubjects}}>
																			<span className="fa fa-arrow-right" style={{color: "#fff"}}></span>
																		</ListItemIcon>
																	</ListItem>
																
																))}
															</List>
														</Grid>

														<Grid item className={classes.grid} xs={12} sm={12} md={6}>
															<Paper className={classes.subjectsSelectedPaper} style={{marginTop: 174}}>
																{selectedSubjects.map(s => {
																	return (
																		<Chip
																			avatar={
																				<Avatar style={{backgroundColor: this.returnBgColor(s.type), color: '#fff', marginBottom: 5}}>
																					<span className={this.returnIcon(s.modality)}></span>
																				</Avatar>
																			}
																			title={s.name+' ('+s.category+')'}
																			key={s.id}
																			label={((s.name.length > 30)?s.name.substring(0, 29)+'..':s.name)+' ('+((s.category.length > 30)?s.category.substring(0, 29)+'..':s.category)+')'+this.returnLimit(s.type, s.limit)}
																			onDelete={this.deleteSubject(s)}
																			className={classes.subjectChip}
																			style={{backgroundColor: this.returnBgColor(s.type)}}
																			classes={
																				{
																					label: classes.pLeft,
																					deleteIcon: classes.chipSubjects
																				}
																			}
																		/>
																	);
																})}
												            </Paper>
														</Grid>

													</Grid>  

												</CardContent>}
										{currentTab === 'one' &&
												<CardContent>
											
													<Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>
														<span className={classes.cntTitle}>{t('users.form.tabs.general.divisions.basicInformation.title')}</span>

														<Grid container>
															<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																<CustomInput
																	labelText={t('users.form.fields.name')}
																	id="name"
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		name:"name",
																		value:formData.name,
																		onChange:(this.handleChange),
																		error:errors.has('name'),
																		disabled: sameCampus
																	}}
																	customInputProps={{maxLength: "50"}}
																	
																/>
																{ errors.has('name') &&
																		<label className={classes.error} htmlFor="name"></label>
																	}
															</Grid>
															<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																<CustomInput
																	labelText={t('users.form.fields.lastName')}
																	id="lastName"
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		name:"lastName",
																		value:formData.lastName,
																		onChange:(this.handleChange),
																		error:errors.has('lastName'),
																		disabled: sameCampus
																	}}
																	customInputProps={{maxLength: "50"}}
																	
																/>
																{ errors.has('lastName') &&
																		<label className={classes.error} htmlFor="lastName"></label>
																	}
															</Grid>
														</Grid>

														<Grid container>

															<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																<Grid container>
																	<Grid item className={classes.grid} xs={12} sm={12} md={6}> 
																		<FormControl fullWidth={true} className={classes.formControl}>
																			<InputLabel htmlFor="email">{t('users.form.fields.email')}</InputLabel>
																			<Input
																				disabled={match.params.id === "add"?false:true}
																				id="email"
																				name="email"
																				value={formData.email}
																				endAdornment={
																					<InputAdornment position="end">@</InputAdornment>
																				}
																				onChange= {this.handleChange}
																				error={errors.has('email')}
																			/>
																		</FormControl>
																	</Grid>  
																	<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																		<FormControl fullWidth={true} className={classes.formControl} style={{marginTop: 42}}>
																			<Select
																				disabled={match.params.id === "add"?false:true}
																				value={domain}
																				onChange={this.changeDomain}
																				inputProps={{
																					name: 'domain',
																					id: 'domain'
																				}}
																			>
																				{domains.map((i, k) => {
																					return (
																							<MenuItem value={i.name} key={k}>{i.name}</MenuItem>
																						)
																				})}
																			</Select>
																		</FormControl>
																	</Grid>
																   {errors.has('email') && <label className={classes.error} htmlFor="email"></label>}
																</Grid>
														    </Grid>
 
															<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{position: 'relative'}}>
																<FormControl fullWidth={true} className={classes.formControl}>
																	<InputLabel htmlFor="password">{t('users.form.fields.password')}</InputLabel>
																	<Input
																		id="password"
																		name="password"
																		value={formData.password}
																		type="password"
																		onChange= {this.handleChange}
																		error={errors.has('password')}
																		disabled={sameCampus}			
																		inputProps={{
																			minLength:"6",
																			maxLength:"50"
																		}}			
																	/>
																</FormControl>
																<div style={{display:'flex', position:'absolute'}}>
																    <div>
																	  <span className={classNames(classes.spanIconInfo, "fa fa-info-circle")}></span>
																	</div>
																    <div style={{paddingLeft: 6}}>
																	   <Typography variant="caption" style={{color: '#9B9B9B'}} >{t('users.form.tutor.alerts.infoPassword')}</Typography>
																    </div>	
																</div>
																{errors.has('password') && <label className={classes.error} htmlFor="password"></label>}
															</Grid>

													    </Grid>

													    <Grid container>

															<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																<FormControl fullWidth={true} className={classes.formControl}>
																	<InputLabel htmlFor="gender">{t('users.form.fields.gender')}</InputLabel>
																	<Select
																		value={formData.gender}
																		onChange={this.handleChange}
																		inputProps={{
																			name: 'gender',
																			id: 'gender'
																		}}
																		disabled={sameCampus}
																	>
																		<MenuItem value="F">{t('selectGender.female')}</MenuItem>
																		<MenuItem value="M">{t('selectGender.male')}</MenuItem>
																		<MenuItem value="O">{t('selectGender.other')}</MenuItem>
																	</Select>
																</FormControl>
															</Grid>

													    </Grid>

													</Grid>

													<Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>
														<span className={classes.cntTitle}>{t('users.form.tabs.general.divisions.advancedInformation.title')}</span>

														<Grid container>

															<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																<CustomInput
																	labelText={t('users.form.fields.phone')}
																	id="phone"
																	formControlProps={{
																		fullWidth: true
																	}}
																	inputProps={{
																		name:"phone",
																		value:formData.phone,
																		onChange:(this.handleChange)
																	}}
																	disabled={sameCampus}
																	customInputProps={{maxLength: "15"}}
																	
																/>
																{ errors.has('phone') &&
																		<label className={classes.error} htmlFor="phone"></label>
																	}
															</Grid> 
															
															<Grid item className={classNames(classes.grid, 'birthdayDatePicker')} xs={12} sm={12} md={6}>
																	{/*<TextField
																		fullWidth={true}
																		id="birthday"
																		name="birthday"
																		label={t('users.form.fields.birthday')}
																		type="date"
																		value={formData.birthday}
																		//className={classes.textField}
																		onChange={this.handleChange}
																		disabled={sameCampus}
																		style={{marginTop: "27px"}}
																		InputLabelProps={{
																			shrink: true,
																		}}
																	/>*/}
																	 <LocalizationProvider dateAdapter={AdapterDateFns} locale={ i18n.language==='en'?enLocale:esLocale }>
																		<DatePicker
																			label={t('users.form.fields.birthday')}
																			id="birthday"
																		    name="birthday"
																			inputFormat={t('dateFormat')}
																			value={formData.birthday}
																			onChange={this.birthdayChangeHandle}
																			disabled={sameCampus}
																			renderInput={(params) => <TextField {...params} />}
																		/>
																	</LocalizationProvider>
															</Grid>

														</Grid>

                                                        <Grid container>
															<Grid item className={classes.grid} xs={12} sm={12} md={12}>
																<TextField
																	fullWidth={true}
																	id="description"
																	name="description"
																	label={t('users.form.fields.description')}
																	multiline
																	rows="6"
																	value={formData.description}
																	className={classes.textArea}
																	onChange={this.handleChange}
																	style={{marginTop: "27px"}}
																	disabled={sameCampus}
																	error={errors.has('description')}
															/>
															{ errors.has('description') && <label className={classes.error} htmlFor="description"></label>}
														    </Grid>
														</Grid>

													</Grid>	

													<Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>
														<span className={classes.cntTitle}>{t('users.form.tabs.general.divisions.settings.title')}</span>

														<Grid container className={classes.studentCheckboxContainerFirts}>

														    <Grid item className={classes.grid} xs={12} sm={12} md={5}>
																<div>
																	<label className={classes.studentCheckboxContainerItemLabel} >{t('users.form.fields.status')}</label>
																	<Tooltip title={t('users.form.tabs.general.divisions.settings.tooltips.accountStatus')} classes={{ tooltip: classes.lightTooltip }}>  
																		<span className={classNames(classes.studentCheckboxContainerItemTooltipSpan, "fa fa-info-circle")}></span>
																	</Tooltip>
																</div>
															</Grid>	

															<Grid item className={classes.grid} xs={12} sm={12} md={7}>
																<IOSSwitch 
																	checked={formData.status}
																	onChange={this.activeAccount("status")}
																	value="status"
																	name="status" 
																/>
															</Grid>

														</Grid>

														<Grid container className={classes.studentCheckboxContainer}>

														    <Grid item className={classes.grid} xs={12} sm={12} md={5}>
																<div>
																	<label className={classes.studentCheckboxContainerItemLabel}>{t('users.form.fields.confirmedEmail')}</label>
																	<Tooltip title={t('users.form.tabs.general.divisions.settings.tooltips.confirmedEmail')} classes={{ tooltip: classes.lightTooltip }}>  
																		<span className={classNames(classes.studentCheckboxContainerItemTooltipSpan, "fa fa-info-circle")}></span>
																	</Tooltip>
																</div>
															</Grid>	

															<Grid item className={classes.grid} xs={12} sm={12} md={7}>
																<IOSSwitch 
																	checked={formData.confirmed}
																	onChange={this.activeAccount("confirmed")}
																	value="confirmed"
																	name="confirmed" 
																/>
															</Grid>

														</Grid>

														{match.params.id !== "add" && <Grid container className={classes.studentCheckboxContainer}>

														    <Grid item className={classes.grid} xs={12} sm={12} md={5}>
																<div>
																	<label className={classes.studentCheckboxContainerItemLabel} >{t('users.form.fields.asTutor')}</label>
																	<Tooltip title={t('users.form.tabs.general.divisions.settings.tooltips.tutorPermissions')} classes={{ tooltip: classes.lightTooltip }}>  
																	<span className={classNames(classes.studentCheckboxContainerItemTooltipSpan, "fa fa-info-circle")}></span>
																	</Tooltip>
																</div>
															</Grid>	

															<Grid item className={classes.grid} xs={12} sm={12} md={7}>
																<IOSSwitch 
																	checked={formData.isTutor} 
																	onChange={this.activeAsTutor("isTutor")} 
																	name="isTutor" 
																	value="isTutor"
																/>
															</Grid>
														
														</Grid>}	

														{/*<Grid container className={classes.studentCheckboxContainerFirts}>

														    <Grid item className={classes.grid} xs={12} sm={12} md={5}>
																<div>
																	<label className={classes.studentCheckboxContainerItemLabel} >Hola</label>
																	
																</div>
															</Grid>	

															<Grid item className={classes.grid} xs={12} sm={12} md={7}>
																<Autocomplete
																	multiple
																	id="tags-filled"
																	options={top100Films.map((option) => option.title)}
																	defaultValue={[top100Films[1].title]}
																	freeSolo
																	filterSelectedOptions
																	renderTags={(value, getTagProps) =>
																		value.map((option, index) => (
																			<Chip variant="outlined" label={option} {...getTagProps({ index })} />
																		))
																	}
																	renderInput={(params) => (
																	<TextField
																		{...params}
																		variant="filled"
																		label="freeSolo"
																		placeholder="Favorites"
																	/>
																	)}
																	onChange={this.onChangeTag}
																/>
															</Grid>

																	</Grid>*/}

													</Grid>

												</CardContent>}
												{currentTab === 'two' &&
												<CardContent>
												<Grid container> 

												    <Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>
														<span className={classes.cntTitle}>{t('users.form.tabs.disponibility.divisions.settings.title')}</span>

														{!sameCampus &&<Grid container className={classes.studentCheckboxContainerFirts}>

														    <Grid item className={classes.grid} xs={12} sm={12} md={6}>
																<div>
																	<label className={classes.studentCheckboxContainerItemLabel} >{t('users.form.tutor.tabDisponibility.radioBtnLockSchedules')}</label>
																	<Tooltip title={t('users.form.tutor.tabDisponibility.toopltipLockSchedules')} classes={{ tooltip: classes.lightTooltip }}>  
																		<span className={classNames(classes.studentCheckboxContainerItemTooltipSpan, "fa fa-info-circle")}></span>
																	</Tooltip>
																</div>
															</Grid>	

															<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																<IOSSwitch 
																	checked={disabledDisponibility}
																	onChange={this.changeDisponibility("disabledDisponibility")}
																	value={"disabledDisponibility"}
																	disabled={sameCampus}
																	name="disabledDisponibility" 
																/>
															</Grid>

														</Grid>} 

														<Grid container className={classes.studentCheckboxContainer}>

														    <Grid item className={classes.grid} xs={12} sm={12} md={6}>
																<div>
																	<label className={classes.studentCheckboxContainerItemLabel}>{t('users.form.fields.vacationMode')}</label>
																	<Tooltip title={t('users.form.tabs.disponibility.divisions.settings.tooltips.vacationMode')} classes={{ tooltip: classes.lightTooltip }}>  
																		<span className={classNames(classes.studentCheckboxContainerItemTooltipSpan, "fa fa-info-circle")}></span>
																	</Tooltip>
																</div>
															</Grid>	

															<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																<IOSSwitch 
																	checked={formData.vacationMode}
																	onChange={this.activeAsTutor("vacationMode")}
																	value="vacationMode"
																	disabled={sameCampus}
																	name="vacationMode" 
																/>
															</Grid>

														</Grid>

												    </Grid>  		 

												<Grid item className={classes.cntSeparate} xs={12} sm={12} md={12} style={{marginTop:40}}>
													<span className={classes.cntTitle}>{t('users.form.tutor.tabDisponibility.sections.weekly')}</span>

													<Grid container style={{marginTop: 40}}>
													   <Grid item className={classes.grid} xs={12} sm={12} md={12}>
															<Paper className={classNames(classes.root, classes.paperCss)}>
																<Tabs
																	value={weekDay}
																	onChange={this.filterByStatus}
																	indicatorColor="primary"
																	textColor="primary"       
																>
																	<Tab value={"Lunes"} label={t('users.form.tutor.tabDisponibility.weekDays.monday')} className={classes.tabForm} />
																	<Tab value={"Martes"} label={t('users.form.tutor.tabDisponibility.weekDays.tuesday')} className={classes.tabForm} />
																	<Tab value={"Miercoles"} label={t('users.form.tutor.tabDisponibility.weekDays.wednesday')} className={classes.tabForm} />
																	<Tab value={"Jueves"} label={t('users.form.tutor.tabDisponibility.weekDays.thursday')} className={classes.tabForm} />
																	<Tab value={"Viernes"} label={t('users.form.tutor.tabDisponibility.weekDays.friday')} className={classes.tabForm} />
																	<Tab value={"Sabado"} label={t('users.form.tutor.tabDisponibility.weekDays.saturday')} className={classes.tabForm} />
																	<Tab value={"Domingo"} label={t('users.form.tutor.tabDisponibility.weekDays.sunday')} className={classes.tabForm} />
																</Tabs>
															</Paper>
														</Grid>  
													   <Grid item className={classes.grid} xs={12} sm={12} md={12}>
														{weekDay === 'Lunes' && <div>
															<Grid container className={classes.contentWeekDays}>

																	<ActiveCheck label={t('users.form.tutor.tabDisponibility.radioBtnActiveDay')} status={Lunes} eventChange={this.activeDay} value="Lunes" sameCampus={sameCampus} />

																	{typeof schedules.Lunes !== 'undefined' && schedules.Lunes.length > 0 ? 
																	(<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{marginTop: 20}}>
																		<Grid container>
																			<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																					<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.from')}</Typography>
																			</Grid>
																			<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																					<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.to')}</Typography>
																			</Grid>
																		</Grid>
																		<div>
																			<SelectsSchedule schedules={schedules.Lunes} day="Lunes" deleteSchedule={this.deleteSchedule} changeSchedule={this.changeSchedule} sameCampus={sameCampus}/>
																		</div>
																	</Grid>):
																	(<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{marginTop: 20}}>
																		<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.msgWithoutSchedules')}</Typography>
																	</Grid>)}

														</Grid>  
														</div>}
														{weekDay === 'Martes' && <div>
															<Grid container className={classes.contentWeekDays}>

																	<ActiveCheck label={t('users.form.tutor.tabDisponibility.radioBtnActiveDay')} status={Martes} eventChange={this.activeDay} value="Martes" sameCampus={sameCampus} />

																	{typeof schedules.Martes !== 'undefined' && schedules.Martes.length > 0 ? 
																	(<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{marginTop: 20}}>
																		<Grid container>
																			<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																					<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.from')}</Typography>
																			</Grid>
																			<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																					<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.to')}</Typography>
																			</Grid>
																		</Grid>
																		<div>
																			<SelectsSchedule schedules={schedules.Martes} day="Martes" deleteSchedule={this.deleteSchedule} changeSchedule={this.changeSchedule} sameCampus={sameCampus}/>
																		</div>
																	</Grid>):
																	(<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{marginTop: 20}}>
																		<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.msgWithoutSchedules')}</Typography>
																	</Grid>)}

														</Grid>  
														</div>}
														{weekDay === 'Miercoles' && <div>
															<Grid container className={classes.contentWeekDays}>

																<ActiveCheck label={t('users.form.tutor.tabDisponibility.radioBtnActiveDay')} status={Miercoles} eventChange={this.activeDay} value="Miercoles" sameCampus={sameCampus} />
																
																{typeof schedules.Miercoles !== 'undefined' && schedules.Miercoles.length > 0 ? 
																(<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{marginTop: 20}}>
																	<Grid container>
																			<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																					<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.from')}</Typography>
																			</Grid>
																			<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																					<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.to')}</Typography>
																			</Grid>
																	</Grid>
																	<div>
																			<SelectsSchedule schedules={schedules.Miercoles} day="Miercoles" deleteSchedule={this.deleteSchedule} changeSchedule={this.changeSchedule} sameCampus={sameCampus}/>
																	</div>
																</Grid>):
																(<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{marginTop: 20}}>
																		<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.msgWithoutSchedules')}</Typography>
																	</Grid>)}

														</Grid>  
														</div>}
														{weekDay === 'Jueves' && <div>
															<Grid container className={classes.contentWeekDays}>

																	<ActiveCheck label={t('users.form.tutor.tabDisponibility.radioBtnActiveDay')} status={Jueves} eventChange={this.activeDay} value="Jueves" sameCampus={sameCampus} />
																	
																	{typeof schedules.Jueves !== 'undefined' && schedules.Jueves.length > 0 ?
																	(<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{marginTop: 20}}>
																		<Grid container>
																			<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																					<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.from')}</Typography>
																			</Grid>
																			<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																					<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.to')}</Typography>
																			</Grid>
																		</Grid>
																		<div>
																			<SelectsSchedule schedules={schedules.Jueves} day="Jueves" deleteSchedule={this.deleteSchedule} changeSchedule={this.changeSchedule} sameCampus={sameCampus}/>
																		</div>
																	</Grid>):
																	(<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{marginTop: 20}}>
																		<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.msgWithoutSchedules')}</Typography>
																	</Grid>)}

														</Grid>  
														</div>}
														{weekDay === 'Viernes' && <div>
															<Grid container className={classes.contentWeekDays}>

																<ActiveCheck label={t('users.form.tutor.tabDisponibility.radioBtnActiveDay')} status={Viernes} eventChange={this.activeDay} value="Viernes" sameCampus={sameCampus} />
																	
																	{typeof schedules.Viernes !== 'undefined' && schedules.Viernes.length > 0 ?
																	(<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{marginTop: 20}}>
																		<Grid container>
																			<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																					<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.from')}</Typography>
																			</Grid>
																			<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																					<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.to')}</Typography>
																			</Grid>
																		</Grid>
																		<div>
																			<SelectsSchedule schedules={schedules.Viernes} day="Viernes" deleteSchedule={this.deleteSchedule} changeSchedule={this.changeSchedule} sameCampus={sameCampus}/>
																	</div>
																	</Grid>):
																	(<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{marginTop: 20}}>
																		<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.msgWithoutSchedules')}</Typography>
																	</Grid>)}

														</Grid>  
														</div>}
														{weekDay === 'Sabado' && <div>
															<Grid container className={classes.contentWeekDays}>

																<ActiveCheck label={t('users.form.tutor.tabDisponibility.radioBtnActiveDay')} status={Sabado} eventChange={this.activeDay} value="Sabado" sameCampus={sameCampus} />
																	
																	{typeof schedules.Sabado !== 'undefined' && schedules.Sabado.length > 0 ?
																		(<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{marginTop: 20}}>
																		<Grid container>
																			<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																					<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.from')}</Typography>
																			</Grid>
																			<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																					<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.to')}</Typography>
																			</Grid>
																		</Grid>
																		<div>
																		<SelectsSchedule schedules={schedules.Sabado} day="Sabado" deleteSchedule={this.deleteSchedule} changeSchedule={this.changeSchedule} sameCampus={sameCampus}/>
																	</div>
																</Grid>):
																	(<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{marginTop: 20}}>
																		<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.msgWithoutSchedules')}</Typography>
																	</Grid>)}

														</Grid>  
														</div>}
														{weekDay === 'Domingo' && <div>
															<Grid container className={classes.contentWeekDays}>

																<ActiveCheck label={t('users.form.tutor.tabDisponibility.radioBtnActiveDay')} status={Domingo} eventChange={this.activeDay} value="Domingo" sameCampus={sameCampus} />
																
																{typeof schedules.Domingo !== 'undefined' && schedules.Domingo.length > 0 ?
																(<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{marginTop: 20}}>
																	<Grid container>
																		<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																				<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.from')}</Typography>
																		</Grid>
																		<Grid item className={classes.grid} xs={12} sm={12} md={6}>
																				<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.to')}</Typography>
																		</Grid>
																	</Grid>
																	<div>
																	<SelectsSchedule schedules={schedules.Domingo} day="Domingo" deleteSchedule={this.deleteSchedule} changeSchedule={this.changeSchedule} sameCampus={sameCampus}/>
																</div>
															</Grid>):
																(<Grid item className={classes.grid} xs={12} sm={12} md={6} style={{marginTop: 20}}>
																		<Typography variant="body1" style={{marginLeft:15}}>{t('users.form.tutor.tabDisponibility.msgWithoutSchedules')}</Typography>
																 </Grid>)}

														</Grid>  
														</div>}
													   </Grid>
													   {!sameCampus &&
														<Grid container style={{marginTop: 20}}>
															<Grid item className={classes.grid} xs={12} sm={12} md={4}>
																<Button style={{marginTop: 3, marginBottom: 10, marginLeft: 18}} variant="contained" color="default" onClick={this.addSchedule}>
																	<span className={classNames(classes.iconBack, "fa fa-plus")}></span> {t('users.form.tutor.tabDisponibility.btnAddSchedule')}
																</Button>
															</Grid>
														</Grid>}
												    </Grid>
													</Grid>

												{customSchedules.length > 0 && 
												<Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>
													<span className={classes.cntTitle}>{t('users.form.tutor.tabDisponibility.sections.byDate')}</span>
													<Grid container style={{marginTop: 20}}>
														<Grid item className={classes.grid} xs={12} sm={12} md={12}>
														    <TableContainer component={Paper} style={{marginTop: 20, border: 0}}>
																<Table className={classes.table} size="small" aria-label="a dense table">
																	<TableBody>
																	{customSchedules.map((row, index) => (
																		<TableRow key={index}>
																			<TableCell component="th" style={{width: '35%', borderBottom:0}} scope="row">
																				{row.custom_date}
																			</TableCell>
																			<TableCell style={{width: '65%', borderBottom:0}}>{row.schedules}</TableCell>
																		</TableRow>
																	))}
																	</TableBody>
																</Table>
														    </TableContainer>
														</Grid>
													</Grid>
												</Grid>}

                                                {Object.keys(schedulesBySubject).length > 0 && 
												<Grid item className={classes.cntSeparate} xs={12} sm={12} md={12}>
													<span className={classes.cntTitle}>{t('users.form.tutor.tabDisponibility.sections.bySubject')}</span>
													<Grid container style={{marginTop: 20}}>
														<Grid item className={classes.grid} xs={12} sm={12} md={8}>
														{subjectSched}
														</Grid>
													</Grid>
												</Grid>}

												</Grid>
												</CardContent>}
												<CardActions>
													<Button variant="contained" color="primary" className={classes.buttonBottom} disabled={this.state.disabledButton} type="submit">
														{t('categories.form.btnSave')}
													</Button>
												</CardActions>
											</Card>
										</Grid>
								</Grid>  
								</form>  
								<AlertDelete open={openAlert} eventClose={this.closeAlert} eventOk={this.deleteRow} title={t('users.form.tutor.modalInactive.title')} content={t('users.form.tutor.modalInactive.content')} />
								<AlertMessage open={openAlertMessage} variant={alertClassName} message={alertMessage} eventClose={this.closeMessage} />
			       </React.Fragment>
             )
          }
        }
        </Translation>
      </div>

    );

  }

}

export default withStyles(styles, { withTheme: true })(Tutor);
