import axios from 'axios';
import BaseApi from "../BaseApi";
import UserProfile from "../../models/UserProfile";

const userProfile = new UserProfile();

class SubjectsApi extends BaseApi {

  constructor(){
    super();
    this.limit = 10;
    this.api = this.url + 'subjects/';
  }
  
  search(token, universityId, page, textToSearch, status, type) {

    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.api+'list?universityId='+universityId+'&limit='+this.limit+'&skip='+(page * this.limit);

    if (textToSearch !== '') url = url+'&search='+textToSearch.trim();
    if (status !== '') url = url+'&status='+status;
    if (type !== '') url = url+'&type='+type;
    
    return axios.get(url,{headers: this.headers});

  }

  getById(token, universityId, id) {

    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.api+'getone?universityId='+universityId+'&id='+id;
    
    return axios.get(url,{headers: this.headers});

  }

  getCategories(token, universityId) {

    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.url+'categories/all?universityId='+universityId;
    
    return axios.get(url,{headers: this.headers});

  }

  delete(token, id, universityId, page, textToSearch, status, type) {
    
  	this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
  	let skip = (page*this.limit);
  	let url = this.api+'delete', 
  	    data = {
	       	universityId:universityId,
	       	limit:this.limit,
	       	skip: skip,
	       	id: parseInt(id, 10),
          type: type
	    }

  	if (textToSearch !== '') data.search = textToSearch.trim();
    if (status !== '') data.status = status;

  	return axios.request({method:'patch', url:url, headers: this.headers, data:data});

  }


  save(token, universityId, info) {
    
    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'save', 
        data = {
          universityId:universityId,
          ...info
      }

    return axios.request({method:'post', url:url, headers: this.headers, data:data});

  }

  update(token, universityId, info) {
    
    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url = this.api+'update', 
        data = {
          universityId:universityId,
          ...info
      }

    return axios.request({method:'patch', url:url, headers: this.headers, data:data});

  }

  getTutors(token, subejctId) {

    this.headers.Authorization = token;
    this.headers['Accept-Language'] = userProfile.getLanguage();
    let url =  this.url+'subjects/tutors/'+subejctId;
    
    return axios.get(url,{headers: this.headers});

  }

}

export default SubjectsApi;