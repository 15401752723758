import * as types from "../actions/menu";
const initialState = {
	compact: false,
    menu: [],
    mainBtnActive: '' 
};

const reducer = ( state = initialState, { type, payload } ) => {
    switch(type){
    	case types.ADD_MENU : 
    	    return { ...state, 'menu' : payload  }
    	case types.ACTIVE_SUB : 
    		return { ...state, 'mainBtnActive' : payload  }
    	case types.COMPACT : 
    	    return { ...state, 'compact' : payload  }
    	default: 
    	    return state
    }
}

export default reducer;