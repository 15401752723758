import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';

import { Translation } from 'react-i18next';

// Components
import Datagrid from "../../components/Datagrid/Grid";
import SearcherInput from "../../components/Inputs/SearcherInput";
import AlertDelete from "../../components/Modal/AlertDelete";
import AlertMessage from "../../components/Modal/AlertMessage";

// Api
import StudentsApi from "../../api/Users/StudentsApi";

// Models
import UserProfile from "../../models/UserProfile";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

const Api = new StudentsApi();
const userProfile = new UserProfile();

let alertMessage = '';
let alertClassName = 'success';
let idToDelete = 0;
let nameToDelete = '';
let status = 'Activo';
let rowsPerPage = 20;
let totalRows = 0;
let currentPage = 0;
let textToSearch = '';
let columns = {
    'fullName': {}, 
    'email': {},
    'lastActivity':{},
    'created':{'label': 'Se registro'}
  };

class Students extends Component {

  constructor(){
	  super();
	  this.state = {
	  	data: [],
	  	openAlert: false,
	  	openAlertMessage: false
	  }

	  Api.limit = rowsPerPage;
  }

  getRowsPerPage = (page) => {
      
	  Api.search(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   page, 
	  	   textToSearch,
	  	   status).then(response => {

	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

	       if (responseData.modal) {
	           console.log(responseData.message);
	       } else {
	       	
	        totalRows = responseData.data.total;
	        this.setState({data: responseData.data.rows});
	       
	       }

      }).catch((err) => {Api.closeSession(err)});
    
  };

  del = () => {

	  Api.delete(
	  	   userProfile.getToken(), 
	  	   idToDelete,
	  	   userProfile.getUser().universityId,
	  	   currentPage, 
	  	   textToSearch,
	  	   status).then(response => {

	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
	       	
         if (responseData.modal) {

          if (responseData.data.className === 'warning' || responseData.data.className === 'success') {
            alertMessage = responseData.message;
            alertClassName = responseData.data.className;
            this.setState({ openAlertMessage: true });
            if (responseData.data.className === 'success') {
              totalRows = responseData.data.total;
              this.setState({data: responseData.data.rows});  
            }
          } else {

            totalRows = responseData.data.total;
            this.setState({data: responseData.data.rows});
          }
        
        }
	       

      }).catch((err) => {Api.closeSession(err)});
    
  };

  handleChangePage = (event, page) => {
    currentPage = page;
    this.getRowsPerPage(page);
  };

  searchRows = (e, search) => {
    textToSearch = search.value;
    currentPage = 0;
    this.getRowsPerPage(currentPage);
  };

  filterByStatus = (event, tab) => {
    status = tab;
    currentPage = 0;
    this.getRowsPerPage(currentPage);
  };

  showAlert = (id, name, e) => {
    idToDelete = id; // e.target.id;
    nameToDelete = name;
    this.setState({ openAlert: true });
  };

  closeAlert = () => {
    this.setState({ openAlert: false });
  };

  deleteRow = () => {
    this.setState({ openAlert: false });
    this.del();
    idToDelete = 0;
    nameToDelete = '';
  };

  closeMessage = (event, reason) => {
    this.setState({ openAlertMessage: false });
  };

  translateColumns = (tr) => {

    columns.fullName.label = tr('users.table.columns.fullName');
    columns.email.label = tr('users.table.columns.email');
    // columns.phone.label = tr('users.table.columns.phone');
    columns.lastActivity.label = tr('users.table.columns.lastActivity');
    columns.created.label = tr('users.table.columns.created');
    
    if (typeof userProfile.getPermissions()['users_student_update'] !== 'undefined' ||
        typeof userProfile.getPermissions()['users_student_delete'] !== 'undefined')
        columns.opciones = {label: tr('users.table.columns.options')};

    return columns;
  
  }

  componentWillMount(){
  	this.getRowsPerPage(currentPage);
  }

  componentDidMount(){
    
    let alert = userProfile.getAlert();
    if (alert && alert !== null) {
        
        alertMessage = alert.msg;
        alertClassName = alert.class; 
        this.setState({ openAlertMessage: true });

    }

  }

  render() {
    
  	const { classes } = this.props;
    let { data, openAlert, openAlertMessage } = this.state;

    data = data.map((val, key) => {
          
          val.icon = (<span className={classNames(classes.iconColumn, "icon-"+val.icon)} ></span>);
                         
          val.opciones = (<React.Fragment>
                           {typeof userProfile.getPermissions()['users_student_update'] !== 'undefined'?
                            <IconButton key={val.id+'e'} className={classes.button} 
                            classes={{
                              root: classes.zeroPadTableBtn
                            }}
                            style={{marginTop:0, color: '#3498db', display: 'inline-block'}} component={Link} to={"/users/students/"+val.id}>
                            <EditIcon />
                           </IconButton>:''}
                           {typeof userProfile.getPermissions()['users_student_delete'] !== 'undefined'?
                            <IconButton key={val.id+'d'} className={classes.button} 
                            classes={{
                              root: classes.zeroPadTableBtn
                            }}
                            style={{marginTop:0, color: '#d9534f', display: 'inline-block'}} onClick={this.showAlert.bind(this, val.id, val.fullName)}>
                            <ClearIcon />
                           </IconButton>:''}
            </React.Fragment>);
                                        
          return val;
    });

    return (
      
      <div>
        <Translation>
         {
          (t, { i18n }) => {
            
            return (
              <React.Fragment>
                  <Grid container className={classes.container}>
          	        <Grid item className={classes.grid} xs={12} sm={12} md={12}>
          	          <Paper className={classNames(classes.root, classes.paperCss)}>
          		         <Tabs
          		          value={status}
          		          onChange={this.filterByStatus}
          		          indicatorColor="primary"
          		          textColor="primary"
          		          >
          		          <Tab icon={<span className="fa fa-th"></span>} value={"Activo"} label={t('places.filtersOptions.active')} />
          		          <Tab icon={<span className="fa fa-power-off"></span>} value={"Inactivo"} label={t('places.filtersOptions.inactive')} />
          		        </Tabs>
                      {typeof userProfile.getPermissions()['users_student_write'] !== 'undefined' &&
                      <Button color="primary" component={Link} to="/users/students/add" className={classes.btnAdd} variant="contained">
                        {t('categories.actionsButtons.add')}
                      </Button>}
          		      </Paper>
          	        </Grid>
          	        <Grid item className={classes.grid} xs={12} sm={12} md={12}>
          		      <SearcherInput eventChange={this.searchRows} textToSearch={textToSearch} placeholder={t('users.search.placeholder')} />
                        <Datagrid data={data} page={currentPage} totalRows={totalRows} rowsPerPage={rowsPerPage} columns={this.translateColumns(t)} eventOnChange={this.handleChangePage} />
          	        </Grid>
          	      </Grid>
                  <AlertDelete open={openAlert} eventClose={this.closeAlert} eventOk={this.deleteRow} title={t('users.modalDelete.title').replace(':student', nameToDelete)} content={t('users.modalDelete.content')} />
                  <AlertMessage open={openAlertMessage} variant={alertClassName} message={alertMessage} eventClose={this.closeMessage} />
               </React.Fragment>
               )
            }
          }
        </Translation>
      </div>

    );

  }

}

export default withStyles(styles, { withTheme: true })(Students);
