import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import global from './reducers/global';
import menu from './reducers/menu';
import news from './reducers/news';

const reducer = combineReducers({
	global,
    menu,
    news
});

const store = createStore(
	reducer,
	compose(applyMiddleware(thunk))
);

export default store;