
//import UserProfile from "../../../models/UserProfile";

//const userProfile = new UserProfile();
//const cUser = userProfile.getUser();

let backColor = "#646464 !important";

const primaryColor = "#2196f3";
const warningColor = "#ff9800";
const dangerColor = "#f44336";
const successColor = "#4caf50";
const infoColor = "#00acc1";
const roseColor = "#e91e63";
const grayColor = "#999999";

/*if ((cUser !== null) && (typeof cUser.universities !== 'undefined') && (typeof cUser.universities.backendstyles !== 'undefined') 
    && (typeof cUser.universities.backendstyles[0] !== 'undefined')) {
    backColor = cUser.universities.backendstyles[0].headerColor;
}*/

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "300",
  lineHeight: "1.5em"
};

const primaryBoxShadow = {
  boxShadow:
    "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
};
const infoBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)"
};
const successBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)"
};
const warningBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)"
};
const dangerBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)"
};
const roseBoxShadow = {
  boxShadow:
    "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)"
};

const warningCardHeader = {
  background: "linear-gradient(60deg, #ffa726, #fb8c00)",
  ...warningBoxShadow
};
const successCardHeader = {
  background: "linear-gradient(60deg, #66bb6a, #43a047)",
  ...successBoxShadow
};
const dangerCardHeader = {
  background: "linear-gradient(60deg, #ef5350, #e53935)",
  ...dangerBoxShadow
};
const infoCardHeader = {
  background: "linear-gradient(60deg, #26c6da, #00acc1)",
  ...infoBoxShadow
};
const primaryCardHeader = {
  background: "#2188fc",
  ...primaryBoxShadow
};
const roseCardHeader = {
  background: "linear-gradient(60deg, #ec407a, #d81b60)",
  ...roseBoxShadow
};

// Special for buttons of table
const buttonList = {
  padding: "1px 5px",
  minWidth: "25px",
  minHeight: "25px",
  lineHeight: "1.5",
  color: "#fafafa"
};

const miniBtnDanger = {
  backgroundColor: "#d9534f",
  '&:hover': {
    backgroundColor: "#d43f3a"
  }
};

const miniBtnInfo = {
  backgroundColor: "#3498db",
  '&:hover': {
    backgroundColor: "#258cd1"
  }
};

// Special for cards of forms
const cardTitle = {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  };

const cardCategory = {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  };

const cardContet = {
    display: "flex",
    position: "relative",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    borderRadius: "6px",
    flexDirection: "column",
    overflow: "visible"
};

const cardHeader = {
    backgroundColor: backColor,
    boxShadow: "0 3px 12px rgba(0, 0, 0, 0.15)"
};

export {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  buttonList,
  miniBtnDanger,
  miniBtnInfo,
  cardTitle,
  cardCategory,
  cardContet,
  cardHeader
};
