import React, { Component } from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

// handle of languages
import { Translation } from 'react-i18next';

// Api
import LessonsApi from "../../api/Lessons/LessonsApi";

// Models
import UserProfile from "../../models/UserProfile";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

import defaultAvatar from "../../assets/img/default-user.png";

const Api = new LessonsApi();
const userProfile = new UserProfile();

class Detail extends Component {

  constructor(){

    super();

    this.state = {
        info: {}
    };

  }

  getOneRow = (id) => {
      
    Api.getDetail(
         userProfile.getToken(), 
         userProfile.getUser().universityId,
         id).then(response => {
            
         let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
           
         if (responseData.modal) {
            console.log(responseData.msg);
         } else {
            this.setState({info:responseData.data});
         }

      }).catch((err) => {Api.closeSession(err)});
    
  };

  returnStars = (rating) => {

    let stars = [], colorClass = '';
    const classes = this.props.classes;

    for (var i=0,x=1; i < 5; i++,x++) { 

        colorClass = (rating && x <= rating)? classes.colorYellow: classes.textOpacity;
        stars.push(<span key={i} className={classNames("fa fa-star", colorClass)}></span>);
    }

    return stars;

  }

  returnStatusColor = (st, classes) => {

      let data = classes.colorPending;

      if (st === 'Confirmada') data = classes.colorInfo;

      if (st === 'Activa') data = classes.colorSuccess;

      if (st === 'Finalizada') data = classes.colorFinished;

      if (st === 'Cancelada') data = classes.colorDanger;

      return data;

  }

  returnStatusName = (st, tr) => {

      let data = tr('status.pending');

      if (st === 'Confirmada') data = tr('status.confirmed');

      if (st === 'Activa') data = tr('status.active');

      if (st === 'Finalizada') data = tr('status.finished');

      if (st === 'Cancelada') data = tr('status.canceled');

      return data;

  }

  returnUserType = (user, tr) => {

    let text;
    
    switch(user) {
      case "Tutor":
        text = tr('userType.tutor');
        break;
      case "Estudiante":
        text = tr('userType.student');
        break;
      case "Admin":
        text = tr('userType.admin');
        break;
      default: 
        text = tr('userType.tutor');
    }

    return text;

  }

  componentDidMount(){
    if (typeof this.props.id !== 'undefined' && this.props.id > 0)
        this.getOneRow(this.props.id);
  }

  render() {

    const {classes} = this.props;
    const {info} = this.state;

    let tutorRating = '', studentRating = ''; 

    tutorRating  = this.returnStars(info.tutorRating);
    studentRating  = this.returnStars(info.studentRating);  

    return (
      
      <div>
       <Translation>
         {
          (t, { i18n }) => {
            
            return (
              <React.Fragment> 
                  <Grid container className={classes.container}>
                    <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                      <Grid container className={classes.container}>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Typography variant="h6" className={classes.textOpacity}><span className={classNames("fa fa-key", classes.textOpacity)}></span> {t('lessons.individual.detail.identifier')}: 
                           <span className={classNames(classes.fontDetail, classes.identifier)}>{info.identifier}</span></Typography>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Typography variant="h6"><span className={classNames("fa fa-tachometer-alt", classes.textOpacity)}></span> {t('lessons.individual.detail.status')}: 
                           <span className={classNames(classes.fontDetail, classes.statusText, this.returnStatusColor(info.status, classes))}>{this.returnStatusName(info.status, t)}</span></Typography>
                         </Grid>
                      </Grid>
                    </Grid>
                    <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{marginTop:'10px'}}></Grid> 
                    <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                      <Grid container className={classes.container}>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Avatar alt="tutor" src={info.tutorPhoto !== ''?info.tutorPhoto:defaultAvatar} className={classes.lessonAvatar} />
                           <Typography variant="subtitle1">{t('lessons.individual.detail.tutor')} </Typography>
                           <Typography variant="h6"><span className={classNames(classes.fontDetail, classes.identifier, classes.textOpacity)}>{info.tutor}</span></Typography>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Avatar alt="student" src={info.studentPhoto !== ''?info.studentPhoto:defaultAvatar} className={classes.lessonAvatar} />
                           <Typography variant="subtitle1">{t('lessons.individual.detail.student')} </Typography>
                           <Typography variant="h6"><span className={classNames(classes.fontDetail, classes.identifier, classes.textOpacity)}>{info.student}</span> 
                           {info.guest?<span className="guest">{t('guest')}</span>:''}</Typography>
                         </Grid>
                      </Grid>
                    </Grid>  
                    <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                     <Grid container className={classes.container}>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={3}>
                           <Paper className={classNames(classes.paperInfoDate, classes.tileStatus)} elevation={1}>
                              <Typography component="p">{t('lessons.individual.detail.category')}</Typography>
                              <Typography component="p" className={classes.tileStatusp}>
                                {info.category}
                              </Typography>
                              <span className={classNames("icon-"+info.categoryIcon, classes.textOpacity, classes.iconStyle)}></span>
                           </Paper>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={3}>
                           <Paper className={classNames(classes.paperInfoDate, classes.tileStatus)} elevation={1}>
                              <Typography component="p">{t('lessons.individual.detail.subject')}</Typography>
                              <Typography component="p" className={classes.tileStatusp}>
                                {info.subject}
                              </Typography>
                              <span className={classNames("fa fa-book", classes.textOpacity, classes.iconStyle)}></span>
                           </Paper>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={3}>
                           <Paper className={classNames(classes.paperInfoDate, classes.tileStatus)} elevation={1}>
                              <Typography component="p">{t('lessons.individual.detail.modality')}</Typography>
                              <Typography component="p" className={classes.tileStatusp}>
                                {info.translatedModality}
                              </Typography>
                              <span className={classNames("icon-"+((info.modality === 'Online')?"online":"in-person"), classes.textOpacity, classes.iconStyle)}></span>
                           </Paper>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={3}>
                           <Paper className={classNames(classes.paperInfoDate, classes.tileStatus)} elevation={1}>
                              <Typography component="p">{t('lessons.individual.detail.place')}</Typography>
                              <Typography component="p" className={classes.tileStatusp}>
                                {info.place}
                              </Typography>
                              <span className={classNames("icon-"+info.placeIcon, classes.textOpacity, classes.iconStyle)}></span>
                           </Paper>
                         </Grid>
                      </Grid>
                    </Grid>

                    <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                      <Grid container className={classes.container}>
                         
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={12}>
                           <Paper className={classNames(classes.paperInfoDate, classes.backBeige)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-user-alt", classes.textOpacity)}></span> {t('lessons.individual.detail.scheduledBy')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.scheduledBy==='t'?t('userType.tutor'):t('userType.student')}
                              </Typography>
                           </Paper>
                         </Grid>

                      </Grid>
                    </Grid>

                    <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                      <Grid container className={classes.container}>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={(typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length === 0)?6:12}>
                           <Paper className={classNames(classes.paperInfoDate, classes.backBeige)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-calendar-alt", classes.textOpacity)}></span> {t('lessons.individual.detail.scheduledDate')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.scheduledDate+" "+info.scheduledTime}
                              </Typography>
                           </Paper>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           {typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length === 0 && <Paper className={classNames(classes.paperInfoDate, classes.backBeige)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-clock", classes.textOpacity)}></span> {t('lessons.individual.detail.duration')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.duration?info.duration:'-----'}
                              </Typography>
                           </Paper>}
                         </Grid>
                      </Grid>
                    </Grid>
                    {typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length === 0 && <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                     <Grid container className={classes.container}>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-stopwatch", classes.textOpacity)}></span> {t('lessons.individual.detail.iniTime')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.initialTime?info.initialTime:'-----'}
                              </Typography>
                           </Paper>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-stopwatch", classes.textOpacity)}></span> {t('lessons.individual.detail.endTime')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.endTime?info.endTime:'-----'}
                              </Typography>
                           </Paper>
                         </Grid>
                      </Grid>
                    </Grid>}
                    <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                     <Grid container className={classes.container}>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-calendar-alt", classes.textOpacity)}></span> {t('lessons.individual.detail.applicationDate')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.created}
                              </Typography>
                           </Paper>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-calendar-alt", classes.textOpacity)}></span> {t('lessons.individual.detail.acceptanceDate')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.acceptedDate?info.acceptedDate+' '+info.acceptedTime:'-----'}
                              </Typography>
                           </Paper>
                         </Grid>
                      </Grid>
                    </Grid>
                    {typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length === 0 && <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                      <Grid container className={classes.container} style={{marginTop: "40px"}}>
                         <Grid item className={classNames(classes.grid)} xs={12} sm={12} md={6}>
                           <Typography variant="h4">{t('lessons.individual.detail.feedback')}</Typography>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}></Grid>
                      </Grid>
                    </Grid>}
                    {typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length === 0 && <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                      <Grid container className={classes.container}>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Typography variant="h6" className={classes.textOpacity}>{t('lessons.individual.detail.studentFeedback')}</Typography>
                           <Typography variant="h6" style={{marginTop: "10px"}}>
                                {studentRating}
                           </Typography>
                           <Grid container className={classes.container}>
                             <Grid item className={classNames(classes.grid, classes.centerLeft, classes.reviewCommentsContent, (info.studentComments?'':classes.disableText))} xs={12} sm={12} md={12}>
                                <p className={classes.reviewCommentsP}>
                                  <span className={classNames("fa fa-comment", classes.textOpacity, classes.reviewCommentsSpan)}></span>
                                  {info.studentComments?info.studentComments:t('noComments.text')}
                                </p>
                             </Grid>
                           </Grid>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Typography variant="h6" className={classes.textOpacity}>{t('lessons.individual.detail.tutorFeedback')}</Typography>
                           <Typography variant="h6" style={{marginTop: "10px"}}>
                               {tutorRating}
                           </Typography>
                            <Grid container className={classes.container}>
                             <Grid item className={classNames(classes.grid, classes.centerLeft, classes.reviewCommentsContent, (info.tutorComments?'':classes.disableText))} xs={12} sm={12} md={12}>
                                <p className={classes.reviewCommentsP}>
                                  <span className={classNames("fa fa-comment", classes.textOpacity, classes.reviewCommentsSpan)}></span>
                                  {info.tutorComments?info.tutorComments:t('noComments.text')}
                                </p> 
                             </Grid>
                           </Grid>
                         </Grid>
                      </Grid>
                    </Grid>}
                    {typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length > 0 && <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                      <Grid container className={classes.container} style={{marginTop: "40px"}}>
                         <Grid item className={classNames(classes.grid)} xs={12} sm={12} md={6}>
                           <Typography variant="h4"><span className={classNames("fa fa-ban", classes.textOpacity)}></span> {t('lessons.individual.detail.cancelationDetail')}</Typography>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}></Grid>
                      </Grid>
                    </Grid>}
                    {typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length > 0 && <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                     <Grid container className={classes.container}>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-user-alt", classes.textOpacity)}></span> {t('lessons.individual.detail.whoCanceled')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.cancellationReasonId !== 3? this.returnUserType(info.cancelledlessons[0].userType, t): '-----'}
                              </Typography>
                           </Paper>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-calendar-alt", classes.textOpacity)}></span> {t('lessons.individual.detail.cancelationDate')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.cancelledlessons[0].created}
                              </Typography>
                           </Paper>
                         </Grid>
                      </Grid>
                      <Grid container className={classes.container}>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-question", classes.textOpacity)}></span> {t('lessons.individual.detail.cancelationReason')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.cancelledlessons[0].cancellationreasons.reason}
                              </Typography>
                           </Paper>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-ruler-vertical", classes.textOpacity)}></span> {t('lessons.individual.detail.range')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.cancelledlessons[0].autocancellationId? info.cancelledlessons[0].autocancellationrules.name: '-----'}
                              </Typography>
                           </Paper>
                         </Grid>
                      </Grid>
                    </Grid>}
                  </Grid>

                </React.Fragment>
               )
            }
          }
        </Translation>

      </div>

    );

  }

}

export default withStyles(styles, { withTheme: true })(Detail);
