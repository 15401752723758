import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Layouts
import DashboardRoute from '../layouts/Dashboard/';
import LoginRoute from '../layouts/Login/';

// Components
import { Components } from './pages';
import NotFound from '../modules/Errors';

// Models
import UserProfile from "../models/UserProfile";
import user from "../models/userData";

// Api
import MainApi from "../api/MainApi";

import commonHelper from '../helpers/common';

const userProfile = new UserProfile();
const Api = new MainApi();
const common = new commonHelper();

// let history = useHistory();
let routes = [];


class Main extends Component {

  state = {
    closeSession: false
  };

  existsUrl = (items, url) => {

    let f = false;
  
    for (var k in items) {

      if (
          items[k].url === url ||
          (
            (items[k].url !== null && items[k].url.indexOf("/:") !== -1) &&
            (url !== null && url.trim() !== '')  &&
            (items[k].url.split("/").length-1 === url.split("/").length-1)
          )
        ) {
         f = true; 
         break;
      } else if (items[k].subSections.length > 0) {
        f = this.existsUrl(items[k].subSections, url);
        if (f) break;
      }

    }

    return f;

  };

  getPermissions = (token) => {

      Api.getPermissions(token).then(response => {

      let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

      if (responseData.modal) {
          console.log(responseData.message);
      } else {
        userProfile.saveOnlyPermissions(responseData.data);
        userProfile.saveUser(responseData.data.user);
        this.setState({closeSession:false});
        
        if (!this.existsUrl(responseData.data.menu, window.location.pathname)) {
          window.location.href = common.getStartUrl(responseData.data.menu);
        }
      }

      }).catch((err) => {Api.closeSession(err)});
  
  };

  returnSubMenu = (items) => {

    for (var k in items) {

      if (items[k] !== null) {

        if (items[k].url && items[k].url !== '')
          routes.push(<DashboardRoute exact path={items[k].url} component={Components[items[k].component]} key={k} />);

        if (items[k].subSections.length > 0)
          this.returnSubMenu(items[k].subSections);

      }

    }
  
  };

  componentDidMount() {
    
    const storagedToken = userProfile.getToken(); 
    
    if (storagedToken) {
        this._asyncRequest = this.getPermissions(storagedToken);
    }

  }

  componentWillUnmount() {

    if (this._asyncRequest) {
        this._asyncRequest.cancel();
    }

  }

  componentDidUpdate() {}

  render() {
    
    const storagedMenu = userProfile.getMenu();
    let tempRoutes = [];
    
    if ((!this.state.closeSession && (storagedMenu && storagedMenu !== null)) || user.closeSession) {
        
      user.closeSession = false;     
      tempRoutes = JSON.parse(storagedMenu);
      this.returnSubMenu(tempRoutes);

      routes.push(<Route exact path="/login" key="login"><Redirect to="/" /></Route>);
      routes.push(<DashboardRoute exact path="/promotion/formats" key="formats" component={Components['Formats']}></DashboardRoute>);
      routes.push(<DashboardRoute exact path="/promotion/audience" key="communication" component={Components['Communication']}></DashboardRoute>);
      routes.push(<DashboardRoute exact path="/promotion/audience/:segment/:download" key="segment" component={Components['Segment']}></DashboardRoute>);
      routes.push(<LoginRoute exact path="/start/:token" key="start" component={Components['Start']} />);

    } else {
      routes.push(<LoginRoute exact path="/login" key="login" component={Components['Login']} />);
      routes.push(<LoginRoute exact path="/recoverpassword" key="recoverpassword" component={Components['RecoverPassword']} />);
      routes.push(<LoginRoute exact path="/restorepassword/:token" key="restorepassword" component={Components['RestorePassword']} />);
      routes.push(<LoginRoute exact path="/start/:token" key="start" component={Components['Start']} />);
      routes.push(<Route exact path="/" key="loginredirect"><Redirect to="/login" /></Route>); 
    }
    
    return (
        
        <Switch>
          {routes}
          <Route path="*" component={NotFound} />
        </Switch>

    );

  }

}

export default Main;
