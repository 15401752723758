
class UserProfile {

  constructor(){
    this.userKey = 'user';
    this.tokenKey = 'token';
    this.lanKey = 'language';
    this.permisionsKey = 'permissions';
    this.menuKey = 'menu';
    this.userKey = 'user';
    this.alertKey = 'alert';
  }

  destroySession(){
    const cl = this.getLanguage();
    localStorage.clear();
    this.saveLanguage(cl);
  }

  getMenu(){
    return localStorage.getItem(this.menuKey);
  }

  getToken(){
    return localStorage.getItem(this.tokenKey);
  }

  getLanguage(){
    return localStorage.getItem(this.lanKey);
  }

  getUser(){
    return JSON.parse(localStorage.getItem(this.userKey));
  }

  getPermissions(){
    return JSON.parse(localStorage.getItem(this.permisionsKey));
  }

  getAlert(){
    let alert = JSON.parse(localStorage.getItem(this.alertKey));
    localStorage.setItem(this.alertKey, null);
    return alert;
  }

  saveToken(token) {
    localStorage.setItem(this.tokenKey, token);
  }

  saveLanguage(lan) {
    localStorage.setItem(this.lanKey, lan);
  }

  savePermissions(permissions) {
    localStorage.setItem(this.permisionsKey, JSON.stringify(permissions));
  }

  saveMenu(menu) {
    localStorage.setItem(this.menuKey, JSON.stringify(menu));
  }

  saveUser(user) {
    localStorage.setItem(this.userKey, JSON.stringify(user));
  }

  addAlert(data) {
    localStorage.setItem(this.alertKey, JSON.stringify(data));
  }

  saveOnlyPermissions(data) {

    this.savePermissions(data.permissions);
    this.saveMenu(data.menu);

  }

  saveUserData(data) {

    this.saveToken(data.token);
    this.saveOnlyPermissions(data);
    this.saveUser(data.user);
    this.saveLanguage(data.language);

  }

  completedGuide(bool){
    let data = JSON.parse(localStorage.getItem(this.userKey));
    data.universities['completedGuide'] = bool;
    localStorage.setItem(this.userKey, JSON.stringify(data));
  }

}

export default UserProfile;