import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";

import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

import i18nHelper from '../../helpers/i18n';
import UserProfile from "../../models/UserProfile";

const i18nH = new i18nHelper();
const userProfile = new UserProfile();
// .MuiInput-underline-163:hover:not(.MuiInput-disabled-162):not(.MuiInput-focused-161):not(.MuiInput-error-164):before

const lanStyle = theme => ({
  selectLan: {
    margin: '0 auto', 
    marginRight: 10,
    border: '0.5px solid #979797',
    padding: '2px 4px 2px 10px',
    borderRadius: 15,
    '&:before': {
      content: "",
      borderBottom: "0px solid rgba(0, 0, 0, 0.42)"
    }
  },
  selectLanicon: {
    color: "#333"
  },
  itemSelectLan: {
    fontSize: 14,
    paddingTop: '0',
    paddingBottom: '0'
  },
  underline: {
    '&:hover:before': {
      borderBottom: "none !important"
    },
    '&.Mui-focused::after': {
      content: 'none'
    }
  }
});

class Languages extends Component {

  state = {
    currentLan: i18nH.getLanguage()
  };

  changeLan = e => {

    const name = e.target.name;
    const value = e.target.value;

    i18nH.changeLanguage(value);
    userProfile.saveLanguage(value);

    this.setState({ [name]: value });
   
  };

	render(){

		const { classes, t } = this.props;

		return (
			<Grid item xs={12} sm={12} md={12} style={{textAlign: 'right'}}>
              <Select
                value={this.state.currentLan}
                name="currentLan"
                onChange={this.changeLan}
                className={classes.selectLan}
                classes={{ icon: classes.selectLanicon, selectMenu: classes.itemSelectLan }}
                input={<Input classes={{
                  underline: classes.underline
                }} />}
              >
                <MenuItem value={'en'} key={'lan1'}>{t('header.languages.english')}</MenuItem>
                <MenuItem value={'es'} key={'lan2'}>{t('header.languages.spanish')}</MenuItem>
              </Select>
            </Grid>
		);

	}

}

export default withStyles(lanStyle)(Languages);