import React, { Component } from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';

// handle of languages
import { Translation } from 'react-i18next';

// Api
import LessonsApi from "../../api/Lessons/LessonsApi";

// Models
import UserProfile from "../../models/UserProfile";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

import defaultAvatar from "../../assets/img/default-user.png";

const Api = new LessonsApi();
const userProfile = new UserProfile();

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#eee',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  body: {
    fontSize: 14,
  },
  root: {
    padding: '0px 0px 0px 10px'
  }
}))(TableCell);

class Detail extends Component {

  constructor(){

    super();

    this.state = {
        info: {},
        tabs: 'participants',
        tabsC: 'general'
    };

  }

  getOneRow = (id) => {
      
    Api.getDetail(
         userProfile.getToken(), 
         userProfile.getUser().universityId,
         id).then(response => {
            
         let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
           console.log(responseData.data);
         if (responseData.modal) {
            console.log(responseData.msg);
         } else {
            this.setState({info:responseData.data});
         }

      }).catch((err) => {Api.closeSession(err)});
    
  };

  returnStars = (rating) => {

    let stars = [], colorClass = '';
    const classes = this.props.classes;

    for (var i=0,x=1; i < 5; i++,x++) { 

        colorClass = (rating && x <= rating)? classes.colorYellow: classes.textOpacity;
        stars.push(<span key={i} className={classNames("fa fa-star", colorClass)}></span>);
    }

    return stars;

  }

  returnAssistance = (value) => {

    const classes = this.props.classes;

    let icon = (value > 0)? 'check': 'times', 
        colorClass = (value > 0)? classes.colorGreen: classes.colorRed,
        r = (<span className={classNames("fa fa-"+icon, colorClass)}></span>);

    return r;

  }

  showByTab = (event, tab) => {
    this.setState({tabs :tab});
  };

  showByTabCancelled = (event, tab) => {
    this.setState({tabsC:tab});
  };

  returnStatusColor = (st, classes) => {

      let data = classes.colorPending;

      if (st === 'Confirmada') data = classes.colorInfo;

      if (st === 'Activa') data = classes.colorSuccess;

      if (st === 'Finalizada') data = classes.colorFinished;

      if (st === 'Cancelada') data = classes.colorDanger;

      return data;

  }

  returnStatusName = (st, tr) => {

      let data = tr('status.pending');

      if (st === 'Confirmada') data = tr('status.confirmed');

      if (st === 'Activa') data = tr('status.active');

      if (st === 'Finalizada') data = tr('status.finished');

      if (st === 'Cancelada') data = tr('status.canceled');

      return data;

  }

  returnUserType = (user, tr) => {

    let text;

    switch(user) {
      case "Tutor":
        text = tr('userType.tutor');
        break;
      case "Estudiante":
        text = tr('userType.student');
        break;
      case "Admin":
        text = tr('userType.admin');
        break;
      default: 
        text = tr('userType.tutor');  
    }
    
    return text;

  }

  componentDidMount(){
    if (typeof this.props.id !== 'undefined' && this.props.id > 0)
        this.getOneRow(this.props.id);
  }

  render() {

    const {classes} = this.props;
    const {info, tabs, tabsC} = this.state;

    return (
      
      <div>
        <Translation>
         {
          (t, { i18n }) => {
            
            return (

                <Grid container className={classes.container}>
                  <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                    <Grid container className={classes.container}>
                       <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                         <Typography variant="h6" className={classes.textOpacity}><span className={classNames("fa fa-key", classes.textOpacity)}></span> {t('lessons.individual.detail.identifier')}: 
                         <span className={classNames(classes.fontDetail, classes.identifier)}>{info.identifier}</span></Typography>
                       </Grid>
                       <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                         <Typography variant="h6"><span className={classNames("fa fa-tachometer-alt", classes.textOpacity)}></span> {t('lessons.individual.detail.status')}: 
                         <span className={classNames(classes.fontDetail, classes.statusText, this.returnStatusColor(info.status, classes))}>{this.returnStatusName(info.status, t)}</span></Typography>
                       </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.grid} xs={12} sm={12} md={12} style={{marginTop:'10px'}}></Grid> 
                  <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                    <Grid container className={classes.container}>
                       <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={(typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length === 0)?6:12}>
                         <Avatar alt="tutor" src={info.tutorPhoto !== ''?info.tutorPhoto:defaultAvatar} className={classes.lessonAvatar} />
                         <Typography variant="subtitle1">{t('lessons.individual.detail.tutor')} </Typography>
                         <Typography variant="h6"><span className={classNames(classes.fontDetail, classes.identifier, classes.textOpacity)}>{info.tutor}</span></Typography>
                       </Grid>
                       {typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length === 0 && <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                         <Typography variant="h6">
                            <span className={classNames('fa fa-star', classes.textOpacity)}></span>
                            <span className={classNames(classes.fontDetail, classes.identifier, classes.textOpacity)}>{t('lessons.group.detail.tutorFeedback')}</span>
                         </Typography>
                         <Paper className={classNames(classes.paperInfoDate, classes.tileStatus)} elevation={1}>
                            <Typography component="p" className={classes.tileStatusp}>
                              {this.returnStars(info.studentRating)}
                            </Typography>
                            <Typography component="p" className={(info.studentComments !== '' && info.studentComments !== null)?'':classes.disableText}>
                              <span className={classNames("fa fa-comments", classes.textOpacity, classes.iconStyleComments)}></span>
                              {(info.studentComments !== '' && info.studentComments !== null)?' '+info.studentComments:' '+t('noComments.text')}
                            </Typography>
                         </Paper>
                       </Grid>}
                    </Grid>
                  </Grid>  
                  <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                   <Grid container className={classes.container}>
                       <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={3}>
                         <Paper className={classNames(classes.paperInfoDate, classes.tileStatus)} elevation={1}>
                            <Typography component="p">{t('lessons.individual.detail.category')}</Typography>
                            <Typography component="p" className={classes.tileStatusp}>
                              {info.category}
                            </Typography>
                            <span className={classNames("icon-"+info.categoryIcon, classes.textOpacity, classes.iconStyle)}></span>
                         </Paper>
                       </Grid>
                       <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={3}>
                         <Paper className={classNames(classes.paperInfoDate, classes.tileStatus)} elevation={1}>
                            <Typography component="p">{t('lessons.individual.detail.subject')}</Typography>
                            <Typography component="p" className={classes.tileStatusp}>
                              {info.subject}
                            </Typography>
                            <span className={classNames("fa fa-book", classes.textOpacity, classes.iconStyle)}></span>
                         </Paper>
                       </Grid>
                       <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={3}>
                         <Paper className={classNames(classes.paperInfoDate, classes.tileStatus)} elevation={1}>
                            <Typography component="p">{t('lessons.individual.detail.modality')}</Typography>
                            <Typography component="p" className={classes.tileStatusp}>
                              {info.modality}
                            </Typography>
                            <span className={classNames("icon-"+((info.modality === 'Online')?"online":"in-person"), classes.textOpacity, classes.iconStyle)}></span>
                         </Paper>
                       </Grid>
                       <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={3}>
                         <Paper className={classNames(classes.paperInfoDate, classes.tileStatus)} elevation={1}>
                            <Typography component="p">{t('lessons.individual.detail.place')}</Typography>
                            <Typography component="p" className={classes.tileStatusp}>
                              {(info.place !== '' && info.place !== null)?info.place:t('lessons.group.detail.placeNotDefined')}
                            </Typography>
                            <span className={classNames((info.modality === 'Online')?"icon-online":"fa fa-university", classes.textOpacity, classes.iconStyle)}></span>
                         </Paper>
                       </Grid>
                    </Grid>
                  </Grid>

                  <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                    <Grid container className={classes.container}>
                        
                        <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={12}>
                          <Paper className={classNames(classes.paperInfoDate, classes.backBeige)} elevation={1}>
                            <Typography component="p" style={{fontSize: "1em"}}>
                              <span className={classNames("fa fa-user-alt", classes.textOpacity)}></span> {t('lessons.individual.detail.scheduledBy')}:
                            </Typography>
                            <Typography variant="h6" component="h3">
                              {info.scheduledBy==='t'?t('userType.tutor'):t('userType.student')}
                            </Typography>
                          </Paper>
                        </Grid>

                    </Grid>
                  </Grid>

                  <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                   <Grid container className={classes.container}>
                       <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={(typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length === 0)?6:12}>
                         <Paper className={classNames(classes.paperInfoDate, classes.backBeige)} elevation={1}>
                            <Typography component="p" style={{fontSize: "1em"}}>
                              <span className={classNames("fa fa-calendar-alt", classes.textOpacity)}></span> {t('lessons.individual.detail.scheduledDate')}:
                            </Typography>
                            <Typography variant="h6" component="h3">
                              {info.scheduledDate+" "+info.scheduledTime}
                            </Typography>
                         </Paper>
                       </Grid>
                       <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                         {typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length === 0 && <Paper className={classNames(classes.paperInfoDate, classes.backBeige)} elevation={1}>
                            <Typography component="p" style={{fontSize: "1em"}}>
                              <span className={classNames("fa fa-clock", classes.textOpacity)}></span> {t('lessons.individual.detail.duration')}:
                            </Typography>
                            <Typography variant="h6" component="h3">
                              {info.duration?info.duration:'-----'}
                            </Typography>
                         </Paper>}
                       </Grid>
                    </Grid>
                  </Grid>
                  {typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length === 0 && <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                   <Grid container className={classes.container}>
                       <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                         <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                            <Typography component="p" style={{fontSize: "1em"}}>
                              <span className={classNames("fa fa-stopwatch", classes.textOpacity)}></span> {t('lessons.individual.detail.iniTime')}:
                            </Typography>
                            <Typography variant="h6" component="h3">
                              {info.initialTime?info.initialTime:'-----'}
                            </Typography>
                         </Paper>
                       </Grid>
                       <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                         <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                            <Typography component="p" style={{fontSize: "1em"}}>
                              <span className={classNames("fa fa-stopwatch", classes.textOpacity)}></span> {t('lessons.individual.detail.endTime')}:
                            </Typography>
                            <Typography variant="h6" component="h3">
                              {info.endTime?info.endTime:'-----'}
                            </Typography>
                         </Paper>
                       </Grid>
                    </Grid>
                  </Grid>}
                  <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                   <Grid container className={classes.container}>
                       <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                         <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                            <Typography component="p" style={{fontSize: "1em"}}>
                              <span className={classNames("fa fa-calendar-alt", classes.textOpacity)}></span> {t('lessons.individual.detail.applicationDate')}:
                            </Typography>
                            <Typography variant="h6" component="h3">
                              {info.created}
                            </Typography>
                         </Paper>
                       </Grid>
                       <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                         <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                            <Typography component="p" style={{fontSize: "1em"}}>
                              <span className={classNames("fa fa-calendar-alt", classes.textOpacity)}></span> {t('lessons.individual.detail.acceptanceDate')}:
                            </Typography>
                            <Typography variant="h6" component="h3">
                              {info.acceptedDate?info.acceptedDate+' '+info.acceptedTime:'-----'}
                            </Typography>
                         </Paper>
                       </Grid>
                    </Grid>
                  </Grid>
                  {typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length === 0 && <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                    
                    <Grid container className={classes.container} style={{marginTop:20}}>
                      <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={12}>

                        <Paper className={classNames(classes.root, classes.backGray)}>
                           <Tabs
                            value={tabs}
                            onChange={this.showByTab}
                            indicatorColor="primary"
                            textColor="primary"
                            >
                            <Tab icon={<span className="fa fa-users"></span>} value={"participants"} label={t('lessons.group.detail.tabs.participants.title') + ' ('+info.participants.length+'/'+info.limit+')'} />
                            <Tab icon={<span className="fa fa-history"></span>} value={"logs"} label={t('lessons.group.detail.tabs.history.title')} />
                          </Tabs>
                        </Paper>
                      
                      </Grid>
                    </Grid>

                    <Grid container className={classes.container}>
                      {tabs === 'participants' && <Grid item className={classNames(classes.grid)} xs={12} sm={12} md={12}>
                       <Paper>
                        <div style={{overflowY: 'scroll', height: 250, width: '100%'}}>
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <CustomTableCell style={{width:'30%'}}>{t('lessons.group.detail.tabs.participants.columns.student')}</CustomTableCell>
                                <CustomTableCell style={{width:'10%', textAlign:'center'}} align="center">{t('lessons.group.detail.tabs.participants.columns.attendance')}</CustomTableCell>
                                <CustomTableCell style={{width:'15%', textAlign:'center'}} align="center">{t('lessons.group.detail.tabs.participants.columns.rating')}</CustomTableCell>
                                <CustomTableCell style={{width:'45%'}} align="center">{t('lessons.group.detail.tabs.participants.columns.comment')}</CustomTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {info.participants.map(row => {
                                return (
                                  <TableRow className={classes.row} key={row.id}>
                                    <CustomTableCell component="th" scope="row">
                                      <div id="cntLabel">
                                        <Avatar alt="student" src={row.photo !== ''?row.photo:defaultAvatar} className={classes.smallAvatar} />
                                        <Typography style={{display: "inline-block", fontSize: 12}}>{row.fullName}</Typography>
                                        {row.guest?<span className="guest">{t('guest')}</span>:''}
                                      </div>
                                    </CustomTableCell>
                                    <CustomTableCell align="center" style={{textAlign:'center'}}>{this.returnAssistance(row.assistance)}</CustomTableCell>
                                    <CustomTableCell align="right" style={{textAlign:'center'}}>{this.returnStars(row.rating)}</CustomTableCell>
                                    <CustomTableCell align="right" style={{fontSize: 12, textAlign:'center', color:(row.comments?'':'rgba(0, 0, 0, 0.26)')}}>{row.comments?row.comments:t('noComments.text')}</CustomTableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </div>
                       </Paper>

                      </Grid>}
                      {tabs === 'logs' && <Grid item className={classNames(classes.grid)} xs={12} sm={12} md={12}>
                          <Paper>
                          <div style={{overflowY: 'scroll', height: 250, width: '100%'}}>
                            <Table className={classes.table}>
                              <TableBody>
                                {info.logs.map(row => {
                                  return (
                                    <TableRow className={classes.row} key={row.id}>
                                      <CustomTableCell component="th" scope="row">
                                        <div id="cntLabel">
                                          <Avatar alt="student" src={row.photo !== ''?row.photo:defaultAvatar} className={classes.smallAvatar} />
                                          <Typography style={{display: "inline-block", fontSize: 12}}>{row.fullName}</Typography>
                                          {row.guest?<i class="fa fa-envelope-open" style={{color: "#3498db", float: 'right', marginTop: 8}}></i>:''}
                                        </div>
                                      </CustomTableCell>
                                      <CustomTableCell align="right" style={{fontSize: 12}}>
                                        <span className={classNames(classes.statusText, (row.type === 'Confirmacion')?classes.colorSuccess:classes.colorDanger)}
                                        style={{fontWeight: "bold"}}>
                                          {(row.type === 'Confirmacion')?t('lessons.group.detail.tabs.history.columns.actionConfirm'):t('lessons.group.detail.tabs.history.columns.actionCancel')}
                                        </span>  
                                      </CustomTableCell>
                                      <CustomTableCell align="right" style={{fontSize: 12}}>{t('lessons.group.detail.tabs.history.columns.inStatus')+' '+this.returnStatusName(row.status, t)}</CustomTableCell>
                                      <CustomTableCell align="right" style={{fontSize: 12}}>{t('lessons.group.detail.tabs.history.columns.theDay')+' '+row.dt}</CustomTableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </div>
                         </Paper> 
                      </Grid>}
                    </Grid>

                  </Grid>}
                  {typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length > 0 && <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                    <Grid container className={classes.container} style={{marginTop: "40px"}}>
                       <Grid item className={classNames(classes.grid)} xs={12} sm={12} md={6}>
                         <Typography variant="h4"><span className={classNames("fa fa-ban", classes.textOpacity)}></span> {t('lessons.individual.detail.cancelationDetail')}</Typography>
                       </Grid>
                       <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}></Grid>
                    </Grid>
                  </Grid>}
                  
                  {typeof info.cancelledlessons !== 'undefined' && info.cancelledlessons.length > 0 && <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                    
                    <Grid container className={classes.container}>
                      <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={12}>

                        <Paper className={classNames(classes.root, classes.backGray)}>
                           <Tabs
                            value={tabsC}
                            onChange={this.showByTabCancelled}
                            indicatorColor="primary"
                            textColor="primary"
                            >
                            <Tab icon={<span className="fa fa-box"></span>} value={"general"} label={t('lessons.group.detail.tabs.cancellationGeneralData.title')} />
                            <Tab icon={<span className="fa fa-history"></span>} value={"logs"} label={t('lessons.group.detail.tabs.cancellationHistory.title')} />
                          </Tabs>
                        </Paper>
                      
                      </Grid>
                    </Grid>

                    {tabsC === 'general' && <Grid container>
                        
                      <Grid container>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-user-alt", classes.textOpacity)}></span> {t('lessons.individual.detail.whoCanceled')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.cancellationReasonId !== 3? this.returnUserType(info.cancelledlessons[0].userType, t): '-----'}
                              </Typography>
                           </Paper>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-calendar-alt", classes.textOpacity)}></span> {t('lessons.individual.detail.cancelationDate')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.cancelledlessons[0].created}
                              </Typography>
                           </Paper>
                         </Grid>
                      </Grid>
                      <Grid container className={classes.container}>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-question", classes.textOpacity)}></span> {t('lessons.individual.detail.cancelationReason')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.cancelledlessons[0].cancellationreasons.reason}
                              </Typography>
                           </Paper>
                         </Grid>
                         <Grid item className={classNames(classes.grid, classes.centerContent)} xs={12} sm={12} md={6}>
                           <Paper className={classNames(classes.paperInfoDate, classes.backGray)} elevation={1}>
                              <Typography component="p" style={{fontSize: "1em"}}>
                                <span className={classNames("fa fa-ruler-vertical", classes.textOpacity)}></span> {t('lessons.individual.detail.range')}:
                              </Typography>
                              <Typography variant="h6" component="h3">
                                {info.cancelledlessons[0].autocancellationId? info.cancelledlessons[0].autocancellationrules.name: '-----'}
                              </Typography>
                           </Paper>
                         </Grid>
                      </Grid>

                    </Grid>}

                    {tabsC === 'logs' && <Grid container>
                      <Grid item className={classNames(classes.grid, classes.container)} xs={12} sm={12} md={12}>
                         <Paper>
                          <div style={{overflowY: 'scroll', height: 250, width: '100%'}}>
                            <Table className={classes.table}>
                              <TableBody>
                                {info.logs.map(row => {
                                  return (
                                    <TableRow className={classes.row} key={row.id}>
                                      <CustomTableCell component="th" scope="row">
                                        <Avatar alt="student" src={row.photo !== ''?row.photo:defaultAvatar} className={classes.smallAvatar} />
                                        <Typography style={{display: "inline-block", fontSize: 12}}>{row.fullName}</Typography>
                                      </CustomTableCell>
                                      <CustomTableCell align="right" style={{fontSize: 12}}>
                                        <span className={classNames(classes.statusText, (row.type === 'Confirmacion')?classes.colorSuccess:classes.colorDanger)}
                                        style={{fontWeight: "bold"}}>
                                          {(row.type === 'Confirmacion')?t('lessons.group.detail.tabs.history.columns.actionConfirm'):t('lessons.group.detail.tabs.history.columns.actionCancel')}
                                        </span>  
                                      </CustomTableCell>
                                      <CustomTableCell align="right" style={{fontSize: 12}}>{t('lessons.group.detail.tabs.history.columns.inStatus')+' '+this.returnStatusName(row.status, t)}</CustomTableCell>
                                      <CustomTableCell align="right" style={{fontSize: 12}}>{t('lessons.group.detail.tabs.history.columns.theDay')+' '+row.dt}</CustomTableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </div>
                         </Paper> 
                      </Grid>
                    </Grid>}

                  </Grid>}

                </Grid>
              )
            }
          }
        </Translation>
      </div>

    );

  }

}

export default withStyles(styles, { withTheme: true })(Detail);
