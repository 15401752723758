import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import styles from "../../../assets/jss/modules/loginStyles";

let max = 5, min = 1, randNro = Math.random()*(max-min)+min <<0;
// local way
// require('../../../assets/img/login_banners/banner1.jpg')
let banners = {
   '1': {'img': 'https://d10epvfnplv6o9.cloudfront.net/images/web/control-panel/login-banner-1.png'},
   '2': {'img': 'https://d10epvfnplv6o9.cloudfront.net/images/web/control-panel/login-banner-2.png'},
   '3': {'img': 'https://d10epvfnplv6o9.cloudfront.net/images/web/control-panel/login-banner-3.png'},
   '4': {'img': 'https://d10epvfnplv6o9.cloudfront.net/images/web/control-panel/login-banner-4.png'}
};

class LoginBanners extends Component {

  constructor(props) {
    
    super(props);
    
    this.state = {}

  }

  render() {
    
    const { classes } = this.props;
    
    return (
    
        <Grid item className={classes.zeroPadding} xs={12} sm={12} md={8}>
           <img src={banners[randNro].img} alt="banner" className={classes.homeBanner} />
        </Grid>

    );

  }

}

export default withStyles(styles, { withTheme: true })(LoginBanners);