import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IOSSwitch from './IOSSwitch';

// handle of languages
import { Translation } from 'react-i18next';

import {styles} from "../../assets/jss/modules/mainStyles";

class ActiveCheck extends Component {

  change = name => e => {
    this.props.eventChange(name, e);
  } 

  render() {
    
    const { classes, label, status, value, sameCampus } = this.props;

    return (
      
      <Translation>
       {
        (t, { i18n }) => {
           return (
             <FormControl fullWidth={true} className={classes.formControl} style={{marginLeft:30}}>
              <FormControlLabel
                control={
                  <IOSSwitch 
                    disableRipple
                    checked={status}
                    onChange={this.change(value)}
                    value={value}
                    disabled={sameCampus}
                  />
                }
                label={<div><span style={{fontWeight:500}}>{label}</span> <span style={{fontSize:11}}>{t('users.form.tutor.tabDisponibility.msgActiveDay')}</span></div>}
              />
            </FormControl>    
            )
          }
        }
        </Translation>

    );

  }

}

export default withStyles(styles, { withTheme: true })(ActiveCheck);