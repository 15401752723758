import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';

// Components
import TablePaginationActionsWrapped from "../../components/Datagrid/TablePaginationActions";

//import UserProfile from "../../models/UserProfile";

//const userProfile = new UserProfile();
//const cUser = userProfile.getUser();

let backColor = "#646464";

/*if ((cUser !== null) && (typeof cUser.universities !== 'undefined') && (typeof cUser.universities.backendstyles !== 'undefined') 
    && (typeof cUser.universities.backendstyles[0] !== 'undefined')) {
    backColor = cUser.universities.backendstyles[0].headerColor;
}*/

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: "#f9f9f9",
    },
  }
});

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: backColor,
    color: theme.palette.common.white,
    textTransform: "uppercase"
  },
  body: {
    fontSize: 13,
    color: "#444",
    font: "13px/1.7em 'Open Sans', 'trebuchet ms', arial, sans-serif",
    padding: "18px 10px 18px 20px"
  },
}))(TableCell);

class DataGrid extends Component {

  createHeader = (columns) => {
  	
  	let headers = [], label;

    for (var key in columns) {
        
        label = (typeof columns[key].label !== 'undefined')? columns[key].label: key; 
		    headers.push(<CustomTableCell key={key}>{label}</CustomTableCell>);

    }

    return headers;

  }

  returnRowsCells = (columns, row) => {

  	let cells = [], index = 0, firstTH = {};
  	
  	for (var key in columns) {

  		if (index === 0) {
		    firstTH = {component:"th", scope:"row"};
		} else { firstTH = {}; }
    	
    	cells.push(<CustomTableCell {...firstTH} key={key}>{row[key]}</CustomTableCell>);
    	index++;
        
    }
    
    return cells;
  
  }

  render() {
    
  	const { classes, data, totalRows, rowsPerPage, columns, page, eventOnChange } = this.props;
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalRows - page * rowsPerPage);
    const emptyRows = 0;

    const headers = this.createHeader(columns);
    const colSpan = Object.keys(columns).length;

    const rows = data.map((row, index) => {
               let st = {};
               if (typeof row.activeAlert !== 'undefined' && row.activeAlert) st = {backgroundColor: "#fffff2"};
                return (
                  <TableRow className={classes.row} key={row.id} style={st}>
                    {this.returnRowsCells(columns, row)}
                  </TableRow>
                );
              });

    return (
      
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headers}
            </TableRow>
          </TableHead>
            <TableBody>
              {rows}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <CustomTableCell colSpan={2} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={colSpan}
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={eventOnChange}
                  ActionsComponent={TablePaginationActionsWrapped}
                  rowsPerPageOptions={[]}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );

  }

}

export default withStyles(styles, { withTheme: true })(DataGrid);
