import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import AlertMessageContent from "../../components/Modal/AlertMessageContent";
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMessage, statusMessage } from '../../redux/actions/global';

const AlertMessage = ({ open, variant, message, state, getMessage, statusMessage }) => {

    const [timer, setTimer] = useState('');

    useEffect( () => {
        // if (open)
        statusMessage(open);
        if (message&&variant)
            getMessage(message,variant)
    },[open,variant,message,getMessage, statusMessage]);


    useEffect( () => {
        
        if (state.status){
            clearTimeout(timer);
            setTimer(setTimeout( () => {
                 statusMessage(false);
            },10000));
        }
    }, [state.status] );

    return <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={ state.status }
            autoHideDuration={120000}
            onClose={() => statusMessage(false)}
        >
        <AlertMessageContent
            onClose={() => statusMessage(false)}
            variant={ state.type }
            message={ state.message }
        />
    </Snackbar>

}

const mapStateToProps = state => ({
    state: state.global.message
})

const mapDispatchToProps = {
   getMessage,
   statusMessage
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AlertMessage));