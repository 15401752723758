import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
// import { cold } from 'react-hot-loader';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';

// Components
import Datagrid from "../../components/Datagrid/Grid";
import SearcherInput from "../../components/Inputs/SearcherInput";
import AlertDelete from "../../components/Modal/AlertDelete";
import AlertMessage from "../../components/Modal/AlertMessage";

// Api
import CategoriesApi from "../../api/Subjects/CategoriesApi";

// Models
import UserProfile from "../../models/UserProfile";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

// handle of languages
import { Translation } from 'react-i18next';

const Api = new CategoriesApi();
const userProfile = new UserProfile();

let alertMessage = '';
let alertClassName = 'success';
let idToDelete = 0;
let status = 'Activo';
let rowsPerPage = 20;
let totalRows = 0;
let currentPage = 0;
let textToSearch = '';
let columns = {
    'name': {}, 
    // 'status': {}, 
    'icon': {}, 
    'color': {}, 
    'subjectsTotal': {}
  };

class Categories extends Component {

  constructor(){
	  super();
	  this.state = {
	  	data: [],
	  	tab: status,
	  	openAlert: false,
	  	openAlertMessage: false
	  }

	  Api.limit = rowsPerPage;
  }

  getRowsPerPage = (page) => {
      
	  Api.getCategories(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   page, 
	  	   textToSearch,
	  	   status).then(response => {

	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

	       if (responseData.modal) {
	           console.log(responseData.message);
	       } else {
	     
	        totalRows = responseData.data.total;
	        this.setState({data: responseData.data.rows});
	       
	       }

      }).catch((err) => {Api.closeSession(err)});
    
  };

  delCategory = () => {

	  Api.deleteCategory(
	  	   userProfile.getToken(), 
	  	   idToDelete,
	  	   userProfile.getUser().universityId,
	  	   currentPage, 
	  	   textToSearch,
	  	   status).then(response => {

	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};
	       	
	        if (responseData.modal) {
	           alertMessage = responseData.message;
	           alertClassName = responseData.data.className;
	           this.setState({ openAlertMessage: true });
	        }

	        totalRows = responseData.data.total;
	        this.setState({data: responseData.data.rows});
	       

      }).catch((err) => {Api.closeSession(err)});
    
  };

  handleChangePage = (event, page) => {
    currentPage = page;
    this.getRowsPerPage(page);
  };

  searchRows = (e, search) => {
    textToSearch = search.value;
    currentPage = 0;
    this.getRowsPerPage(currentPage);
  };

  filterByStatus = (event, tab) => {
    status = tab;
    currentPage = 0;
    this.getRowsPerPage(currentPage);
  };

  showAlert = (id, e) => {
    idToDelete = id;
    this.setState({ openAlert: true });
  };

  closeAlert = () => {
    this.setState({ openAlert: false });
  };

  deleteRow = () => {
    this.setState({ openAlert: false });
    this.delCategory();
    idToDelete = 0;
  };

  closeMessage = (event, reason) => {
    this.setState({ openAlertMessage: false });
  };

  translateColumns = (tr) => {

    columns.name.label = tr('categories.table.columns.name');
    // columns.status.label = tr('categories.table.columns.status');
    columns.icon.label = tr('categories.table.columns.icon');
    columns.subjectsTotal.label = tr('categories.table.columns.subjectsTotal');
     
    if (typeof userProfile.getPermissions()['categories_write'] !== 'undefined')
      columns.opciones = {label: tr('categories.table.columns.options')};

    return columns;
  
  }

  componentWillMount(){
  	this.getRowsPerPage(currentPage);
  }

  componentDidMount(){

    let alert = userProfile.getAlert();
    if (alert && alert !== null) {
        
        alertMessage = alert.msg;
        alertClassName = alert.class; 
        this.setState({ openAlertMessage: true });

    }

  }

  render() {
    
  	const { classes, getSectionTitle } = this.props;
    let { data, openAlert, openAlertMessage } = this.state;
    
    data = data.map((val, key) => {
          
          val.color = (<div style={{backgroundColor: val.color, padding: "4px"}}>&nbsp;</div>);
          val.icon = (<span className={classNames(classes.iconColumn, "icon-"+val.icon)} ></span>);

          if (typeof userProfile.getPermissions()['categories_write'] !== 'undefined')
            val.opciones = (<div>
                            <IconButton key={val.id+'e'} className={classes.button} style={{marginTop:0, color: '#3498db', display: 'inline-block'}} 
                              classes={{
                                root: classes.zeroPadTableBtn
                              }}
                              component={Link} to={"/categories/"+val.id}>
                              <EditIcon />
                            </IconButton>
                            <IconButton key={val.id+'d'} className={classes.button} 
                              classes={{
                                root: classes.zeroPadTableBtn
                              }}
                              style={{marginTop:0, color: '#d9534f', display: 'inline-block', padding: 0}} onClick={this.showAlert.bind(this, val.id)}>
                              <ClearIcon />
                            </IconButton>
                          </div>);
                                        
          return val;
    });

    return (
      
      <div>

        <Translation>
         {
              (t, { i18n }) => {
                
                return (
                  <React.Fragment>
                    <Grid container className={classes.container}>
            	        <Grid item className={classes.grid} xs={12} sm={12} md={12}>
            	          <Paper className={classNames(classes.root, classes.paperCss)}>
            		         <Tabs
                            value={status}
                            onChange={this.filterByStatus}
                            indicatorColor="primary"
                            textColor="primary"
            		          >
            		          <Tab icon={<span className="fa fa-th"></span>} value={"Activo"} label={t('categories.filtersOptions.active')} />
            		          <Tab icon={<span className="fa fa-power-off"></span>} value={"Inactivo"} label={t('categories.filtersOptions.inactive')} />
            		        </Tabs>
                        {typeof userProfile.getPermissions()['categories_write'] !== 'undefined' &&
                        <Button color="primary" variant="contained" component={Link} to="/categories/add" className={classes.btnAdd}>
                          {t('categories.actionsButtons.add')}
                        </Button>}
            		      </Paper>
            	        </Grid>
            	        <Grid item className={classes.grid} xs={12} sm={12} md={12}>
                           
            		          <SearcherInput eventChange={this.searchRows} textToSearch={textToSearch} />
                          <Datagrid data={data} page={currentPage} totalRows={totalRows} rowsPerPage={rowsPerPage} columns={this.translateColumns(t)} eventOnChange={this.handleChangePage} />
                                    
            	        </Grid>
            	      </Grid>
                    <AlertDelete open={openAlert} eventClose={this.closeAlert} eventOk={this.deleteRow} content={t('categories.modalDelete.content')} />
                    <AlertMessage open={openAlertMessage} variant={alertClassName} message={alertMessage} eventClose={this.closeMessage} />
                  </React.Fragment>
               )
            }
          }
        </Translation>

      </div>

    );

  }

}

export default withStyles(styles, { withTheme: true })(Categories);
