import React, { useState,useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

const Graphic = ({ labels, data, color, label, labelsTitle=[] }) =>{

    const [initialState,setInitialState] = useState({});
    

    useEffect(() => { // componentDidMount
        setInitialState({
            labels: labels,
            datasets: [
                {
                    label: label,
                    lineTension: 1,
                    tooltips: { caretSize: 10},
                    backgroundColor: color,
                    borderWidth: 0,
                    data: data
                }
            ]
        })
    },[labels,data,color,label])


    return <Bar data={initialState} 
                height={150}
                options={{ legend: {display: false},
                           maintainAspectRatio: false,
                           scales: { yAxes: [{ ticks: { beginAtZero: true, min: 0 } }] },
                           tooltips: { callbacks: {
                                title: e =>  {
                                    const { index, label } = e[0];
                                    return labelsTitle.length > 0?labelsTitle[index]:label;
                                },
                            } }
                        }} />

}

export default Graphic