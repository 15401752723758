import React, { Component } from 'react';
import classNames from 'classnames';

// Libs
import ReeValidate from 'ree-validate';

import axios from 'axios';
import { Link } from 'react-router-dom';

import {urlApi} from "../../variables/globals";

// Material ui Components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// handle of languages
import { Translation } from 'react-i18next';
import i18nHelper from '../../helpers/i18n';

import commonHelper from '../../helpers/common';

// Components
import CustomInput from "../../components/Inputs/CustomInput";
import Translate from '../../layouts/Login/Translate';
import Footer from '../../layouts/Login/Footer';

// Icons
import Icon from '@material-ui/core/Icon';

// Styles
import styles from "../../assets/jss/modules/loginStyles";

import logo from "../../assets/img/logo-horizontal.svg";
import check from "../../assets/img/check.png";
import backArrow from "../../assets/img/backArrow.svg";

import Banners from "./components/LoginBanners";

// Session
import UserProfile from "../../models/UserProfile";
import user from "../../models/userData";

let currentToken;
let correctColor = '#2188FC';
let urlToRedirect = '/';
const userProfile = new UserProfile();
const i18nH = new i18nHelper();
const common = new commonHelper();

class RestorePassword extends Component {

  constructor(props) {
    
    super(props);
    
    this.validator = new ReeValidate.Validator({
      // password: 'required|min:6'
    })
    
    this.state = {
      formData: {
        password: '',
      },
      loading: false,
      disabledButton: true,
      message: '',
      errors: this.validator.errors,
      showPassword: false,
      showRestorePassword: 'none',
      hideResponse: 'none',
      showCorrectResponse: 'none',
      showArrowBack: 'block',
      response: {
        msg: '',
        title: ''
      },
      rules: {
        minColor: '',
        minCapitalColor: '',
        minLowercaseColor: '',
        symbolColor: '',
        numberColor: ''
      }
    }
    
    this.onChange = this.onChange.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);

  }

  restorePassword(formData, t){
    axios.request({
           method:'patch',
           url:urlApi+'administrators/restorepassword',
           data:{token:currentToken, password: formData.password},
           headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18nH.getLanguage()
         }
    }).then(response => {
           
         this.handleLoading();
         let responseData = response.data.data;

         if (responseData.modal) {

            let responseMsg = responseData.message; 

            this.setState({ message: responseMsg });

         } else {

           userProfile.saveUserData(responseData.data);
           user.closeSession = true;
           urlToRedirect = common.getStartUrl(responseData.data.menu);
           i18nH.changeLanguage(responseData.data.language);
           this.setState({ showRestorePassword: 'none', hideResponse: 'block', showCorrectResponse: 'block', showArrowBack: 'none',
                           response: {msg: responseData.message, title: responseData.data.title } });
                      
         }

    }).catch((err) => {this.handleLoading(); console.log(err);});
  }

  isValidToken(token){
    currentToken = token;
    axios.request({
           method:'patch',
           url:urlApi+'administrators/exitsrecoverytoken',
           data:{token:currentToken},
           headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18nH.getLanguage()
           }
    }).then(response => {
           
         let responseData = response.data.data;

         if (responseData.modal) {

            let responseMsg = responseData.message, title = (typeof responseData.data.title !== 'undefined')?responseData.data.title:'';

            this.setState({ response: {msg: responseMsg, title: title }, hideResponse: 'block' });

         } else {

           this.setState({ showRestorePassword: 'block'});
                      
         }

    }).catch((err) => { console.log(err);});
  }

  areThereCapitalLetters(str){
      return /[A-Z]{1}/g.test(str);
  }

  areThereLowercase(str){
      return /[a-z]{1}/g.test(str);
  }

  areThereNumbers(str){
      return /[0-9]{1}/g.test(str);
  }

  areThereSymbols(str){
      return /[-!$%^&*()_@¿#+|~=`{}[\]:";'<>?,./]/.test(str);
  }
  
  onChange(e) {

    const name = e.target.name;
    const value = e.target.value;

    this.setState({ formData: { ...this.state.formData, [name]: value } });

    let dis = false, sL = value.length, minColor, minCapitalColor, minLowercaseColor, symbolColor, numberColor;

    minColor = (sL >= 8)? correctColor:'';
    minCapitalColor = (this.areThereCapitalLetters(value))? correctColor:'';
    minLowercaseColor = (this.areThereLowercase(value))? correctColor:'';
    symbolColor = (this.areThereSymbols(value))? correctColor:'';
    numberColor = (this.areThereNumbers(value))? correctColor:'';

    if (minColor === '' || minCapitalColor === '' || minLowercaseColor === '' || symbolColor === '' || numberColor === '') dis = true;

    this.setState({ disabledButton: dis, rules: {minColor, minCapitalColor, minLowercaseColor, symbolColor, numberColor}});

  }

  handleLoading = () => {
    this.setState({ loading: !this.state.loading });
    this.setState({ disabledButton: !this.state.disabledButton });
  };
  
  submit(formData, t) {
    this.handleLoading();
    this.restorePassword(formData, t);
  }
  
  validateAndSubmit(t, e) {

    e.preventDefault()
    
    const { formData } = this.state;
    const { errors } = this.validator;

    this.validator.validateAll(formData)
      .then(success => {
        if (success) {
          this.submit(formData, t)
        } else {
          this.setState({ errors })
        }
      })
    
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  componentWillMount(){
    if (typeof this.props.match.params.token !== 'undefined' && this.props.match.params.token.trim() !== '')
        this.isValidToken(this.props.match.params.token.trim());
  }

  render() {

  	const { classes } = this.props;
    const { errors, rules } = this.state;
  
    return (
       
       <Translation>
        {
          (t, { i18n }) => {
             return (       
                    <form onSubmit={this.validateAndSubmit.bind(this, t)}>

                        <Grid container className={classes.containerCenter}>
                            
                            <Banners />
                            
                            <Grid item className={classes.gridLogin} xs={12} sm={12} md={4}>
                              
                              
                              <Grid item className={classNames(classes.grid, classes.cntLogoLogin)} xs={12} sm={12} md={12}>
                                
                                <img src={logo} alt="logo" className={classes.logoLogin} />
                                <Grid container style={{marginTop: 30}}>

                                  <Grid container style={{display: this.state.showArrowBack}}>
                                    <Grid item className="" xs={12} sm={12} md={12}>
                                      <Link to="/login" className=""><img src={backArrow} alt="back arrow" className="" /></Link>
                                    </Grid>
                                  </Grid>

                                  <Grid item className={classNames(classes.center)} xs={12} sm={12} md={12} style={{display: this.state.showRestorePassword}}>
                                    <h4 className={classes.welcomeTitleLogin}>{t('restorePassword.title')}</h4>
                                  </Grid>

                                  <Grid item className={classNames(classes.center)} xs={12} sm={12} md={12} style={{display: this.state.hideResponse}}>
                                    <h4 className={classes.welcomeTitleLogin}>{this.state.response.title}</h4>
                                    <p className={classes.subTitleLogin}>{this.state.response.msg}</p> 
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={12} style={{display: this.state.showRestorePassword}}>
                                    
                                    <Grid container>
                                      <Grid item className={classNames(classes.grid, classes.center)} style={{marginTop:5}} xs={12} sm={12} md={12}>
                                        { this.state.loading ? <CircularProgress className={classes.progress} /> : ''}
                                        { this.state.message !== '' ? <SnackbarContent 
                                        className={classNames(classes.snackbar, classes.warning)}
                                        message={
                                          <span className={classes.message}>
                                            <Icon className={classNames(classes.icon, 'fa fa-exclamation-triangle')} />
                                            <Typography variant="subheading" className={classes.marginLeftTitle} color="inherit" noWrap>{this.state.message}</Typography>
                                          </span>
                                        } /> : ''}
                                      </Grid>
                                    </Grid>  

                                    <Grid container>  
                                      <Grid item className="" xs={12} sm={12} md={12} style={{paddingTop:0}}>
                                       <CustomInput
                                          formControlProps={{
                                            fullWidth: true,
                                            style: ({marginTop: 5})
                                          }}
                                          inputProps={{
                                            type:(this.state.showPassword ? "text" : "password"),
                                            name:"password",
                                            placeholder:(t('restorePassword.inputPasswordPlaceholder')),
                                            onChange:(this.onChange),
                                            style:({paddingTop:0}),
                                            endAdornment:(
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="Toggle password visibility"
                                                  onClick={this.handleClickShowPassword}
                                                >
                                                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }}
                                          customInputProps={{maxLength: "50"}}
                                          error={errors.has('password')}
                                        />
                                        { errors.has('password') &&
                                          <label className={classes.error} htmlFor="password"></label>
                                        }
                                      </Grid>
                                    </Grid>  

                                    <Grid container style={{marginTop: 15}}>
                                       <Grid item className="" xs={12} sm={12} md={12}>
                                          <Typography className={classes.ruleRestorePass} variant="caption" style={{color:rules.minColor, display: 'block'}} gutterBottom>
                                            <span className={classNames('far fa-check-circle')}></span> {t('restorePassword.rules.minChars')}
                                          </Typography>
                                          <Typography className={classes.ruleRestorePass} variant="caption" style={{color:rules.minCapitalColor, display: 'block'}} gutterBottom>
                                            <span className={classNames('far fa-check-circle')}></span> {t('restorePassword.rules.minUppercase')}
                                          </Typography>
                                          <Typography className={classes.ruleRestorePass} variant="caption" style={{color:rules.minLowercaseColor, display: 'block'}} gutterBottom>
                                            <span className={classNames('far fa-check-circle')}></span> {t('restorePassword.rules.minLowercase')}
                                          </Typography>
                                          <Typography className={classes.ruleRestorePass} variant="caption" style={{color:rules.symbolColor, display: 'block'}} gutterBottom>
                                            <span className={classNames('far fa-check-circle')}></span> {t('restorePassword.rules.symbol')}
                                          </Typography>
                                          <Typography className={classes.ruleRestorePass} variant="caption" style={{color:rules.numberColor, display: 'block'}} gutterBottom>
                                            <span className={classNames('far fa-check-circle')}></span> {t('restorePassword.rules.number')}
                                          </Typography>
                                       </Grid>
                                    </Grid>

                                    <Grid container style={{marginTop: 40}}>  
                                      <Grid item xs={12} sm={12} md={12}>
                                        <Button variant="contained" disabled={this.state.disabledButton} className={classes.button} type="submit">{t('restorePassword.btnSend')}</Button>
                                      </Grid>
                                    </Grid> 

                                  </Grid>

                                  <Grid container style={{display: this.state.showCorrectResponse, marginTop: 45}}>
                                    <Grid item className="" xs={12} sm={12} md={12}>
                                        
                                        <Grid container style={{textAlign: 'center'}}>  
                                          <Grid item xs={12} sm={12} md={12}>
                                            <img alt="right" src={check} />
                                          </Grid>
                                        </Grid>

                                        <Grid container style={{marginTop: 45}}>  
                                          <Grid item xs={12} sm={12} md={12}>
                                            <Button variant="contained" className={classes.button} type="button" onClick={()=>window.location.href = urlToRedirect}>{t('restorePassword.btnAdmin')}</Button>
                                          </Grid>
                                        </Grid>

                                    </Grid>
                                  </Grid>
                                  
                                </Grid>
                              </Grid>

                              <Grid container className={classes.cntFooter}><Translate t={t} /><Footer /></Grid>

                            </Grid>

                        </Grid>
                    </form> 

             )
           }
         }
        </Translation>

    );

  }

}

export default withStyles(styles, { withTheme: true })(RestorePassword);