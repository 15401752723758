import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import { Translation } from 'react-i18next';
import Input from '@material-ui/core/Input';

// Models
import UserProfile from "../../models/UserProfile";

// Api
import AdministratorsApi from "../../api/Administrators/AdministratorApi";

import i18n from '../../i18n';

const userProfile = new UserProfile();
const Api = new AdministratorsApi();

const styles = theme => ({
  icon: {
    margin: "0px",
    width: "auto",
    fontSize: "0.8em",
    paddingRight: "10px"
  },
  iconAuto: {
    margin: "0px",
    width: "auto"
  },
  iconRight: {
    paddingRight: "6px",
    overflow: 'none' 
  },
  iconLeft: {
    paddingLeft: "8px" 
  },
  iconOver: {
    overflow: 'initial'
  },
  menuUl: {
    paddingBottom: '0px',
    paddingTop: '0px'
  },
  menuDrown: {
    right: "8px",
    display: "flex",
    position: "absolute",
    alignItems: 'center'
  },
  whiteColor: {
    color: "#eee"
  },
  selectLan: {
    border: 'none',
    padding: '0px 3px 0px 8px',
    marginRight: 1,
    borderRadius: 15,
    color: "#eee",
    backgroundColor: 'rgba(255,255,255,0.32)',
    '&:before': {
      content: "none",
      borderBottom: "0px solid rgba(0, 0, 0, 0.42)"
    }
  },
  selectLanicon: {
    color: "#eee"
  },
  underline: {
    '&:hover': {
      borderBottom: "none"
    },
    '&.Mui-focused::after': {
      content: 'none'
    }
  },
  "itemArrow": {
    paddingRight: 23,
    fontSize: 15,
    paddingTop: 8
  },
  "spanDiv": {
    fontSize: 33,
    padding: 5,
    color: 'rgba(255,255,255,0.15)',
    paddingRight: 0
  },
  btnRoot: {
    fontWeight: 'normal'
  },
  iconRoot: {
    overflow: 'none'
  }
});


class OptionsMenu extends React.Component {
  
  state = {
    anchorEl: null,
    currentLan: userProfile.getLanguage()
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (e) => {
    
    if (e.target.id === 'itemLogout') {
      
      Api.deleteToken(userProfile.getToken()).then(response => {

        let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

        userProfile.destroySession();
        //this.props.history.push("/login");
        window.location.href = '/login';

      }).catch((err) => {Api.closeSession(err)});

    }
    
    this.setState({ anchorEl: null });
  
  };

  changeLan = e => {

    const name = e.target.name;
    const value = e.target.value;

    i18n.changeLanguage(value);
    this.props.changeMenu(value);

    this.setState({ [name]: value });
   
  };

  render() {

    const { anchorEl, currentLan } = this.state;
    const { classes } = this.props;

    let name;

    if (userProfile.getUser() != null) {
        name = userProfile.getUser().name;
    }

    return (
      <div className={classes.menuDrown}>    
       
       <Translation>
               {
                    (t, { i18n }) => {
                      return (

                        <React.Fragment>
                            <Select
                              value={currentLan}
                              onChange={this.changeLan}
                              name="currentLan"
                              className={classes.selectLan}
                              classes={{ icon: classes.selectLanicon, selectMenu: classes.itemArrow }}
                              input={<Input classes={{
                                underline: classes.underline
                              }} />}
                            >
                              <MenuItem value={'en'} key={'lan1'}>{t('header.languages.english')}</MenuItem>
                              <MenuItem value={'es'} key={'lan2'}>{t('header.languages.spanish')}</MenuItem>
                            </Select>
                            <span className={classes.spanDiv}>|</span>
                            <Button
                              aria-owns={anchorEl ? 'simple-menu' : null}
                              aria-haspopup="true"
                              onClick={this.handleClick}
                              className={classes.whiteColor}
                              classes={{root: classes.btnRoot}}
                            >
                              <Icon className={classNames(classes.iconAuto, classes.iconRight, classes.iconOver, 'fa fa-user-circle')} classes={{
                                root: classes.iconRoot
                              }} /> <Hidden smDown>{name} </Hidden>
                              <Icon className={classNames(classes.iconAuto, classes.iconLeft, 'fa fa-caret-down')} />
                            </Button>
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={this.handleClose}
                              className={classes.menuUl}
                            >
                              {/*<MenuItem onClick={this.handleClose} id="itemMyprofile"><Icon className={classNames(classes.icon, 'fa fa-user-alt')} /> Mi perfil</MenuItem>
                              <Divider />*/}
                              <MenuItem onClick={this.handleClose} id="itemLogout"><Icon className={classNames(classes.icon, 'fa fa-power-off')} />{i18n.t('header.adminOptions.exit')}</MenuItem>
                            </Menu>
                          </React.Fragment>
                        )
                  }
                }
              </Translation>

      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(OptionsMenu);