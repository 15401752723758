import React, { Component } from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StopIcon from '@material-ui/icons/Stop';

// Components
import Datagrid from "../../components/Datagrid/Grid";
import SearcherInput from "../../components/Inputs/SearcherInput";
import AlertMessage from "../../components/Modal/AlertMessage";
import AlertDelete from "../../components/Modal/AlertDelete";
import GenericModal from "../../components/Modal/GenericModal";

// handle of languages
import { Translation } from 'react-i18next';

// Detail Modal
import Detail from "./GroupDetail";

// Api
import LessonsApi from "../../api/Lessons/LessonsApi";

// Models
import UserProfile from "../../models/UserProfile";

// Styles
import {styles} from "../../assets/jss/modules/mainStyles";

const Api = new LessonsApi();
const userProfile = new UserProfile();

let alertMessage = '';
let idDetail = 0;
let lessonId = 0;
let alertClassName = 'success';
let status = 'Pendiente';
let type = 'Grupal';
let rowsPerPage = 20;
let totalRows = 0;
let currentPage = 0;
let textToSearch = '';
let columns = {
    'default': {
        'identifier': {}, 
        'scheduledDate':{}, 
        'subject':{},
        'modality': {},
        'tutor':{},
        'options': {}
    },
    'Finalizada': {
        'identifier': {}, 
        'scheduledDate':{}, 
        'subject':{},
        'modality': {},
        'tutor':{},
        // 'rating':{},
        'duration':{},
        'options': {}
    },
    'Cancelada': {
        'identifier': {}, 
        'cancelDate':{}, 
        'subject':{},
        'modality': {},
        'tutor':{},
        'options': {}
    }
};
let columnsToShow = columns['default'];

class GroupLessons extends Component {

  constructor(){
	  super();
	  this.state = {
	  	data: [],
	  	openAlertMessage: false,
      openModal: false,
      openAlert: false,
      showLoading: false
	  }

	  Api.limit = rowsPerPage;
  }

  getRowsPerPage = (page) => {
      
	  Api.search(
	  	   userProfile.getToken(), 
	  	   userProfile.getUser().universityId,
	  	   page, 
	  	   textToSearch,
	  	   status,
         type).then(response => {

	       let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

	       if (responseData.modal) {
	           console.log(responseData.message);
	       } else {
	       	
	        totalRows = responseData.data.total;
	        this.setState({data: responseData.data.rows});
	       
	       }

      }).catch((err) => {Api.closeSession(err)});
    
  };

  removeSelectedRow = (id) => {
    
    let rows = this.state.data;
    rows = rows.filter((val, i) => {
           return val.id !== id;
    });
    this.setState({ data: rows });
    
  };

  endLesson = () => {
      
    Api.end(
         userProfile.getToken(), 
         userProfile.getUser().universityId,
         lessonId).then(response => {
           
         this.handleLoading();
         let responseData = (typeof response !== 'undefined' && typeof response.data !== 'undefined')?response.data.data:{};

         if (responseData.modal) {
             alertMessage = responseData.message;
             alertClassName = responseData.data.className; 
             if (alertClassName === 'success') {
                this.removeSelectedRow(responseData.data.lessonId);
             }
             this.setState({ openAlertMessage: true });
         } else {
             this.showAlert(responseData);
         }

      }).catch((err) => {this.handleLoading();Api.closeSession(err)});
    
  };

  handleChangePage = (event, page) => {
    currentPage = page;
    this.getRowsPerPage(page);
  };

  handleLoading = () => {
    this.setState({ showLoading: !this.state.showLoading });
  };

  searchRows = (e, search) => {
    textToSearch = search.value;
    this.getRowsPerPage(currentPage);
  };

  filterByStatus = (event, tab) => {
    status = tab;
    if (status === 'Finalizada' || status === 'Cancelada') {
      columnsToShow = columns[status];
    } else { columnsToShow = columns['default']; }
    this.getRowsPerPage(currentPage);
  };

  closeMessage = (event, reason) => {
    this.setState({ openAlertMessage: false });
  };

  showModal = (id, e) => {
    idDetail = id; // e.target.id;
    this.setState({ openModal: true });
  };

  showAlert = (id, e) => {
    lessonId = id;
    console.log(lessonId);
    this.setState({ openAlert: true });
  };
  finish = () => {
    this.setState({ openAlert: false });
    this.handleLoading();
    this.endLesson();
    lessonId = 0;
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  closeAlert = () => {
    this.setState({ openAlert: false });
  };

  translateColumns = (tr, cols) => {

    for (var k in cols){
        cols[k].label = tr('lessons.table.columns.'+k);
    } 

    return cols;
  
  }

  componentWillMount(){
  	this.getRowsPerPage(currentPage);
  }

  componentDidMount(){
    
    let alert = userProfile.getAlert();
    if (alert && alert !== null) {
        
        alertMessage = alert.msg;
        alertClassName = alert.class; 
        this.setState({ openAlertMessage: true });

    }

  }

  render() {
    
  	const { classes } = this.props;
    let { data, openAlertMessage, openModal, openAlert } = this.state;

    data = data.map((val, key) => {

          if (typeof val.modality === 'string' || val.modality instanceof String)
            val.modality = (<span className="chipSpan group"><i className={(val.originalModality==='Online'?'icon-online custom-icon-online':'icon-in-person custom-icon-in-person')}></i> {val.modality}</span>);

          val.options = [(<IconButton key={val.id+'i'} color="primary" className={classes.button} style={{marginTop:0, display: 'inline-block', color: '#3498db'}} onClick={this.showModal.bind(this, val.id)}>
                            <VisibilityIcon />
                          </IconButton>)];                 

          if (val.status === 'Activa') {
              val.options.push(<IconButton key={val.id+'c'} className={classes.button} style={{marginTop:0, color: '#d43f3a', display: 'inline-block'}} 
                            onClick={this.showAlert.bind(this, val.id)} disabled={this.state.showLoading}>
                          <StopIcon />
                        </IconButton>);         
          }
                           
          return val;

    });

    return (
      
      <div>
        <Translation>
         {
          (t, { i18n }) => {
            
            return (
              <React.Fragment>

                <Grid container className={classes.container}>
        	        <Grid item className={classes.grid} xs={12} sm={12} md={12}>
        	          <Paper className={classNames(classes.root, classes.paperCss)}>
          		         <Tabs
          		          value={status}
          		          onChange={this.filterByStatus}
          		          indicatorColor="primary"
          		          textColor="primary"
          		          >
          		          <Tab icon={<span className="fa fa-power-off"></span>} value={"Pendiente"} label={t('lessons.tabStatus.pending')} />
                        <Tab icon={<span className="fa fa-check"></span>} value={"Confirmada"} label={t('lessons.tabStatus.confirmed')} />
                        <Tab icon={<span className="fa fa-clock"></span>} value={"Activa"} label={t('lessons.tabStatus.active')} />
                        <Tab icon={<span className="fa fa-flag"></span>} value={"Finalizada"} label={t('lessons.tabStatus.finished')} />
                        <Tab icon={<span className="fa fa-ban"></span>} value={"Cancelada"} label={t('lessons.tabStatus.canceled')} />

          		        </Tabs>
        		        </Paper>
        	        </Grid>
        	        <Grid item className={classes.grid} xs={12} sm={12} md={12}>
        		          <SearcherInput eventChange={this.searchRows} textToSearch={textToSearch} placeholder={t('lessons.search.placeholder')} />
                      <Datagrid data={data} page={currentPage} totalRows={totalRows} rowsPerPage={rowsPerPage} columns={this.translateColumns(t, columnsToShow)} eventOnChange={this.handleChangePage} />
        	        </Grid>
        	      </Grid>
                <AlertMessage open={openAlertMessage} variant={alertClassName} message={alertMessage} eventClose={this.closeMessage} />
                <AlertDelete open={openAlert} eventClose={this.closeAlert} eventOk={this.finish} title={t('lessons.modalDelete.title')} content=' ' />
                <GenericModal open={openModal} title={t('lessons.modalDetail.title')} content={<Detail id={idDetail} />} eventClose={this.closeModal} />

               </React.Fragment>
               )
            }
          }
        </Translation>

      </div>

    );

  }

}

export default withStyles(styles, { withTheme: true })(GroupLessons);
