import {observable, configure} from "mobx";

configure({enforceActions: 'observed'})

// , decorate, action, computed
/*class userData {
  
  menu = false;
  closeSession = false;

  fillMenu(items) {
    this.menu = items;
  }

  setClose(val) {
    this.closeSession = val;
  }

  get cMenu() {
    return this.menu;
  }

  get cSession() {
    return this.closeSession;
  }

}

decorate(userData, {
  menu: observable,
  closeSession: observable,
  fillMenu: action,
  setClose: action,
  cMenu: computed,
  cSession: computed
});*/

var userData = observable({
    closeSession: false
});

export default userData;