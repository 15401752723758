import React from 'react';
// Material UI
import { Box, Button } from '@material-ui/core';
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// handle of languages
import { Translation } from 'react-i18next';

const Modal = ({ status=false, title='', color, handleStatus }) => {
     
    return <Translation>
    	{ 
            (t, { i18n }) => <Box className={ status?"modal-download show":"modal-download"} position="absolute" display="flex" justifyContent="center" zIndex="modal" width={1} height={1}>
                <Box className="colorBlur" position="fixed" left={0} right={0} top={0} bottom={0} bgcolor="rgba(0,0,0,0.7)" onClick={ () => handleStatus(false) }></Box>
                <Box className="content-modal" width="90%" maxWidth={360} position="absolute" zIndex="modal" bgcolor="#fff">
                    {/* ============== Title ============= */}
                    <Box p={{ xs : 3, sm : 6 }} className="title" bgcolor={ color }>
                        <Box component="h3" fontWeight={500} fontSize={{ xs : 18, sm : 23 }} pr={4} m={0}>{title===''?title:t(title)}</Box>
                        <Box component="p" fontWeight={300} fontSize={16} pr={4} m={0}>{t('promotion.modal.subtitle')}</Box>
                        <Box fontSize="24px" component="i" className="btn-close icon-cross2" onClick={ () => handleStatus(false) }></Box>
                    </Box>
                    {/* ============== Body ============= */}
                    <Box className="body" pl={{ xs : 3, sm : 6 }}
                                          pr={{ xs : 3, sm : 6 }} 
                                          pt={{ xs : 5, sm : 10 }}
                                          pb={{ xs : 5, sm : 10 }} textAlign="center">
                        <Box fontSize={110} mt={3} mb={3} component="i" color="#72BD00" className="icon-status icon-checkmark-circle"></Box>
                	    <Box fontSize={{ xs: 14, sm : 16}} mb={10} fontWeight="500" component="p">{t('promotion.modal.msg')}</Box>
                	    <Button className="btn-acept" variant="contained" color="primary" type="submit" onClick={ () => handleStatus(false) }>{t('promotion.modal.btn')}</Button>
                    </Box>
                </Box>
            </Box>
        }
    </Translation>

}

const mapStateToProps = state => ({
    color: state.global.color
})

export default withRouter(connect(mapStateToProps)(Modal));