import React from 'react';
// Material UI
import { Select, MenuItem, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// Styles
import {styles} from "../../../assets/jss/modules/mainStyles";
const BlockTime = ({classes,blockTime,laxtS,nextS,times,day,index,deleteSchedule, changeSchedule, sameCampus}) => {

   return <Grid container style={{position:"relative"}}>
                   <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                       <Select
                           value={blockTime.from}
                           onChange={e => changeSchedule(e,laxtS) }
                           style={{margin: "15px"}}
                           inputProps={{
                               name: (day+'-'+index+'-from')
                           }}
                           disabled={sameCampus}
                       >
                           {times.map((v, i) => {
                               const time = parseInt(v.time.replace(/:/g, ''));
                               const hourFrom = parseInt(blockTime.from.replace(/:/g,''));
                               const hourTo = parseInt(blockTime.to.replace(/:/g,''));

                               if (!v.full && time < 2300 || time < hourTo && time > laxtS)
                                   return <MenuItem key={i} value={v.time}><em>{v.formatedTime}</em></MenuItem>
                               else
                                   return <MenuItem className="disabled" key={i} value={v.time}><em>{v.formatedTime}</em></MenuItem>;
                           })}
                       </Select>
                   </Grid>
                   <Grid item className={classes.grid} xs={12} sm={12} md={6}>
                       <Select
                           value={blockTime.to}
                           onChange={e => changeSchedule(e,laxtS) }
                           style={{margin: "15px"}}
                           inputProps={{
                               name: (day+'-'+index+'-to')
                           }}
                           disabled={sameCampus}
                       >
                           {times.map((v, i) => {
                               const time = parseInt(v.time.replace(/:/g, ''));
                               const hourFrom = parseInt(blockTime.from.replace(/:/g,''));
                               const hourTo = parseInt(blockTime.to.replace(/:/g,''));

                               if (time > hourFrom && time <= nextS)
                                   return <MenuItem key={i} value={v.time}><em>{v.formatedTime}</em></MenuItem>
                               else
                                   return <MenuItem className="disabled" key={i} value={v.time}><em>{v.formatedTime}</em></MenuItem>;
                           })}
                       </Select>
                   </Grid>
                   {!sameCampus &&
                   <Button color="default" size="small" id={index} onClick={() => deleteSchedule(index)} style={{padding:"3px 15px", minWidth: 0, width:20, height:20, position: "absolute", top: 0, right: 0, backgroundColor: "gainsboro", borderRadius: 10}}>
                      <span className="fa fa-times" id={index}></span>
                   </Button>}
               </Grid>
}
export default withStyles(styles, { withTheme: true })(BlockTime);